// @flow

import { gql } from '@apollo/client';

export const GET_BOOKINGS = gql`
  query GetBookings(
    $shortId: String = null
    $startDateFrom: DateTime!
    $startDateTo: DateTime!
    $zoneCode: String = null
    $traveler: String = null
    $businessFields: [BusinessFieldInput] = []
    $type: String = null
    $page: Int = 1
    $perPage: Int = 10
  ) {
    me {
      businessPartner {
        code
        bookings(
          startDateFrom: $startDateFrom
          startDateTo: $startDateTo
          zoneCode: $zoneCode
          traveler: $traveler
          businessFields: $businessFields
          shortId: $shortId
          type: $type
          page: $page
          perPage: $perPage
        ) {
          _metadata {
            page
            perPage
            totalResults
            totalPages
          }
          results {
            id
            shortId
            startAt
            endAt
            isRideInSigned
            isRideOutSigned
            isCancelled
            isRefunded
            refundAmount
            hasCancellationInsurance
            fromSpot {
              id
              name
            }
            contact {
              id
              lastName
              firstName
            }
            returnUrl
            businessFields {
              name
              code
              value
            }
            summaryPrices {
              totalPrice
            }
          }
        }
        utilsBusinessFields(isListable: true) {
          name
          code
        }
      }
    }
  }
`;
