// @flow

import { connect } from 'react-redux';
import { AlertsStateActions } from 'Reducers';

const mapStateToProps = state => ({
  alerts: state.alerts,
});

const mapDispatchToProps = dispatch => ({
  showAlert: (alertName, title, message) =>
    dispatch(AlertsStateActions.showAlert(alertName, title, message)),
  hideAlert: alertName => dispatch(AlertsStateActions.hideAlert(alertName)),
});

export default (whitelist: ?('values' | 'dispatch') = undefined) =>
  connect(
    whitelist === 'dispatch' ? null : mapStateToProps,
    whitelist === 'values' ? null : mapDispatchToProps,
  );
