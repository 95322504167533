/* eslint-disable import/no-duplicates */
// @flow

import * as React from 'react';
import { useEffect, useReducer } from 'react';
import LocalForage from 'localforage';
import Immutable from 'seamless-immutable';
import { PurchaseCanalSteps } from 'Config';

const InitialState: AppSettingsValueStateType = {
  menuVisible: true,
  selectedPurchaseCanalStep: PurchaseCanalSteps.placeAndDates,
  purchaseCanalHistory: {
    placeAndDates: false,
    options: false,
    travelerInformation: false,
    payment: false,
  },
};

// $FlowFixMe
const context = React.createContext(InitialState);

const reducer = (currState: Immutable, newState: Immutable) => {
  const state = Immutable.merge(currState, newState);
  LocalForage.setItem('appSettings', Immutable.asMutable(state));

  return state;
};

const loadSettings = update => {
  LocalForage.getItem('appSettings').then(appSettings => {
    if (!appSettings) {
      LocalForage.setItem('appSettings', InitialState);
    }

    update(appSettings || InitialState);
  });
};

export const AppSettingsProvider = ({ children }: { children: React.Node }) => {
  const [current, update] = useReducer(reducer, Immutable(InitialState));

  useEffect(() => loadSettings(update), []);

  const methods = {
    toggleMenu: () => update({ menuVisible: !current.menuVisible }),
    clearPurchaseCanalHistory: () =>
      update({ purchaseCanalHistory: InitialState.purchaseCanalHistory }),
    selectPurchaseCanalStep: selectedPurchaseCanalStep => {
      const { purchaseCanalHistory } = current;
      const stepKey = Object.keys(PurchaseCanalSteps).find(
        key => PurchaseCanalSteps[key] === selectedPurchaseCanalStep,
      );

      update({
        selectedPurchaseCanalStep,
        purchaseCanalHistory: {
          ...purchaseCanalHistory,
          [stepKey]: true,
        },
      });

      return selectedPurchaseCanalStep;
    },
  };

  return <context.Provider value={{ ...current, ...methods, update }}>{children}</context.Provider>;
};

export const withAppSettings = (Component: React.ComponentType<any>) => (props: {}) =>
  (
    <context.Consumer>
      {appSettings => <Component {...props} appSettings={appSettings} />}
    </context.Consumer>
  );

export default context;
