// @flow
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap';

type BookingRecapCardPropsType = {
  children: React.Node,
  editable: boolean,
  selected: boolean,
  onSelect: (?UpdateBookingCardNameType) => void,
  title: string,
};

const BookingRecapCard = ({
  children,
  editable,
  selected,
  onSelect,
  title,
}: BookingRecapCardPropsType) => (
  <Card className="mb-3">
    <Card.Body className="d-flex flex-column align-items-center">
      <Row className="justify-content-between">
        <Card.Title className="text-center mb-4 text-nowrap">{title}</Card.Title>
        {editable && !selected && (
          <Col>
            <FontAwesomeIcon icon="pen" className="cursor-pointer" onClick={onSelect} />
          </Col>
        )}
      </Row>
      {children}
    </Card.Body>
  </Card>
);

export default BookingRecapCard;
