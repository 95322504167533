// @flow

import { gql } from '@apollo/client';

export const CREATE_CAR = gql`
  mutation CreateCar(
    $utilsCar: ID
    $color: String
    $numberPlate: String
    $userId: ID
    $hasRoofBox: Boolean
  ) {
    createCar(
      utilsCarId: $utilsCar
      color: $color
      numberPlate: $numberPlate
      userId: $userId
      hasRoofBox: $hasRoofBox
    ) {
      id
      utilsCar {
        id
        brand
        model
        motorization
        variants
      }
      color
      numberPlate
      hasRoofBox
    }
  }
`;
