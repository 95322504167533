// @flow

import { gql } from '@apollo/client';

export const UPDATE_BOOKING = gql`
  mutation UpdateBooking(
    $id: ID!
    $endAt: DateTime
    $startAt: DateTime
    $fromSpot: String
    $toSpot: String
    $stripePaymentMethodId: String = null
    $paymentIntentId: String = null
    $cardId: String = null
    $persistModification: Boolean
    $amount: String
  ) {
    updateBooking(
      id: $id
      endAt: $endAt
      startAt: $startAt
      fromSpot: $fromSpot
      toSpot: $toSpot
      stripePaymentMethodId: $stripePaymentMethodId
      paymentIntentId: $paymentIntentId
      persistModification: $persistModification
      cardId: $cardId
      amount: $amount
    ) {
      id
      fromSpot {
        id
        code
        name
      }
      toSpot {
        id
        code
        name
      }
      startAt
      endAt
      summaryPrices {
        parkingValet
        totalPrice
        routingFee
        yieldsFee
        parkingFee
        administrativeFee
        countRoutingFee
        countParkingFee
        countAdministrativeFee
        manualAdjustment
      }
    }
  }
`;

export const GET_BOOKING = gql`
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      latestBookingUpdate {
        id
        parkingFee
        routingFee
        yieldsFee
        administrativeFee
      }
      summaryPrices {
        totalPrice
      }
    }
  }
`;
