// @flow
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Loader, StatCard } from 'Components';
import { translate } from 'Hoc';
import Styles from './Stylesheets/FlashReportCards.module.css';

type FlashReportCardsPropsType = {
  flashReport: FlashReportType,
  t: string => string,
  className: string,
  loading: boolean,
};

const CardContent = ({ value, loading }: { value: string | number, loading: boolean }) =>
  loading ? <Loader /> : value;

const FlashReportCards = ({ flashReport, t, className, loading }: FlashReportCardsPropsType) => (
  <Row className={['row-eq-height justify-content-between', className].join(' ')}>
    <Col md={4} className="pl-0 pr-1">
      <StatCard className="d-flex flex-column justify-content-between h-100" label={t('bookings')}>
        <Row className={['text--purple font-weight-bold', Styles.statValue].join(' ')} noGutters>
          <CardContent loading={loading} value={flashReport.countBookings} />
        </Row>
      </StatCard>
    </Col>
    <Col md={4} className="px-1">
      <StatCard
        className="d-flex flex-column justify-content-between h-100"
        label={t('parkingDays')}
      >
        <Row className={['text--cyan font-weight-bold', Styles.statValue].join(' ')} noGutters>
          <CardContent loading={loading} value={flashReport.countParkingDays} />
        </Row>
      </StatCard>
    </Col>
    <Col md={4} className="pr-0 pl-1">
      <StatCard
        className="d-flex flex-column justify-content-between  h-100"
        label={t('parkingDaysAverage')}
      >
        <Row className={['text--yellow font-weight-bold', Styles.statValue].join(' ')} noGutters>
          <CardContent loading={loading} value={flashReport.averageParkingDays} />
        </Row>
      </StatCard>
    </Col>
  </Row>
);

export default translate('flash_report_cards')(FlashReportCards);
