// @flow

import { gql } from '@apollo/client';

export const UPDATE_BOOKING = gql`
  mutation UpdateBooking(
    $id: ID!
    $travelingNumberFrom: String = null
    $travelingNumberTo: String = null
    $persistModification: Boolean = true
  ) {
    updateBooking(
      id: $id
      travelingNumberFrom: $travelingNumberFrom
      travelingNumberTo: $travelingNumberTo
      persistModification: $persistModification
    ) {
      id
      travelingNumberFrom
      travelingNumberTo
    }
  }
`;
