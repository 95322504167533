// @flow
import React, { type Element } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Navbar, Sidemenu } from 'Containers';
import { withAppSettings } from 'Context/AppSettings';
import Styles from './Stylesheets/AppLayout.module.css';

type AppLayoutPropsType = {
  children: Element<*>,
  appSettings: AppSettingsStateType,
};

const AppLayout = ({ children, appSettings: { menuVisible } }: AppLayoutPropsType) => (
  <Container fluid className={['m-0', 'p-0', Styles.rootContainer]} as={Col}>
    <Navbar />
    <Container as={Row} className="bg-light-neutral m-0 h-100 p-0" fluid>
      <Sidemenu
        visible={menuVisible}
        className={['bg-primary px-0', Styles.pageContainer].join(' ')}
      />
      <Col
        className={[Styles.pageContainer, menuVisible ? Styles.withMenuVisible : undefined].join(
          ' ',
        )}
      >
        {children}
      </Col>
    </Container>
  </Container>
);

export default withAppSettings(AppLayout);
