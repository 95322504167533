// @flow

import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const Modals = {
  createCollaborator: 'createCollaborator',
  disableCollaborator: 'disableCollaborator',
  createCar: 'createCar',
  cancelBooking: 'cancelBooking',
  missingPassword: 'missingPassword',
  freeUpdatePlaceAndDates: 'freeUpdatePlaceAndDates',
  paidUpdatePlaceAndDates: 'paidUpdatePlaceAndDates',
  updateContactPhoneNumber: 'updateContactPhoneNumber',
  notSameLoginEmail: 'notSameLoginEmail',
};

const { Types, Creators } = createActions({
  showModal: ['modalName'],
  hideModal: ['modalName'],
});

export default Creators;

const INITIAL_STATE: FormsStateType = Immutable({
  [Modals.createCollaborator]: false,
  [Modals.createCar]: false,
  [Modals.cancelBooking]: false,
  [Modals.disableCollaborator]: false,
  [Modals.missingPassword]: false,
  [Modals.freeUpdatePlaceAndDates]: false,
  [Modals.paidUpdatePlaceAndDates]: false,
  [Modals.updateContactPhoneNumber]: false,
  [Modals.notSameLoginEmail]: false,
});

const showModal = (state, { modalName }: { modalName: string }) =>
  state.merge({
    [modalName]: true,
  });

const hideModal = (state, { modalName }: { modalName: string }) =>
  state.merge({
    [modalName]: false,
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOW_MODAL]: showModal,
  [Types.HIDE_MODAL]: hideModal,
});
