// @flow

import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { BookingsFilters } from 'Config';

export const Forms = {
  login: 'login',
  pricing: 'pricing',
  bookingsFilter: 'bookingsFilter',
  collaboratorsFilter: 'collaboratorsFilter',
  createCollaborator: 'createCollaborator',
  updateCollaborator: 'updateCollaborator',
  purchaseCanal: 'purchaseCanal',
  createCar: 'createCar',
  cancelBooking: 'cancelBooking',
  disableCollaborator: 'disableCollaborator',
  payment: 'payment',
  updateBooking: 'updateBooking',
  flashReport: 'flashReport',
  updateContactPhoneNumber: 'updateContactPhoneNumber',
};

const { Types, Creators } = createActions({
  setFormValue: ['formName', 'fieldName', 'value'],
  setFormError: ['formName', 'fieldName', 'error'],
  setFormValues: ['formName', 'values'],
  setFormErrors: ['formName', 'errors'],
  clearForm: ['formName'],
  clearFormErrors: ['formName'],
  clearFormValues: ['formName'],
  clearForms: [],
});

export const FormsTypes = Types;
export default Creators;

const INITIAL_STATE: FormsStateType = Immutable({
  [Forms.login]: {
    values: {
      email: undefined,
      password: undefined,
    },
    errors: {
      email: undefined,
      password: undefined,
    },
  },
  [Forms.pricing]: {
    values: {
      fromSpot: undefined,
      toSpot: undefined,
      startDate: undefined,
      endDate: undefined,
      startTime: undefined,
      endTime: undefined,
    },
    errors: {
      fromSpot: undefined,
      toSpot: undefined,
      startDate: undefined,
      endDate: undefined,
      startTime: undefined,
      endTime: undefined,
    },
  },
  [Forms.bookingsFilter]: {
    values: {
      page: 1,
      perPage: 10,
      type: BookingsFilters.current,
      shortId: undefined,
      selectedMonth: undefined,
      selectedZone: undefined,
      selectedCollaborator: undefined,
      businessFields: {},
    },
    errors: {
      shortId: undefined,
      selectedMonth: undefined,
      selectedZone: undefined,
      selectedCollaborator: undefined,
      businessFields: undefined,
    },
  },
  [Forms.collaboratorsFilter]: {
    values: {
      searchText: undefined,
      page: 1,
      perPage: 10,
    },
    errors: {
      searchText: undefined,
    },
  },
  [Forms.createCollaborator]: {
    values: {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phoneNumber: undefined,
      businessFields: {},
      groupName: 'collaborator',
    },
    errors: {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phoneNumber: undefined,
      businessFields: {},
      groupName: undefined,
    },
  },
  [Forms.updateCollaborator]: {
    values: {
      id: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phoneNumber: undefined,
      groupName: undefined,
      businessFields: undefined,
      cars: undefined,
    },
    errors: {
      id: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phoneNumber: undefined,
      groupName: undefined,
      businessFields: undefined,
      cars: undefined,
    },
  },
  [Forms.purchaseCanal]: {
    values: {
      bookingId: undefined,
      services: [],
      carId: undefined,
      carBrand: undefined,
      carModel: undefined,
      carColor: undefined,
      carNumberPlate: undefined,
      contactId: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phoneNumber: undefined,
      travelingNumberFrom: undefined,
      travelingNumberTo: undefined,
      businessFields: undefined,
      hidePrice: undefined,
      userId: undefined,
    },
    errors: {
      services: undefined,
      carId: undefined,
      carBrand: undefined,
      carModel: undefined,
      carColor: undefined,
      carNumberPlate: undefined,
      contactId: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phoneNumber: undefined,
      travelingNumberFrom: undefined,
      travelingNumberTo: undefined,
      businessFields: {},
      userId: undefined,
    },
  },
  [Forms.payment]: {
    values: {
      discountCode: undefined,
      selectedPaymentMethodId: undefined,
    },
    errors: {
      discountCode: undefined,
      cardNumber: undefined,
      cardExpirationDate: undefined,
      cardSecurityCode: undefined,
      selectedPaymentMethodId: undefined,
    },
  },
  [Forms.createCar]: {
    values: {
      carUtilsCardId: undefined,
      carColor: undefined,
      carNumberPlate: undefined,
      carHasRoofBox: undefined,
    },
    errors: {
      carUtilsCardId: undefined,
      carColor: undefined,
      carNumberPlate: undefined,
    },
  },
  [Forms.cancelBooking]: {
    values: {
      id: undefined,
    },
    errors: {
      id: undefined,
    },
  },
  [Forms.disableCollaborator]: {
    values: {
      id: undefined,
    },
    errors: {
      id: undefined,
    },
  },
  [Forms.updateBooking]: {
    values: {
      id: undefined,
      carId: undefined,
      carModel: undefined,
      carBrand: undefined,
      carColor: undefined,
      carHasRoofBox: undefined,
      carNumberPlate: undefined,
      travelingNumberFrom: undefined,
      travelingNumberTo: undefined,
      fromSpot: undefined,
      toSpot: undefined,
      startDate: undefined,
      endDate: undefined,
      startTime: undefined,
      endTime: undefined,
      contactId: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phoneNumber: undefined,
    },
    errors: {
      carId: undefined,
      carModel: undefined,
      carBrand: undefined,
      carColor: undefined,
      carNumberPlate: undefined,
      travelingNumberFrom: undefined,
      travelingNumberTo: undefined,
      fromSpot: undefined,
      toSpot: undefined,
      startDate: undefined,
      endDate: undefined,
      startTime: undefined,
      endTime: undefined,
      contactId: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phoneNumber: undefined,
    },
  },
  [Forms.flashReport]: {
    values: {
      date: undefined,
    },
    errors: {},
  },
  [Forms.updateContactPhoneNumber]: {
    values: {
      phoneNumber: undefined,
    },
    errors: {
      phoneNumber: undefined,
    },
  },
});

const setFormValue = (state, { formName, fieldName, value }) =>
  state.merge(
    {
      [formName]: {
        values: {
          [fieldName]: value,
        },
      },
    },
    { deep: true },
  );
const setFormError = (state, { formName, fieldName, error }) =>
  state.merge(
    {
      [formName]: {
        errors: {
          [fieldName]: error,
        },
      },
    },
    { deep: true },
  );
const setFormValues = (state, { formName, values }) =>
  state.merge(
    {
      [formName]: { values },
    },
    { deep: true },
  );
const setFormErrors = (state, { formName, errors }) =>
  state.merge(
    {
      [formName]: { errors },
    },
    { deep: true },
  );
const clearForm = (state, { formName }) =>
  state.merge({
    ...state,
    [formName]: INITIAL_STATE[formName],
  });
const clearFormErrors = (state, { formName }) =>
  state.merge(
    {
      [formName]: { errors: INITIAL_STATE[formName].errors },
    },
    { deep: true },
  );
const clearFormValues = (state, { formName }) =>
  state.merge(
    {
      [formName]: { values: INITIAL_STATE[formName].values },
    },
    { deep: true },
  );
const clearForms = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_FORM_VALUE]: setFormValue,
  [Types.SET_FORM_ERROR]: setFormError,
  [Types.SET_FORM_VALUES]: setFormValues,
  [Types.SET_FORM_ERRORS]: setFormErrors,
  [Types.CLEAR_FORM]: clearForm,
  [Types.CLEAR_FORM_VALUES]: clearFormValues,
  [Types.CLEAR_FORM_ERRORS]: clearFormErrors,
  [Types.CLEAR_FORMS]: clearForms,
});
