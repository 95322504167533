// @flow
import { connect } from 'react-redux';
import { FormsStateActions } from 'Reducers';
import { FormsValidation, ucfirst } from 'Services';

const getFormStatePropsFromName = (formName: string) => state => ({
  [`${formName}Form`]: state.forms[formName],
});

const getFormDispatchPropsFromName = (formName: string) => dispatch => ({
  [`set${ucfirst(formName)}FormValue`]: (field, value) =>
    dispatch(FormsStateActions.setFormValue(formName, field, value)),
  [`set${ucfirst(formName)}FormError`]: (field, error) =>
    dispatch(FormsStateActions.setFormError(formName, field, error)),
  [`set${ucfirst(formName)}FormValues`]: values =>
    dispatch(FormsStateActions.setFormValues(formName, values)),
  [`set${ucfirst(formName)}FormErrors`]: errors =>
    dispatch(FormsStateActions.setFormErrors(formName, errors)),
  [`clear${ucfirst(formName)}FormValues`]: () =>
    dispatch(FormsStateActions.clearFormValues(formName)),
  [`clear${ucfirst(formName)}FormErrors`]: () =>
    dispatch(FormsStateActions.clearFormErrors(formName)),
  [`clear${ucfirst(formName)}Form`]: () => dispatch(FormsStateActions.clearForm(formName)),
  [`is${ucfirst(formName)}FormValid`]: async (values, validationStep) => {
    const errors = await FormsValidation(formName, values, validationStep);
    dispatch(FormsStateActions.setFormErrors(formName, errors));

    return Object.keys(errors).every(key => typeof errors[key] === 'undefined');
  },
});

export default (formName: string, whitelist: ?('values' | 'dispatch') = undefined) =>
  connect(
    whitelist === 'dispatch' ? null : getFormStatePropsFromName(formName),
    whitelist === 'values' ? null : getFormDispatchPropsFromName(formName),
  );
