// @flow

type PricePropsType = {
  value: number,
  currency: string,
};

const Price = ({ value, currency }: PricePropsType) =>
  `${typeof value === 'number' ? value.toFixed(2) : Number(value).toFixed(2)}\xa0${currency}`;

Price.defaultProps = {
  currency: '€',
};

export default Price;
