// @flow

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Alert, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { compose } from 'redux';
import { MutationButton } from 'Components';
import { BookingsFilters } from 'Config';
import { BookingsListContainer, BookingsScreenFilters, CancelBookingModal } from 'Containers';
import {
  protect,
  Roles,
  translate,
  withAuth,
  withReduxAlerts,
  withReduxForm,
  withReduxModals,
} from 'Hoc';
import { Alerts, Forms, Modals } from 'Reducers';
import { EXPORT_BOOKINGS } from './Gql/BookingsScreen';
import Styles from './Stylesheets/BookingsScreen.module.scss';

type BookingsScreenPropsType = {
  t: string => string,
  values: { [string]: string },
  errors: { [string]: string },
  modals: { [string]: boolean },
  alerts: AlertsStateType,
  hideModal: () => void,
  hideAlert: () => void,
  setBookingsFilterFormValues: ({ type: string, page: number }) => void,
  bookingsFilterForm: BookingsFilterType,
};

type BookingsScreenStateType = {
  mutationError: ?ErrorType,
};

class BookingsScreen extends Component<BookingsScreenPropsType, BookingsScreenStateType> {
  hideCancelBookingModal: () => void;

  hideCancelBookingSucceedAlert: () => void;

  state = {
    mutationError: undefined,
  };

  constructor(props) {
    super(props);

    this.hideCancelBookingModal = props.hideModal.bind(this, Modals.cancelBooking);
    this.hideCancelBookingSucceedAlert = props.hideAlert.bind(this, Alerts.cancelBookingSucceed);
  }

  componentDidCatch(mutationError: ErrorType) {
    this.setState({ mutationError });
  }

  handleCloseMutationErrorAlert = () => {
    this.setState({ mutationError: undefined });
  };

  handleChangeTab = key => {
    const { setBookingsFilterFormValues } = this.props;

    setBookingsFilterFormValues({
      type: key,
      page: 1,
    });
  };

  handleExportBookingsSubmit = async exportBookings => {
    const {
      bookingsFilterForm: { values: bookingFiltersFormValues },
    } = this.props;

    exportBookings({
      variables: {
        type: 'csv',
        bookingsFilters: {
          shortId: bookingFiltersFormValues.shortId,
          startDateFrom: moment(bookingFiltersFormValues.selectedMonth).format(
            'YYYY-MM-DD HH:mm:ss',
          ),
          startDateTo: moment(bookingFiltersFormValues.selectedMonth)
            .endOf('month')
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss'),
          zoneCode: bookingFiltersFormValues.selectedZone || '',
          traveler: bookingFiltersFormValues.selectedCollaborator || '',
          businessFields:
            Object.entries(bookingFiltersFormValues.businessFields).map(entry => ({
              code: entry[0],
              value: entry[1],
            })) || [],
        },
      },
    });
  };

  handleExportBookingsCompleted = ({ exportBookings: { url } }) => {
    if (url) {
      const decodedUrl = decodeURIComponent(url);
      const a = document.createElement('a');

      if (document.body !== null) {
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = decodedUrl;
        a.click();

        window.URL.revokeObjectURL(decodedUrl);
        // $FlowFixMe
        document.body.removeChild(a);
      }
    }
  };

  renderTabs = () => {
    const {
      t,
      modals: { [Modals.cancelBooking]: cancelBookingModalVisible },
      alerts: { [Alerts.cancelBookingSucceed]: cancelBookingSucceedAlert },
      bookingsFilterForm: { values: bookingFiltersFormValues },
    } = this.props;
    const { mutationError } = this.state;

    return (
      <Container fluid>
        <Alert
          dismissible
          variant="danger"
          show={typeof mutationError !== 'undefined'}
          onClose={this.handleCloseMutationErrorAlert}
        >
          <Alert.Heading>{t('errorOccurred')}</Alert.Heading>
          <p>{mutationError ? mutationError.message : ''}</p>
        </Alert>
        <Alert
          dismissible
          variant="success"
          show={cancelBookingSucceedAlert.visible}
          onClose={this.hideCancelBookingSucceedAlert}
        >
          <Alert.Heading>{cancelBookingSucceedAlert.title}</Alert.Heading>
          <p>{cancelBookingSucceedAlert.message}</p>
        </Alert>
        <BookingsScreenFilters onBookingsFilterChange={this.handleChangeTab} />
        <Row className="d-flex mt-3 d-block justify-content-end no-gutters">
          <MutationButton
            className="d-block"
            mutation={EXPORT_BOOKINGS}
            onClick={this.handleExportBookingsSubmit}
            onCompleted={this.handleExportBookingsCompleted}
          >
            <FontAwesomeIcon icon="file-download" className="mr-1" />
            {t('downloadCsvLabel')}
          </MutationButton>
        </Row>
        <Tabs
          id="booking-tabs"
          defaultActiveKey={BookingsFilters.current}
          activeKey={bookingFiltersFormValues.type}
          onSelect={this.handleChangeTab}
          className={['mt-3 border-bottom-0 rounded-top', Styles.tabs].join(' ')}
          fill
          unmountOnExit
        >
          <Tab
            title={t('currentBookingsTitle')}
            eventKey={BookingsFilters.current}
            tabClassName="border border-neutral text-primary"
          >
            <BookingsListContainer filter={bookingFiltersFormValues.type} />
          </Tab>
          <Tab
            title={t('incomingBookingsTitle')}
            eventKey={BookingsFilters.incoming}
            tabClassName="border border-neutral text-primary"
          >
            <BookingsListContainer filter={bookingFiltersFormValues.type} />
          </Tab>
          <Tab
            title={t('pastBookingsTitle')}
            eventKey={BookingsFilters.past}
            tabClassName="border border-neutral text-primary"
          >
            <BookingsListContainer filter={bookingFiltersFormValues.type} />
          </Tab>
          <Tab
            title={t('cancelBookingsTitle')}
            eventKey={BookingsFilters.cancel}
            tabClassName="border border-neutral text-primary"
          >
            <BookingsListContainer filter={bookingFiltersFormValues.type} />
          </Tab>
        </Tabs>
        <CancelBookingModal
          show={cancelBookingModalVisible}
          onHide={this.hideCancelBookingModal}
          onExit={this.hideCancelBookingModal}
        />
      </Container>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <Row className="d-flex justify-content-center">
        <Col xs={12} className="d-flex justify-content-center mt-4 mb-2">
          <h2 className="font-weight-bold">{t('bookingScreenTitle')}</h2>
        </Col>
        <Col xs={12} as={this.renderTabs} />
      </Row>
    );
  }
}

export default compose(
  translate(['booking_screen', 'gql_errors']),
  withAuth(true),
  protect(Roles.BusinessBookingList),
  withReduxForm(Forms.bookingsFilter),
  withReduxModals(),
  withReduxAlerts(),
)(BookingsScreen);
