// @flow
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import Styles from './Stylesheets/RatingStars.module.css';

type RatingStarsPropsType = {
  value: number,
  starSize: string,
};

const RatingStars = ({ value, starSize, ...rest }: RatingStarsPropsType) => (
  <Col {...rest}>
    <Row className="position-relative d-inline-block text-center" noGutters>
      <FontAwesomeIcon size={starSize} icon={['far', 'star']} />
      <FontAwesomeIcon size={starSize} icon={['far', 'star']} />
      <FontAwesomeIcon size={starSize} icon={['far', 'star']} />
      <FontAwesomeIcon size={starSize} icon={['far', 'star']} />
      <FontAwesomeIcon size={starSize} icon={['far', 'star']} />
      <Row
        className={['position-absolute', 'text-nowrap', 'd-block', Styles.markStars].join(' ')}
        noGutters
        style={{ width: `${value * 20}%` }}
      >
        <FontAwesomeIcon size={starSize} icon={['fas', 'star']} />
        <FontAwesomeIcon size={starSize} icon={['fas', 'star']} />
        <FontAwesomeIcon size={starSize} icon={['fas', 'star']} />
        <FontAwesomeIcon size={starSize} icon={['fas', 'star']} />
        <FontAwesomeIcon size={starSize} icon={['fas', 'star']} />
      </Row>
    </Row>
  </Col>
);

RatingStars.defaultProps = {
  starSize: '2x',
};

export default RatingStars;
