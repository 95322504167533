// @flow
import React, { Component, Fragment } from 'react';
import { Col } from 'react-bootstrap';
import { compose } from 'redux';
import { PaymentMethodsList } from 'Components';
import { BookingUpdatePricingSummary, PaymentMethodSwitchCard } from 'Containers';
import { translate, withReduxForm } from 'Hoc';
import { Forms } from 'Reducers';
import { apolloClient } from 'Services';
import { GET_PAYMENT_METHODS } from './Gql/PaymentMethodSwitchCard';

type PaidUpdatePlaceAndDatesModalBodyContentPropsType = {
  t: string => string,
  className: string,
  updateBookingForm: UpdateBookingType,
  paymentForm: PaymentType,
  setPaymentFormValue: (string, string) => void,
  status: 'confirmation' | 'payment',
};

type PaidUpdatePlaceAndDatesModalBodyContentStateType = {
  paymentMethodChoice: SwitchCardChoiceType,
};

class PaidUpdatePlaceAndDatesModalBodyContent extends Component<
  PaidUpdatePlaceAndDatesModalBodyContentPropsType,
  PaidUpdatePlaceAndDatesModalBodyContentStateType,
> {
  constructor() {
    super();
    let paymentMethods;

    try {
      const data = apolloClient.cache.readQuery({
        query: GET_PAYMENT_METHODS,
      });
      ({
        me: {
          businessPartner: { paymentMethods },
        },
      } = data);
    } catch {}

    this.state = {
      paymentMethodChoice: paymentMethods && paymentMethods.length === 0 ? 'new' : 'existing',
    };
  }

  handleChangePaymentMethodChoice = paymentMethodChoice => {
    this.setState({ paymentMethodChoice });
  };

  handleClickPaymentMethod = paymentMethod => {
    const { setPaymentFormValue } = this.props;

    setPaymentFormValue('selectedPaymentMethodId', paymentMethod.id);
  };

  renderPaymentSwitchCardFirstComponent = ({ paymentMethods }) => {
    const {
      paymentForm: {
        values: { selectedPaymentMethodId },
      },
    } = this.props;

    return (
      <PaymentMethodsList
        paymentMethods={paymentMethods}
        onClick={this.handleClickPaymentMethod}
        selected={selectedPaymentMethodId}
      />
    );
  };

  renderPaymentSwitchCardRootComponent = ({ children }) => <>{children}</>;

  renderPaymentSwitchCardTitleComponent = () => null;

  render() {
    const {
      t,
      className,
      updateBookingForm: {
        values: { id: bookingId },
      },
      status,
    } = this.props;
    const { paymentMethodChoice } = this.state;

    return (
      <Col className={className}>
        <p className="px-2">{t(`${status}Description`)}</p>
        {status === 'confirmation' ? (
          <BookingUpdatePricingSummary bookingId={bookingId} />
        ) : (
          <PaymentMethodSwitchCard
            choice={paymentMethodChoice}
            onChange={this.handleChangePaymentMethodChoice}
            FirstComponent={this.renderPaymentSwitchCardFirstComponent}
            RootComponent={this.renderPaymentSwitchCardRootComponent}
            TitleComponent={this.renderPaymentSwitchCardTitleComponent}
          />
        )}
      </Col>
    );
  }
}

export default compose(
  withReduxForm(Forms.updateBooking, 'values'),
  withReduxForm(Forms.payment),
  translate('paid_update_place_dates_modal'),
)(PaidUpdatePlaceAndDatesModalBodyContent);
