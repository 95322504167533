// @flow
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar as BootstrapNavbar, Dropdown, Image, Nav, NavItem } from 'react-bootstrap';
import { compose } from 'redux';
import { BurgerButton, Link, Query } from 'Components';
import { UserMenu } from 'Containers';
import { withAppSettings } from 'Context/AppSettings';
import { translate, withAuth } from 'Hoc';
import { GET_USER } from './Gql/Navbar';
import Styles from './Stylesheets/Navbar.module.css';

type NavbarPropsType = {
  auth: AuthStateType,
  appSettings: AppSettingsStateType,
  t: string => string,
};

class Navbar extends Component<NavbarPropsType> {
  renderUserDropdown = ({ data: { me = { businessPartners: [], businessPartner: {} } } }) => {
    const { businessPartners: businessPartnersNotFiltered, businessPartner: mainBusinessPartner } =
      me;
    const businessPartners = businessPartnersNotFiltered.filter(
      businessPartner => businessPartner.code !== mainBusinessPartner.code,
    );

    businessPartners.sort(
      (l, r) =>
        +(l.name.toLowerCase() > r.name.toLowerCase()) -
        +(l.name.toLowerCase() < r.name.toLowerCase()),
    );

    return (
      <Nav className="ml-auto float-right">
        <Dropdown as={NavItem} id="userDropdown">
          <Dropdown.Toggle id="userDropdownToggle" size="sm" className="text-accent p-0">
            {mainBusinessPartner.logoUrl ? (
              <Image
                src={mainBusinessPartner.logoUrl}
                className={['bg-transparent', 'border-0', Styles.avatar].join(' ')}
                thumbnail
                fluid
              />
            ) : (
              <FontAwesomeIcon icon="user-circle" size="2x" />
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu
            alignRight
            className={['p-0 pt-1', Styles.menu].join(' ')}
            id="userDropdownMenu"
          >
            <UserMenu
              mainBusinessPartner={mainBusinessPartner}
              businessPartners={businessPartners}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    );
  };

  render() {
    const {
      auth: { isConnected },
      appSettings: { menuVisible, toggleMenu },
      t,
    } = this.props;

    return (
      <BootstrapNavbar bg="primary" fixed="top">
        <BurgerButton
          visible={isConnected}
          onClick={toggleMenu}
          active={menuVisible}
          className="mr-2"
        />
        <Link to="/dashboard" className="text-white" exact as={BootstrapNavbar.Brand}>
          {t('navbarTitle')}
        </Link>
        <BootstrapNavbar.Toggle aria-controls="sidebar-menu" />
        {isConnected && <Query query={GET_USER}>{this.renderUserDropdown}</Query>}
      </BootstrapNavbar>
    );
  }
}

export default compose(translate('navbar'), withAppSettings, withAuth())(Navbar);
