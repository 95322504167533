// @flow

import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from 'firebase/app';
import LocalForage from 'localforage';
import { Alert } from 'react-bootstrap';
import { compose } from 'redux';
import { Button, Loader, MutationButton } from 'Components';
import { SWAP_TOKEN } from 'Gql';
import { translate, withAuth } from 'Hoc';
import { ectorV4ApolloClient } from 'Services';
import { pursueLogin } from '../Services/Login';
import {
  checkedEmailKey,
  getSsoToken,
  signInWithSocialMedia,
  SocialMedia,
  ssoLogout,
  updateCacheLoginSwapToken,
} from '../Services/SSO';
import LoginForm from './LoginForm';
import Styles from './Stylesheets/LoginMethodSelector.module.css';

const State = {
  initial: 'initial',
  email: 'email',
  signInSocialMediaSuccess: 'signInSocialMediaSuccess',
};

type LoginMethodSelectorPropsType = {
  t: string => string,
  setPartnerHeaderCode: string => void,
};
type LoginMethodSelectorStateType = {
  state: $Values<typeof State>,
  mutationError: ?ErrorType,
};

class LoginMethodSelector extends React.PureComponent<
  LoginMethodSelectorPropsType,
  LoginMethodSelectorStateType,
> {
  constructor(props) {
    super(props);

    this.state = {
      state: State.initial,
      mutationError: undefined,
    };
  }

  componentDidCatch(mutationError) {
    this.setState({ mutationError });
  }

  handleCloseAlert = () => this.setState({ mutationError: undefined });

  loginWithSsoCacheUpdate = async (cache, { data }) => {
    const { t } = this.props;
    const firebaseUser = firebase && firebase.apps.length ? firebase.auth().currentUser : null;
    const hasSucceed = await updateCacheLoginSwapToken(
      cache,
      { data },
      firebaseUser ? firebaseUser.email : '',
    );

    if (!hasSucceed) {
      this.setState({
        mutationError: {
          message: t('errorImpossibleLogin'),
          translationKey: 'errorImpossibleLogin',
        },
      });
    }
  };

  loginSocialMedia = async (swapToken, socialMedia: $Values<typeof SocialMedia>) => {
    let ssoToken = null;

    await LocalForage.removeItem(checkedEmailKey);
    try {
      await signInWithSocialMedia(socialMedia);
      ssoToken = await getSsoToken();
    } catch (error) {
      console.warn('error', error);

      return;
    }

    this.setState({ state: State.signInSocialMediaSuccess });

    const response = await swapToken({
      variables: {
        ssoToken,
      },
    });

    if (response && response.data && response.data.swapToken) {
      const { setPartnerHeaderCode } = this.props;
      const partnerCode = await pursueLogin();
      await setPartnerHeaderCode(partnerCode);
    } else {
      this.setState({ state: State.initial });
      await ssoLogout();
    }
  };

  render() {
    const { t } = this.props;
    const { state, mutationError } = this.state;

    const LoginButton = ({ children, ...props }) => (
      <Button
        className={[
          'font-weight-light',
          state === State.signInSocialMediaSuccess ? 'd-none' : 'd-flex',
          'justify-content-center',
          'align-items-center',
          'w-100 mb-2',
          Styles.button,
        ].join(' ')}
        variant="outline-primary"
        {...props}
      >
        {children}
      </Button>
    );

    if (state === State.email) {
      return (
        <>
          <Button
            className={['p-0 mb-1', Styles.link].join(' ')}
            variant="link"
            onClick={e => {
              e.preventDefault();
              this.setState({ state: State.initial });
            }}
          >
            <FontAwesomeIcon icon="chevron-left" className="mr-1" />
            <u>{t('login_form:back')}</u>
          </Button>
          <LoginForm />
        </>
      );
    }

    return (
      <>
        <Alert
          dismissible
          variant="danger"
          show={typeof mutationError !== 'undefined'}
          onClose={this.handleCloseAlert}
        >
          <Alert.Heading>{t('form_errors:errorOccurred')}</Alert.Heading>
          <p>{mutationError ? mutationError.message : ''}</p>
        </Alert>
        {state === State.signInSocialMediaSuccess && (
          <div className="d-flex flex-column align-items-center">
            <div className="text-primary">{t('login_form:loadingMessage')}</div>
            <Loader color="info" />
          </div>
        )}
        <LoginButton onClick={() => this.setState({ state: State.email })}>
          <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
          {t('login_form:email')}
        </LoginButton>
        <ApolloProvider client={ectorV4ApolloClient}>
          <MutationButton
            mutation={SWAP_TOKEN}
            update={this.loginWithSsoCacheUpdate}
            className={[
              'font-weight-light',
              state === State.signInSocialMediaSuccess ? 'd-none' : 'd-flex',
              'justify-content-center',
              'align-items-center',
              'w-100 mb-2',
              Styles.button,
            ].join(' ')}
            variant="outline-primary"
            type="submit"
            onClick={swapToken => this.loginSocialMedia(swapToken, SocialMedia.google)}
            isLoading={false}
            block
          >
            <img
              src={t('login_method_selector:googleIcon')}
              alt="google-icon"
              className={['mr-1', Styles.icon].join(' ')}
            />
            {t('login_form:google')}
          </MutationButton>
        </ApolloProvider>
      </>
    );
  }
}

export default compose(
  withAuth(),
  translate(['login_form', 'login_method_selector', 'form_errors']),
)(LoginMethodSelector);
