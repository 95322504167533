// @flow

import React from 'react';
import { compose } from 'redux';
import { Query } from 'Components';
import { translate, withAuth } from 'Hoc';
import { GET_USER } from '../../Gql/UserGql';
import Styles from '../Stylesheets/Jockey.module.scss';

type RenderIframeProps = {
  data: {
    me: {
      email: string,
      firstName: string,
      lastName: string,
      businessPartner: {
        name: string,
        airtableBookingFormLink: string,
        enableJockey: boolean,
      },
    },
  },
};

const getDefaultData = data => {
  const { me } = data;
  const {
    email,
    firstName,
    lastName,
    businessPartner: { name },
  } = me;

  return {
    'prefill_Email preneur de resa': email,
    'prefill_Prenom Nom preneur de resa': `${firstName} ${lastName}`,
    'prefill_Client': name,
    'backgroundColor': 'blue',
  };
};

const getAirtableLinkData = link => {
  if (link.includes('?')) {
    const argsLink = decodeURI(link.split('?')[1]);
    const args = argsLink.split('&');

    return args
      .map(arg => arg.split('='))
      .reduce(
        (acc, cur) => ({
          ...acc,
          [cur[0]]: cur[1],
        }),
        {},
      );
  }

  return {};
};

const getPrefill = formData =>
  Object.entries(formData).reduce((acc, cur) => acc.concat(`&${cur[0]}=${String(cur[1])}`), '?');

const BookingFormScreen = () => {
  const renderForm = ({ data }: RenderIframeProps) => {
    if (data && data.me && data.me.businessPartner) {
      let formData = getDefaultData(data);
      const formLink = data.me.businessPartner.airtableBookingFormLink
        ? data.me.businessPartner.airtableBookingFormLink
            .split('?')[0]
            .replace('airtable.com/', 'airtable.com/embed/')
        : 'https://airtable.com/embed/shrI8FhMjgPNEg17w';

      if (data.me.businessPartner.enableJockey && data.me.businessPartner.airtableBookingFormLink) {
        formData = {
          ...formData,
          ...getAirtableLinkData(data.me.businessPartner.airtableBookingFormLink),
        };
      }

      const prefill = getPrefill(formData);

      return (
        <div
          className={Styles.bookingForm}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `<iframe title="Book a Jockey" class="airtable-embed" src="${formLink}${encodeURI(
              prefill,
            )}" frameborder="0" onmousewheel="" width="100%" height="100%" style="background: transparent;"></iframe>`,
          }}
        />
      );
    }

    return null;
  };

  return (
    <Query query={GET_USER} withLoading={false}>
      {renderForm}
    </Query>
  );
};

export default compose(withAuth(true), translate('jockey'))(BookingFormScreen);
