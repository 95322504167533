// @flow

import React, { Component, Fragment } from 'react';
import { Alert, ButtonToolbar, Card, Form } from 'react-bootstrap';
import { compose } from 'redux';
import { Button, BusinessFieldsForm, Input, MutationButton, Query } from 'Components';
import { PurchaseCanalSteps } from 'Config';
import {
  CollaboratorAutosuggest,
  CollaboratorCarSwitchCard,
  TravelerSwitchCard,
  UpdateContactPhoneNumberModal,
} from 'Containers';
import { withAppSettings } from 'Context/AppSettings';
import {
  protect,
  Roles,
  translate,
  withAuth,
  withReduxAlerts,
  withReduxForm,
  withReduxModals,
} from 'Hoc';
import { Alerts, Forms, Modals } from 'Reducers';
import { apolloClient, BusinessFieldErrors, getParamQueryString, parseGqlError } from 'Services';
import {
  GET_BOOKING,
  GET_COLLABORATORS,
  GET_UTILS_BUSINESS_FIELDS,
  GET_UTILS_BUSINESS_FIELDS_WITH_PARAMS,
  UPDATE_BOOKING_QUOTE,
} from './Gql/BookingInformationForm';

type BookingInformationFormPropsType = {
  t: string => string,
  history: RouterHistoryType,
  location: {
    search: () => string,
  },
  isPurchaseCanalFormValid: PurchaseCanalFormValuesType => boolean,
  purchaseCanalForm: PurchaseCanalFormType,
  setPurchaseCanalFormValues: ({
    [field: string]: ?(string | boolean | { [code: string]: string | boolean } | Array<CarType>),
  }) => void,
  setPurchaseCanalFormErrors: ({ [string]: string | { [string]: string } }) => void,
  showModal: string => void,
  hideModal: string => void,
  modals: ModalsStateType,
  alerts: AlertsStateType,
  appSettings: AppSettingsStateType,
};

type BookingInformationFormStateType = {
  travelerChoice: 'new' | 'existing',
  carChoice: 'new' | 'existing',
  mutationError: ?ErrorType,
  businessFields: Array<{ [string]: string }>,
  isAllowed: boolean,
};

class BookingInformationForm extends Component<
  BookingInformationFormPropsType,
  BookingInformationFormStateType,
> {
  travelerTexts: SwitchCardTextsType;

  carTexts: SwitchCardTextsType;

  onChangeTravelingNumberFrom: string => void;

  onChangeTravelingNumberTo: string => void;

  handleBackClick: () => void;

  handleNextClick: () => void;

  constructor(props: BookingInformationFormPropsType) {
    super(props);
    const {
      t,
      location,
      purchaseCanalForm: { values: purchaseCanalFormValues },
    } = props;
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      carBrand,
      carModel,
      carNumberPlate,
      carColor,
      carHasRoofBox,
    } = purchaseCanalFormValues;
    const id = getParamQueryString('id', location.search);
    const travelerChoice = firstName || lastName || email || phoneNumber ? 'new' : 'existing';
    const carChoice =
      carBrand ||
      carHasRoofBox ||
      carModel ||
      carNumberPlate ||
      carColor ||
      travelerChoice === 'new'
        ? 'new'
        : 'existing';

    this.state = {
      travelerChoice,
      carChoice,
      mutationError: undefined,
      businessFields: [],
      isAllowed: true,
    };
    this.travelerTexts = {
      title: t('travelerTitle'),
      firstChoice: t('existingTraveler'),
      secondChoice: t('newTraveler'),
    };
    this.carTexts = {
      title: t('carTitle'),
      firstChoice: t('existingCar'),
      secondChoice: t('newCar'),
    };
    this.onChangeTravelingNumberFrom = this.onChangeFormField.bind(this, 'travelingNumberFrom');
    this.onChangeTravelingNumberTo = this.onChangeFormField.bind(this, 'travelingNumberTo');
    this.handleBackClick = this.handleClick.bind(this, `${PurchaseCanalSteps.options}?id=${id}`);
    this.handleNextClick = this.handleClick.bind(this, `${PurchaseCanalSteps.payment}?id=${id}`);
  }

  componentDidMount() {
    const {
      purchaseCanalForm: { values: purchaseCanalFormValues },
    } = this.props;

    this.setState({
      businessFields: purchaseCanalFormValues.businessFields
        ? Object.keys(purchaseCanalFormValues.businessFields).map(key => ({
            code: key,
            value: purchaseCanalFormValues.businessFields[key],
          }))
        : [],
    });
  }

  componentDidCatch(mutationError: ErrorType) {
    const {
      setPurchaseCanalFormErrors,
      purchaseCanalForm: { errors },
      t,
      showModal,
    } = this.props;

    if (BusinessFieldErrors.includes(mutationError.translationKey) && mutationError.errors) {
      const businessFieldErrors = {};
      mutationError.errors.forEach(businessFieldCode => {
        if (mutationError.translationKey === 'apiErrorInvalidBusinessFields') {
          businessFieldErrors[businessFieldCode] = 'invalidBusinessField';
        } else {
          businessFieldErrors[businessFieldCode] = mutationError.translationKey;
        }
      });

      setPurchaseCanalFormErrors({
        businessFields: {
          ...errors.businessFields,
          ...businessFieldErrors,
        },
      });
    }
    const parsedError = parseGqlError(mutationError);
    parsedError.message = t(parsedError.translationKey);
    this.setState({ mutationError: parsedError });

    if (parsedError.translationKey === 'apiErrorPhoneNumberIsNotMobile') {
      showModal(Modals.updateContactPhoneNumber);
    }
  }

  onChangeFormField(field: string, value: string) {
    const { setPurchaseCanalFormValues } = this.props;

    setPurchaseCanalFormValues({ [field]: value });
  }

  onChangeBusinessFields = (newValues: { [field: string]: string | boolean }) => {
    const {
      setPurchaseCanalFormValues,
      purchaseCanalForm: { values },
    } = this.props;

    setPurchaseCanalFormValues({
      businessFields: {
        ...(values.businessFields ? { ...values.businessFields } : {}),
        ...newValues,
      },
    });
  };

  handleUpdateContactPhoneNumberModalHide = () => {
    const { hideModal } = this.props;

    hideModal(Modals.updateContactPhoneNumber);
    this.setState({ mutationError: undefined });
  };

  handleChangeCarType = (carChoice: 'new' | 'existing') => {
    const { setPurchaseCanalFormValues } = this.props;

    if (carChoice === 'new') {
      setPurchaseCanalFormValues({ carId: undefined });
    } else {
      setPurchaseCanalFormValues({
        carBrand: undefined,
        carColor: undefined,
        carModel: undefined,
        carNumberPlate: undefined,
        carHasRoofBox: false,
      });
    }
    this.setState({ carChoice });
  };

  handleChangeTravelerType = (travelerChoice: 'new' | 'existing') => {
    const { setPurchaseCanalFormValues } = this.props;

    if (travelerChoice === 'new') {
      setPurchaseCanalFormValues({ collaboratorId: undefined, contactId: undefined });
    } else {
      setPurchaseCanalFormValues({
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phoneNumber: undefined,
      });
    }
    this.setState({ travelerChoice, carChoice: travelerChoice });
  };

  handleSelectTraveler = (traveler: CollaboratorType) => {
    const {
      setPurchaseCanalFormValues,
      purchaseCanalForm: { values: purchaseCanalFormValues },
    } = this.props;
    const businessFields = {};

    if (traveler && traveler.cars && traveler.cars.length === 0) {
      this.setState({ carChoice: 'new' });
    } else {
      this.setState({ carChoice: 'existing' });
    }

    if (typeof purchaseCanalFormValues.businessFields === 'object') {
      Object.keys(purchaseCanalFormValues.businessFields).forEach(code => {
        businessFields[code] = '';
      });
    }

    if (traveler.businessFields && traveler.businessFields.length > 0) {
      traveler.businessFields.forEach(businessField => {
        businessFields[businessField.code] = businessField.value;
      });
    }

    setPurchaseCanalFormValues({
      userId: traveler.id,
      contactId: traveler.mainContact && traveler.mainContact.id ? traveler.mainContact.id : '',
      businessFields,
    });
  };

  getCollaboratorLabel = (collaborator: CollaboratorType) =>
    `${collaborator.firstName} ${collaborator.lastName} - ${collaborator.email}`;

  getCollaboratorValue = (collaborator: CollaboratorType) => collaborator.mainContact.id;

  handleCloseAlert = () => this.setState({ mutationError: undefined });

  handleClick = path => {
    const {
      history,
      appSettings: { selectPurchaseCanalStep },
    } = this.props;

    history.push(path);
    selectPurchaseCanalStep(PurchaseCanalSteps.payment);
  };

  handleUpdate = (cache, { data: { updateQuote } }) => {
    cache.writeQuery({
      query: GET_BOOKING,
      variables: { id: updateQuote.id },
      data: { getBooking: updateQuote },
    });
  };

  handleSubmit = async updateQuote => {
    const {
      t,
      isPurchaseCanalFormValid,
      purchaseCanalForm: { values },
      location,
    } = this.props;
    const { travelerChoice } = this.state;
    const isFormValid = await isPurchaseCanalFormValid({
      ...values,
      businessFieldType: travelerChoice === 'new' ? 'booking' : null,
    });

    const { data = { me: { businessPartner: { utilsBusinessFields: [] } } } } =
      await apolloClient.query({
        query: GET_UTILS_BUSINESS_FIELDS_WITH_PARAMS,
        variables: {
          isFilterable: false,
          businessFieldType: travelerChoice === 'new' ? 'booking' : null,
        },
      });

    if (isFormValid) {
      const businessFieldsData = [];

      data.me.businessPartner.utilsBusinessFields.forEach(businessField => {
        if (values.businessFields[businessField.code]) {
          businessFieldsData.push({
            code: businessField.code,
            value: values.businessFields[businessField.code],
          });
        }
      });

      const updateBookingQuoteVariables = {
        ...values,
        businessFields:
          travelerChoice === 'new'
            ? businessFieldsData.map(entry => ({ code: entry.code, value: entry.value }))
            : Object.entries(values.businessFields).map(entry => ({
                code: entry[0],
                value: entry[1],
              })),
        id: getParamQueryString('id', location.search),
      };

      await updateQuote({ variables: updateBookingQuoteVariables });
    } else {
      window.scrollTo(0, 0);
      this.setState({ mutationError: { message: t('formValidation') } });
    }
  };

  handleComplete = response => {
    const { updateQuote } = response;

    if (updateQuote.allowed || updateQuote.disallowedReason !== 'parking') {
      this.handleNextClick();
    } else {
      this.setState({ isAllowed: false });
      window.scrollTo(0, 0);
    }
  };

  renderCollaboratorBusinessFieldsForm = ({
    data = { me: { businessPartner: { code: '', utilsBusinessFields: [] } } },
  }) => {
    const {
      t,
      purchaseCanalForm: { values, errors },
    } = this.props;
    const { travelerChoice } = this.state;

    const businessFieldsData =
      travelerChoice === 'new'
        ? data.me.businessPartner.utilsBusinessFields.filter(
            utilsBusinessField => utilsBusinessField.linkedTo !== 'user',
          )
        : data.me.businessPartner.utilsBusinessFields;

    return data.me.businessPartner.utilsBusinessFields.length > 0 ? (
      <Card className="mb-3">
        <Card.Body className=" d-flex flex-column align-items-center">
          <Card.Title className="text-center mb-3">{t('businessFieldsTitle')}</Card.Title>
          {travelerChoice === 'new' && <p>{t('noUserBusinessFieldsNewTraveler')}</p>}
          <BusinessFieldsForm
            partnerCode={data.me.businessPartner.code}
            businessFields={businessFieldsData}
            values={values.businessFields || {}}
            errors={errors.businessFields || {}}
            onChange={this.onChangeBusinessFields}
          />
        </Card.Body>
      </Card>
    ) : null;
  };

  renderAlternativeCarCardContent() {
    const {
      t,
      purchaseCanalForm: { values: purchaseCanalFormValues },
    } = this.props;
    const { travelerChoice, carChoice } = this.state;

    if (
      travelerChoice === 'existing' &&
      carChoice === 'existing' &&
      !purchaseCanalFormValues.contactId
    ) {
      return t('selectTravelerFirst');
    }

    if (travelerChoice === 'new' && carChoice === 'existing') {
      return t('newTravelerNoCardAssociated');
    }

    return null;
  }

  renderTravelerCollaboratorAutosuggestComponent = ({ value, placeholder, onSelectTraveler }) => (
    <CollaboratorAutosuggest
      query={GET_COLLABORATORS}
      value={value}
      className="align-self-start w-50"
      placeholder={placeholder}
      onChange={onSelectTraveler}
      name="bookingListCollaboratorAutoSuggest"
    />
  );

  renderOptionsAlert = ({
    data = {
      getBooking: {
        allowed: true,
        disallowedReason: null,
        summaryPrices: null,
      },
    },
  }) => {
    const { allowed, disallowedReason } = data.getBooking;
    const { isAllowed } = this.state;
    const { t } = this.props;
    const message = {
      title: 'disallowedBookingAlertTitle',
      content: 'bookingDisallowedHeightContent',
    };

    if (!allowed && disallowedReason === 'parking' && isAllowed) {
      this.setState({ isAllowed: false });
    }

    if (
      data.getBooking.summaryPrices &&
      data.getBooking.summaryPrices.serviceUpdates &&
      data.getBooking.summaryPrices.serviceUpdates.length
    ) {
      message.title = 'optionDisallowedTitle';
      message.content = 'optionDisallowedContent';
    }

    return (
      <Alert variant="danger" show={!isAllowed}>
        <Alert.Heading>{t(message.title)}</Alert.Heading>
        <p>{t(message.content)}</p>
      </Alert>
    );
  };

  render() {
    const {
      t,
      location,
      purchaseCanalForm: { values: purchaseCanalFormValues, errors: purchaseCanalFormErrors },
      setPurchaseCanalFormValues,
      modals: { [Modals.updateContactPhoneNumber]: updateContactPhoneNumberVisible },
      alerts: { [Alerts.updateCollaboratorSucceeded]: updateCollaboratorSucceededAlert },
    } = this.props;
    const { travelerChoice, carChoice, mutationError, businessFields } = this.state;
    const id = getParamQueryString('id', location.search);
    const updateBookingQuoteVariables = {
      ...purchaseCanalFormValues,
      businessFields,
      id,
    };

    return (
      <>
        <Query query={GET_BOOKING} variables={{ id }}>
          {this.renderOptionsAlert}
        </Query>
        <Alert
          dismissible
          variant="danger"
          show={typeof mutationError !== 'undefined'}
          onClose={this.handleCloseAlert}
        >
          <Alert.Heading>{t('errorOccurred')}</Alert.Heading>
          <p>{mutationError ? mutationError.message : ''}</p>
        </Alert>
        <Alert
          dismissible
          variant="success"
          show={updateCollaboratorSucceededAlert.visible}
          onClose={this.handleCloseAlert}
        >
          <Alert.Heading>{updateCollaboratorSucceededAlert.title || ''}</Alert.Heading>
          <p>{updateCollaboratorSucceededAlert.message || ''}</p>
        </Alert>
        <Form>
          <TravelerSwitchCard
            autosuggestValue={purchaseCanalFormValues.contactId}
            choice={travelerChoice}
            onChoiceChange={this.handleChangeTravelerType}
            onSelectTraveler={this.handleSelectTraveler}
            values={purchaseCanalFormValues}
            errors={purchaseCanalFormErrors}
            onChangeTravelerFormProperties={setPurchaseCanalFormValues}
            CollaboratorAutosuggestComponent={this.renderTravelerCollaboratorAutosuggestComponent}
          />

          <CollaboratorCarSwitchCard
            choice={carChoice}
            onChoiceChange={this.handleChangeCarType}
            hideButtons={travelerChoice === 'existing' && !purchaseCanalFormValues.contactId}
            contactId={purchaseCanalFormValues.contactId}
            values={purchaseCanalFormValues}
            errors={purchaseCanalFormErrors}
            onChangeCarFormProperties={setPurchaseCanalFormValues}
          >
            {this.renderAlternativeCarCardContent()}
          </CollaboratorCarSwitchCard>

          <Card className="mb-3">
            <Card.Body className=" d-flex flex-column align-items-center">
              <Card.Title className="text-center mb-3">{t('travelingNumberTitle')}</Card.Title>
              <Form.Row className="w-100">
                <Input
                  label={t('in')}
                  className="col"
                  placeholder={t('travelingNumberInPlaceholder')}
                  value={purchaseCanalFormValues.travelingNumberFrom || ''}
                  error={purchaseCanalFormErrors.travelingNumberFrom}
                  onChange={this.onChangeTravelingNumberFrom}
                />
                <Input
                  label={t('out')}
                  className="col"
                  placeholder={t('travelingNumberOutPlaceholder')}
                  value={purchaseCanalFormValues.travelingNumberTo || ''}
                  error={purchaseCanalFormErrors.travelingNumberTo}
                  onChange={this.onChangeTravelingNumberTo}
                  isMandatory
                />
              </Form.Row>
            </Card.Body>
          </Card>
          <Query query={GET_UTILS_BUSINESS_FIELDS} variables={{ isFilterable: false }}>
            {this.renderCollaboratorBusinessFieldsForm}
          </Query>
        </Form>
        <ButtonToolbar className="justify-content-between my-2">
          <Button variant="link" onClick={this.handleBackClick}>
            {t('backButton')}
          </Button>
          <MutationButton
            mutation={UPDATE_BOOKING_QUOTE}
            type="submit"
            variables={updateBookingQuoteVariables}
            onCompleted={this.handleComplete}
            update={this.handleUpdate}
            onClick={this.handleSubmit}
            checkErrorTranslation={false}
          >
            {t('nextButton')}
          </MutationButton>
        </ButtonToolbar>
        <UpdateContactPhoneNumberModal
          show={updateContactPhoneNumberVisible}
          onExit={this.handleUpdateContactPhoneNumberModalHide}
          onHide={this.handleUpdateContactPhoneNumberModalHide}
        />
      </>
    );
  }
}

export default compose(
  translate([
    'booking_information_form',
    'form_errors',
    'gql_errors',
    'create_booking_screen',
    'create_booking_form',
  ]),
  withAuth(true),
  withAppSettings,
  withReduxAlerts(),
  withReduxModals(),
  withReduxForm(Forms.purchaseCanal),
  protect(Roles.BusinessBookingCreate),
)(BookingInformationForm);
