// @flow

import React, { PureComponent } from 'react';
import { Form } from 'react-bootstrap';
import { translate } from 'Hoc';

type InputPropsType = {
  value: string,
  error: string,
  label: string,
  className?: string,
  labelClassName?: string,
  inputClassName?: string,
  onChange: string => void,
  isMandatory?: boolean,
  t: string => string,
  t2: string => string,
  as: () => null,
  placeholder: string,
};

class Input extends PureComponent<InputPropsType> {
  static defaultProps = {
    className: undefined,
    labelClassName: undefined,
    inputClassName: undefined,
    isMandatory: false,
    placeholder: undefined,
  };

  onChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { onChange } = this.props;

    if (!event.currentTarget) {
      return;
    }
    const {
      currentTarget: { value },
    } = event;

    onChange(value);
  };

  render() {
    const {
      t,
      label,
      error,
      className,
      labelClassName,
      inputClassName,
      isMandatory,
      value,
      as,
      placeholder,
    } = this.props;
    const inputProps = { value, as, placeholder };

    return (
      <Form.Group className={className}>
        {label && (
          <Form.Label
            className={[labelClassName, isMandatory ? 'font-weight-bold' : undefined].join(' ')}
          >
            {label}
            {isMandatory && '*'}
          </Form.Label>
        )}
        <Form.Control
          {...inputProps}
          className={inputClassName}
          onChange={this.onChange}
          isInvalid={typeof error === 'string'}
        />
        <Form.Control.Feedback className="d-block" type="invalid">
          {t(error)}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
}

export default translate('form_errors')(Input);
