// @flow

import React from 'react';
import moment from 'moment';
import { Card } from 'react-bootstrap';
import { Price } from 'Components';
import { GlobalInvoiceActions } from 'Containers';
import { translate } from 'Hoc';
import Styles from './Stylesheets/GlobalInvoiceCard.module.css';

type GlobalInvoiceCardPropsType = {
  t: string => string,
  globalInvoice: GlobalInvoiceType,
};

const getText = (globalInvoice: GlobalInvoiceType) => {
  const paidText = 'paid';

  if (globalInvoice.type === 'sepa') {
    return globalInvoice.isPaid ? paidText : 'planned';
  }

  return globalInvoice.isPaid ? paidText : 'toPay';
};

const GlobalInvoiceCard = ({ t, globalInvoice }: GlobalInvoiceCardPropsType) => (
  <Card
    as="tr"
    className={[
      Styles.container,
      'd-table-row',
      'text-primary',
      'rounded',
      'border-light',
      'border-bottom-0',
    ].join(' ')}
  >
    <td className="align-middle">{moment(globalInvoice.month).format('DD/MM/YY')}</td>
    <td className="align-middle">
      {!globalInvoice.isJustSummary ? globalInvoice.invoiceNumber : ''}
    </td>
    <td className="align-middle">{t(globalInvoice.type)}</td>
    <td className="align-middle">{t(getText(globalInvoice))}</td>
    <td className="align-middle">
      <Price value={globalInvoice.total} /> TTC
    </td>
    <GlobalInvoiceActions className={[Styles.container].join(' ')} globalInvoice={globalInvoice} />
  </Card>
);

export default translate('global_invoice_card')(GlobalInvoiceCard);
