// @flow

import { GET_SPOTS } from 'Gql';

const getSpotsFromCodes = async (
  _obj: any,
  { codes = [] }: { codes: Array<string> },
  { cache }: { cache: any },
) => {
  const getSpotsData = await cache.readQuery({
    query: GET_SPOTS,
  });
  const { spots } = getSpotsData;

  return spots.reduce(
    (acc, spot) => (codes.includes(spot.code) && !acc.includes(spot.code) ? [...acc, spot] : acc),
    [],
  );
};

export const Queries = {
  getSpotsFromCodes,
};
