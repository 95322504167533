// @flow

import React from 'react';
import { Autosuggest, Query } from 'Components';
import type { AutosuggestPropsType } from 'Components/Autosuggest';
import { GET_ZONES } from 'Gql';

const getZoneKey = item => item.code;

const getZoneLabel = item => item.name;

const ZoneAutosuggest = (props: AutosuggestPropsType) => (
  <Query query={GET_ZONES} withLoading={false}>
    {({ data = { zones: [] } }) => (
      <Autosuggest
        {...props}
        data={data.zones}
        getItemKey={getZoneKey}
        getItemLabel={getZoneLabel}
        getItemValue={getZoneKey}
      />
    )}
  </Query>
);

ZoneAutosuggest.defaultProps = Autosuggest.defaultProps;

export default ZoneAutosuggest;
