// @flow

import React from 'react';
import { Modal } from 'react-bootstrap';
import {
  FreeUpdatePlaceAndDatesModalBodyContent,
  FreeUpdatePlaceAndDatesModalFooterContent,
} from 'Containers';
import { translate } from 'Hoc';

type FreeUpdatePlaceAndDatesModalPropsType = {
  t: string => string,
  onHide: () => void,
  show: boolean,
  onCompleted: () => {},
};

const FreeUpdatePlaceAndDatesModal = ({
  t,
  show,
  onHide,
  onCompleted,
}: FreeUpdatePlaceAndDatesModalPropsType) => (
  <Modal {...{ show, onHide }}>
    <Modal.Header closeButton>
      <Modal.Title className="text-center w-100">{t('modalTitle')}</Modal.Title>
    </Modal.Header>
    <Modal.Body as={FreeUpdatePlaceAndDatesModalBodyContent} />
    <Modal.Footer
      as={FreeUpdatePlaceAndDatesModalFooterContent}
      onCancel={onHide}
      onCompleted={onCompleted}
      className="justify-content-center"
    />
  </Modal>
);

export default translate('free_update_place_dates_modal')(FreeUpdatePlaceAndDatesModal);
