// @flow

import React, { Component, type Node } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Button, MutationButton } from 'Components';
import { BookingsFilters } from 'Config';
import { Protect, Roles, translate, withAuth, withReduxForm, withReduxModals } from 'Hoc';
import { Forms, Modals } from 'Reducers';
import { GET_BOOKING_INVOICE } from './Gql/BookingRowActions';

type BookingRowActionsPropsType = {
  showModal: string => void,
  booking: BookingType,
  EditIconComponent: () => Node,
  InvoiceIconComponent: () => Node,
  CancelIconComponent: () => Node,
  setCancelBookingFormValue: (string, string) => void,
  currentTab: string,
};

class BookingRowActions extends Component<BookingRowActionsPropsType> {
  handleCancel = () => {
    const { booking, showModal, setCancelBookingFormValue } = this.props;

    setCancelBookingFormValue('id', booking.id);
    showModal(Modals.cancelBooking);
  };

  handleGetBookingInvoiceSubmit = async getBookingInvoice => {
    const { booking } = this.props;

    getBookingInvoice({
      variables: {
        id: booking.id,
      },
    });
  };

  handleGetBookingInvoiceCompleted = ({ getBookingInvoice: { url } }) => {
    if (url) {
      const decodedUrl = decodeURIComponent(url);
      const a = document.createElement('a');

      if (document.body !== null) {
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = decodedUrl;
        a.click();

        window.URL.revokeObjectURL(decodedUrl);
        // $FlowFixMe
        document.body.removeChild(a);
      }
    }
  };

  render() {
    const { booking, EditIconComponent, InvoiceIconComponent, CancelIconComponent, currentTab } =
      this.props;
    const isPastOrCancelled = [BookingsFilters.cancel, BookingsFilters.past].includes(currentTab);

    return (
      <div className="d-flex justify-content-center">
        {EditIconComponent && (
          <Protect required={Roles.BusinessBookingEdit}>
            <Link
              to={`/bookings/${booking.id}${!isPastOrCancelled ? '/edit' : ''}`}
              className="d-flex align-items-center p-1"
            >
              <EditIconComponent />
            </Link>
          </Protect>
        )}
        {InvoiceIconComponent && (
          <Protect required={Roles.BusinessBookingInvoice}>
            <MutationButton
              size="sm"
              variant="link"
              className="ml-1 p-1"
              mutation={GET_BOOKING_INVOICE}
              onClick={this.handleGetBookingInvoiceSubmit}
              onCompleted={this.handleGetBookingInvoiceCompleted}
            >
              <InvoiceIconComponent />
            </MutationButton>
          </Protect>
        )}
        {CancelIconComponent && (
          <Protect required={Roles.BusinessBookingCancel}>
            <Button size="sm" variant="link" className="ml-1 p-1" onClick={this.handleCancel}>
              <CancelIconComponent />
            </Button>
          </Protect>
        )}
      </div>
    );
  }
}

export default compose(
  withAuth(true),
  withReduxModals(),
  withReduxForm(Forms.cancelBooking),
  translate('booking_row_actions'),
)(BookingRowActions);
