// @flow

import React, { Component } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { compose } from 'redux';
import { CarFormFields, MutationButton } from 'Components';
import { translate, withReduxForm, withReduxModals } from 'Hoc';
import { Forms } from 'Reducers';
import { CREATE_CAR } from './Gql/CreateCarForm';

type CreateCarFormPropsType = {
  t: string => string,
  className: string,
  createCarForm: FormStateType,
  updateCollaboratorForm: FormStateType,
  setCreateCarFormValues: ({ [code: string]: string }) => void,
  clearCreateCarForm: () => void,
  isCreateCarFormValid: CarType => Promise<boolean>,
  onCompleted: () => void,
};

type CreateCarFormStateType = {
  mutationError: ?ErrorType,
};

class CreateCarForm extends Component<CreateCarFormPropsType, CreateCarFormStateType> {
  constructor(props) {
    super(props);
    const { clearCreateCarForm } = props;

    clearCreateCarForm();
    this.state = { mutationError: undefined };
  }

  componentDidCatch(mutationError) {
    this.setState({ mutationError });
  }

  handleCloseAlert = () => this.setState({ mutationError: undefined });

  handlePreventSubmit = event => event.preventDefault();

  handleSaveCar = async createCar => {
    const {
      isCreateCarFormValid,
      createCarForm: { values },
      updateCollaboratorForm: {
        values: { id },
      },
    } = this.props;

    const isFormValid = await isCreateCarFormValid(values);

    if (isFormValid) {
      const mutationVariables = {
        utilsCarId: values.utilsCarId,
        color: values.carColor,
        numberPlate: values.carNumberPlate ? values.carNumberPlate : '',
        userId: id,
        hasRoofBox: !!values.carHasRoofBox,
      };
      createCar({ variables: mutationVariables });
    }
  };

  render() {
    const {
      t,
      createCarForm: { values, errors },
      updateCollaboratorForm: {
        values: { id },
      },
      setCreateCarFormValues,
      className,
      onCompleted,
    } = this.props;
    const { mutationError } = this.state;

    return (
      <Form as={Col} onSubmit={this.handlePreventSubmit} className={className}>
        <Alert
          dismissible
          variant="danger"
          show={typeof mutationError !== 'undefined'}
          onClose={this.handleCloseAlert}
        >
          <Alert.Heading>{t('errorOccurred')}</Alert.Heading>
          <p>{mutationError ? mutationError.message : ''}</p>
        </Alert>
        <CarFormFields values={values} errors={errors} onChange={setCreateCarFormValues} as={Col} />
        <Row className="justify-content-center">
          <MutationButton
            mutation={CREATE_CAR}
            variables={{
              utilsCar: values.utilsCarId,
              color: values.carColor,
              numberPlate: values.carNumberPlate ? values.carNumberPlate : '',
              userId: id,
              hasRoofBox: values.carHasRoofBox,
            }}
            onClick={this.handleSaveCar}
            refetchQueries={['GetCollaborators']}
            onCompleted={onCompleted}
          >
            {t('saveCarButton')}
          </MutationButton>
        </Row>
      </Form>
    );
  }
}

export default compose(
  translate(['create_car_form', 'form_errors', 'alerts']),
  withReduxForm(Forms.createCar),
  withReduxForm(Forms.updateCollaborator),
  withReduxModals(),
)(CreateCarForm);
