// @flow

import * as React from 'react';
import { LinkContainer } from 'react-router-bootstrap';

type LinkPropsType = {
  to: string,
  exact?: boolean,
  as: () => null,
  children: React.Node,
  activeClassName?: string,
};

const Link = ({ to, exact, as, children, activeClassName, ...childrenProps }: LinkPropsType) => (
  <LinkContainer to={to} exact={exact} activeClassName={activeClassName}>
    {React.createElement(as, childrenProps, children)}
  </LinkContainer>
);

Link.defaultProps = {
  exact: false,
  activeClassName: '',
};

export default Link;
