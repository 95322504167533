import { gql } from '@apollo/client';

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods($showExpired: Boolean = false, $showDeleted: Boolean = false) {
    me {
      businessPartner {
        code
        paymentMethods(showExpired: $showExpired, showDeleted: $showDeleted) {
          id
          type
          brand
          last4digits
          isDefault
          expireAt
          name
        }
      }
    }
  }
`;
