import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GetUser {
    me {
      email
      firstName
      lastName
      roles
      businessPartner {
        code
        name
        logoUrl
        enableJockey
      }
      businessPartners {
        code
        name
        logoUrl
      }
    }
  }
`;
