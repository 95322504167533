import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GetUser {
    me {
      email
      firstName
      lastName
      roles
      businessPartner {
        code
        name
        logoUrl
        enableJockey
        airtableBookingFormLink
      }
      businessPartners {
        code
        name
        logoUrl
      }
    }
  }
`;

export const CHECK_EMAIL = gql`
  mutation checkEmail($email: String!) {
    checkEmail(email: $email) {
      found
      standardSSO
      idSSO
    }
  }
`;

export const SWAP_TOKEN = gql`
  mutation swapToken($ssoToken: String!) {
    swapToken(sso_token: $ssoToken, origin: business) {
      token
      refreshToken
    }
  }
`;
