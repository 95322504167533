// @flow
import React, { typeof Children } from 'react';
import { Col, Container } from 'react-bootstrap';
import { Navbar } from 'Containers';
import { translate } from 'Hoc';
import Styles from './Stylesheets/LoginLayout.module.css';

type LoginLayoutPropsType = {
  t: string => string,
  children: Children,
};

const LoginLayout = ({ t, children }: LoginLayoutPropsType) => (
  <Container fluid className={['p-0', Styles.rootContainer]} as={Col}>
    <Navbar />
    <Container
      as={Col}
      className={[
        'h-100 d-flex justify-content-center justify-content-sm-end align-items-center',
        Styles.pageContainer,
      ].join(' ')}
      fluid
      style={{
        backgroundImage: `url(${t('backgroundImage')})`,
      }}
    >
      {children}
    </Container>
  </Container>
);

export default translate('login_layout')(LoginLayout);
