// @flow

import React from 'react';
import { Modal } from 'react-bootstrap';
import { translate } from 'Hoc';

type MissingPasswordModalPropsType = {
  t: string => string,
  show: () => void,
  onExit: () => void,
  onHide: () => void,
};

const MissingPasswordModal = ({ t, show, onExit, onHide }: MissingPasswordModalPropsType) => (
  <Modal show={show} onExit={onExit} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>{t('title')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{t('text1')}</p>
      <p>
        <i>{t('text2')}</i>
      </p>
    </Modal.Body>
    <Modal.Footer>
      <a className="btn btn-primary" href={t('url')} target="_blank" rel="noreferrer noopener">
        {t('submit')}
      </a>
    </Modal.Footer>
  </Modal>
);

export default translate('missing_password_modal')(MissingPasswordModal);
