// @flow

import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password, origin: business) {
      token
      refreshToken
      user {
        email
      }
    }
  }
`;

export const GET_AUTH = gql`
  query GetAuth {
    auth @client {
      token
      expireAt
      email
      isConnected
      partnerHeaderCode
    }
  }
`;
