// @flow

import { InitialState as AuthInitialState, Mutations as AuthMutations } from './AuthState';
import { InitialState as MeInitialState } from './MeState';
import { Queries as SpotQueries } from './SpotState';

export const InitialStates = {
  auth: AuthInitialState,
  me: MeInitialState,
};

export const CacheResolvers = {
  Mutation: {
    ...AuthMutations,
  },
  Query: {
    ...SpotQueries,
  },
};
