import React from 'react';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    Sentry.captureMessage(error);
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default ErrorBoundary;
