// @flow

export const Errors = {
  errorOccurred: 'ErrorOccurred',
  network: 'NetworkError',
  //  Ector V3 aka ector-prisma errors
  invalidCredentials: 'INVALID_CREDENTIALS',
  unauthorizedUntilDelay: 'UNAUTHORIZED_UNTIL_DELAY',
  missingRole: 'MISSING_ROLE',
  missingB2bPartner: 'MISSING_B2B_PARTNER',
  missingBusinessGroupname: 'MISSING_BUSINESS_GROUPNAME',
};

export const AddDiscountCodeErrors = [
  'apiErrorInvalidDiscountCode',
  'apiErrorNotFoundDiscountCode',
  'apiErrorContactRequired',
  'apiErrorBadBookingOccurenceDiscountCode',
  'apiErrorBadPeriodDiscountCode',
  'apiErrorBadBookingPeriodDiscountCode',
  'apiErrorBadZoneDiscountCode',
  'apiErrorIndividualUsageLimitDiscountCode',
  'apiErrorMaxUsageLimitDiscountCode',
  'apiErrorForbiddenEmailUseDiscountCode',
  'apiErrorBadPartnerDiscountCode',
  'apiErrorMissingServiceDiscountCode',
  'apiErrorReferralCodeOwnerDiscountCode',
];

export const whiteListTranslationKey = [
  'apiErrorrequires_source_action',
  'apiErrorfindParkingError',
  'apiErrorNotFoundParking',
];

export const BusinessFieldErrors = ['apiErrorInvalidBusinessFields'];

const gqlErrors = {
  [Errors.errorOccurred]: {
    translationKey: 'errorOccurred',
    message: '',
  },
  [Errors.network]: {
    translationKey: 'networkError',
    message: '',
  },
  //  Ector V3 aka ector-prisma errors
  [Errors.invalidCredentials]: {
    translationKey: 'invalidCredentials',
    message: '',
  },
  [Errors.unauthorizedUntilDelay]: {
    translationKey: 'unauthorizedUntilDelay',
    message: '',
  },
  [Errors.missingRole]: {
    translationKey: 'missingRole',
    message: '',
  },
  [Errors.missingB2bPartner]: {
    translationKey: 'missingB2bPartner',
    message: '',
  },
  [Errors.missingBusinessGroupname]: {
    translationKey: 'missingBusinessGroupname',
    message: '',
  },
};

export const parseGqlError = (error: ErrorType) => {
  if (
    error.message === error.translationKey &&
    !whiteListTranslationKey.includes(error.translationKey)
  ) {
    return gqlErrors[Errors.errorOccurred];
  }

  return error;
};

type TransformedErrorType = {
  code: string,
  errors: Array<string>,
  data: any,
};

export default class GqlError extends Error {
  constructor(error: TransformedErrorType) {
    super(error.code);
    this.message = '';
    this.errors = error.errors || [];
    this.data = error.data;
    this.translationKey = `apiError${error.code}`;

    if (gqlErrors[error.code]) {
      const { translationKey, message } = gqlErrors[error.code];

      this.translationKey = translationKey;
      this.message = message;
    }

    // $FlowFixMe
    this.name = new.target.prototype.constructor.name;
    // $FlowFixMe
    Object.setPrototypeOf(this, new.target.prototype);
  }

  message: string;

  errors: Array<string>;

  data: Array<any>;

  translationKey: string;
}
