// @flow
import * as React from 'react';
import { SwitchCard, TravelerFormFields } from 'Components';
import { CollaboratorAutosuggest } from 'Containers';
import { translate } from 'Hoc';

type CollaboratorAutosuggestComponentPropsType = {
  value: string,
  onSelectTraveler: CollaboratorType => void,
  placeholder: string,
};

type TravelerSwitchCardPropsType = {
  t: string => string,
  children: React.Node,
  choice: SwitchCardChoiceType,
  choices: SwitchCardChoicesType,
  onChoiceChange: ('new' | 'existing') => void,
  onSelectTraveler: CollaboratorType => void,
  values: TravelerFormValuesType,
  errors: TravelerFormErrorsType,
  onChangeTravelerFormProperties: ({ [string]: string }) => void,
  hideButtons: boolean,
  autosuggestValue: string,
  CollaboratorAutosuggestComponent: CollaboratorAutosuggestComponentPropsType => React.Element<*>,
};

const TravelerSwitchCard = ({
  choice,
  choices,
  onChoiceChange,
  onSelectTraveler,
  onChangeTravelerFormProperties,
  values,
  errors,
  t,
  autosuggestValue,
  CollaboratorAutosuggestComponent,
  ...rest
}: TravelerSwitchCardPropsType) => (
  <SwitchCard
    {...rest}
    value={choice}
    name="travelerChoice"
    texts={{
      title: '',
      firstChoice: t('existingTraveler'),
      secondChoice: t('newTraveler'),
    }}
    choices={choices}
    onChange={onChoiceChange}
    FirstComponent={
      <CollaboratorAutosuggestComponent
        value={autosuggestValue}
        onSelectTraveler={onSelectTraveler}
        placeholder={t('collaboratorAutosuggestPlaceholder')}
      />
    }
    SecondComponent={
      <TravelerFormFields
        values={values}
        errors={errors}
        onChange={onChangeTravelerFormProperties}
      />
    }
  />
);

TravelerSwitchCard.defaultProps = {
  CollaboratorAutosuggestComponent: ({
    value,
    onSelectTraveler,
    placeholder,
  }: CollaboratorAutosuggestComponentPropsType) => (
    <CollaboratorAutosuggest
      value={value}
      className="align-self-start w-50"
      placeholder={placeholder}
      onChange={onSelectTraveler}
      name="bookingListCollaboratorAutoSuggest"
    />
  ),
};

export default translate('traveler_switch_card')(TravelerSwitchCard);
