// @flow

import React from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { LoginLayout, ScrollToTop } from 'Components';
import {
  AppLayout,
  BillingScreen,
  BookingsScreen,
  CollaboratorsScreen,
  CreateBookingScreen,
  DashboardScreen,
  ExternalLoginScreen,
  LoginScreen,
  LogoutScreen,
  RecapBookingScreen,
  UpdateBookingScreen,
} from 'Containers';
import JockeyCreateBookingScreen from 'Containers/Jockey/BookingForm';
import JockeyBookingScreen from 'Containers/Jockey/Bookings';

type DashboardRoutePropsType = {
  component: any,
};

const DashboardRoute = ({ component: Component, ...props }: DashboardRoutePropsType) => (
  <Route
    {...props}
    render={matchProps => (
      <ScrollToTop location={matchProps.location}>
        <AppLayout>
          <Component {...matchProps} />
        </AppLayout>
      </ScrollToTop>
    )}
  />
);

const LoginRoute = ({ component: Component, ...props }) => (
  <Route
    {...props}
    render={matchProps => (
      <ScrollToTop location={matchProps.location}>
        <LoginLayout>
          <Component {...matchProps} />
        </LoginLayout>
      </ScrollToTop>
    )}
  />
);

const App = () => (
  <Router>
    <Switch>
      <DashboardRoute path="/dashboard" component={DashboardScreen} exact />
      <DashboardRoute path="/collaborators" component={CollaboratorsScreen} exact />
      <DashboardRoute path="/bookings" component={BookingsScreen} exact />
      <DashboardRoute path="/bookings/create" component={CreateBookingScreen} />
      <DashboardRoute path="/jockey/bookings" component={JockeyBookingScreen} exact />
      <DashboardRoute path="/jockey/bookings/create" component={JockeyCreateBookingScreen} />
      <DashboardRoute
        path="/bookings/:id"
        component={props => <RecapBookingScreen {...props} screenTitle="bookingSummaryTitle" />}
        exact
      />
      <DashboardRoute
        path="/bookings/:id/recap"
        component={props => (
          <RecapBookingScreen
            {...props}
            screenTitle="bookingConfirmationTitle"
            shouldDisplayAlert
          />
        )}
        exact
      />
      <DashboardRoute path="/bookings/:id/edit" component={UpdateBookingScreen} />
      <DashboardRoute path="/billing" component={BillingScreen} exact />
      <LoginRoute path="/login" component={LoginScreen} />
      <Route path="/connect/:token" component={ExternalLoginScreen} exact />
      <Route path="/logout" component={LogoutScreen} exact />
      <Redirect from="/" to="/dashboard" />
    </Switch>
  </Router>
);

export default App;
