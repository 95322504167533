// @flow
import * as firebase from 'firebase/app';
import 'firebase/auth';
import LocalForage from 'localforage';
import { CacheResolvers } from 'LocalStates';

export const checkedEmailKey = 'checkedEmail';

export const SsoTokenErrors = {
  NO_TOKEN: 'NO_TOKEN',
  NOT_SAME_EMAIL: 'NOT_SAME_EMAIL',
};

export const SocialMedia = {
  google: 'google',
};

export async function ssoLogout() {
  try {
    await firebase.auth().signOut();
  } catch (e) {
    console.warn('logout error', e);
  }
}

export async function initFirebaseAndCheckRedirect() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  /**
   * Check if we're coming from a LoginWithRedirect
   * This is because the redirection happens inside the same tab so everything unmounts
   */
  const result = await firebase.auth().getRedirectResult();
  const checkedEmail = await LocalForage.getItem(checkedEmailKey);

  if (
    checkedEmail &&
    /**
     * we check for `checkedEmail` isn't null
     * because we need to know that we're not in a clicked Social Media Button Method flow
     */
    result &&
    result.user &&
    result.user.email &&
    checkedEmail !== result.user.email
  ) {
    // eslint-disable-next-line no-throw-literal
    throw { message: SsoTokenErrors.NOT_SAME_EMAIL, email: result.user.email };
  }

  return result;
}

export async function getSsoToken(): Promise<string> {
  const { currentUser } = firebase.auth();
  const ssoToken: string = currentUser && (await currentUser.getIdToken(/* forceRefresh */ true));

  const checkedEmail = await LocalForage.getItem(checkedEmailKey);

  if (!ssoToken) {
    throw Error(SsoTokenErrors.NO_TOKEN);
  }

  if (
    checkedEmail &&
    /**
     * we check for `checkedEmail` isn't null
     * because we need to know that we're not in a clicked Social Media Button Method flow
     */
    currentUser &&
    currentUser.email &&
    checkedEmail !== currentUser.email
  ) {
    // eslint-disable-next-line no-throw-literal
    throw { message: SsoTokenErrors.NOT_SAME_EMAIL, email: currentUser.email };
  }

  return ssoToken;
}

export async function signInWithSSO(ssoId: string, standardSSO: 'saml' | 'oauth'): Promise<void> {
  let ssoProvider: string;
  switch (standardSSO) {
    case 'saml':
      ssoProvider = new firebase.auth.SAMLAuthProvider(ssoId);
      break;
    case 'oauth':
      ssoProvider = new firebase.auth.OAuthProvider(ssoId);
      break;
    default:
      throw Error(`❌ SSO: ${standardSSO} isn't valid`);
  }

  /**
   * Try to SSO with Popup
   * If we can't, then do it with Redirect
   */
  try {
    await firebase.auth().signInWithPopup(ssoProvider);
  } catch (error) {
    console.warn('❌ SSO with popup failed, trying with redirection…', error);

    if (error.code === 'auth/popup-blocked') {
      await firebase.auth().signInWithRedirect(ssoProvider);
    }
  }
}

export async function signInWithSocialMedia(socialMedia: $Keys<typeof SocialMedia>): Promise<void> {
  let provider;

  switch (socialMedia) {
    case SocialMedia.google:
      provider = new firebase.auth.GoogleAuthProvider();
      break;
    default:
      provider = null;
  }

  if (!provider) {
    throw Error(`❌ SSO: ${socialMedia} isn't valid`);
  }

  provider.setCustomParameters({
    prompt: 'select_account',
  });
  try {
    await firebase.auth().signInWithPopup(provider);
  } catch (error) {
    if (error.code === 'auth/popup-blocked') {
      await firebase.auth().signInWithRedirect(provider);
    }
  }
}

export const updateCacheLoginSwapToken = async (cache: any, { data }: any, email: string) => {
  if (!data || !data.swapToken) {
    return false;
  }

  const {
    swapToken: { token },
  } = data;

  await CacheResolvers.Mutation.login(undefined, { token, email } || {}, { cache });

  return true;
};
