// @flow

import { connect } from 'react-redux';
import { ModalsStateActions } from '../Reducers';

const mapStateToProps = state => ({
  modals: state.modals,
});

const mapDispatchToProps = dispatch => ({
  showModal: modalName => dispatch(ModalsStateActions.showModal(modalName)),
  hideModal: modalName => dispatch(ModalsStateActions.hideModal(modalName)),
});

export default (whitelist: ?('values' | 'dispatch') = undefined) =>
  connect(
    whitelist === 'dispatch' ? null : mapStateToProps,
    whitelist === 'values' ? null : mapDispatchToProps,
  );
