// @flow
import React, { PureComponent } from 'react';
import { Row } from 'react-bootstrap';
import { compose } from 'redux';
import { Button, MutationButton } from 'Components';
import { translate, withReduxForm } from 'Hoc';
import { Forms } from 'Reducers';
import { UPDATE_BOOKING } from './Gql/FreeUpdatePlaceAndDatesModalFooterContent';

type FreeUpdatePlaceAndDatesModalFooterContentPropsType = {
  t: string => string,
  onCancel: () => void,
  updateBookingForm: UpdateBookingType,
  className: ?string,
  showAlert: (string, string, string) => void,
  onCompleted: () => void,
};

class FreeUpdatePlaceAndDatesModalFooterContent extends PureComponent<FreeUpdatePlaceAndDatesModalFooterContentPropsType> {
  handleUpdateBooking = async updateBooking => {
    const {
      updateBookingForm: {
        values: { id, fromSpot, toSpot, endDate, endTime, startDate, startTime },
      },
    } = this.props;

    await updateBooking({
      variables: {
        id,
        fromSpot,
        toSpot,
        endAt: `${endDate || ''} ${endTime || ''}`,
        startAt: `${startDate || ''} ${startTime || ''}`,
        persistModification: true,
      },
    });
  };

  handleUpdateBookingCompleted = () => {
    const { onCompleted } = this.props;

    onCompleted();
  };

  render() {
    const { onCancel, t, className } = this.props;

    return (
      <Row noGutters className={className}>
        <Button variant="link" className="mr-2 font-weight-bold" onClick={onCancel}>
          {t('cancelButton')}
        </Button>
        <MutationButton
          mutation={UPDATE_BOOKING}
          onClick={this.handleUpdateBooking}
          onCompleted={this.handleUpdateBookingCompleted}
        >
          {t('submitButton')}
        </MutationButton>
      </Row>
    );
  }
}

export default compose(
  withReduxForm(Forms.updateBooking, 'values'),
  translate('free_update_place_dates_modal'),
)(FreeUpdatePlaceAndDatesModalFooterContent);
