// @flow

import { gql } from '@apollo/client';

export const GET_BOOKING = gql`
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
    }
  }
`;

export const ADD_DISCOUNT_CODE = gql`
  mutation ApplyDiscountCode($id: ID!, $code: String) {
    applyDiscountCode(id: $id, code: $code) {
      id
      summaryPrices {
        totalPrice
        discountCode {
          code
          amount
        }
      }
    }
  }
`;

export const DELETE_DISCOUNT_CODE = gql`
  mutation RemoveDiscountCode($id: ID!) {
    removeDiscountCode(id: $id) {
      id
      summaryPrices {
        totalPrice
        discountCode {
          code
          amount
        }
      }
    }
  }
`;
