// @flow

import { gql } from '@apollo/client';

export const UPDATE_BOOKING_QUOTE = gql`
  mutation UpdateQuote($id: ID!, $services: [String]) {
    updateQuote(id: $id, services: $services) {
      id
      allowed
      disallowedReason
      summaryPrices {
        parkingValet
        totalPrice
        discountCode {
          code
          amount
          rate
        }
        serviceUpdates {
          name
          price
          code
          isOffered
        }
      }
    }
  }
`;

export const GET_BOOKING = gql`
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      allowed
      disallowedReason
      availableServices {
        code
        name
        price
      }
      summaryPrices {
        serviceUpdates {
          name
          code
          price
          isOffered
        }
      }
      hasCancellationInsurance
      shouldRefundWithoutCancellationInsurance
    }
  }
`;
