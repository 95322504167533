// @flow

import React, { Component } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { compose } from 'redux';
import { OptionsRecapCard, Query } from 'Components';
import {
  CarRecapCard,
  PlaceAndDatesRecapCard,
  PricingSummaryCard,
  TravelerRecapCard,
  TravelRecapCard,
} from 'Containers';
import { translate, withAuth, withReduxAlerts } from 'Hoc';
import { Alerts } from 'Reducers';
import { GET_BOOKING } from './Gql/RecapBookingScreen';

type RecapBookingScreenPropsType = {
  location: {
    search: () => string,
  },
  match: {
    params: {
      id: string,
    },
  },
  t: string => string,
  alerts: AlertsStateType,
  showAlert: () => void,
  hideAlert: () => void,
  screenTitle: string,
  shouldDisplayAlert: boolean,
};

class RecapBookingScreen extends Component<RecapBookingScreenPropsType> {
  hideBookingSucceedAlert: () => void;

  static defaultProps = {
    shouldDisplayAlert: false,
  };

  constructor(props) {
    super(props);
    const { showAlert, t } = props;

    showAlert(Alerts.bookingSucceed, t('bookingSuccessTitle'), t('bookingSuccessContent'));

    this.hideBookingSucceedAlert = props.hideAlert.bind(this, Alerts.bookingSucceed);
  }

  renderReturnScreenContent = ({ data = { getBooking: null }, loading, error }) => {
    const {
      t,
      match: {
        params: { id },
      },
      alerts: { [Alerts.bookingSucceed]: bookingSucceedAlert },
      screenTitle,
      shouldDisplayAlert,
    } = this.props;

    const { getBooking } = data;

    if (!loading && !error && getBooking) {
      return (
        <Container fluid>
          <Row>
            <Col xs={12} className="d-flex justify-content-center font-weight-bold my-4" as="h2">
              {t(screenTitle)}
            </Col>
          </Row>
          <Alert
            dismissible
            variant="success"
            show={bookingSucceedAlert.visible && shouldDisplayAlert}
            onClose={this.hideBookingSucceedAlert}
          >
            <Alert.Heading>{bookingSucceedAlert.title}</Alert.Heading>
            <p>{bookingSucceedAlert.message}</p>
          </Alert>
          <Row>
            <Col xs={8}>
              <PlaceAndDatesRecapCard booking={getBooking} />
              <OptionsRecapCard booking={getBooking} />
              <TravelRecapCard booking={getBooking} />
              <TravelerRecapCard booking={getBooking} />
              <CarRecapCard booking={getBooking} />
            </Col>
            <Col xs={4}>
              <PricingSummaryCard bookingId={id} visible totalPriceKey="totalPrice" />
            </Col>
          </Row>
        </Container>
      );
    }

    return null;
  };

  render() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return (
      <Query query={GET_BOOKING} variables={{ id }}>
        {this.renderReturnScreenContent}
      </Query>
    );
  }
}

export default compose(
  translate('recap_booking_screen'),
  withReduxAlerts(),
  withAuth(true),
)(RecapBookingScreen);
