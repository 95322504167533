// @flow
import { gql } from '@apollo/client';

export const GET_FLASH_REPORT = gql`
  query GetFlashReport($date: DateTime! = null) {
    me {
      businessPartner {
        getFlashReport(date: $date) {
          id
          countBookings
          countParkingDays
          averageParkingDays
          averageMarks
          countBookingsByZones {
            zone {
              name
              code
            }
            value
          }
        }
      }
    }
  }
`;
