// @flow

import { gql } from '@apollo/client';

export const UPDATE_BOOKING_QUOTE = gql`
  mutation UpdateQuote(
    $id: ID!
    $contactId: ID = null
    $carId: ID = null
    $utilsCarId: ID = null
    $carColor: String = null
    $carNumberPlate: String = null
    $carHasRoofBox: Boolean = false
    $firstName: String = null
    $lastName: String = null
    $email: String = null
    $phoneNumber: String = null
    $businessFields: [BusinessFieldInput] = []
    $travelingNumberFrom: String = null
    $travelingNumberTo: String
  ) {
    updateQuote(
      id: $id
      contactId: $contactId
      carId: $carId
      utilsCarId: $utilsCarId
      carColor: $carColor
      carNumberPlate: $carNumberPlate
      carHasRoofBox: $carHasRoofBox
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      businessFields: $businessFields
      travelingNumberFrom: $travelingNumberFrom
      travelingNumberTo: $travelingNumberTo
    ) {
      id
      allowed
      disallowedReason
      contact {
        id
        firstName
        lastName
        email
        phone
      }
      car {
        id
        name
        brand
        model
        utilsCar {
          brand
          model
          motorization
          variants
        }
        color
        numberPlate
        hasRoofBox
      }
      travelingNumberFrom
      travelingNumberTo
      businessFields {
        code
        value
      }
    }
  }
`;

export const GET_BOOKING = gql`
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      allowed
      disallowedReason
      summaryPrices {
        totalPrice
        serviceUpdates {
          code
        }
      }
    }
  }
`;

export const GET_UTILS_BUSINESS_FIELDS = gql`
  query GetUtilsBusinessFields {
    me {
      businessPartner {
        code
        utilsBusinessFields {
          name
          code
          regex
          isMandatory
          type
          linkedTo
          choices
          tooltip
        }
      }
    }
  }
`;

export const GET_UTILS_BUSINESS_FIELDS_WITH_PARAMS = gql`
  query GetUtilsBusinessFields($businessFieldType: String = null, $isFilterable: Boolean = false) {
    me {
      businessPartner {
        utilsBusinessFields(isFilterable: $isFilterable, type: $businessFieldType) {
          name
          code
          regex
          isMandatory
          type
          linkedTo
          tooltip
        }
      }
    }
  }
`;

export const GET_COLLABORATORS = gql`
  query GetCollaborators($searchText: String!, $page: Int = 1) {
    me {
      businessPartner {
        code
        collaborators(searchText: $searchText, page: $page) {
          _metadata {
            page
            perPage
            totalResults
            totalPages
          }
          results {
            id
            firstName
            lastName
            email
            businessFields {
              name
              value
              code
            }
            mainContact {
              id
            }
          }
        }
      }
    }
  }
`;
