// @flow

import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { PaymentMethodItem } from 'Components';

type PaymentMethodsListPropsType = {
  paymentMethods: Array<PaymentMethodType>,
  onClick: PaymentMethodType => void,
  selected: ?string,
};

const PaymentMethodsList = ({ paymentMethods, onClick, selected }: PaymentMethodsListPropsType) => (
  <ListGroup className="w-100 flex-row flex-wrap justify-content-center">
    {paymentMethods.map(paymentMethod => (
      <PaymentMethodItem
        key={paymentMethod.id}
        paymentMethod={paymentMethod}
        onClick={onClick}
        selected={selected === paymentMethod.id}
      />
    ))}
  </ListGroup>
);

export default PaymentMethodsList;
