// @flow

import * as React from 'react';
import moment from 'moment';
import { Col, Form, Row } from 'react-bootstrap';
import { DatePickerInput, TimePickerInput } from 'Components';
import { AppConfig } from 'Config';
import { SpotAutosuggest } from 'Containers';
import { translate } from 'Hoc';
import Styles from './Stylesheets/PlaceAndDatesForm.module.scss';

const now = moment();

type PlaceAndDatesFormPropsType = {
  t: string => string,
  values: TravelFormValuesType,
  errors: TravelFormErrorsType,
  className: ?string,
  onStartDateChange: (?string) => void,
  onEndDateChange: (?string) => void,
  onFromSpotChange: (?string) => void,
  onToSpotChange: (?string) => void,
  onStartTimeChange: (?string) => void,
  onEndTimeChange: (?string) => void,
  onDateInputFocusChange: () => void,
  FormGroupComponent: () => React.Element<*>,
  shouldDisablePickers: boolean,
};

type PlaceAndDatesFormStateType = {
  startPickersDisabled: boolean,
  endPickersDisabled: boolean,
};

class PlaceAndDatesForm extends React.Component<
  PlaceAndDatesFormPropsType,
  PlaceAndDatesFormStateType,
> {
  static defaultProps = {
    className: undefined,
    onDateInputFocusChange: () => {},
    FormGroupComponent: ({ children, ...rest }) => <Form.Group {...rest}>{children}</Form.Group>,
    shouldDisablePickers: false,
  };

  constructor(props) {
    super(props);
    const {
      values: { startDate, startTime, endDate, endTime },
      shouldDisablePickers,
    } = props;
    let startPickersDisabled = false;
    let endPickersDisabled = false;

    if (startDate && startTime && shouldDisablePickers) {
      startPickersDisabled = moment(`${startDate} ${startTime}`).isBefore(now);
    }

    if (endDate && endTime && shouldDisablePickers) {
      endPickersDisabled = moment(`${endDate} ${endTime}`).isBefore(now);
    }
    this.state = { startPickersDisabled, endPickersDisabled };
  }

  handlePreventSubmit = event => event.preventDefault();

  handleStartDateChange = value => {
    const {
      onStartDateChange,
      onEndDateChange,
      onEndTimeChange,
      values: { endDate },
    } = this.props;

    if (!value) {
      return;
    }
    onStartDateChange(value.format(AppConfig.format.date));

    if (endDate) {
      const endDateMoment = moment(endDate);

      if (value.isAfter(endDateMoment)) {
        onEndDateChange(undefined);
        onEndTimeChange(undefined);
      }
    }
  };

  handleEndDateChange = value => {
    const {
      onEndDateChange,
      onStartDateChange,
      onStartTimeChange,
      values: { startDate },
    } = this.props;

    if (!value) {
      return;
    }
    onEndDateChange(value.format(AppConfig.format.date));

    if (startDate) {
      const startDateMoment = moment(startDate);

      if (startDateMoment.isAfter(value)) {
        onStartDateChange(undefined);
        onStartTimeChange(undefined);
      }
    }
  };

  handleFromSpotChange = value => {
    const {
      onFromSpotChange,
      onToSpotChange,
      values: { toSpot },
    } = this.props;

    onFromSpotChange(value.code);

    if (!toSpot && value) {
      onToSpotChange(value.code);
    }
  };

  handleToSpotChange = value => {
    const { onToSpotChange } = this.props;

    onToSpotChange(value.code);
  };

  handleStartTimeChange = value => {
    const { onStartTimeChange } = this.props;

    if (value) {
      onStartTimeChange(value.format(AppConfig.format.time));
    }
  };

  handleEndTimeChange = value => {
    const { onEndTimeChange } = this.props;

    if (value) {
      onEndTimeChange(value.format(AppConfig.format.time));
    }
  };

  getInitialEndDayMonth = () => {
    const { values } = this.props;

    if (values.startDate) {
      return moment(values.startDate);
    }

    return now;
  };

  isDayBlocked = startDay => startDay.isBefore(now, 'day');

  isDayHighlighted = day => {
    const { values } = this.props;

    if (values.startDate && values.endDate) {
      const startDate = moment(values.startDate);
      const endDate = moment(values.endDate);

      return day.isBetween(startDate, endDate) || day.isSame(startDate, 'day');
    }

    if (values.startDate) {
      const startDate = moment(values.startDate);

      return day.isSame(startDate, 'day');
    }

    if (values.endDate) {
      const endDate = moment(values.endDate);

      return day.isSame(endDate, 'day');
    }

    return false;
  };

  render() {
    const { className, t, values, errors, onDateInputFocusChange, FormGroupComponent } = this.props;
    const { startPickersDisabled, endPickersDisabled } = this.state;

    return (
      <Form className={className} onSubmit={this.handlePreventSubmit}>
        <Row className="align-items-start justify-content-center">
          <Col
            className={['mb-sm-2 align-self-sm-center text-center', Styles.rowLabel].join(' ')}
            xl={2}
          >
            {t('outward')}
          </Col>
          <FormGroupComponent controlId="fromSpot" as={Col} xl={4} md={4} xs={9}>
            <Form.Label
              className={['text-nowrap text-ellipsis d-block', Styles.inputLabel].join(' ')}
            >
              {t('fromSpotLabel')}
            </Form.Label>
            <SpotAutosuggest
              placeholder=""
              onChange={this.handleFromSpotChange}
              value={values.fromSpot}
              error={errors.fromSpot ? t(errors.fromSpot) : ''}
              isInvalid={!!errors.fromSpot}
              disabled={startPickersDisabled}
            />
          </FormGroupComponent>
          <FormGroupComponent controlId="startDate" as={Col} xl={3} md={4} xs={9}>
            <Form.Label
              className={['text-nowrap text-ellipsis d-block', Styles.inputLabel].join(' ')}
            >
              {t('startDateLabel')}
            </Form.Label>
            <DatePickerInput
              date={values.startDate}
              onDateChange={this.handleStartDateChange}
              id="startDate"
              isDayBlocked={this.isDayBlocked}
              isDayHighlighted={this.isDayHighlighted}
              onFocusChange={onDateInputFocusChange}
              disabled={startPickersDisabled}
              invalid={!!errors.startDate}
            />
            {errors.startDate && (
              <Form.Control.Feedback className="d-block" type="invalid">
                {t(errors.startDate)}
              </Form.Control.Feedback>
            )}
          </FormGroupComponent>
          <FormGroupComponent as={Col} xl={3} md={4} xs={9}>
            <Form.Label
              className={['text-nowrap text-ellipsis d-block', Styles.inputLabel].join(' ')}
            >
              {t('startTimeLabel')}
            </Form.Label>
            <TimePickerInput
              autoComplete="off"
              value={values.startTime || ''}
              onChange={this.handleStartTimeChange}
              readOnly
              className={['w-100', errors.startTime ? 'is-invalid' : undefined].join(' ')}
              id="startTime"
              showSecond={false}
              minuteStep={1}
              disabled={startPickersDisabled}
            />
            {errors.startTime && (
              <Form.Control.Feedback className="d-block" type="invalid">
                {t(errors.startTime)}
              </Form.Control.Feedback>
            )}
          </FormGroupComponent>
        </Row>
        <Row className="align-items-start justify-content-center">
          <Col
            className={['my-md-2 align-self-sm-center text-center', Styles.rowLabel].join(' ')}
            xl={2}
          >
            {t('return')}
          </Col>
          <FormGroupComponent controlId="toSpot" as={Col} xl={4} md={4} xs={9}>
            <Form.Label
              className={['text-nowrap text-ellipsis d-block', Styles.inputLabel].join(' ')}
            >
              {t('toSpotLabel')}
            </Form.Label>
            <SpotAutosuggest
              placeholder=""
              onChange={this.handleToSpotChange}
              value={values.toSpot}
              error={errors.toSpot ? t(errors.toSpot) : ''}
              isInvalid={errors && !!errors.toSpot}
              disabled={endPickersDisabled}
            />
          </FormGroupComponent>
          <FormGroupComponent controlId="endDate" as={Col} xl={3} md={4} xs={9}>
            <Form.Label
              className={['text-nowrap text-ellipsis d-block', Styles.inputLabel].join(' ')}
            >
              {t('endDateLabel')}
            </Form.Label>
            <DatePickerInput
              date={values.endDate}
              onDateChange={this.handleEndDateChange}
              id="endDate"
              isDayBlocked={this.isDayBlocked}
              isDayHighlighted={this.isDayHighlighted}
              initialVisibleMonth={this.getInitialEndDayMonth}
              onFocusChange={onDateInputFocusChange}
              disabled={endPickersDisabled}
              invalid={!!errors.endDate}
            />
            {errors.endDate && (
              <Form.Control.Feedback className="d-block" type="invalid">
                {t(errors.endDate)}
              </Form.Control.Feedback>
            )}
          </FormGroupComponent>
          <FormGroupComponent controlId="endTime" as={Col} xl={3} md={4} xs={9}>
            <Form.Label
              className={['text-nowrap text-ellipsis d-block', Styles.inputLabel].join(' ')}
            >
              {t('endTimeLabel')}
            </Form.Label>
            <TimePickerInput
              onChange={this.handleEndTimeChange}
              value={values.endTime || ''}
              readOnly
              className={['w-100', errors.endTime ? 'is-invalid' : undefined].join(' ')}
              id="endTime"
              showSecond={false}
              minuteStep={1}
              disabled={endPickersDisabled}
            />
            {errors.endTime && (
              <Form.Control.Feedback className="d-block" type="invalid">
                {t(errors.endTime)}
              </Form.Control.Feedback>
            )}
          </FormGroupComponent>
        </Row>
      </Form>
    );
  }
}

export default translate(['create_booking_form', 'form_errors'])(PlaceAndDatesForm);
