// @flow

import * as React from 'react';
import type { Element } from 'react';
import { Card, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

type BookingInformationFormPropsType = {
  texts: SwitchCardTextsType,
  value: SwitchCardChoiceType,
  name: string,
  onChange: SwitchCardChoiceType => void,
  choices: SwitchCardChoicesType,
  FirstComponent: ?Element<*>,
  SecondComponent: ?Element<*>,
  renderAlternativeText?: string,
  hideButtons?: boolean,
  className: ?string,
  FirstChoiceComponent: any => Element<*> | null,
  SecondChoiceComponent: any => Element<*> | null,
  RootComponent: any => Element<*> | null,
  TitleComponent: any => Element<*> | null,
  children: React.Node,
};

const SwitchCard = ({
  texts,
  value,
  name,
  onChange,
  choices,
  FirstComponent,
  SecondComponent,
  renderAlternativeText,
  hideButtons,
  className,
  FirstChoiceComponent,
  SecondChoiceComponent,
  RootComponent,
  TitleComponent,
  children,
}: BookingInformationFormPropsType) => (
  <RootComponent className={['mb-3', className].join(' ')}>
    <TitleComponent className="text-center mb-3">{texts.title}</TitleComponent>
    {!hideButtons && (
      <ToggleButtonGroup
        type="radio"
        name={name}
        className="w-100 mb-3"
        value={value}
        onChange={onChange}
      >
        <FirstChoiceComponent value={choices.firstChoice}>{texts.firstChoice}</FirstChoiceComponent>
        <SecondChoiceComponent value={choices.secondChoice}>
          {texts.secondChoice}
        </SecondChoiceComponent>
      </ToggleButtonGroup>
    )}
    {typeof renderAlternativeText === 'string' && <p>{renderAlternativeText}</p>}
    {typeof renderAlternativeText === 'undefined' &&
      value === choices.firstChoice &&
      FirstComponent}
    {typeof renderAlternativeText === 'undefined' &&
      value === choices.secondChoice &&
      SecondComponent}
    {children}
  </RootComponent>
);

SwitchCard.defaultProps = {
  renderAlternativeText: undefined,
  hideButtons: false,
  className: undefined,
  FirstComponent: undefined,
  SecondComponent: undefined,
  RootComponent: ({ children, ...rest }) => (
    <Card {...rest}>
      <Card.Body className="align-items-center">{children}</Card.Body>
    </Card>
  ),
  TitleComponent: ({ children, ...rest }) => <Card.Title {...rest}>{children}</Card.Title>,
  FirstChoiceComponent: ({ children, ...props }) => (
    <ToggleButton size="sm" variant="outline-primary" className="flex-fill" {...props}>
      {children}
    </ToggleButton>
  ),
  SecondChoiceComponent: ({ children, ...props }) => (
    <ToggleButton size="sm" variant="outline-primary" className="flex-fill" {...props}>
      {children}
    </ToggleButton>
  ),
  children: null,
  texts: { firstChoice: '', secondChoice: '', title: '' },
  choices: { firstChoice: 'existing', secondChoice: 'new' },
};

export default SwitchCard;
