// @flow

import * as React from 'react';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import { translate } from 'Hoc';
import { type ApolloErrorType, Errors, type ErrorType, GqlError, parseGqlError } from 'Services';

const getFormattedDelayTime = (delay: number): string =>
  delay !== 0 ? moment.duration((delay - new Date().getTime()) / 1000, 's').humanize(true) : '';

const transformError = e => {
  if (e.extensions) {
    return { code: e.extensions.code, errors: [e] };
  }
  let error;
  try {
    error = JSON.parse(e.message);
  } catch {
    error = { code: Errors.errorOccurred, errors: [], data: {} };
  }

  return {
    code: error.code,
    errors: error.errors,
    data: error.data,
  };
};

export const getError = ({ networkError, graphQLErrors }: ApolloErrorType): ErrorType => {
  if (networkError) {
    return new GqlError(Errors.network);
  }

  if (graphQLErrors) {
    const gqlError = transformError(graphQLErrors[0]);

    return new GqlError(gqlError);
  }

  return undefined;
};

type translatePayload = { delay: string };

type GraphQlErrorHandlerPropsType = {
  children: React.Node,
  error: ApolloErrorType,
  t: (string, ?translatePayload) => string,
  checkErrorTranslation: boolean,
};

class GraphQlErrorHandler extends React.Component<GraphQlErrorHandlerPropsType> {
  componentDidUpdate() {
    const { error: propsError, t, checkErrorTranslation } = this.props;

    if (!propsError) {
      return;
    }

    let error = getError(propsError);
    error.message = t(error.translationKey);

    if (checkErrorTranslation) {
      error = parseGqlError(error);
      let delay;

      if (error.translationKey === 'unauthorizedUntilDelay') {
        delay = error.errors[0].extensions.until;
      }

      error.message = t(error.translationKey, delay && { delay: getFormattedDelayTime(delay) });
    }

    if (error.errors && error.errors.length > 0) {
      const reformatedErrors = error.errors.map(checkedError => {
        let errorCode = checkedError.code;

        if (checkedError.extensions) {
          errorCode = checkedError.extensions.code;
        }

        return {
          translationKey: errorCode,
          message: t(errorCode),
        };
      });
      error.errors = reformatedErrors;
    }

    Sentry.captureMessage(error && error.code ? error.code : JSON.stringify(error), error);

    throw error;
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default translate('gql_errors')(GraphQlErrorHandler);
