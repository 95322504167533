// @flow
import React from 'react';
import { Col } from 'react-bootstrap';
import { Price, PricingRow, Query } from 'Components';
import { translate } from 'Hoc';
import { GET_BOOKING } from './Gql/BookingUpdatePricingSummary';

type BookingUpdatePricingSummaryPropsType = {
  bookingId: string,
  t: string => string,
  className: ?string,
};

const FeeValueComponent = ({ value, isOffered, ...rest }: { value: number, className: string }) => (
  <Col {...rest} xs={4}>
    +<Price value={value} />
  </Col>
);

const TotalLabelComponent = ({
  label,
  className,
  ...rest
}: {
  label: string,
  className: string,
}) => (
  <Col as="h5" className={[className, 'text-uppercase'].join(' ')} {...rest}>
    {label}
  </Col>
);

const TotalValueComponent = ({ value, isOffered, ...rest }: { value: number }) => (
  <Col as="h5" {...rest} xs={4}>
    <Price value={value} />
  </Col>
);

const BookingUpdatePricingSummary = ({
  bookingId,
  t,
  className,
}: BookingUpdatePricingSummaryPropsType) => (
  <Query query={GET_BOOKING} variables={{ id: bookingId }}>
    {({
      data = {
        getBooking: {
          summaryPrices: { totalPrice: 0 },
          latestBookingUpdate: { routingFee: 0, administrativeFee: 0, parkingFee: 0, yieldsFee: 0 },
        },
      },
    }) => {
      const {
        getBooking: {
          latestBookingUpdate: { routingFee, administrativeFee, parkingFee, yieldsFee },
          summaryPrices: { totalPrice },
        },
      } = data;

      return (
        <Col className={['px-4 py-2', className].join(' ')}>
          <PricingRow value={totalPrice} label={t('initialPrice')} />
          <hr />
          {routingFee > 0 && (
            <PricingRow
              value={routingFee}
              label={t('routingFee')}
              ValueComponent={FeeValueComponent}
            />
          )}
          {administrativeFee > 0 && (
            <PricingRow
              value={administrativeFee}
              label={t('administrativeFee')}
              ValueComponent={FeeValueComponent}
            />
          )}
          {parkingFee > 0 && (
            <PricingRow
              value={parkingFee}
              label={t('parkingFee')}
              ValueComponent={FeeValueComponent}
            />
          )}
          {yieldsFee > 0 && (
            <PricingRow
              value={yieldsFee}
              label={t('yieldsFee')}
              ValueComponent={FeeValueComponent}
            />
          )}
          <hr />
          <PricingRow
            value={routingFee + administrativeFee + parkingFee + yieldsFee}
            label={t('total')}
            LabelComponent={TotalLabelComponent}
            ValueComponent={TotalValueComponent}
          />
        </Col>
      );
    }}
  </Query>
);

export default translate('booking_update_pricing_summary')(BookingUpdatePricingSummary);
