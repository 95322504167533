// @flow

import { gql } from '@apollo/client';

export const EXPORT_BOOKINGS = gql`
  mutation ExportBookings($type: String!, $bookingsFilters: BookingsFiltersInput!) {
    exportBookings(type: $type, bookingsFilters: $bookingsFilters) {
      url
    }
  }
`;
