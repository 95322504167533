// @flow

import React, { Component, Fragment } from 'react';
import { Nav } from 'react-bootstrap';
import { StepDivider } from 'Components';
import Styles from './Stylesheets/Stepper.module.scss';

type StepType = {
  value: string,
  label: string,
  description: string,
  disabled: boolean,
};

type StepperPropsType = {
  steps: Array<StepType>,
  onStepChange: string => void,
  currentStep: string,
};

class Stepper extends Component<StepperPropsType> {
  renderStepIndicator = (step: StepType, index: number) => {
    const { steps, currentStep } = this.props;
    const isLast = index === steps.length - 1;
    const isActive = currentStep === step.value;

    return (
      <Fragment key={step.value}>
        <Nav.Item className="text-center pl-xs-2 col-md-1 col-lg-1 col-xs-3 p-0 col">
          <Nav.Link
            eventKey={step.value}
            disabled={step.disabled}
            className={[
              'd-inline-flex',
              'align-items-center',
              'justify-content-center',
              'rounded-circle',
              'border',
              'border-info',
              'position-relative',
              isActive ? 'bg-info' : 'text-light-primary',
              Styles.stepItem,
            ].join(' ')}
          >
            {step.label}
            <span
              className={[
                Styles.stepDescription,
                'position-absolute',
                'text-sm-center',
                'text-right',
                'text-nowrap',
                'text-ellipsis',
                !isActive ? 'text-light-primary' : 'text-primary',
              ].join(' ')}
            >
              {step.description}
            </span>
          </Nav.Link>
        </Nav.Item>
        {!isLast && <StepDivider className={Styles.stepDivider} />}
      </Fragment>
    );
  };

  render() {
    const { steps, onStepChange, currentStep } = this.props;

    return (
      <>
        <Nav
          variant="pills"
          activeKey={currentStep}
          onSelect={onStepChange}
          className="align-items-center justify-content-center mb-5"
        >
          {steps.map(this.renderStepIndicator)}
        </Nav>
      </>
    );
  }
}

export default Stepper;
