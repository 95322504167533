// @flow

import * as React from 'react';
import { Mutation as RAMutation } from '@apollo/client/react/components';
import { GraphQlErrorHandler } from 'Components';

type MutationPropsType = {
  children: (((?MutationType) => Promise<any>), any) => React.Node,
  checkErrorTranslation: boolean,
};

const Mutation = ({ children, checkErrorTranslation, ...rest }: MutationPropsType) => (
  <RAMutation {...rest}>
    {(mutate, data) => (
      <GraphQlErrorHandler checkErrorTranslation={checkErrorTranslation} {...data}>
        {children(mutate, data)}
      </GraphQlErrorHandler>
    )}
  </RAMutation>
);

Mutation.defaultProps = {
  checkErrorTranslation: true,
};

export default Mutation;
