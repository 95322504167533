// @flow

import { gql } from '@apollo/client';

export const GET_BOOKING = gql`
  query PricingSummaryCardGetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      startAt
      endAt
      fromSpot {
        id
        name
      }
      toSpot {
        id
        name
      }
      summaryPrices {
        parkingValet
        totalPrice
        discountCode {
          code
          amount
        }
        gift {
          name
          amount
          rate
          hasOfferedService
        }
        serviceUpdates {
          name
          code
          price
          isOffered
        }
        administrativeFee
        parkingFee
        routingFee
        yieldsFee
        countAdministrativeFee
        countParkingFee
        countRoutingFee
        manualAdjustment
        cashback
      }
    }
  }
`;
