// @flow

import * as React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as Sentry from '@sentry/browser';
import LocalForage from 'localforage';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { AppConfig } from 'Config';
import { GET_AUTH, GET_USER, LOGIN } from 'Gql';
import { CacheResolvers, InitialStates } from 'LocalStates';
import { ectorV4ApolloClient } from 'Services';
import { ssoLogout } from '../Services/SSO';

const getAuthQueryOptions = {
  props: ({ data: { auth = InitialStates.auth }, ...props }) => ({
    ...props,
    auth,
  }),
};

const getUserQueryOptions = {
  props: ({ data: { me = InitialStates.me }, ...props }) => ({
    ...props,
    me,
  }),
};

const loginMutationOptions = {
  props: ({ loginMutation, ...props }) => ({
    ...props,
    login: async (email: string, password: string) =>
      loginMutation({ variables: { email, password } }),
  }),
  options: {
    update: async (cache, { data: { login } }) =>
      CacheResolvers.Mutation.login(undefined, login, { cache }),
  },
};

const logout = async () => {
  await ssoLogout();
  const data = { auth: InitialStates.auth };

  await LocalForage.setItem(AppConfig.persistence.authKey, data.auth);
  await ectorV4ApolloClient.cache.reset();

  return null;
};

const setPartnerHeaderCode = async (partnerHeaderCode: string) => {
  console.log(partnerHeaderCode);

  const { auth } = ectorV4ApolloClient.readQuery({ query: GET_AUTH });
  const data = {
    auth: {
      ...auth,
      partnerHeaderCode,
    },
  };

  await LocalForage.setItem(AppConfig.persistence.authKey, data.auth);
  await ectorV4ApolloClient.cache.reset();
  await ectorV4ApolloClient.writeQuery({ query: GET_AUTH, data });

  return null;
};

const loginPath = { pathname: '/login' };

export default (shouldRedirect: boolean = false) =>
  (WrappedComponent: React.ComponentType<*>) => {
    const component = props => {
      const {
        auth: { isConnected },
        match = { path: '' },
        me: { email },
      } = props;

      if (isConnected && email) {
        Sentry.setUser({
          email,
          username: email,
        });
      }

      return shouldRedirect && !isConnected && match.path !== loginPath.pathname ? (
        <Redirect to={loginPath} />
      ) : (
        <WrappedComponent {...props} logout={logout} setPartnerHeaderCode={setPartnerHeaderCode} />
      );
    };

    return compose(
      graphql(GET_AUTH, getAuthQueryOptions),
      graphql(GET_USER, getUserQueryOptions),
      graphql(LOGIN, { name: 'loginMutation', ...loginMutationOptions }),
    )(component);
  };
