// @flow

import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Loader, Price } from 'Components';
import { translate } from 'Hoc';

type ServiceListItemPropsType = {
  service: ServiceType,
  onClick: ServiceType => void,
  selected: boolean,
  disabled: boolean,
  t: string => string,
  fetching: boolean,
};

type ServiceListItemStateType = {
  isHovered: boolean,
};

class ServiceListItem extends Component<ServiceListItemPropsType, ServiceListItemStateType> {
  static defaultProps = {
    disabled: false,
    fetching: false,
  };

  constructor(props: ServiceListItemPropsType) {
    super(props);

    this.state = {
      isHovered: false,
    };
  }

  handleClick = () => {
    const { service, onClick, disabled } = this.props;

    if (!disabled) {
      onClick(service);
    }
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const { service, selected, t, disabled, fetching } = this.props;
    const { isHovered } = this.state;
    const hoverVariant = isHovered ? 'dark-neutral' : undefined;

    return (
      <ListGroup.Item
        className={[
          'm-2 w-25 p-1 d-flex flex-column justify-content-between rounded',
          disabled ? undefined : 'cursor-pointer',
        ].join(' ')}
        variant={hoverVariant}
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        active={selected}
        disabled={disabled}
      >
        {fetching ? (
          <Loader />
        ) : (
          <>
            <span className="d-block text-center">{service.name}</span>
            <span className="d-block text-center">
              {service.isOffered ? t('freeOption') : <Price value={service.price} />}
            </span>
          </>
        )}
      </ListGroup.Item>
    );
  }
}

export default translate('service_list_item')(ServiceListItem);
