// @flow

import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

export const Alerts = {
  createCollaboratorSucceed: 'createCollaboratorSucceed',
  disableCollaboratorSucceed: 'disableCollaboratorSucceed',
  cancelBookingSucceed: 'cancelBookingSucceed',
  bookingSucceed: 'bookingSucceed',
  bookingDisallowed: 'bookingDisallowed',
  bookingUpdateCarSucceed: 'bookingUpdateCarSucceed',
  bookingUpdatePlaceAndDatesSucceed: 'bookingUpdatePlaceAndDatesSucceed',
  bookingUpdateTravelSucceed: 'bookingUpdateTravelSucceed',
  updateCollaboratorSucceeded: 'updateCollaboratorSucceeded',
};

const { Types, Creators } = createActions({
  showAlert: ['alertName', 'title', 'message'],
  hideAlert: ['alertName'],
});

export default Creators;

const INITIAL_STATE: FormsStateType = Immutable({
  [Alerts.createCollaboratorSucceed]: {
    title: undefined,
    message: undefined,
    visible: false,
  },
  [Alerts.disableCollaboratorSucceed]: {
    title: undefined,
    message: undefined,
    visible: false,
  },
  [Alerts.cancelBookingSucceed]: {
    title: undefined,
    message: undefined,
    visible: false,
  },
  [Alerts.bookingSucceed]: {
    title: undefined,
    message: undefined,
    visible: false,
  },
  [Alerts.bookingDisallowed]: {
    title: undefined,
    message: undefined,
    visible: false,
  },
  [Alerts.bookingUpdateCarSucceed]: {
    title: undefined,
    message: undefined,
    visible: false,
  },
  [Alerts.bookingUpdatePlaceAndDatesSucceed]: {
    title: undefined,
    message: undefined,
    visible: false,
  },
  [Alerts.bookingUpdateTravelSucceed]: {
    title: undefined,
    message: undefined,
    visible: false,
  },
  [Alerts.updateCollaboratorSucceeded]: {
    title: undefined,
    message: undefined,
    visible: false,
  },
});

const showAlert = (
  state,
  { alertName, title, message }: { alertName: string, title: string, message: string },
) =>
  state.merge(
    {
      [alertName]: {
        visible: true,
        title,
        message,
      },
    },
    { deep: true },
  );

const hideAlert = (state, { alertName }: { alertName: string }) =>
  state.merge(
    {
      [alertName]: INITIAL_STATE[alertName],
    },
    { deep: true },
  );

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOW_ALERT]: showAlert,
  [Types.HIDE_ALERT]: hideAlert,
});
