// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row } from 'react-bootstrap';
import { MutationButton } from 'Components/index';
import translate from '../Hoc/translate';
import { DELETE_CAR } from './Gql/CarCard';
import Styles from './Stylesheets/CarCard.module.css';

type CarCardPropsType = {
  t: string => string,
  car: CarType,
  className: ?string,
};

const CarCard = ({ car, className, t }: CarCardPropsType) => (
  <Card className={['w-25 px-3 pt-1 pb-1 align-self-stretch', className].join(' ')}>
    <Row className="d-flex flex-nowrap align-items-start">
      <span className="flex-grow-1">
        {car &&
          (car.utilsCar
            ? `${car.utilsCar.brand} ${car.utilsCar.model} ${
                car.utilsCar.variants > 1 ? `(${t(car.utilsCar.motorization)})` : ''
              }`
            : car.name)}
      </span>
      <MutationButton
        mutation={DELETE_CAR}
        variables={{ id: car.id }}
        type="submit"
        className="bg-white text-primary p-0 border-0"
        refetchQueries={['GetCollaborators']}
      >
        <FontAwesomeIcon icon="trash-alt" className={Styles.deleteIcon} />
      </MutationButton>
    </Row>
    <Row>
      <span>{car && car.numberPlate}</span>
    </Row>
  </Card>
);

CarCard.defaultProps = {
  className: undefined,
};

// $FlowFixMe
export default translate('car_motorization')(CarCard);
