// @flow

import React from 'react';
import { Autosuggest, Query } from 'Components';
import type { AutosuggestPropsType } from 'Components/Autosuggest';
import { GET_SPOTS } from 'Gql';

const getSpotLabel = spot => spot.name;

const getSpotKey = spot => spot.code;

const SpotAutosuggest = (props: AutosuggestPropsType) => (
  <Query query={GET_SPOTS}>
    {({ data = { spots: [] } }) => {
      const spotsByPriority = [...data.spots].sort((a, b) => a.priority - b.priority);

      return (
        <Autosuggest
          {...props}
          data={spotsByPriority}
          getItemKey={getSpotKey}
          getItemLabel={getSpotLabel}
          getItemValue={getSpotKey}
        />
      );
    }}
  </Query>
);

SpotAutosuggest.defaultProps = Autosuggest.defaultProps;

export default SpotAutosuggest;
