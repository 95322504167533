// @flow

import React, { Component } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { compose } from 'redux';
import { MutationButton, Query } from 'Components';
import { GET_UTILS_BUSINESS_FIELDS } from 'Gql';
import { translate, withReduxAlerts, withReduxForm } from 'Hoc';
import { Alerts, Forms } from 'Reducers';
import { CREATE_COLLABORATOR } from './Gql/CreateCollaboratorForm';

type CreateCollaboratorFormPropsType = {
  createCollaboratorForm: CreateCollaboratorType,
  setCreateCollaboratorFormValue: (string, string | { [string]: string }) => void,
  t: (string, ?{}) => string,
  className: ?string,
  isCreateCollaboratorFormValid: CreateCollaboratorFormValuesType => boolean,
  clearCreateCollaboratorForm: () => void,
  onCompleted: () => void,
  showAlert: (string, string, string) => void,
};

type CreateCollaboratorFormStateType = {
  mutationError?: {
    message: string,
  },
};

class CreateCollaboratorForm extends Component<
  CreateCollaboratorFormPropsType,
  CreateCollaboratorFormStateType,
> {
  handleFirstNameChange: (event: SyntheticInputEvent<HTMLInputElement>, string) => void;

  handleLastNameChange: (event: SyntheticInputEvent<HTMLInputElement>, string) => void;

  handleEmailChange: (event: SyntheticInputEvent<HTMLInputElement>, string) => void;

  handlePhoneNumberChange: (event: SyntheticInputEvent<HTMLInputElement>, string) => void;

  handleChangeGroupName: (event: SyntheticInputEvent<HTMLInputElement>, string) => void;

  constructor(props) {
    super(props);
    const { clearCreateCollaboratorForm } = props;

    clearCreateCollaboratorForm();

    this.state = {
      mutationError: undefined,
    };

    this.handleFirstNameChange = this.handleFieldChange.bind(this, 'firstName');
    this.handleLastNameChange = this.handleFieldChange.bind(this, 'lastName');
    this.handleEmailChange = this.handleFieldChange.bind(this, 'email');
    this.handlePhoneNumberChange = this.handleFieldChange.bind(this, 'phoneNumber');
    this.handleChangeGroupName = this.handleFieldChange.bind(this, 'groupName');
  }

  componentDidCatch(mutationError: ErrorType) {
    this.setState({ mutationError });
  }

  handleBusinessFieldChange = (businessFieldCode, event) => {
    const {
      setCreateCollaboratorFormValue,
      createCollaboratorForm: {
        values: { businessFields: oldBusinessFields },
      },
    } = this.props;
    const {
      currentTarget: { value },
    } = event;

    const businessFields = {
      ...oldBusinessFields,
      [businessFieldCode]: value,
    };
    setCreateCollaboratorFormValue('businessFields', businessFields);
  };

  handleFieldChange(field, event) {
    const { setCreateCollaboratorFormValue } = this.props;
    const {
      currentTarget: { value },
    } = event;

    setCreateCollaboratorFormValue(field, field === 'phoneNumber' ? value.trim() : value);
  }

  handlePreventSubmit = event => event.preventDefault();

  handleCloseAlert = () => this.setState({ mutationError: undefined });

  handleSubmit = async createCollaborator => {
    const {
      isCreateCollaboratorFormValid,
      createCollaboratorForm: { values },
    } = this.props;

    const isFormValid = await isCreateCollaboratorFormValid(values);

    if (isFormValid) {
      createCollaborator({
        variables: {
          ...values,
          businessFields: Object.keys(values.businessFields).map(code => ({
            code,
            value: values.businessFields[code],
          })),
        },
      });
    }
  };

  handleCreateCollaboratorCompleted = ({
    createCollaborator: { firstName, lastName },
  }: {
    createCollaborator: CollaboratorType,
  }) => {
    const { onCompleted, showAlert, t } = this.props;

    showAlert(
      Alerts.createCollaboratorSucceed,
      t('createCollaboratorSucceedAlertTitle'),
      t('createCollaboratorSucceedAlertMessage', { firstName, lastName }),
    );
    onCompleted();
  };

  renderUtilsBusinessFieldInputByType = (_, businessField) => {
    const {
      createCollaboratorForm: { values, errors },
      t,
    } = this.props;

    switch (businessField.type) {
      case 'choice':
        return (
          <Form.Control
            as="select"
            onChange={e => this.handleBusinessFieldChange(businessField.code, e)}
            value={values.businessFields[businessField.code] || ''}
            isInvalid={
              typeof (
                errors &&
                errors.businessFields &&
                errors.businessFields[businessField.code]
              ) === 'string'
            }
          >
            <option value="">{t('business_fields:empty_value')}</option>
            {(businessField.choices ? JSON.parse(businessField.choices) : []).map(choiceCode => (
              <option key={choiceCode.value} value={choiceCode.value}>
                {choiceCode.label ? choiceCode.label : choiceCode.value}
              </option>
            ))}
          </Form.Control>
        );

      default:
        return (
          <Form.Control
            type="text"
            value={values.businessFields[businessField.code] || ''}
            onChange={e => this.handleBusinessFieldChange(businessField.code, e)}
            isInvalid={
              typeof (
                errors &&
                errors.businessFields &&
                errors.businessFields[businessField.code]
              ) === 'string'
            }
          />
        );
    }
  };

  renderUtilsBusinessFieldInput = (partnerCode, businessField) => {
    const {
      createCollaboratorForm: { errors },
      t,
    } = this.props;

    return (
      <Col
        as={Form.Group}
        controlId="phoneNumber"
        xs={6}
        key={businessField.code}
        className={businessField.isMandatory ? 'required' : ''}
      >
        <Form.Label className="text-primary">{businessField.name}</Form.Label>
        {this.renderUtilsBusinessFieldInputByType(partnerCode, businessField)}
        <Form.Control.Feedback type="invalid">
          {errors && errors.businessFields && t(errors.businessFields[businessField.code])}
        </Form.Control.Feedback>
      </Col>
    );
  };

  renderUtilsBusinessFieldsInputs = ({
    data: { me = { businessPartner: { code: '', utilsBusinessFields: [] } } },
  }) => {
    const {
      businessPartner: { code, utilsBusinessFields },
    } = me;

    return utilsBusinessFields.map(utilsBusinessField =>
      this.renderUtilsBusinessFieldInput(code, utilsBusinessField),
    );
  };

  render() {
    const {
      t,
      createCollaboratorForm: { values, errors },
      className,
    } = this.props;
    const { mutationError } = this.state;

    return (
      <Col onSubmit={this.handlePreventSubmit} as={Form} className={className}>
        <Alert
          dismissible
          variant="danger"
          show={typeof mutationError !== 'undefined'}
          onClose={this.handleCloseAlert}
        >
          <Alert.Heading>{t('errorOccurred')}</Alert.Heading>
          <p>{mutationError ? mutationError.message : ''}</p>
        </Alert>
        <Row>
          <Col as={Form.Group} controlId="firstName" className="required">
            <Form.Label className="text-primary">{t('firstName')}</Form.Label>
            <Form.Control
              type="text"
              value={values.firstName || ''}
              placeholder={t('firstName')}
              onChange={this.handleFirstNameChange}
              isInvalid={typeof errors.firstName === 'string'}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName && t(errors.firstName)}
            </Form.Control.Feedback>
          </Col>
          <Col as={Form.Group} controlId="lastName" className="required">
            <Form.Label className="text-primary">{t('lastName')}</Form.Label>
            <Form.Control
              type="text"
              value={values.lastName || ''}
              placeholder={t('lastName')}
              onChange={this.handleLastNameChange}
              isInvalid={typeof errors.lastName === 'string'}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName && t(errors.lastName)}
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col as={Form.Group} controlId="email" className="required" xs={6}>
            <Form.Label className="text-primary">{t('email')}</Form.Label>
            <Form.Control
              type="text"
              value={values.email || ''}
              placeholder={t('email')}
              onChange={this.handleEmailChange}
              isInvalid={typeof errors.email === 'string'}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email && t(errors.email)}
            </Form.Control.Feedback>
          </Col>
          <Col as={Form.Group} controlId="phoneNumber" xs={6} className="required">
            <Form.Label className="text-primary">{t('phoneNumber')}</Form.Label>
            <Form.Control
              type="text"
              value={values.phoneNumber || ''}
              placeholder={t('phoneNumber')}
              onChange={this.handlePhoneNumberChange}
              isInvalid={typeof errors.phoneNumber === 'string'}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phoneNumber && t(errors.phoneNumber)}
            </Form.Control.Feedback>
          </Col>
          <Col xs={6}>
            <Form.Label>{t('statusLabel')}</Form.Label>
            <Form.Control
              as="select"
              aria-label="option 1"
              onChange={this.handleChangeGroupName}
              value={values.groupName}
              defaultValue="collaborator"
            >
              <option value="administrator">{t('adminOption')}</option>
              <option value="manager">{t('managerOption')}</option>
              <option value="collaborator">{t('collaboratorOption')}</option>
            </Form.Control>
          </Col>
          <Query query={GET_UTILS_BUSINESS_FIELDS} variables={{ businessFieldType: 'user' }}>
            {this.renderUtilsBusinessFieldsInputs}
          </Query>
        </Row>
        <Row className="justify-content-center mt-2">
          <MutationButton
            mutation={CREATE_COLLABORATOR}
            variables={values}
            onCompleted={this.handleCreateCollaboratorCompleted}
            refetchQueries={['GetCollaborators']}
            onClick={this.handleSubmit}
            type="submit"
          >
            {t('submit')}
          </MutationButton>
        </Row>
      </Col>
    );
  }
}

export default compose(
  withReduxAlerts(),
  withReduxForm(Forms.createCollaborator),
  translate(['create_collaborator_form', 'form_errors', 'alerts']),
)(CreateCollaboratorForm);
