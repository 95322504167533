export default {
  urls: {
    ectorApi: process.env.REACT_APP_ECTOR_API_URL,
    ectorV4Api: process.env.REACT_APP_ECTOR_V4_API_URL,
    sentryDsn: 'https://26498f2fed084be396f03bf6f81c575c@o911992.ingest.sentry.io/5870685',
  },
  persistence: {
    apolloCacheKey: 'ector-cache-persist',
    schemaVersionKey: 'ector-schema-version',
    schemaVersion: process.env.REACT_APP_SCHEMA_VERSION,
    authKey: 'ector-auth-persist',
    reduxStoreKey: 'ector-store',
    reduxVersion: process.env.REACT_APP_REDUX_VERSION,
    reduxVersionKey: 'redux-persist-version',
    blacklistedReducers: ['modals', 'alerts'],
    refetchQueries: [
      'GetUser',
      'GetBookings',
      'GetCollaborators',
      'GetUtilsBusinessFields',
      'GetPaymentMethods',
      'GetFlashReport',
    ],
  },
  stripeApiKey: process.env.REACT_APP_STRIPE_API_KEY,
  isStaging: process.env.REACT_APP_ECTOR_API_URL.includes('staging'),
  isProductionBuild: process.env.NODE_ENV === 'production',
  logoutErrors: [
    'businessPartnerFieldError',
    'businessPartnersFieldError',
    'UserPartnerUnauthorized',
  ],
  format: {
    date: 'YYYY-MM-DD',
    time: 'HH:mm:ss',
  },
  gtm: {
    gtmId: process.env.REACT_APP_GTM_ID,
  },
  refundDelay: 24,
};
