// @flow

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withAuth } from 'Hoc';
import { CacheResolvers } from 'LocalStates';
import { apolloClient } from 'Services';

type ExternalLoginScreenPropsType = {
  match: {
    params: {
      token: string,
    },
  },
  auth: AuthStateType,
};

type ExternalLoginScreenStateType = {
  loading: boolean,
};

class ExternalLoginScreen extends Component<
  ExternalLoginScreenPropsType,
  ExternalLoginScreenStateType,
> {
  state = {
    loading: true,
  };

  async componentDidMount() {
    const {
      match: {
        params: { token },
      },
    } = this.props;

    if (token) {
      await CacheResolvers.Mutation.login(undefined, { token }, { cache: apolloClient.cache });
    }
    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return null;
    }

    return <Redirect to="/" />;
  }
}

export default withAuth()(ExternalLoginScreen);
