// @flow

import { gql } from '@apollo/client';

export const GET_BOOKING = gql`
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      startAt
      endAt
      fromSpot {
        id
        code
        name
      }
      toSpot {
        id
        code
        name
      }
      travelingNumberFrom
      travelingNumberTo
      contact {
        id
        lastName
        firstName
        email
        phone
      }
      car {
        id
        name
        utilsCar {
          brand
          model
          motorization
          variants
        }
        numberPlate
      }
      subscribedServices {
        code
        name
      }
    }
  }
`;
