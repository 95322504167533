// @flow

import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { DatePickerInput } from 'Components';
import { translate } from 'Hoc';
import { translateBusinessField } from 'Services';

type BusinessFieldInputPropsType = {
  t: string => string,
  partnerCode: string,
  code: string,
  name: string,
  placeholder: string,
  onChange: string => void,
  label: string,
  value: string,
  error: string,
  type: 'text' | 'number' | 'date',
  isMandatory?: boolean,
  choices: string,
  className?: string,
  labelClassName?: string,
  inputClassName?: string,
  timeout: number,
};

type BusinessFieldInputStateType = {
  timeoutId: ?TimeoutID,
  value: string,
};

class BusinessFieldInput extends Component<
  BusinessFieldInputPropsType,
  BusinessFieldInputStateType,
> {
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value && !state.timeoutId) {
      return { value: props.value };
    }

    return null;
  }

  static defaultProps = {
    className: undefined,
    labelClassName: undefined,
    inputClassName: undefined,
    isMandatory: false,
    timeout: 0,
  };

  state = {
    timeoutId: undefined,
    value: '',
  };

  componentDidMount() {
    const { value } = this.props;

    this.setState({ value });
  }

  componentWillUnmount() {
    const { timeoutId } = this.state;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }

  handleChangeText = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { onChange, timeout } = this.props;
    const { timeoutId: previousTimeoutId } = this.state;
    const {
      currentTarget: { value: newValue },
    } = event;

    if (previousTimeoutId) {
      clearTimeout(previousTimeoutId);
    }

    const timeoutId = setTimeout(() => {
      onChange(newValue);
    }, timeout);

    this.setState({ timeoutId, value: newValue });
  };

  handleChangeDate = momentDate => {
    const { onChange } = this.props;

    onChange(momentDate.format('YYYY-MM-DD'));
  };

  handleChangeChoice = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    const {
      currentTarget: { value: newValue },
    } = event;

    onChange(newValue);
  };

  renderInput() {
    const { t, error, type, inputClassName, placeholder, choices } = this.props;
    const { value } = this.state;

    switch (type) {
      case 'date':
        return (
          <DatePickerInput
            placeholder="JJ/MM/YYYY"
            date={value}
            onDateChange={this.handleChangeDate}
          />
        );

      case 'choice':
        return (
          <Form.Control
            as="select"
            onChange={e => this.handleChangeChoice(e)}
            value={value}
            isInvalid={typeof error === 'string'}
          >
            <option value="">{t('business_fields:empty_value')}</option>
            {JSON.parse(choices || '[]').map(choiceCode => (
              <option key={choiceCode.value} value={choiceCode.value}>
                {choiceCode.label ? choiceCode.label : choiceCode.value}
              </option>
            ))}
          </Form.Control>
        );

      default:
        return (
          <Form.Control
            className={inputClassName}
            placeholder={placeholder}
            aria-label={placeholder}
            onChange={this.handleChangeText}
            value={value}
            isInvalid={typeof error === 'string'}
            type="text"
          />
        );
    }
  }

  render() {
    const { partnerCode, code, name, error, isMandatory, className, labelClassName, label, t } =
      this.props;

    const fieldName = label || translateBusinessField(t, partnerCode, code, 'Name', name);

    return (
      <Form.Group className={className}>
        {fieldName && (
          <Form.Label className={[labelClassName, !isMandatory || 'font-weight-bold'].join(' ')}>
            {fieldName}
            {!isMandatory || '*'}
          </Form.Label>
        )}
        {this.renderInput()}
        <Form.Control.Feedback className="d-block" type="invalid">
          {t(error)}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
}

export default translate('form_errors')(BusinessFieldInput);
