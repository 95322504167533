// @flow

import { gql } from '@apollo/client';

export const GET_COLLABORATORS = gql`
  query GetCollaborators($searchText: String! = null, $page: Int = 1) {
    me {
      businessPartner {
        code
        collaborators(searchText: $searchText, page: $page) {
          results {
            id
            firstName
            lastName
            email
            mainContact {
              id
            }
          }
        }
      }
    }
  }
`;
