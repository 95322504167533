// @flow
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { RatingStars } from 'Components';
import { dotToComma } from 'Services';
import Styles from './Stylesheets/ValetMarkContent.module.css';

type ValetMarkContentPropsType = {
  value: number,
};

const ValetMarkContent = ({ value, ...rest }: ValetMarkContentPropsType) => (
  <Col {...rest}>
    <Row
      className={['justify-content-center text--cyan d-flex w-100', Styles.container].join(' ')}
      noGutters
    >
      <Col className={Styles.mark} md="auto">
        {value === 0 ? '-' : dotToComma(value)}
      </Col>
      <Col className={['mt-2', Styles.markAppend].join(' ')} md="auto">
        &nbsp;/5
      </Col>
    </Row>
    <Row noGutters className="w-100 mt-2">
      <RatingStars value={value} className="text-center text-accent" />
    </Row>
  </Col>
);

export default ValetMarkContent;
