// @flow

import { gql } from '@apollo/client';

export const GET_BOOKING = gql`
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      allowed
      disallowedReason
      subscribedServices {
        code
        name
      }
      summaryPrices {
        totalPrice
        discountCode {
          code
        }
      }
    }
  }
`;

export const UPDATE_QUOTE_HIDE_PRICE = gql`
  mutation UpdateQuote($id: ID!, $hidePrice: Boolean = false) {
    updateQuote(id: $id, hidePrice: $hidePrice) {
      id
      hidePrice
    }
  }
`;

export const PAY_QUOTE_WITH_CARD_ID = gql`
  mutation PayQuote(
    $id: ID!
    $price: String
    $cardId: ID = null
    $stripePaymentMethodId: String = null
    $paymentIntentId: String = null
  ) {
    payQuote(
      id: $id
      price: $price
      cardId: $cardId
      stripePaymentMethodId: $stripePaymentMethodId
      paymentIntentId: $paymentIntentId
    ) {
      id
      returnUrl
      price
      subscribedServices {
        code
        name
      }
      summaryPrices {
        totalPrice
      }
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods($showExpired: Boolean = false, $showDeleted: Boolean = false) {
    me {
      businessPartner {
        code
        paymentMethods(showExpired: $showExpired, showDeleted: $showDeleted) {
          id
          type
          brand
          last4digits
          name
        }
      }
    }
  }
`;
