// @flow
import React, { PureComponent } from 'react';
import { Button, Mutation } from 'Components';

type MutationButtonPropsType = {
  mutation: {},
  variables: ?{ [string]: any },
  update: ?(any, any) => ?Promise<*>,
  onClick: ((any) => Promise<*>) => any,
  onCompleted: ?(any) => ?Promise<any>,
  refetchQueries: ?Array<string>,
  onError: () => void,
  checkErrorTranslation: boolean,
  isLoading: boolean,
};

class MutationButton extends PureComponent<MutationButtonPropsType> {
  static defaultProps = {
    update: undefined,
    onClick: () => {},
    refetchQueries: [],
    onCompleted: undefined,
    onError: () => {},
    checkErrorTranslation: true,
    variables: undefined,
    isLoading: false,
  };

  renderButtonMutation = (mutate: any => Promise<*>, { loading }: { loading: boolean }) => {
    const {
      onClick: propsOnClick,
      mutation,
      refetchQueries,
      onCompleted,
      checkErrorTranslation,
      variables,
      onError,
      update,
      isLoading,
      ...buttonProps
    } = this.props;
    const onClick =
      propsOnClick !== MutationButton.defaultProps.onClick ? () => propsOnClick(mutate) : mutate;

    return (
      <Button
        disabled={loading || isLoading}
        loading={loading || isLoading}
        {...buttonProps}
        onClick={onClick}
      />
    );
  };

  render() {
    const {
      mutation,
      variables,
      update,
      onCompleted,
      refetchQueries,
      onError,
      checkErrorTranslation,
    } = this.props;

    return (
      <Mutation
        mutation={mutation}
        variables={variables}
        update={update}
        onCompleted={onCompleted}
        refetchQueries={refetchQueries}
        onError={onError}
        checkErrorTranslation={checkErrorTranslation}
      >
        {this.renderButtonMutation}
      </Mutation>
    );
  }
}

export default MutationButton;
