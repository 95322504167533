// @flow

import React from 'react';
import { Modal } from 'react-bootstrap';
import { CreateCollaboratorForm } from 'Containers';
import { translate } from 'Hoc';

const CreateCollaboratorModal = ({ t, ...modalProps }) => (
  <Modal {...modalProps}>
    <Modal.Header closeButton>
      <Modal.Title>{t('modalTitle')}</Modal.Title>
    </Modal.Header>
    <Modal.Body as={CreateCollaboratorForm} onCompleted={modalProps.onExit} />
  </Modal>
);

export default translate('create_collaborator_modal')(CreateCollaboratorModal);
