// @flow

import { gql } from '@apollo/client';

export const GET_COLLABORATOR = gql`
  query GetCollaborator($id: ID! = null, $contactId: ID! = null) {
    me {
      businessPartner {
        getCollaborator(id: $id, contactId: $contactId) {
          id
          cars {
            id
            name
            brand
            model
            utilsCar {
              brand
              model
              motorization
              variants
            }
            numberPlate
          }
        }
      }
    }
  }
`;
