// @flow

import { gql } from '@apollo/client';

export const DISABLE_COLLABORATOR = gql`
  mutation DisableCollaborator($id: ID!) {
    disableCollaborator(id: $id) {
      id
    }
  }
`;
