// @flow
import React, { PureComponent } from 'react';
// $FlowFixMe
import Lottie from 'react-lottie';
import { loader } from '../Resources';

type LoaderSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type LoaderColor =
  | 'primary'
  | 'light-primary'
  | 'neutral'
  | 'light-neutral'
  | 'dark-neutral'
  | 'background'
  | 'accent'
  | 'light-accent'
  | 'info'
  | 'action'
  | 'success'
  | 'error'
  | 'bronze'
  | 'silver'
  | 'gold'
  | 'platinum';

type LoaderProps = {
  size?: LoaderSize,
  color: LoaderColor,
  noPadding?: boolean,
  className?: string,
};

const parseLoaderSize = (size?: LoaderSize) => {
  switch (size) {
    case 'xs':
      return 24;

    case 'sm':
      return 32;

    case 'md':
      return 40;

    case 'lg':
      return 60;

    case 'xl':
      return 120;

    default:
      return undefined;
  }
};

class Loader extends PureComponent<LoaderProps> {
  static defaultProps = {
    size: 'md',
    color: 'primary',
    noPadding: false,
    className: undefined,
  };

  render() {
    const { size, color, noPadding, className } = this.props;

    return (
      <div className={className}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loader,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          style={{
            color: `var(--${color})`,
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'center',
            margin: noPadding ? 'auto' : '1rem auto',
            maxHeight: parseLoaderSize(size),
            maxWidth: parseLoaderSize(size),
            zIndex: 1,
          }}
        />
      </div>
    );
  }
}

export default Loader;
