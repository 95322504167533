// @flow

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Col, Container, Row, Table } from 'react-bootstrap';
import { compose } from 'redux';
import { Button, CreateCarModal, CreateCollaboratorModal } from 'Components';
import { CollaboratorsFilterForm, CollaboratorsList, DisableCollaboratorModal } from 'Containers';
import {
  Protect,
  protect,
  Roles,
  translate,
  withAuth,
  withReduxAlerts,
  withReduxForm,
  withReduxModals,
} from 'Hoc';
import { Alerts, Forms, Modals } from 'Reducers';
import Styles from './Stylesheets/CollaboratorsScreen.module.css';

type CollaboratorsScreenPropsType = {
  t: string => string,
  modals: ModalsStateType,
  hideModal: string => void,
  showModal: string => void,
  hideAlert: string => void,
  alerts: AlertsStateType,
  clearDisableCollaboratorForm: () => void,
};

type CollaboratorsScreenStateType = {
  mutationError: ?ErrorType,
};

class CollaboratorsScreen extends Component<
  CollaboratorsScreenPropsType,
  CollaboratorsScreenStateType,
> {
  hideAddCollaboratorModal: () => void;

  showAddCollaboratorModal: () => void;

  hideAddCollaboratorSucceedAlert: () => void;

  hideDisableCollaboratorSucceedAlert: () => void;

  hideAddCarModal: () => void;

  state = {
    mutationError: undefined,
  };

  constructor(props) {
    super(props);

    this.hideAddCollaboratorModal = props.hideModal.bind(this, Modals.createCollaborator);
    this.showAddCollaboratorModal = props.showModal.bind(this, Modals.createCollaborator);
    this.hideAddCarModal = props.hideModal.bind(this, Modals.createCar);

    this.hideAddCollaboratorSucceedAlert = props.hideAlert.bind(
      this,
      Alerts.createCollaboratorSucceed,
    );
    this.hideDisableCollaboratorSucceedAlert = props.hideAlert.bind(
      this,
      Alerts.disableCollaboratorSucceed,
    );
  }

  componentDidCatch(mutationError: ErrorType) {
    this.setState({ mutationError });
  }

  handleAddCollaborator = () => {
    this.showAddCollaboratorModal();
  };

  handleCloseMutationErrorAlert = () => {
    this.setState({ mutationError: undefined });
  };

  handleHideDisableCollaborator = () => {
    const { hideModal, clearDisableCollaboratorForm } = this.props;

    hideModal(Modals.disableCollaborator);
    clearDisableCollaboratorForm();
  };

  render() {
    const {
      t,
      modals: {
        [Modals.createCollaborator]: createCollaboratorModalVisible,
        [Modals.disableCollaborator]: disableCollaboratorModalVisible,
        [Modals.createCar]: createCarModalVisible,
      },
      alerts: {
        [Alerts.createCollaboratorSucceed]: createCollaboratorSucceedAlert,
        [Alerts.disableCollaboratorSucceed]: disableCollaboratorSucceedAlert,
      },
    } = this.props;
    const { mutationError } = this.state;

    return (
      <Container>
        <Col xs={12} className="d-flex justify-content-center mt-4 mb-2">
          <h2 className="font-weight-bold">{t('collaboratorsScreenTitle')}</h2>
        </Col>
        <Alert
          dismissible
          variant="danger"
          show={typeof mutationError !== 'undefined'}
          onClose={this.handleCloseMutationErrorAlert}
        >
          <Alert.Heading>{t('errorOccurred')}</Alert.Heading>
          <p>{mutationError ? mutationError.message : ''}</p>
        </Alert>
        <Alert
          dismissible
          variant="success"
          show={createCollaboratorSucceedAlert.visible}
          onClose={this.hideAddCollaboratorSucceedAlert}
        >
          <Alert.Heading>{createCollaboratorSucceedAlert.title}</Alert.Heading>
          <p>{createCollaboratorSucceedAlert.message}</p>
        </Alert>
        <Alert
          dismissible
          variant="success"
          show={disableCollaboratorSucceedAlert.visible}
          onClose={this.hideDisableCollaboratorSucceedAlert}
        >
          <Alert.Heading>{disableCollaboratorSucceedAlert.title}</Alert.Heading>
          <p>{disableCollaboratorSucceedAlert.message}</p>
        </Alert>
        <Row>
          <Col className="mb-2" xs={5}>
            <CollaboratorsFilterForm />
          </Col>
          <Col xs={7}>
            <Protect required={Roles.BusinessCollaboratorCreate}>
              <Button
                variant="primary"
                className="d-block ml-auto"
                onClick={this.handleAddCollaborator}
              >
                <FontAwesomeIcon icon="plus-circle" size="md" className="mr-1" />
                {t('addCollaborator')}
              </Button>
            </Protect>
          </Col>
        </Row>
        <Table className={['table-borderless', Styles.collaboratorsTable].join(' ')}>
          <tbody>
            <CollaboratorsList />
          </tbody>
        </Table>
        <CreateCollaboratorModal
          show={createCollaboratorModalVisible}
          onHide={this.hideAddCollaboratorModal}
          onExit={this.hideAddCollaboratorModal}
        />
        <DisableCollaboratorModal
          show={disableCollaboratorModalVisible}
          onHide={this.handleHideDisableCollaborator}
          onExit={this.handleHideDisableCollaborator}
        />
        <CreateCarModal
          show={createCarModalVisible}
          onHide={this.hideAddCarModal}
          onExit={this.hideAddCarModal}
        />
      </Container>
    );
  }
}

export default compose(
  translate(['collaborators_screen', 'gql_errors']),
  withAuth(true),
  protect(Roles.BusinessCollaboratorList),
  withReduxModals(),
  withReduxForm(Forms.updateCollaborator),
  withReduxForm(Forms.disableCollaborator),
  withReduxAlerts(),
)(CollaboratorsScreen);
