import { gql } from '@apollo/client';

export const UPDATE_BOOKING = gql`
  mutation UpdateBooking(
    $id: ID!
    $carId: ID = null
    $utilsCarId: ID = null
    $carNumberPlate: String = null
    $carColor: String = null
    $carHasRoofBox: Boolean = false
    $persistModification: Boolean = true
  ) {
    updateBooking(
      id: $id
      carId: $carId
      utilsCarId: $utilsCarId
      carNumberPlate: $carNumberPlate
      carColor: $carColor
      carHasRoofBox: $carHasRoofBox
      persistModification: $persistModification
    ) {
      id
      car {
        id
        name
        color
        numberPlate
        hasRoofBox
        utilsCar {
          brand
          model
          variants
          motorization
        }
      }
    }
  }
`;

export const GET_BOOKING = gql`
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
    }
  }
`;
