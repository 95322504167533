// @flow

import React, { Component } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { compose } from 'redux';
import { Button, MutationButton } from 'Components';
import { translate, withReduxAlerts, withReduxForm } from 'Hoc';
import { Alerts, Forms } from 'Reducers';
import { DISABLE_COLLABORATOR } from './Gql/DisableCollaboratorModal';

type DisableCollaboratorModalPropsType = {
  t: string => string,
  onExit: () => void,
  onHide: () => void,
  disableCollaboratorForm: FormStateType,
  showAlert: (string, string, string) => void,
  show: boolean,
};

type DisableCollaboratorModalStateType = {
  mutationError: ?ErrorType,
};

class DisableCollaboratorModal extends Component<
  DisableCollaboratorModalPropsType,
  DisableCollaboratorModalStateType,
> {
  constructor(props) {
    super(props);

    this.state = {
      mutationError: undefined,
    };
  }

  componentDidCatch(mutationError) {
    this.setState({ mutationError });
  }

  handleCloseAlert = () => this.setState({ mutationError: undefined });

  handleClick = () => {
    const { t, onExit, showAlert } = this.props;

    onExit();
    showAlert(
      Alerts.disableCollaboratorSucceed,
      t('disableCollaboratorSucceedAlertTitle'),
      t('disableCollaboratorSucceedAlertMessage'),
    );
  };

  render() {
    const {
      t,
      disableCollaboratorForm: { values },
      show,
      onHide,
      onExit,
    } = this.props;
    const { mutationError } = this.state;

    return (
      <Modal show={show} onHide={onHide} onExit={onExit}>
        <Modal.Header closeButton>
          <Modal.Title>{t('modalTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body onCompleted={onExit}>
          <Alert
            dismissible
            variant="danger"
            show={typeof mutationError !== 'undefined'}
            onClose={this.handleCloseAlert}
            className="m-2"
          >
            <Alert.Heading>{t('errorOccurred')}</Alert.Heading>
            <p>{mutationError ? mutationError.message : ''}</p>
          </Alert>
          {t('modalText')}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="link" onClick={onExit}>
            {t('backButton')}
          </Button>
          <MutationButton
            type="submit"
            mutation={DISABLE_COLLABORATOR}
            variables={values}
            onCompleted={this.handleClick}
            refetchQueries={['GetCollaborators']}
          >
            {t('submit')}
          </MutationButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default compose(
  translate('disable_collaborator_modal'),
  withReduxForm(Forms.disableCollaborator),
  withReduxAlerts(),
)(DisableCollaboratorModal);
