// @flow

import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { BusinessFieldInput } from 'Components';

type BusinessFieldsFormPropsType = {
  partnerCode: string,
  businessFields: Array<UtilsBusinessFieldType>,
  values: { [code: string]: string },
  errors: { [code: string]: string },
  onChange: ({ [field: string]: string | boolean }) => void,
};

class BusinessFieldsForm extends Component<BusinessFieldsFormPropsType> {
  renderBusinessField = (businessField: UtilsBusinessFieldType) => {
    const { partnerCode, onChange: onChangeProp, values, errors } = this.props;
    const onChange = (newValue: string) => onChangeProp({ [businessField.code]: newValue });

    return (
      <BusinessFieldInput
        key={businessField.code}
        partnerCode={partnerCode}
        code={businessField.code}
        name={businessField.name}
        type={businessField.type}
        className="col-md-6"
        placeholder=""
        value={values[businessField.code] || ''}
        error={errors[businessField.code]}
        onChange={onChange}
        isMandatory={businessField.isMandatory}
        choices={businessField.choices}
      />
    );
  };

  render() {
    const { businessFields } = this.props;

    return <Form.Row className="w-100">{businessFields.map(this.renderBusinessField)}</Form.Row>;
  }
}

export default BusinessFieldsForm;
