// @flow

import { gql } from '@apollo/client';

export const GET_BOOKING = gql`
  query RecapBookingScreenGetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      startAt
      endAt
      fromSpot {
        id
        name
      }
      toSpot {
        id
        name
      }
      subscribedServices {
        name
        code
      }
      travelingNumberFrom
      travelingNumberTo
      contact {
        id
        firstName
        lastName
        email
        phone
      }
      car {
        id
        name
        utilsCar {
          brand
          model
          motorization
          variants
        }
        numberPlate
      }
    }
  }
`;
