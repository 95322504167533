// @flow

import 'airbnb-browser-shims';
import 'react-app-polyfill/ie9';
import 'react-dates/initialize';
// $FlowFixMe
import 'rc-time-picker/assets/index.css';
// $FlowFixMe
import 'react-dates/lib/css/_datepicker.css';
import React, { Component } from 'react';
import { ApolloProvider } from '@apollo/client';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
// $FlowFixMe
import { PersistGate } from 'redux-persist/integration/react';
import { Loader } from 'Components';
import ErrorBoundary from 'Components/ErrorBoundary';
import { AppConfig } from 'Config';
import { App } from 'Containers';
import { AppSettingsProvider } from 'Context/AppSettings';
import { createApolloClient, createReduxStore, i18n } from 'Services';
// $FlowFixMe
import 'index.scss';

library.add(fas, faStar);
class EctorBusinessV2 extends Component<*, *> {
  state = {
    gqlClientLoaded: false,
    client: null,
    reduxStore: null,
    reduxPersistor: null,
    stripe: null,
  };

  setStripeInstance = () => {
    this.setState({ stripe: window.Stripe(AppConfig.stripeApiKey) });
  };

  async componentDidMount() {
    const client = await createApolloClient();
    const { store: reduxStore, persistor: reduxPersistor } = await createReduxStore();
    const stripeScriptElement = document.querySelector('#stripe-js');

    TagManager.initialize(AppConfig.gtm);
    this.setState({ client, gqlClientLoaded: true, reduxStore, reduxPersistor });

    if (AppConfig.isProductionBuild || AppConfig.isStaging) {
      Sentry.init({
        dsn: AppConfig.urls.sentryDsn,
        environment: process.env.REACT_APP_ENV,
        attachStacktrace: true,
      });
    }

    if (window.Stripe) {
      this.setStripeInstance();
    } else if (stripeScriptElement) {
      stripeScriptElement.addEventListener('load', this.setStripeInstance);
    }
  }

  render() {
    const { client, gqlClientLoaded, reduxStore, reduxPersistor, stripe } = this.state;

    return !gqlClientLoaded ? (
      <Loader size="lg" />
    ) : (
      <StripeProvider stripe={stripe}>
        <I18nextProvider i18n={i18n}>
          <ApolloProvider client={client}>
            <ReduxProvider store={reduxStore}>
              <PersistGate loading={null} persistor={reduxPersistor}>
                <AppSettingsProvider>
                  <ErrorBoundary>
                    <App />
                  </ErrorBoundary>
                </AppSettingsProvider>
              </PersistGate>
            </ReduxProvider>
          </ApolloProvider>
        </I18nextProvider>
      </StripeProvider>
    );
  }
}
// $FlowFixMe
ReactDOM.render(<EctorBusinessV2 />, document.getElementById('root'));
