// @flow

import React from 'react';
import { Pagination as BootstrapPagination } from 'react-bootstrap';

type PaginationPropsType = {
  currentPage: number,
  currentPerPage: number,
  total: number,
  onClickChangePage: number => void,
  onClickChangePerPage: (SyntheticInputEvent<*>) => void,
  size: ?string,
  perPageText: string,
};

const Pagination = ({
  currentPage,
  currentPerPage,
  total,
  onClickChangePage,
  onClickChangePerPage,
  size,
  perPageText,
}: PaginationPropsType) => {
  const firstPrevious = currentPage - 1;
  const secondPrevious = currentPage - 2;
  const firstNext = currentPage + 1;
  const secondNext = currentPage + 2;
  const perPageChoices = [10, 25, 50, 100];

  return (
    <div className="d-flex flex-column flex-nowrap align-items-center position-relative">
      <BootstrapPagination size={size}>
        <BootstrapPagination.First
          onClick={() => onClickChangePage(1)}
          disabled={currentPage <= 1}
        />
        <BootstrapPagination.Prev
          onClick={() => onClickChangePage(firstPrevious)}
          disabled={firstPrevious <= 0}
        />
        {secondPrevious > 0 && (
          <BootstrapPagination.Item onClick={() => onClickChangePage(secondPrevious)}>
            {secondPrevious}
          </BootstrapPagination.Item>
        )}
        {firstPrevious > 0 && (
          <BootstrapPagination.Item onClick={() => onClickChangePage(firstPrevious)}>
            {firstPrevious}
          </BootstrapPagination.Item>
        )}
        <BootstrapPagination.Item active>{currentPage}</BootstrapPagination.Item>
        {firstNext <= total && (
          <BootstrapPagination.Item onClick={() => onClickChangePage(firstNext)}>
            {firstNext}
          </BootstrapPagination.Item>
        )}
        {secondNext <= total && (
          <BootstrapPagination.Item onClick={() => onClickChangePage(secondNext)}>
            {secondNext}
          </BootstrapPagination.Item>
        )}
        <BootstrapPagination.Next
          onClick={() => onClickChangePage(firstNext)}
          disabled={firstNext > total}
        />
        <BootstrapPagination.Last
          onClick={() => onClickChangePage(total)}
          disabled={currentPage >= total}
        />
      </BootstrapPagination>
      <div className="position-absolute justify-content-end align-self-end">
        <div className="form-inline">
          <div className="form-group mb-1">
            <div className="mr-1">{perPageText}</div>
            <select
              id="inputState"
              className="form-control"
              value={currentPerPage}
              onChange={onClickChangePerPage}
            >
              {perPageChoices.map(value => (
                <option key={value}>{value}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  currentPage: 1,
  currentPerPage: 10,
  size: undefined,
  perPageText: 'Par page:',
};

export default Pagination;
