// @flow

import * as React from 'react';
import { Query as RAQuery } from '@apollo/client/react/components';
import type { DocumentNode } from 'graphql';
import { GraphQlErrorHandler, Loader } from 'Components';
import { AppConfig } from 'Config';
import { translate } from 'Hoc';

type QueryPropsType = {
  children: ({ data: any, loading: boolean, error: any }) => React.Node,
  checkErrorTranslation: boolean,
  query: DocumentNode,
  pollInterval: number,
  t: string => string,
  LoadingComponent: React.Node,
  withLoading: boolean,
};

type QueryStateType = {
  computedPollInterval: number,
};

class Query extends React.Component<QueryPropsType, QueryStateType> {
  shouldLoad: boolean;

  static defaultProps = {
    checkErrorTranslation: true,
    pollInterval: 0,
    LoadingComponent: <Loader />,
    withLoading: true,
  };

  constructor(props: QueryPropsType) {
    super(props);
    const { query, t, pollInterval, LoadingComponent } = props;
    let computedPollInterval;

    if (pollInterval === Query.defaultProps.pollInterval) {
      const queryDefinition = query.definitions.slice(0, 1)[0];
      const {
        name: { value: queryName },
      } = queryDefinition;
      const translatedPollInterval = Number(t(`pollInterval${queryName}`));
      this.shouldLoad =
        LoadingComponent !== Query.defaultProps.LoadingComponent ||
        AppConfig.persistence.refetchQueries.includes(queryName);

      computedPollInterval = Number.isNaN(translatedPollInterval)
        ? pollInterval
        : translatedPollInterval;
    } else {
      computedPollInterval = pollInterval;
    }

    this.state = {
      computedPollInterval,
    };
  }

  render() {
    const { children, checkErrorTranslation, LoadingComponent, withLoading, ...rest } = this.props;
    const { computedPollInterval } = this.state;

    return (
      <RAQuery {...rest} pollInterval={computedPollInterval}>
        {result => (
          <GraphQlErrorHandler checkErrorTranslation={checkErrorTranslation} error={result.error}>
            {withLoading && this.shouldLoad && result.loading ? LoadingComponent : children(result)}
          </GraphQlErrorHandler>
        )}
      </RAQuery>
    );
  }
}

export default translate('gql_options')(Query);
