// @flow
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
// $FlowFixMe
import 'moment/locale/fr';
// $FlowFixMe
import Translations from 'Resources/locales/fr.json';

i18n.use(LanguageDetector).init({
  fallbackLng: 'fr',
  ns: ['homepage'],
  whitelist: ['fr'],
  load: 'languageOnly',
  defaultNS: 'homepage',
  resources: Translations,
  debug: false,
});

moment.locale(i18n.locale);
moment.updateLocale('en', {
  longDateFormat: {
    llll: 'ddd MMM D YYYY',
  },
});
moment.updateLocale('fr', {
  longDateFormat: {
    LT: 'HH[h]mm',
    llll: 'ddd D MMM YYYY',
  },
});

export default i18n;
