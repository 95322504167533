// @flow
import React, { PureComponent } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import {
  PaidUpdatePlaceAndDatesModalBodyContent,
  PaidUpdatePlaceAndDatesModalFooterContent,
} from 'Containers';
import { translate } from 'Hoc';

type PaidUpdatePlaceAndDatesModalPropsType = {
  t: string => string,
  show: boolean,
  onHide: () => void,
  onCompleted: () => void,
  stripe: StripePropType,
};

type PaidUpdatePlaceAndDatesModalStateType = {
  status: 'confirmation' | 'payment',
  mutationError: ?ErrorType,
};

class PaidUpdatePlaceAndDatesModal extends PureComponent<
  PaidUpdatePlaceAndDatesModalPropsType,
  PaidUpdatePlaceAndDatesModalStateType,
> {
  state = {
    status: 'confirmation',
    mutationError: undefined,
  };

  componentDidUpdate(prevProps) {
    const { show: isVisible } = this.props;
    const { show: wasVisible } = prevProps;

    if (wasVisible && !isVisible) {
      this.setState({ status: 'confirmation' });
    }
  }

  handleError = (mutationError: ErrorType) => {
    this.setState({ mutationError });
  };

  handleStatusChange = (status: 'payment' | 'confirmation') => {
    this.setState({ status });
  };

  handleCloseAlert = () => {
    this.setState({ mutationError: undefined });
  };

  render() {
    const { t, show, onHide, onCompleted } = this.props;
    const { status, mutationError } = this.state;

    return (
      <Modal {...{ show, onHide }}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">{t('modalTitle')}</Modal.Title>
        </Modal.Header>
        <Alert
          dismissible
          variant="danger"
          show={typeof mutationError !== 'undefined'}
          onClose={this.handleCloseAlert}
          className="mx-4 my-2"
        >
          <Alert.Heading>{t('errorOccurred')}</Alert.Heading>
          <p>{mutationError ? mutationError.message : ''}</p>
        </Alert>
        <Modal.Body as={PaidUpdatePlaceAndDatesModalBodyContent} status={status} className="px-3" />
        <Modal.Footer
          as={PaidUpdatePlaceAndDatesModalFooterContent}
          onError={this.handleError}
          onCompleted={onCompleted}
          onStatusChange={this.handleStatusChange}
          onCancel={onHide}
          className="justify-content-center"
          status={status}
        />
      </Modal>
    );
  }
}

export default translate(['paid_update_place_dates_modal', 'gql_errors', 'stripe_errors'])(
  PaidUpdatePlaceAndDatesModal,
);
