import { ApolloClient, ApolloLink, HttpLink, Observable } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import { CachePersistor } from 'apollo-cache-persist';
import LocalForage from 'localforage';
import { AppConfig } from 'Config';
import { resetLocalCache } from 'Services';
import { ssoLogout } from './SSO';

const logout = async () => {
  await ssoLogout();
  await resetLocalCache();
  window.location.reload();
};

const request = async operation => {
  const auth = await LocalForage.getItem(AppConfig.persistence.authKey);
  const { headers = {} } = operation.getContext();

  headers['X-ECTOR-ORIGIN'] = 'business';

  const now = new Date();

  if (!auth) {
    return;
  }

  if (auth.expireAt < now.getTime()) {
    await logout();

    return;
  }

  if (auth.token) {
    headers.Authorization = `Bearer ${auth.token}`;
  }

  if (auth.partnerHeaderCode) {
    headers['X-ECTOR-PARTNER'] = auth.partnerHeaderCode;
  }
  operation.setContext({ headers });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(async observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

export let apolloClient = null; // eslint-disable-line import/no-mutable-exports
const cache = new InMemoryCache();

export const ectorV4ApolloClient = new ApolloClient({
  link: ApolloLink.from([
    requestLink,
    new HttpLink({
      uri: `${AppConfig.urls.ectorV4Api}/graphql`,
      credentials: 'same-origin',
    }),
  ]),
  cache,
  addTypename: false,
});

export const createApolloClient = async () => {
  apolloClient = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path, extensions }) => {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ); // eslint-disable-line
            try {
              if (AppConfig.logoutErrors.includes(extensions.code)) {
                logout();
              }
            } catch {}
          });
        }

        if (networkError) {
          if (!networkError.message.startsWith("Can't find field")) {
            logout();
          }
          Sentry.captureException(networkError);
          console.log(`[Network error]: ${networkError}`); // eslint-disable-line
        }
      }),
      requestLink,
      new HttpLink({
        uri: `${AppConfig.urls.ectorApi}/graphql`,
        credentials: 'same-origin',
      }),
    ]),
    cache,
    addTypename: false,
  });
  const persistor = new CachePersistor({
    cache,
    storage: LocalForage,
  });
  const {
    persistence: { schemaVersion, schemaVersionKey, authKey },
  } = AppConfig;
  const currentVersion = await LocalForage.getItem(schemaVersionKey);

  if (currentVersion === schemaVersion) {
    await persistor.restore();
  } else {
    await persistor.purge();
    await LocalForage.removeItem(authKey);
    await LocalForage.setItem(schemaVersionKey, schemaVersion);
  }

  return apolloClient;
};

export default { createApolloClient };
