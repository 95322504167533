// @flow

import React from 'react';
import * as firebase from 'firebase/app';
import { Modal } from 'react-bootstrap';
import { translate } from 'Hoc';

type NotSameLoginEmailModalPropsType = {
  t: (string, ?any) => string,
  show: () => void,
  onExit: () => void,
  onHide: () => void,
};

function NotSameLoginEmailModal({ t, show, onExit, onHide }: NotSameLoginEmailModalPropsType) {
  const user = firebase && firebase.apps.length ? firebase.auth().currentUser : null;

  return (
    <Modal show={show} onExit={onExit} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('not_same_login_email_modal:title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('not_same_login_email_modal:text', { email: user ? user.email : '' })}</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" type="button" onClick={onExit}>
          {t('not_same_login_email_modal:submit')}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default translate()(NotSameLoginEmailModal);
