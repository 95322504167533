// @flow

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MutationButton } from 'Components';
import { Protect, Roles } from 'Hoc';
import { GET_GLOBAL_INVOICE } from './Gql/GlobalInvoiceActions';

type GlobalInvoiceActionsPropsType = {
  className: string,
  globalInvoice: GlobalInvoiceType,
};

class GlobalInvoiceActions extends Component<GlobalInvoiceActionsPropsType> {
  handleClickInvoice = async (getGlobalInvoice: ({ variables: { id: string } }) => {}) => {
    const { globalInvoice } = this.props;

    getGlobalInvoice({
      variables: {
        id: globalInvoice.id,
      },
    });
  };

  handleGetGlobalInvoiceCompleted = ({
    getGlobalInvoice: { url },
  }: {
    getGlobalInvoice: { url: string },
  }) => {
    if (url) {
      const decodedUrl = decodeURIComponent(url);
      const a = document.createElement('a');

      if (document.body !== null) {
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = decodedUrl;
        a.click();

        window.URL.revokeObjectURL(decodedUrl);
        // $FlowFixMe
        document.body.removeChild(a);
      }
    }
  };

  render() {
    const { className } = this.props;

    return (
      <td className="align-middle d-flex justify-content-end">
        <Protect required={Roles.BusinessBillingInvoice}>
          <MutationButton
            size="sm"
            variant="transparent"
            className={['text-primary', className]}
            mutation={GET_GLOBAL_INVOICE}
            onClick={this.handleClickInvoice}
            onCompleted={this.handleGetGlobalInvoiceCompleted}
          >
            <FontAwesomeIcon icon="file-invoice" size="lg" />
          </MutationButton>
        </Protect>
      </td>
    );
  }
}

export default GlobalInvoiceActions;
