// @flow
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Price } from 'Components';

type PricingRowPropsType = {
  label: string,
  value: number,
  ValueComponent: ({ value: number, isOffered: boolean, className: string }) => React.Element<*>,
  LabelComponent: ({ label: string, className: string }) => React.Element<*>,
  className: string,
  count: number,
  isOffered: ?boolean,
};

const PricingRow = ({
  label,
  value,
  ValueComponent,
  LabelComponent,
  className,
  count,
  isOffered,
}: PricingRowPropsType) => (
  <Row className={className}>
    <LabelComponent label={count > 1 ? `${label} (x${count})` : label} xs={12} lg={8} />
    <ValueComponent
      value={value}
      isOffered={isOffered}
      xs={{ offset: 4 }}
      lg={{ offset: 0, span: 4 }}
      className="text-right text-nowrap"
    />
  </Row>
);

PricingRow.defaultProps = {
  ValueComponent: ({ value, isOffered, ...rest }: { value: number, isOffered: boolean }) => (
    <Col {...rest}>{isOffered ? 'Free' : <Price value={value} />}</Col>
  ),
  LabelComponent: ({ label, ...rest }: { label: string }) => <Col {...rest}>{label}</Col>,
  className: 'align-items-center',
  count: 1,
  isOffered: undefined,
};

export default PricingRow;
