// @flow

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, InputGroup } from 'react-bootstrap';
import { compose } from 'redux';
import { Button } from 'Components';
import { translate, withReduxForm } from 'Hoc';
import { Forms } from 'Reducers';

type CollaboratorsFilterFormPropsType = {
  t: string => string,
  collaboratorsFilterForm: FormStateType,
  setCollaboratorsFilterFormValues: CollaboratorsFilterFormValuesType => void,
};

class CollaboratorsFilterForm extends Component<CollaboratorsFilterFormPropsType> {
  handleChangeFilter = event => {
    const { setCollaboratorsFilterFormValues } = this.props;

    event.preventDefault();
    setCollaboratorsFilterFormValues({
      searchText: event.target.value,
      page: 1,
    });
  };

  handleClearFilter = () => {
    const { setCollaboratorsFilterFormValues } = this.props;

    setCollaboratorsFilterFormValues({
      searchText: '',
      page: 1,
    });
  };

  render() {
    const {
      t,
      collaboratorsFilterForm: { values },
    } = this.props;

    return (
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text id="searchInput">
            <FontAwesomeIcon icon="search" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          type="text"
          value={values.searchText}
          onChange={this.handleChangeFilter}
          placeholder={t('searchPlaceholder')}
          aria-label="Search"
          aria-describedby="searchInput"
        />
        {values.searchText && values.searchText.length > 0 && (
          <InputGroup.Append>
            <Button onClick={this.handleClearFilter} size="sm">
              <FontAwesomeIcon icon="times" />
            </Button>
          </InputGroup.Append>
        )}
      </InputGroup>
    );
  }
}

export default compose(
  withReduxForm(Forms.collaboratorsFilter),
  translate('collaborators_filter_form'),
)(CollaboratorsFilterForm);
