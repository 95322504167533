// @flow

import React, { Component, Fragment } from 'react';
import moment from 'moment';
import type Moment from 'moment';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { compose } from 'redux';
import {
  FlashReportCards,
  FlashReportPieChart,
  Loader,
  MonthPicker,
  Query,
  StatCard,
  ValetMarkContent,
} from 'Components';
import { translate, withAuth, withReduxForm } from 'Hoc';
import { Forms } from 'Reducers';
import { ucfirst } from 'Services';
import { GET_AIRTABLE_LINKS } from '../Gql/Jockey';
import { GET_FLASH_REPORT } from './Gql/DashboardScreen';
import Styles from './Stylesheets/DashboardScreen.module.css';
import JockeyStyles from './Stylesheets/Jockey.module.scss';

type DashboardScreenPropsType = {
  t: (string, ?{}) => string,
  flashReportForm: FlashReportFormType,
  setFlashReportFormValue: (string, string) => void,
  me: { businessPartner: { code: string, enableJockey: boolean } },
};

const selected = moment();

const startMonth = moment().subtract(11, 'M');

const endMonth = moment().add(1, 'M');

const getMonthsList = (start: Moment, end: Moment): Array<Moment> => {
  const months = [];

  const startDate = moment(start).startOf('month').startOf('day');
  const endDate = moment(end).startOf('month').startOf('day');
  let currentDate = endDate;
  while (currentDate.isAfter(startDate)) {
    currentDate = currentDate.subtract(1, 'month');
    months.push(currentDate.clone());
  }

  return months;
};

class DashboardScreen extends Component<DashboardScreenPropsType> {
  renderMonthPickerDropdownToggleComponent = ({ value }) => (
    <Dropdown.Toggle
      drop="down"
      id="monthSelection"
      className={[
        'bg-transparent border-0 py-0 text-primary font-weight-normal',
        Styles.titleDate,
      ].join(' ')}
      size="sm"
    >
      {ucfirst(value.format('MMMM YYYY'))}
    </Dropdown.Toggle>
  );

  handleChangeMonth = date => {
    const { setFlashReportFormValue } = this.props;

    setFlashReportFormValue('date', date.format('YYYY-MM-DD HH:mm:ss'));
    selected.year(date.year());
    selected.month(date.month());
  };

  renderIframe = ({ loading, data }) => {
    const { t } = this.props;
    const regex = /(https:\/\/airtable\.com)/gm;
    const subst = `$1/embed`;
    const jockeyListView =
      !loading && data.me ? data.me.businessPartner.airtableRidesLink.replace(regex, subst) : null;

    return (
      jockeyListView && (
        <>
          <h2 className={JockeyStyles.header}>{t('ridesSummary')}</h2>
          <div className={JockeyStyles.iframeContainer}>
            <Loader size="md" className={JockeyStyles.iframeLoader} />
            <div
              className={JockeyStyles.bookingForm}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `<iframe title="${t(
                  'ridesSummary',
                )}" class="airtable-embed" src="${jockeyListView}?backgroundColor=blue&viewControls=on" frameborder="0" onmousewheel="" width="100%" height="100%" style="background: transparent;"></iframe>`,
              }}
            />
          </div>
        </>
      )
    );
  };

  render() {
    const {
      t,
      flashReportForm: {
        values: { date },
      },
      me: { businessPartner },
    } = this.props;

    if (businessPartner.enableJockey) {
      return (
        <Query query={GET_AIRTABLE_LINKS} withLoading={false}>
          {this.renderIframe}
        </Query>
      );
    }

    return (
      <Container fluid>
        <Row className="justify-content-center font-weight-bold mt-4 mb-2" as="h2">
          <Col md="auto">{t('title')}</Col>
          <Col className="p-0" md="auto" as="span">
            -
          </Col>
          <Col md="auto" as="span" className="font-weight-light p-0">
            <MonthPicker
              onChange={this.handleChangeMonth}
              selected={selected}
              end={endMonth}
              start={startMonth}
              DropdownToggleComponent={this.renderMonthPickerDropdownToggleComponent}
              getMonths={getMonthsList}
            />
          </Col>
        </Row>
        {typeof date !== 'undefined' && businessPartner && businessPartner.code && (
          <Query
            query={GET_FLASH_REPORT}
            variables={{ date }}
            withLoading={false}
            context={{
              headers: {
                'X-ECTOR-PARTNER': businessPartner.code,
              },
            }}
          >
            {({ loading, data }) => {
              const flashReport =
                !data ||
                !data.me ||
                !data.me.businessPartner.getFlashReport ||
                !data.me.businessPartner.getFlashReport.flashReport
                  ? {
                      countBookings: 0,
                      countParkingDays: 0,
                      averageParkingDays: 0,
                      averageMarks: 0,
                      countBookingsByZones: [],
                    }
                  : data.me.businessPartner.getFlashReport.flashReport;

              return (
                <>
                  <FlashReportCards
                    flashReport={flashReport}
                    className={Styles.reportCards}
                    loading={loading}
                  />
                  <Row className="row-eq-height mt-4 justify-content-between">
                    <Col md={7} className="pl-0 pr-1">
                      <StatCard className="position-relative" label={t('travelDistribution')}>
                        {loading ? <Loader /> : <FlashReportPieChart flashReport={flashReport} />}
                      </StatCard>
                    </Col>
                    <Col md={5} className="pr-0 pl-1">
                      <StatCard label={t('valetMarkAverage')} className="d-flex flex-column h-100">
                        {loading ? (
                          <Loader />
                        ) : (
                          <ValetMarkContent
                            className="flex-column d-flex justify-content-center font-weight-bold"
                            value={flashReport.averageMarks}
                          />
                        )}
                      </StatCard>
                    </Col>
                  </Row>
                </>
              );
            }}
          </Query>
        )}
      </Container>
    );
  }
}

export default compose(
  withAuth(true),
  withReduxForm(Forms.flashReport),
  translate(['dashboard_screen', 'jockey']),
)(DashboardScreen);
