// @flow

import React, { Component } from 'react';
import { compose } from 'redux';
import { BookingRecapCard } from 'Components';
import { TravelRecapCardContent } from 'Containers';
import { translate, withReduxAlerts, withReduxForm } from 'Hoc';
import { Alerts, Forms } from 'Reducers';
import { UpdateBookingFormSteps } from 'Services';

type TravelRecapCardPropsType = {
  t: (string, ?{ [string]: string }) => string,
  booking: BookingType,
  editable: boolean,
  setUpdateBookingFormValue: (string, string) => void,
  setUpdateBookingFormValues: ({ [string]: ?string }) => void,
  updateBookingForm: UpdateBookingType,
  clearUpdateBookingForm: () => void,
  isUpdateBookingFormValid: (UpdateBookingFormValuesType, string) => boolean,
  showAlert: (string, string, string) => void,
  selected: boolean,
  onSelect: (?UpdateBookingCardNameType) => void,
  clearUpdateBookingForm: () => void,
};

class TravelRecapCard extends Component<TravelRecapCardPropsType> {
  handleChangeTravelingNumberFrom: string => void;

  handleChangeTravelingNumberTo: string => void;

  static defaultProps = {
    editable: false,
  };

  constructor(props) {
    super(props);

    this.handleChangeTravelingNumberFrom = this.handleFieldChange.bind(this, 'travelingNumberFrom');
    this.handleChangeTravelingNumberTo = this.handleFieldChange.bind(this, 'travelingNumberTo');
  }

  handleFieldChange(field: 'travelingNumberFrom' | 'travelingNumberTo', value: string) {
    const { setUpdateBookingFormValue } = this.props;

    setUpdateBookingFormValue(field, value);
  }

  handleEdit = () => {
    const { setUpdateBookingFormValues, booking, onSelect } = this.props;

    setUpdateBookingFormValues({
      travelingNumberFrom: booking.travelingNumberFrom,
      travelingNumberTo: booking.travelingNumberTo,
    });
    onSelect('travel');
  };

  handleCancel = () => {
    const { clearUpdateBookingForm, onSelect } = this.props;

    onSelect(undefined);
    clearUpdateBookingForm();
  };

  handleSubmit = async updateBooking => {
    const {
      isUpdateBookingFormValid,
      booking,
      updateBookingForm: { values },
    } = this.props;

    const isFormValid = await isUpdateBookingFormValid(values, UpdateBookingFormSteps.travel);

    if (isFormValid) {
      updateBooking({
        variables: {
          id: booking.id,
          travelingNumberFrom: values.travelingNumberFrom,
          travelingNumberTo: values.travelingNumberTo,
        },
      });
    }
  };

  handleBookingUpdateCompleted = () => {
    const { showAlert, t, onSelect } = this.props;

    window.scrollTo(0, 0);
    showAlert(
      Alerts.bookingUpdateTravelSucceed,
      t('bookingUpdateTravelSucceedTitle'),
      t('bookingUpdateTravelSucceedMessage'),
    );
    onSelect(undefined);
  };

  render() {
    const {
      editable,
      t,
      booking,
      selected,
      updateBookingForm: { values, errors },
    } = this.props;

    return (
      <BookingRecapCard
        title={t('travelRecapTitle')}
        onSelect={this.handleEdit}
        selected={selected}
        editable={editable}
      >
        <TravelRecapCardContent
          selected={selected}
          booking={booking}
          values={values}
          errors={errors}
          onChangeTravelingNumberFrom={this.handleChangeTravelingNumberFrom}
          onChangeTravelingNumberTo={this.handleChangeTravelingNumberTo}
          onClickCancelButton={this.handleCancel}
          onClickSaveMutation={this.handleSubmit}
          onUpdateCompleted={this.handleBookingUpdateCompleted}
        />
      </BookingRecapCard>
    );
  }
}

export default compose(
  translate(['recap_booking_screen', 'travel_recap_card']),
  withReduxForm(Forms.updateBooking),
  withReduxAlerts('dispatch'),
)(TravelRecapCard);
