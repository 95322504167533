// @flow

import React from 'react';
import { CollaboratorCard } from 'Components';
import { CollaboratorEditCard } from 'Containers';

type CollaboratorsListItemPropsType = {
  collaborator: CollaboratorType,
  isBeingEdited: boolean,
  toggleEditable: CollaboratorType => void,
  onClickDisable: CollaboratorType => void,
  onEditionCompleted: () => void,
};

const CollaboratorsListItem = ({
  collaborator,
  isBeingEdited,
  toggleEditable,
  onEditionCompleted,
  onClickDisable,
}: CollaboratorsListItemPropsType) =>
  isBeingEdited ? (
    <CollaboratorEditCard collaborator={collaborator} onCompleted={onEditionCompleted} />
  ) : (
    <CollaboratorCard
      collaborator={collaborator}
      onClickEdit={toggleEditable}
      onClickDisable={onClickDisable}
    />
  );

export default CollaboratorsListItem;
