// @flow

import React from 'react';
import { Form } from 'react-bootstrap';
import { translate } from 'Hoc';
import { colors } from 'Resources';

type ColorPickerPropsType = {
  t: string => string,
  value: string,
  className: string,
  onChange: string => void,
  isMandatory?: boolean,
  error: string,
};

const renderColorOption = color => (
  <option value={color.value} key={color.value}>
    {color.label}
  </option>
);

const ColorPicker = ({
  t,
  value,
  onChange,
  className,
  isMandatory,
  error,
}: ColorPickerPropsType) => {
  const handleOnChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value: eventValue },
    } = event;

    onChange(eventValue);
  };

  return (
    <Form.Group className={className} controlId="color">
      <Form.Label className={!isMandatory || 'font-weight-bold'}>
        {t('color')}
        {!isMandatory || '*'}
      </Form.Label>
      <Form.Control
        as="select"
        placeholder={t('colorPlaceholder')}
        value={value}
        onChange={handleOnChange}
        isInvalid={typeof error === 'string'}
      >
        <option value="">{t('colorPlaceholder')}</option>
        {colors.map(renderColorOption)}
      </Form.Control>
      <Form.Control.Feedback className="d-block" type="invalid">
        {t(error)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

ColorPicker.defaultProps = {
  isMandatory: false,
};

export default translate(['color_picker', 'form_errors'])(ColorPicker);
