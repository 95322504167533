import { gql } from '@apollo/client';

export const DELETE_CAR = gql`
  mutation DeleteCar($id: ID!) {
    deleteCar(id: $id) {
      id
      brand
      model
      color
      numberPlate
    }
  }
`;
