import { gql } from '@apollo/client';

export const GET_SIDEMENU_INFOS = gql`
  query GetUser {
    me {
      businessPartner {
        name
        code
        enableJockey
        paymentMethodTypes
        __typename
      }
      __typename
    }
  }
`;
