// @flow

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Col, Table } from 'react-bootstrap';
import { compose } from 'redux';
import { BookingRow, Pagination, Query } from 'Components';
import { BookingsFilters } from 'Config';
import { BookingRowActions } from 'Containers';
import { translate, withReduxForm } from 'Hoc';
import { Forms } from 'Reducers';
import { GET_BOOKINGS } from './Gql/BookingsListContainer';

type BookingsListContainerPropsType = {
  t: (string, ?{ [string]: string }) => string,
  filter: string,
  bookings: Array<BookingType>,
  utilsBusinessFields: Array<BusinessFieldType>,
  bookingsFilterForm: BookingsFilterType,
  setBookingsFilterFormValue: (string, number) => void,
  setBookingsFilterFormValues: BookingsFilterFormValuesType => void,
};

class BookingsListContainer extends Component<BookingsListContainerPropsType> {
  renderBusinessFieldHeader = utilsBusinessField => (
    <th className="font-weight-normal" key={utilsBusinessField.code}>
      {utilsBusinessField.name}
    </th>
  );

  handleChangePage = page => {
    const { setBookingsFilterFormValue } = this.props;

    setBookingsFilterFormValue('page', page);
  };

  handleChangePerPage = (event: SyntheticInputEvent<*>) => {
    const { setBookingsFilterFormValues } = this.props;

    event.preventDefault();
    setBookingsFilterFormValues({
      page: 1,
      perPage: +event.target.value,
    });
  };

  renderBookingRow = (booking, utilsBusinessFields) => {
    const { filter } = this.props;

    return (
      <BookingRow
        key={booking.id}
        currentTab={filter}
        booking={booking}
        utilsBusinessFields={utilsBusinessFields}
        ActionsComponent={() => (
          <BookingRowActions
            booking={booking}
            currentTab={filter}
            EditIconComponent={() => (
              <FontAwesomeIcon
                icon={
                  [BookingsFilters.incoming, BookingsFilters.current].includes(filter)
                    ? 'pen'
                    : 'eye'
                }
                size="lg"
              />
            )}
            InvoiceIconComponent={() => <FontAwesomeIcon icon="receipt" size="lg" />}
            CancelIconComponent={
              ![BookingsFilters.current, BookingsFilters.past, BookingsFilters.cancel].includes(
                filter,
              )
                ? () => <FontAwesomeIcon icon="times" size="lg" />
                : undefined
            }
          />
        )}
      />
    );
  };

  renderTable = ({ ...tableProps }) => {
    const {
      t,
      bookingsFilterForm: { values: bookingFiltersFormValues },
      filter,
    } = this.props;

    return (
      <Query
        query={GET_BOOKINGS}
        errorPolicy="ignore"
        variables={{
          page: +bookingFiltersFormValues.page,
          perPage: +bookingFiltersFormValues.perPage,
          type: bookingFiltersFormValues.type,
          shortId: bookingFiltersFormValues.shortId,
          startDateFrom: moment(bookingFiltersFormValues.selectedMonth).format(
            'YYYY-MM-DD HH:mm:ss',
          ),
          startDateTo: moment(bookingFiltersFormValues.selectedMonth)
            .endOf('month')
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss'),
          zoneCode: bookingFiltersFormValues.selectedZone || '',
          traveler: bookingFiltersFormValues.selectedCollaborator || '',
          businessFields:
            Object.entries(bookingFiltersFormValues.businessFields).map(entry => ({
              code: entry[0],
              value: entry[1],
            })) || [],
        }}
      >
        {({
          error,
          data: {
            me = {
              businessPartner: {
                bookings: { _metadata: { totalPages: 1 }, results: [] },
                utilsBusinessFields: [],
              },
            },
          },
        }) => {
          const {
            businessPartner: {
              bookings: {
                _metadata: { totalPages },
                results: bookings,
              },
              utilsBusinessFields,
            },
          } = me;
          const renderBookingRow = booking => this.renderBookingRow(booking, utilsBusinessFields);

          if (error) {
            return null;
          }

          return (
            <>
              <Table {...tableProps}>
                <thead>
                  <tr className="small">
                    <th className="font-weight-normal">{t('shortId')}</th>
                    <th className="font-weight-normal">{t('travelerTitle')}</th>
                    <th className="font-weight-normal">{t('spotTitle')}</th>
                    <th className="font-weight-normal">{t('fromDateTitle')}</th>
                    <th className="font-weight-normal">{t('toDateTitle')}</th>
                    {utilsBusinessFields.map(this.renderBusinessFieldHeader)}
                    {[BookingsFilters.past, BookingsFilters.cancel].includes(filter) && (
                      <th className="font-weight-normal">{t('refunded')}</th>
                    )}
                    <th className="font-weight-normal">{t('priceTitle')}</th>
                    <th className="font-weight-normal">{t('actionsTitle')}</th>
                  </tr>
                </thead>
                <tbody>
                  {bookings.map(renderBookingRow)}
                  {bookings.length === 0 && (
                    <tr>
                      <td colSpan={100}>
                        <span>{t('noBookings', { filter: t(filter.toLowerCase()) })}</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Pagination
                className="justify-content-center"
                currentPage={bookingFiltersFormValues.page}
                currentPerPage={bookingFiltersFormValues.perPage}
                total={totalPages}
                onClickChangePage={this.handleChangePage}
                onClickChangePerPage={this.handleChangePerPage}
              />
            </>
          );
        }}
      </Query>
    );
  };

  render() {
    return (
      <Col
        xs={12}
        as={this.renderTable}
        className="rounded-bottom bg-background text-center"
        responsive
      />
    );
  }
}

export default compose(
  translate('booking_screen_list'),
  withReduxForm(Forms.bookingsFilter),
)(BookingsListContainer);
