// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { CollaboratorsListItem, Pagination, Query } from 'Components';
import { translate, withReduxForm, withReduxModals } from 'Hoc';
import { Forms, Modals } from 'Reducers';
import { GET_COLLABORATORS } from './Gql/CollaboratorsList';

type CollaboratorsListPropsType = {
  t: string => string,
  clearUpdateCollaboratorForm: () => void,
  collaboratorsFilterForm: FormStateType,
  showModal: string => void,
  setUpdateCollaboratorFormValues: UpdateCollaboratorFormType => void,
  setCollaboratorsFilterFormValues: CollaboratorsFilterFormValuesType => void,
  setDisableCollaboratorFormValue: (string, ?string) => void,
  setCollaboratorsFilterFormValue: (string, ?number) => void,
};

type CollaboratorsListStateType = {
  collaboratorBeingEdited: ?string,
};

class CollaboratorsList extends Component<CollaboratorsListPropsType, CollaboratorsListStateType> {
  constructor(props: CollaboratorsListPropsType) {
    super(props);

    this.state = {
      collaboratorBeingEdited: undefined,
    };
  }

  handleToggleEditable = (collaborator: CollaboratorType) => {
    const { clearUpdateCollaboratorForm, setUpdateCollaboratorFormValues } = this.props;
    const businessFields = {};

    collaborator.businessFields.forEach(businessField => {
      businessFields[businessField.code] = businessField.value;
    });

    this.setState({ collaboratorBeingEdited: collaborator.id });
    clearUpdateCollaboratorForm();
    setUpdateCollaboratorFormValues({
      id: collaborator.id,
      firstName: collaborator.firstName,
      lastName: collaborator.lastName,
      email: collaborator.email,
      phoneNumber: collaborator.phoneNumber,
      groupName: collaborator.businessGroupName,
      businessFields,
    });
  };

  handleClickDisable = (collaborator: CollaboratorType) => {
    const { showModal, setDisableCollaboratorFormValue } = this.props;

    setDisableCollaboratorFormValue('id', collaborator.id);
    showModal(Modals.disableCollaborator);
  };

  handleChangePage = page => {
    const { setCollaboratorsFilterFormValue } = this.props;

    setCollaboratorsFilterFormValue('page', page);
  };

  handleChangePerPage = (event: SyntheticInputEvent<*>) => {
    const { setCollaboratorsFilterFormValues } = this.props;

    event.preventDefault();
    setCollaboratorsFilterFormValues({
      page: 1,
      perPage: +event.target.value,
    });
  };

  clearReducerAndState = () => {
    const { clearUpdateCollaboratorForm } = this.props;

    this.setState({ collaboratorBeingEdited: undefined });
    clearUpdateCollaboratorForm();
  };

  renderCollaborator = collaborator => {
    const { collaboratorBeingEdited } = this.state;

    return (
      <CollaboratorsListItem
        collaborator={collaborator}
        isBeingEdited={collaboratorBeingEdited === collaborator.id}
        toggleEditable={this.handleToggleEditable}
        onClickDisable={this.handleClickDisable}
        onEditionCompleted={this.clearReducerAndState}
        key={collaborator.id}
      />
    );
  };

  renderList = ({
    error,
    data = {
      me: {
        businessPartner: {
          collaborators: { _metadata: { totalPages: 1, totalResults: 0 }, results: [] },
        },
      },
    },
  }) => {
    if (error) {
      return null;
    }

    const {
      t,
      collaboratorsFilterForm: { values },
    } = this.props;
    const {
      businessPartner: {
        collaborators: {
          _metadata: { totalPages, totalResults },
          results: collaborators,
        },
      },
    } = data.me;

    return (
      <>
        <tr>
          <td colSpan="4" className="p-1">
            <strong>{totalResults}</strong>
            {` ${t('findedCollaborators')}`}
          </td>
        </tr>
        {collaborators.map(this.renderCollaborator)}
        <tr>
          <td colSpan="4">
            <Pagination
              currentPage={values.page}
              currentPerPage={values.perPage}
              total={totalPages}
              onClickChangePage={this.handleChangePage}
              onClickChangePerPage={this.handleChangePerPage}
            />
          </td>
        </tr>
      </>
    );
  };

  render() {
    const {
      collaboratorsFilterForm: { values },
    } = this.props;
    const getCollaboratorsQueryVariables = {
      searchText: values.searchText || null,
      page: +values.page || 1,
      perPage: +values.perPage || 10,
    };

    return (
      <Query query={GET_COLLABORATORS} variables={getCollaboratorsQueryVariables}>
        {this.renderList}
      </Query>
    );
  }
}

export default compose(
  withReduxForm(Forms.updateCollaborator),
  withReduxForm(Forms.disableCollaborator),
  withReduxForm(Forms.collaboratorsFilter),
  withReduxModals(),
  translate('collaborators_list'),
)(CollaboratorsList);
