import { gql } from '@apollo/client';

export const GET_COLLABORATORS = gql`
  query GetCollaborators($searchText: String! = null, $page: Int = 1, $perPage: Int = 10) {
    me {
      businessPartner {
        collaborators(searchText: $searchText, page: $page, perPage: $perPage) {
          _metadata {
            page
            perPage
            totalResults
            totalPages
          }
          results {
            id
            firstName
            lastName
            email
            phoneNumber
            businessGroupName
            cars {
              id
              color
              name
              brand
              model
              utilsCar {
                brand
                model
                motorization
                variants
              }
              numberPlate
            }
            businessFields {
              name
              code
              value
            }
            mainContact {
              id
            }
          }
        }
      }
    }
  }
`;
