// @flow

import * as React from 'react';
import { withNamespaces } from 'react-i18next';

export default function translate(namespace: string, namespaces: Array<string>) {
  return (WrappedComponent: React.ComponentType<*>) => {
    const component = class extends React.Component<{
      translateKeyPrefix?: string,
      t: (string, ?{}) => string,
    }> {
      static defaultProps = {
        translateKeyPrefix: null,
      };

      t = (key, variables, noPrefix = false) => {
        const { t, translateKeyPrefix } = this.props;

        return translateKeyPrefix && !noPrefix
          ? t(`${translateKeyPrefix}-${key}`, {
              ...variables,
              interpolation: { escapeValue: false },
            })
          : t(key, { ...variables, interpolation: { escapeValue: false } });
      };

      t2 = (key, variables, noPrefix = false) => (
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: this.t(key, variables, noPrefix) }}
        />
      );

      render() {
        return <WrappedComponent {...this.props} t2={this.t2} t={this.t} />;
      }
    };

    return withNamespaces(namespaces || namespace, { nsMode: 'fallback' })(component);
  };
}
