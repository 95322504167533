// @flow

import { gql } from '@apollo/client';

export const UPDATE_BOOKING = gql`
  mutation UpdateBooking(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $persistModification: Boolean = true
  ) {
    id
    contact {
      id
      firstName
      lastName
      email
      phoneNumber
    }
  }
`;
