// @flow

import { gql } from '@apollo/client';

export const UPDATE_BOOKING = gql`
  mutation UpdateBooking(
    $id: ID!
    $endAt: DateTime
    $startAt: DateTime
    $fromSpot: String
    $toSpot: String
    $persistModification: Boolean = false
  ) {
    updateBooking(
      id: $id
      endAt: $endAt
      startAt: $startAt
      fromSpot: $fromSpot
      toSpot: $toSpot
      persistModification: $persistModification
    ) {
      id
      latestBookingUpdate {
        id
        parkingFee
        administrativeFee
        routingFee
        yieldsFee
      }
    }
  }
`;
