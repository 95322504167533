import { gql } from '@apollo/client';

export const GET_AIRTABLE_LINKS = gql`
  query getAirtableLinks {
    me {
      businessPartner {
        airtableBookingsLink
        airtableBookingFormLink
        airtableRidesLink
      }
    }
  }
`;
