// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import { Button } from 'Components';
import { Protect, Roles } from 'Hoc';

type CollaboratorCardPropsType = {
  collaborator: CollaboratorType,
  onClickEdit: CollaboratorType => void,
  onClickDisable: CollaboratorType => void,
};

const CollaboratorCard = ({
  collaborator,
  onClickEdit,
  onClickDisable,
}: CollaboratorCardPropsType) => {
  const handleClickEditButton = () => onClickEdit(collaborator);
  const handleClickDisableButton = () => onClickDisable(collaborator);

  return (
    <Card as="tr" className="d-table-row">
      <td className="align-middle rounded-left">
        <FontAwesomeIcon icon="user-circle" size="lg" className="mr-1" />
        {`${collaborator.lastName.toUpperCase()} ${collaborator.firstName}`}
      </td>
      <td className="align-middle">
        <FontAwesomeIcon icon="envelope" size="lg" className="mr-1" />
        {collaborator.email}
      </td>
      <td className="align-middle">
        <FontAwesomeIcon icon="phone" size="lg" className="mr-1" />
        {collaborator.phoneNumber}
      </td>
      <td className="text-right rounded-right">
        <Protect required={Roles.BusinessCollaboratorEdit}>
          <Button
            size="sm"
            variant="transparent"
            className="text-primary mr-1"
            onClick={handleClickEditButton}
          >
            <FontAwesomeIcon icon="pen" />
          </Button>
        </Protect>
        <Protect required={Roles.BusinessCollaboratorDisable}>
          <Button
            size="sm"
            variant="transparent"
            className="text-primary"
            onClick={handleClickDisableButton}
          >
            <FontAwesomeIcon icon="user-slash" />
          </Button>
        </Protect>
      </td>
    </Card>
  );
};

export default CollaboratorCard;
