// @flow

import LocalForage from 'localforage';
import { combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { AppConfig } from 'Config';
import { alertsReducer, formsReducer, modalsReducer } from 'Reducers';
import { ImmutablePersistenceTransform } from 'Services';

const appReducer = combineReducers({
  forms: formsReducer,
  modals: modalsReducer,
  alerts: alertsReducer,
});

const persistenceConfig = {
  key: AppConfig.persistence.reduxStoreKey,
  storage: LocalForage,
  transforms: [ImmutablePersistenceTransform],
  blacklist: AppConfig.persistence.blacklistedReducers,
  version: AppConfig.persistence.reduxVersion,
};

const persistedReducer = persistReducer(persistenceConfig, appReducer);

const rehydrate = async store => {
  const {
    persistence: { reduxVersion, reduxVersionKey },
  } = AppConfig;
  let persistor;

  try {
    const localVersion = await LocalForage.getItem(reduxVersionKey);

    if (localVersion !== reduxVersion) {
      persistor = persistStore(store);
      await persistor.purge();
      await LocalForage.setItem(reduxVersionKey, reduxVersion);
    } else {
      persistor = persistStore(store);
      await LocalForage.setItem(reduxVersionKey, reduxVersion);
    }
  } catch {
    persistor = persistStore(store);
    await LocalForage.setItem(reduxVersionKey, reduxVersion);
  }

  return persistor;
};

export default async () => {
  const store = createStore<typeof persistenceConfig | boolean, typeof appReducer, any>(
    persistedReducer,
    !AppConfig.isProductionBuild &&
      window.__REDUX_DEVTOOLS_EXTENSION__ && // eslint-disable-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION__(), // eslint-disable-line no-underscore-dangle
  );
  const persistor = await rehydrate(store);

  return { store, persistor };
};
