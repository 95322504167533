// @flow

import React, { Component } from 'react';
import LocalForage from 'localforage';
import { Redirect } from 'react-router-dom';

type LogoutScreenStateType = {
  loading: boolean,
};

class LogoutScreen extends Component<null, LogoutScreenStateType> {
  state = {
    loading: true,
  };

  async componentDidMount() {
    await LocalForage.clear();
    localStorage.clear();
    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return null;
    }

    return <Redirect to="/" />;
  }
}

export default LogoutScreen;
