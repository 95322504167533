// @flow
import React, { PureComponent } from 'react';
import { Row } from 'react-bootstrap';
import { injectStripe } from 'react-stripe-elements';
import type { StripeShape } from 'react-stripe-elements';
import { compose } from 'redux';
import { Button, MutationButton, Query } from 'Components';
import { translate, withReduxForm } from 'Hoc';
import { Forms } from 'Reducers';
import { apolloClient, StripePaymentFormValidation } from 'Services';
import { getError } from '../Components/GraphQlErrorHandler';
import { GET_BOOKING, UPDATE_BOOKING } from './Gql/PaidUpdatePlaceAndDatesModalFooterContent';

type PaidUpdatePlaceAndDatesModalFooterContentPropsType = {
  t: (string, ?{}) => string,
  updateBookingForm: UpdateBookingType,
  paymentForm: PaymentType,
  className: string,
  onCompleted: () => void,
  onCancel: () => void,
  onError: ErrorType => void,
  status: 'payment' | 'confirmation',
  stripe: StripeShape,
  setPaymentFormErrors: UpdateBookingFormErrorsType => void,
  onStatusChange: ('payment' | 'confirmation') => void,
};

type PaidUpdatePlaceAndDatesModalFooterContentStateType = {
  loading: boolean,
};

class PaidUpdatePlaceAndDatesModalFooterContent extends PureComponent<
  PaidUpdatePlaceAndDatesModalFooterContentPropsType,
  PaidUpdatePlaceAndDatesModalFooterContentStateType,
> {
  static defaultProps = {
    className: undefined,
  };

  state = {
    loading: false,
  };

  componentDidCatch(mutationError: ErrorType) {
    const { onError } = this.props;

    if (mutationError.translationKey === 'apiErrorrequires_source_action' && mutationError.data) {
      this.handleRequiresSourceAction(mutationError.data);
    } else {
      onError(mutationError);
    }
  }

  getBooking = async () => {
    const {
      updateBookingForm: {
        values: { id },
      },
    } = this.props;

    return apolloClient.query({
      query: GET_BOOKING,
      variables: {
        id,
      },
    });
  };

  handleRequiresSourceAction = async (errorData: { client_secret: string }) => {
    const {
      t,
      stripe,
      onError,
      onCompleted,
      updateBookingForm: {
        values: { id, fromSpot, toSpot, endDate, endTime, startDate, startTime },
      },
    } = this.props;
    const { error: stripeError, paymentIntent } = await stripe.handleCardAction(
      errorData ? errorData.client_secret : '',
    );

    if (stripeError) {
      onError({ message: t('stripeRequiresSourceActionFail') });

      return undefined;
    }

    this.setState({ loading: true });

    const {
      data: {
        getBooking: {
          latestBookingUpdate: { routingFee, administrativeFee, parkingFee },
        },
      },
    } = await this.getBooking();
    const bookingUpdateFees = routingFee + administrativeFee + parkingFee;
    try {
      await apolloClient.mutate({
        mutation: UPDATE_BOOKING,
        variables: {
          id,
          fromSpot,
          toSpot,
          endAt: `${endDate || ''} ${endTime || ''}`,
          startAt: `${startDate || ''} ${startTime || ''}`,
          persistModification: true,
          stripePaymentMethodId: paymentIntent.payment_method,
          paymentIntentId: paymentIntent.id,
          amount: bookingUpdateFees,
        },
      });

      onCompleted();
    } catch (error) {
      const mutationError = getError(error);

      onError({ message: t(mutationError.translationKey) });
    }

    this.setState({ loading: false });

    return undefined;
  };

  getStripePaymentMethodId = async () => {
    const { stripe, setPaymentFormErrors } = this.props;
    const { error, paymentMethod } = await stripe.createPaymentMethod('card');

    if (error) {
      setPaymentFormErrors(StripePaymentFormValidation(error));

      return undefined;
    }

    return paymentMethod.id;
  };

  handleSubmit = async updateBooking => {
    const {
      updateBookingForm: {
        values: { id, fromSpot, toSpot, endDate, endTime, startDate, startTime },
      },
      paymentForm: {
        values: { selectedPaymentMethodId },
      },
      status,
      onStatusChange,
    } = this.props;
    let token;

    if (status === 'confirmation') {
      onStatusChange('payment');

      return;
    }

    this.setState({ loading: true });

    if (!selectedPaymentMethodId) {
      token = await this.getStripePaymentMethodId();
    }

    if (token || selectedPaymentMethodId) {
      await updateBooking({
        variables: {
          id,
          fromSpot,
          toSpot,
          endAt: `${endDate || ''} ${endTime || ''}`,
          startAt: `${startDate || ''} ${startTime || ''}`,
          stripePaymentMethodId: token,
          cardId: selectedPaymentMethodId,
          persistModification: true,
        },
      });
    }
    this.setState({ loading: false });
  };

  handleCancel = () => {
    const { status, onStatusChange, onCancel } = this.props;

    if (status === 'payment') {
      onStatusChange('confirmation');
    } else {
      onCancel();
    }
  };

  render() {
    const {
      className,
      t,
      status,
      onCompleted,
      updateBookingForm: {
        values: { id },
      },
    } = this.props;
    const { loading } = this.state;

    return (
      <Query query={GET_BOOKING} variables={{ id }}>
        {({
          data = {
            getBooking: {
              latestBookingUpdate: {
                routingFee: 0,
                administrativeFee: 0,
                parkingFee: 0,
                yieldsFee: 0,
              },
            },
          },
        }) => {
          const {
            getBooking: {
              latestBookingUpdate: { routingFee, administrativeFee, parkingFee, yieldsFee },
            },
          } = data;
          const bookingUpdateFees = routingFee + administrativeFee + parkingFee + yieldsFee;

          return (
            <Row className={className} noGutters>
              <Button variant="link" className="mr-2 font-weight-bold" onClick={this.handleCancel}>
                {status === 'payment' ? t('previousButton') : t('cancelButton')}
              </Button>
              <MutationButton
                mutation={UPDATE_BOOKING}
                onClick={this.handleSubmit}
                loading={loading}
                onCompleted={onCompleted}
                variables={{ amount: bookingUpdateFees }}
              >
                {status === 'payment'
                  ? t('payButton', { amount: bookingUpdateFees.toFixed(2) })
                  : t('nextButton')}
              </MutationButton>
            </Row>
          );
        }}
      </Query>
    );
  }
}

export default compose(
  injectStripe,
  translate('paid_update_place_dates_modal', 'gql_errors', 'stripe_errors'),
  withReduxForm(Forms.updateBooking, 'values'),
  withReduxForm(Forms.payment),
)(PaidUpdatePlaceAndDatesModalFooterContent);
