// @flow

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { compose } from 'redux';
import { Button, MutationButton, Query } from 'Components';
import { CarCard } from 'Containers';
import { translate, withReduxForm, withReduxModals } from 'Hoc';
import { Forms, Modals } from 'Reducers';
import { transformBusinessFieldsObjectToArray, translateBusinessField } from 'Services';
import { GET_USER_UTILS_BUSINESS_FIELDS, UPDATE_COLLABORATOR } from './Gql/CollaboratorEditCard';

type CollaboratorEditCardPropsType = {
  t: string => string,
  onCompleted: () => void,
  collaborator: CollaboratorType,
  updateCollaboratorForm: UpdateCollaboratorType,
  setUpdateCollaboratorFormValue: (string, string | { [string]: string }) => void,
  isUpdateCollaboratorFormValid: UpdateCollaboratorFormType => Promise<boolean>,
  hideModal: string => void,
  showModal: string => void,
  modals: ModalsStateType,
};

class CollaboratorEditCard extends Component<CollaboratorEditCardPropsType> {
  handleChangeFirstName: (SyntheticInputEvent<*>) => void;

  handleChangeLastName: (SyntheticInputEvent<*>) => void;

  handleChangeEmail: (SyntheticInputEvent<*>) => void;

  handleChangePhoneNumber: (SyntheticInputEvent<*>) => void;

  handleChangeGroupName: (SyntheticInputEvent<*>) => void;

  showAddCarModal: () => void;

  constructor(props) {
    super(props);

    this.handleChangeFirstName = this.handleChange.bind(this, 'firstName');
    this.handleChangeLastName = this.handleChange.bind(this, 'lastName');
    this.handleChangeEmail = this.handleChange.bind(this, 'email');
    this.handleChangePhoneNumber = this.handleChange.bind(this, 'phoneNumber');
    this.handleChangeGroupName = this.handleChange.bind(this, 'groupName');

    this.showAddCarModal = props.showModal.bind(this, Modals.createCar);
  }

  handleChange(fieldName, event: SyntheticInputEvent<*>) {
    const { setUpdateCollaboratorFormValue } = this.props;
    setUpdateCollaboratorFormValue(
      fieldName,
      fieldName === 'phoneNumber' ? event.target.value.trim() : event.target.value,
    );
  }

  handleChangeBusinessField = (code: string, event: SyntheticInputEvent<*>) => {
    const {
      setUpdateCollaboratorFormValue,
      updateCollaboratorForm: { values },
    } = this.props;

    const businessFields = {
      ...values.businessFields,
      [code]: event.target.value,
    };

    setUpdateCollaboratorFormValue('businessFields', businessFields);
  };

  handleSave = async updateCollaborator => {
    const {
      isUpdateCollaboratorFormValid,
      updateCollaboratorForm: { values },
    } = this.props;

    const isFormValid = await isUpdateCollaboratorFormValid(values);

    if (isFormValid) {
      const queryVariables = {
        id: values.id,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        groupName: values.groupName,
        businessFields: transformBusinessFieldsObjectToArray(values.businessFields),
      };

      updateCollaborator({ variables: queryVariables });
    }
  };

  renderBusinessFieldsInputByType = (partnerCode, businessField) => {
    const {
      updateCollaboratorForm: { values },
      t,
    } = this.props;

    switch (businessField.type) {
      case 'choice':
        return (
          <Form.Control
            as="select"
            onChange={e => this.handleChangeBusinessField(businessField.code, e)}
            value={values.businessFields[businessField.code]}
          >
            <option value="">{t('business_fields:empty_value')}</option>
            {JSON.parse(businessField.choices ? businessField.choices : '[]').map(choiceCode => (
              <option key={choiceCode.value} value={choiceCode.value}>
                {choiceCode.label ? choiceCode.label : choiceCode.value}
              </option>
            ))}
          </Form.Control>
        );

      default:
        return (
          <Form.Control
            type="text"
            value={values.businessFields[businessField.code]}
            onChange={e => this.handleChangeBusinessField(businessField.code, e)}
          />
        );
    }
  };

  renderBusinessFieldsInput = (partnerCode, businessField) => {
    const {
      updateCollaboratorForm: { errors },
      t,
    } = this.props;

    const fieldName = translateBusinessField(
      t,
      partnerCode,
      businessField.code,
      'Name',
      businessField.name,
    );

    return (
      <Col key={businessField.code}>
        <Form.Label>{fieldName}</Form.Label>
        {this.renderBusinessFieldsInputByType(partnerCode, businessField)}
        <Form.Control.Feedback className="d-block" type="invalid">
          {errors.businessFields &&
            errors.businessFields[businessField.code] &&
            t(errors.businessFields[businessField.code])}
        </Form.Control.Feedback>
      </Col>
    );
  };

  renderBusinessFieldsInputs = ({
    data = { me: { businessPartner: { code: '', utilsBusinessFields: [] } } },
  }) => {
    const {
      me: {
        businessPartner: { code, utilsBusinessFields },
      },
    } = data;

    return utilsBusinessFields.map(utilsBusinessField =>
      this.renderBusinessFieldsInput(code, utilsBusinessField),
    );
  };

  renderStatusField = ({ data = { me: { businessPartner: { utilsBusinessFields: [] } } } }) => {
    const {
      t,
      updateCollaboratorForm: { values },
    } = this.props;

    const className = !data.me.businessPartner.utilsBusinessFields.length ? 'col-sm-4' : '';

    return (
      <Col className={className}>
        <Form.Label>{t('statusLabel')}</Form.Label>
        <Form.Control
          as="select"
          aria-label="option 1"
          onChange={this.handleChangeGroupName}
          value={values.groupName}
        >
          <option value="administrator">{t('adminOption')}</option>
          <option value="manager">{t('managerOption')}</option>
          <option value="collaborator">{t('collaboratorOption')}</option>
        </Form.Control>
        <Form.Control.Feedback className="d-block" />
      </Col>
    );
  };

  getBusinessFieldsValues = () => {
    const {
      updateCollaboratorForm: { values },
    } = this.props;

    return transformBusinessFieldsObjectToArray(values.businessFields);
  };

  renderCarCard = car => <CarCard key={car.id} car={car} className="mr-1" />;

  render() {
    const {
      t,
      updateCollaboratorForm: { values, errors },
      collaborator,
      onCompleted,
    } = this.props;

    const editCollaboratorMutationVariables = {
      id: values.id,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      groupName: values.groupName,
      businessFields: this.getBusinessFieldsValues(),
    };

    return (
      <Card as="tr" className="d-table-row">
        <td colSpan={42} className="rounded">
          <Form>
            <Form.Row className="mb-2">
              <Col>
                <Form.Label>{t('firstNameLabel')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('firstNamePlaceholder')}
                  value={values.firstName}
                  onChange={this.handleChangeFirstName}
                />
                <Form.Control.Feedback className="d-block" type="invalid">
                  {errors.firstName && t(errors.firstName)}
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>{t('lastNameLabel')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('lastNamePlaceholder')}
                  value={values.lastName}
                  onChange={this.handleChangeLastName}
                />
                <Form.Control.Feedback className="d-block" type="invalid">
                  {errors.lastName && t(errors.lastName)}
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="mb-2">
              <Col>
                <Form.Label>{t('emailLabel')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('emailPlaceholder')}
                  value={values.email}
                  onChange={this.handleChangeEmail}
                />
                <Form.Control.Feedback className="d-block" type="invalid">
                  {errors.email && t(errors.email)}
                </Form.Control.Feedback>
              </Col>

              <Col>
                <Form.Label>{t('mobileLabel')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('mobilePlaceholder')}
                  value={values.phoneNumber}
                  onChange={this.handleChangePhoneNumber}
                />
                <Form.Control.Feedback className="d-block" type="invalid">
                  {errors.phoneNumber && t(errors.phoneNumber)}
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="align-items-end mb-2">
              <Query query={GET_USER_UTILS_BUSINESS_FIELDS}>{this.renderStatusField}</Query>
              <Query query={GET_USER_UTILS_BUSINESS_FIELDS}>
                {this.renderBusinessFieldsInputs}
              </Query>
            </Form.Row>
            <Form.Group className="mb-2 mx-0">
              <Form.Label>{t('cars')}</Form.Label>
              <Row className="mx-0">
                <Col xs={12}>
                  <Row>{collaborator.cars.map(this.renderCarCard)}</Row>
                  <FontAwesomeIcon
                    icon="plus-circle"
                    className="cursor-pointer mt-1"
                    size="2x"
                    onClick={this.showAddCarModal}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
          <Row className="d-flex justify-content-center">
            <Button variant="link" className="mr-3" onClick={onCompleted}>
              {t('cancelButton')}
            </Button>
            <MutationButton
              mutation={UPDATE_COLLABORATOR}
              variables={editCollaboratorMutationVariables}
              type="submit"
              onClick={this.handleSave}
              onCompleted={onCompleted}
              refetchQueries={['GetCollaborators']}
            >
              {t('saveButton')}
            </MutationButton>
          </Row>
        </td>
      </Card>
    );
  }
}

export default compose(
  withReduxForm(Forms.updateCollaborator),
  withReduxModals(),
  translate(['edit_collaborator', 'form_errors']),
)(CollaboratorEditCard);
