// @flow

import LocalForage from 'localforage';
import QueryString from 'query-string';
import { AppConfig } from 'Config';
import { apolloClient } from 'Services';

export const ucfirst = (str: string) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const dotToComma = (value: number) => value.toString().replace('.', ',');

export const getParamQueryString = (name: string, search: string) => {
  const queryStrings = QueryString.parse(search);

  return queryStrings[name];
};

export const transformBusinessFieldsObjectToArray = (businessFieldObject: BusinessFieldType) =>
  // $FlowFixMe
  Object.entries(businessFieldObject).map(([key, value]) => ({ code: key, value }));

export const transformBusinessFieldsArrayToObject = (
  businessFieldArray: Array<BusinessFieldType>,
) =>
  businessFieldArray.reduce(
    (businessFieldObject, businessField) => ({
      ...businessFieldObject,
      [businessField.code]: { ...businessField },
    }),
    {},
  );

export const resetLocalCache = async () => {
  const {
    persistence: { schemaVersionKey, reduxVersionKey, schemaVersion, reduxVersion },
  } = AppConfig;

  await LocalForage.setItem(schemaVersionKey, `${schemaVersion}_r`);
  await LocalForage.setItem(reduxVersionKey, `${reduxVersion}_r`);
  await apolloClient.clearStore();
};

const BUSINESS_FIELD_TRANSLATION_NAMESPACE = 'business_fields';

export const getBusinessFieldTranslationKey = (
  partnerCode: string | null,
  fieldCode: string,
  property: string,
) => `${partnerCode || ''}${fieldCode}${property}`;

export const translateBusinessFieldKey = (t: string => string, key: string) =>
  t(`${BUSINESS_FIELD_TRANSLATION_NAMESPACE}:${key}`);

const searchBusinessFieldTranslation = (
  t: string => string,
  partnerCode: string | null,
  fieldCode: string,
  property: string,
) => {
  const translationKey = getBusinessFieldTranslationKey(partnerCode, fieldCode, property);
  const translation = translateBusinessFieldKey(t, translationKey);

  return translation !== translationKey ? translation : '';
};

export const translateBusinessField = (
  t: string => string,
  partnerCode: string | null,
  fieldCode: string,
  property: string,
  defaultValue?: string,
) =>
  searchBusinessFieldTranslation(t, partnerCode, fieldCode, property) ||
  searchBusinessFieldTranslation(t, '', fieldCode, property) ||
  defaultValue ||
  getBusinessFieldTranslationKey(partnerCode, fieldCode, property);

export default ucfirst;
