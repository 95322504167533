// @flow

import React, { PureComponent } from 'react';
import moment from 'moment';
import { Card, Col } from 'react-bootstrap';
import { Price, PricingRow, Query } from 'Components';
import { translate } from 'Hoc';
import { GET_BOOKING } from './Gql/PricingSummaryCard';
import Styles from './Stylesheets/PricingSummaryCard.module.scss';

type PricingSummaryCardPropsType = {
  bookingId: ?string,
  visible: boolean,
  t: (string, ?{ [string]: string }) => string,
  totalPriceKey: string,
  sticky: boolean,
  className: ?string,
};

class PricingSummaryCard extends PureComponent<PricingSummaryCardPropsType> {
  static defaultProps = {
    totalPriceKey: 'totalPrice',
    sticky: true,
    className: undefined,
  };

  renderPricingServiceValueComponent = ({
    value,
    isOffered,
    ...rest
  }: {
    value: number,
    isOffered: boolean,
  }) => {
    const { t } = this.props;

    return (
      <Col xs={4} {...rest}>
        {isOffered ? t('offered') : <Price value={value} />}
      </Col>
    );
  };

  renderPricingService = service => (
    <PricingRow
      key={service.code}
      className="align-items-center"
      label={service.name}
      value={service.price}
      isOffered={service.isOffered}
      ValueComponent={this.renderPricingServiceValueComponent}
    />
  );

  renderTotalLabelComponent = ({ label, ...rest }) => (
    <Col as="h5" {...rest} lg={6}>
      {label}
    </Col>
  );

  renderTotalValueComponent = ({ value, className, isOffered, ...rest }) => (
    <Col
      as="h5"
      className={['text-uppercase', className].join(' ')}
      {...rest}
      lg={{ offset: 0, span: 6 }}
      xs={{ offset: 3 }}
    >
      <Price value={value} />
    </Col>
  );

  renderPricingSummaryCard = ({
    data = {
      getBooking: undefined,
    },
  }) => {
    const {
      getBooking = {
        summaryPrices: {
          serviceUpdates: [],
          parkingValet: 0,
          totalPrice: 0,
          discountCode: null,
          gift: null,
          administrativeFee: 0,
          parkingFee: 0,
          routingFee: 0,
          yieldsFee: 0,
          countAdministrativeFee: 0,
          countParkingFee: 0,
          countRoutingFee: 0,
          manualAdjustment: 0,
          cashback: 0,
        },
        fromSpot: {},
        toSpot: {},
        startAt: '',
        endAt: '',
      },
    } = data;
    const { t, totalPriceKey, sticky, className } = this.props;
    const { summaryPrices, startAt: rawStartAt, endAt: rawEndAt, fromSpot, toSpot } = getBooking;
    const startAt = moment(rawStartAt);
    const endAt = moment(rawEndAt);

    return (
      <Card className={sticky ? ['sticky-top', Styles.stickyCard, className].join(' ') : className}>
        <Card.Body>
          <Card.Title as="h3" className="text-nowrap text-ellipsis">
            {t('summary')}
          </Card.Title>
          <Card.Text as="div">
            <h5>{t('outward')}</h5>
            <p className="mb-0">{t('place', { name: fromSpot.name })}</p>
            <p>{t('dateTime', { date: startAt.format('LL'), time: startAt.format('LT') })}</p>
            <h5>{t('return')}</h5>
            <p className="mb-0">{t('place', { name: toSpot.name })}</p>
            <p>{t('dateTime', { date: endAt.format('LL'), time: endAt.format('LT') })}</p>
          </Card.Text>
          <Card.Title as="h3" className="text-nowrap text-ellipsis">
            {t('price')}
          </Card.Title>
          <Card.Text as="div">
            <PricingRow label={t('parkingValet')} value={summaryPrices.parkingValet} />
            {summaryPrices.serviceUpdates.map(this.renderPricingService)}
            {summaryPrices.discountCode && (
              <PricingRow
                label={t('discountCode', { code: summaryPrices.discountCode.code })}
                value={-summaryPrices.discountCode.amount}
              />
            )}
            {summaryPrices.gift && !summaryPrices.gift.hasOfferedService && (
              <PricingRow
                label={summaryPrices.gift.name || t('gift')}
                value={-summaryPrices.gift.amount}
              />
            )}
            {summaryPrices.administrativeFee > 0 && (
              <PricingRow
                label={t('administrativeFee')}
                value={summaryPrices.administrativeFee}
                count={summaryPrices.countAdministrativeFee}
              />
            )}
            {summaryPrices.parkingFee > 0 && (
              <PricingRow
                label={t('parkingFee')}
                value={summaryPrices.parkingFee}
                count={summaryPrices.countParkingFee}
              />
            )}
            {summaryPrices.routingFee > 0 && (
              <PricingRow
                label={t('routingFee')}
                value={summaryPrices.routingFee}
                count={summaryPrices.countRoutingFee}
              />
            )}
            {summaryPrices.yieldsFee > 0 && (
              <PricingRow label={t('yieldsFee')} value={summaryPrices.yieldsFee} />
            )}
            {summaryPrices.manualAdjustment < 0 && (
              <PricingRow label={t('manualAdjustment')} value={summaryPrices.manualAdjustment} />
            )}
            {summaryPrices.cashback > 0 && (
              <PricingRow label={t('cashback')} value={-summaryPrices.cashback} />
            )}
          </Card.Text>
          <hr />
          <Card.Text
            as={PricingRow}
            className="align-items-center"
            LabelComponent={this.renderTotalLabelComponent}
            label={t('total')}
            value={summaryPrices[totalPriceKey]}
            ValueComponent={this.renderTotalValueComponent}
          />
        </Card.Body>
      </Card>
    );
  };

  render() {
    const { bookingId: id, visible } = this.props;

    return visible && id ? (
      <Query query={GET_BOOKING} variables={{ id }}>
        {this.renderPricingSummaryCard}
      </Query>
    ) : null;
  }
}

export default translate('pricing_summary_card')(PricingSummaryCard);
