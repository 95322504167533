// @flow

import { gql } from '@apollo/client';

export const CREATE_COLLABORATOR = gql`
  mutation CreateCollaborator(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $groupName: String!
    $businessFields: [BusinessFieldInput!]!
  ) {
    createCollaborator(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      groupName: $groupName
      businessFields: $businessFields
    ) {
      id
      firstName
      lastName
      email
      phoneNumber
      businessGroupName
      cars {
        id
        name
        brand
        utilsCar {
          brand
          model
          motorization
          variants
        }
        numberPlate
        hasRoofBox
      }
      businessFields {
        name
        code
        value
      }
      mainContact {
        id
      }
    }
  }
`;
