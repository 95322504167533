// @flow

import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Alert, ButtonToolbar, Card, Form } from 'react-bootstrap';
import { injectStripe } from 'react-stripe-elements';
import type { StripeShape } from 'react-stripe-elements';
import { compose } from 'redux';
import { Button, Mutation, MutationButton, PaymentMethodsList, Query } from 'Components';
import { PurchaseCanalSteps } from 'Config';
import { DiscountCodeCardContent, PaymentForm, PaymentMethodSwitchCard } from 'Containers';
import { protect, Roles, translate, withAuth, withReduxForm } from 'Hoc';
import { Forms } from 'Reducers';
import {
  AddDiscountCodeErrors,
  apolloClient,
  getParamQueryString,
  parseGqlError,
  StripePaymentFormValidation,
} from 'Services';
import { getError } from '../Components/GraphQlErrorHandler';
import {
  GET_BOOKING,
  GET_PAYMENT_METHODS,
  PAY_QUOTE_WITH_CARD_ID,
  UPDATE_QUOTE_HIDE_PRICE,
} from './Gql/BookingPaymentForm';
import { GET_BOOKINGS } from './Gql/BookingsListContainer';

type BookingPaymentFormPropsType = {
  t: (string, ?{ [string]: string }) => string,
  history: RouterHistoryType,
  location: {
    search: () => string,
  },
  setPaymentFormValue: (string, string) => void,
  setPurchaseCanalFormValue: (string, string) => void,
  setPaymentFormError: (string, string) => void,
  setPaymentFormErrors: PaymentFormErrorsType => void,
  paymentForm: FormStateType,
  isPaymentFormValid: PaymentForm => boolean,
  stripe: StripeShape,
  purchaseCanalForm: FormStateType,
};

type BookingPaymentFormStateType = {
  paymentChoice: SwitchCardChoiceType,
  bookingId: string,
  mutationError: ?ErrorType,
  loading: boolean,
};

class BookingPaymentForm extends Component<
  BookingPaymentFormPropsType,
  BookingPaymentFormStateType,
> {
  handleBackClick: () => void;

  constructor(props: BookingPaymentFormPropsType) {
    super(props);
    const { location } = props;
    const id = getParamQueryString('id', location.search);
    let paymentMethods;

    try {
      const data = apolloClient.cache.readQuery({
        query: GET_PAYMENT_METHODS,
      });
      ({
        me: {
          businessPartner: { paymentMethods },
        },
      } = data);
    } catch {}

    this.state = {
      paymentChoice: paymentMethods && paymentMethods.length === 0 ? 'new' : 'existing',
      bookingId: id,
      mutationError: undefined,
      loading: false,
    };

    this.handleBackClick = this.handleClick.bind(
      this,
      `${PurchaseCanalSteps.travelerInformation}?id=${id}`,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { mutationError: prevMutationError } = prevState;
    const { mutationError: newMutationError } = this.state;

    if (newMutationError && newMutationError !== prevMutationError) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'auto',
        });
      }, 300);
    }
  }

  componentDidCatch(mutationError: ErrorType) {
    const { setPaymentFormError, t } = this.props;
    const catchError = mutationError;

    if (catchError.translationKey === 'apiErrorrequires_source_action' && catchError.data) {
      this.handleRequiresSourceAction(catchError.data);
    } else {
      if (AddDiscountCodeErrors.includes(catchError.translationKey)) {
        setPaymentFormError(
          'discountCode',
          catchError.message === catchError.translationKey ? ' ' : catchError.message,
        );
      }

      if (catchError.errors && catchError.errors.length > 0) {
        catchError.errors.map(error => {
          if (error.translationKey && error.translationKey.includes('stripeError')) {
            catchError.translationKey = error.translationKey;
            catchError.message = t(error.translationKey);
          }

          return error;
        });
      }

      const parsedError = parseGqlError(catchError);

      parsedError.message = t(parsedError.translationKey);
      this.setState({ mutationError: parsedError });
    }
  }

  getBooking = async () => {
    const { bookingId } = this.state;

    return apolloClient.query({
      query: GET_BOOKING,
      variables: {
        id: bookingId,
      },
    });
  };

  handleRequiresSourceAction = async (errorData: { client_secret: string }) => {
    const { t, stripe } = this.props;
    const { error: stripeError, paymentIntent } = await stripe.handleCardAction(
      errorData ? errorData.client_secret : '',
    );

    if (stripeError) {
      this.setState({ mutationError: { message: t('stripeRequiresSourceActionFail') } });

      return undefined;
    }

    this.setState({ loading: true });

    const {
      data: { getBooking: booking },
    } = await this.getBooking();

    try {
      const response = await apolloClient.mutate({
        mutation: PAY_QUOTE_WITH_CARD_ID,
        variables: {
          id: booking.id,
          price: booking.summaryPrices.totalPrice,
          stripePaymentMethodId: paymentIntent.payment_method,
          paymentIntentId: paymentIntent.id,
        },
        update: this.handlePaymentUpdateCache,
      });

      this.handlePaymentUpdateCache(apolloClient.cache, response);
      this.handlePaymentCompleted();
    } catch (error) {
      this.handlePayError();
      const mutationError = getError(error);
      mutationError.message = t(mutationError.translationKey);
      this.setState({ mutationError });
    }

    return undefined;
  };

  handleCloseAlert = () => this.setState({ mutationError: undefined });

  handleClick = path => {
    const { history } = this.props;

    history.push(path);
  };

  getStripePaymentMethodId = async () => {
    const { stripe, setPaymentFormErrors } = this.props;
    const { error, paymentMethod } = await stripe.createPaymentMethod('card');

    if (error) {
      const mutationError = { message: error.message, translationKey: error.code };
      setPaymentFormErrors(StripePaymentFormValidation(error));

      this.setState({ mutationError, loading: false });

      return undefined;
    }

    return paymentMethod.id;
  };

  handleClickPay = async pay => {
    const {
      paymentForm: { values: paymentFormValues },
      t,
    } = this.props;
    const { paymentChoice } = this.state;
    const paymentVariables = {};

    this.setState({ loading: true, mutationError: undefined });

    if (paymentChoice === 'new') {
      paymentVariables.stripePaymentMethodId = await this.getStripePaymentMethodId();
    } else {
      paymentVariables.cardId = paymentFormValues.selectedPaymentMethodId;
    }

    if (
      (paymentChoice === 'new' && paymentVariables.stripePaymentMethodId) ||
      paymentVariables.cardId
    ) {
      await pay({ variables: paymentVariables });
    } else if (paymentChoice === 'existing' && !paymentVariables.cardId) {
      this.setState({ loading: false, mutationError: { message: t('selectPaymentMethod') } });
    }
  };

  handlePayError = () => {
    this.setState({ loading: false });
  };

  handleClickPaymentMethod = paymentMethod => {
    const { setPaymentFormValue } = this.props;

    setPaymentFormValue('selectedPaymentMethodId', paymentMethod.id);
  };

  handleClickApplyDiscountCode = async addDiscountCode => {
    const {
      isPaymentFormValid,
      paymentForm: { values },
    } = this.props;
    const { bookingId } = this.state;
    const discountCodeMutationVariables = {
      id: bookingId,
      code: values.discountCode || null,
    };

    const isFormValid = await isPaymentFormValid(values);

    if (isFormValid) {
      await addDiscountCode({ variables: discountCodeMutationVariables });
    }
  };

  handlePaymentCompleted = () => {
    const { history } = this.props;
    const { bookingId } = this.state;

    this.setState({ loading: false });
    history.push(`/bookings/${bookingId}/recap`);
  };

  handlePaymentUpdateCache = async (cache, { data: { payQuote } }) => {
    try {
      const booking = await cache.readQuery({
        query: GET_BOOKING,
        variables: { id: payQuote.id },
      });
      const variables = {
        businessFields: [],
        traveler: '',
        zoneCode: '',
        startDateFrom: moment(payQuote.startAt)
          .startOf('month')
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss'),
        startDateTo: moment(payQuote.startAt)
          .endOf('month')
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss'),
      };
      await cache.writeQuery({
        query: GET_BOOKINGS,
        variables,
        data: {
          me: {
            businessPartner: {
              bookings: [{ ...booking, ...payQuote }],
            },
          },
        },
      });
    } catch (e) {}
  };

  handleCheckChange = mutate => {
    const {
      setPurchaseCanalFormValue,
      purchaseCanalForm: {
        values: { hidePrice, bookingId },
      },
    } = this.props;

    setPurchaseCanalFormValue('hidePrice', !hidePrice);
    mutate({
      variables: {
        id: bookingId,
        hidePrice: !hidePrice,
      },
    });
  };

  onChangePaymentMethod = choice => {
    this.setState({ paymentChoice: choice });
  };

  renderPaymentForm = ({
    data = {
      getBooking: {
        hidePrice: false,
        summaryPrices: { totalPrice: 0, discountCode: { code: '' } },
        subscribedServices: [],
        allowed: true,
        disallowedReason: null,
      },
    },
  }) => {
    const {
      t,
      purchaseCanalForm: { values: purchaseCanalFormValues },
      paymentForm: { values },
    } = this.props;
    const { paymentChoice, bookingId, mutationError, loading } = this.state;
    const paymentMutationVariables = {
      id: bookingId,
      price: data.getBooking.summaryPrices.totalPrice,
    };
    const message = {
      title: 'disallowedBookingAlertTitle',
      content: 'bookingDisallowedHeightContent',
    };

    if (
      data.getBooking.summaryPrices &&
      data.getBooking.subscribedServices &&
      data.getBooking.subscribedServices.length
    ) {
      message.title = 'optionDisallowedTitle';
      message.content = 'optionDisallowedContent';
    }

    const isAllowed = data.getBooking.allowed || data.getBooking.disallowedReason !== 'parking';

    return (
      <>
        <Alert
          dismissible
          variant="danger"
          show={typeof mutationError !== 'undefined'}
          onClose={this.handleCloseAlert}
        >
          <Alert.Heading>{t('errorOccurred')}</Alert.Heading>
          <p>{mutationError ? mutationError.message : ''}</p>
        </Alert>
        <Alert variant="danger" show={!isAllowed}>
          <Alert.Heading>{t(message.title)}</Alert.Heading>
          <p>{t(message.content)}</p>
        </Alert>
        <Form>
          <Card className="mb-3">
            <Card.Body className=" d-flex flex-column align-items-center">
              <Card.Title className="text-center mb-3">{t('emailConfigurationTitle')}</Card.Title>
              <Form.Group controlId="formBasicChecbox">
                <Mutation
                  mutation={UPDATE_QUOTE_HIDE_PRICE}
                  variables={{ hidePrice: purchaseCanalFormValues.hidePrice }}
                >
                  {mutate => (
                    <Form.Check
                      checked={purchaseCanalFormValues.hidePrice}
                      type="checkbox"
                      label={t('emailConfigurationCheckboxLabel')}
                      onChange={() => this.handleCheckChange(mutate)}
                    />
                  )}
                </Mutation>
              </Form.Group>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body className=" d-flex flex-column align-items-center">
              <Card.Title className="text-center mb-3">{t('discountCodeTitle')}</Card.Title>
              <DiscountCodeCardContent
                bookingId={bookingId}
                discountCode={data.getBooking.summaryPrices.discountCode}
                onClickApplyDiscountCode={this.handleClickApplyDiscountCode}
              />
            </Card.Body>
          </Card>
          <PaymentMethodSwitchCard
            choice={paymentChoice}
            onChange={this.onChangePaymentMethod}
            FirstComponent={({ paymentMethods }) => (
              <PaymentMethodsList
                paymentMethods={paymentMethods}
                onClick={this.handleClickPaymentMethod}
                selected={values.selectedPaymentMethodId}
              />
            )}
          />
        </Form>
        <ButtonToolbar className="justify-content-between my-2">
          <Button variant="link" onClick={this.handleBackClick}>
            {t('backButton')}
          </Button>
          <MutationButton
            mutation={PAY_QUOTE_WITH_CARD_ID}
            variables={paymentMutationVariables}
            onCompleted={this.handlePaymentCompleted}
            update={this.handlePaymentUpdateCache}
            type="submit"
            onClick={this.handleClickPay}
            loading={loading}
            disabled={loading || !isAllowed}
            onError={this.handlePayError}
          >
            {t('confirmAndPayLabel')}
          </MutationButton>
        </ButtonToolbar>
      </>
    );
  };

  render() {
    const { bookingId } = this.state;

    return (
      <Query query={GET_BOOKING} variables={{ id: bookingId }}>
        {this.renderPaymentForm}
      </Query>
    );
  }
}

export default compose(
  injectStripe,
  translate([
    'booking_payment_form',
    'gql_errors',
    'form_errors',
    'create_booking_screen',
    'create_booking_form',
  ]),
  withAuth(true),
  withReduxForm(Forms.payment),
  withReduxForm(Forms.purchaseCanal),
  protect(Roles.BusinessBookingCreate),
)(BookingPaymentForm);
