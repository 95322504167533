// @flow

import React, { Component } from 'react';
import { ApolloProvider } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from 'firebase/app';
import LocalForage from 'localforage';
import { Alert, Badge, Col, Form, Row } from 'react-bootstrap';
import { compose } from 'redux';
import { Button, MissingPasswordModal, MutationButton } from 'Components';
import { AppConfig } from 'Config';
import { CHECK_EMAIL, LOGIN, SWAP_TOKEN } from 'Gql';
import { translate, withAuth, withReduxForm, withReduxModals } from 'Hoc';
import { CacheResolvers } from 'LocalStates';
import { Forms, Modals } from 'Reducers';
import { ectorV4ApolloClient } from 'Services';
import { pursueLogin } from '../Services/Login';
import {
  checkedEmailKey,
  getSsoToken,
  signInWithSSO,
  ssoLogout,
  SsoTokenErrors,
  updateCacheLoginSwapToken,
} from '../Services/SSO';

type LoginFormPropsType = {
  onFieldValueChange: (string, string) => void,
  onSubmit: () => void,
  hideModal: (modalName: string) => void,
  showModal: (modalName: string) => void,
  t: string => string,
  setLoginFormValue: (string, string) => void,
  loginForm: LoginType,
  modals: ModalsStateType,
  isLoginFormValid: LoginFormType => boolean,
  clearLoginForm: () => void,
  setPartnerHeaderCode: string => void,
  className?: string,
};

type LoginFormStateType = {
  mutationError: ?ErrorType,
  ssoStep: string,
  isSsoLoading: boolean,
};

const SSO_STEPS = {
  NOT_CHECKED: 'NOT_CHECKED',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
};

class LoginForm extends Component<LoginFormPropsType, LoginFormStateType> {
  handleEmailValueChange: (SyntheticEvent<*>) => Promise<*>;

  handlePasswordValueChange: (SyntheticEvent<*>) => Promise<*>;

  handleMissingPasswordShowModal: (SyntheticEvent<*>) => Promise<*>;

  handleMissingPasswordHideModal: (SyntheticEvent<*>) => Promise<*>;

  static defaultProps = {
    className: undefined,
  };

  constructor(props) {
    super(props);
    const { showModal, hideModal, clearLoginForm } = props;

    this.handleMissingPasswordShowModal = showModal.bind(this, Modals.missingPassword);
    this.handleMissingPasswordHideModal = hideModal.bind(this, Modals.missingPassword);
    this.handleEmailValueChange = this.handleFieldChange.bind(this, 'email');
    this.handlePasswordValueChange = this.handleFieldChange.bind(this, 'password');
    this.state = {
      mutationError: undefined,
      ssoStep: SSO_STEPS.NOT_CHECKED,
      isSsoLoading: false,
    };
    clearLoginForm();
  }

  componentDidCatch(mutationError) {
    this.setState({ mutationError });
  }

  loginWithSsoCacheUpdate = async (cache, { data }) => {
    const { t } = this.props;
    const firebaseUser = firebase && firebase.apps.length ? firebase.auth().currentUser : null;

    const hasSucceed = await updateCacheLoginSwapToken(
      cache,
      { data },
      firebaseUser ? firebaseUser.email : '',
    );

    if (!hasSucceed) {
      this.setState({
        mutationError: {
          message: t('errorImpossibleLogin'),
          translationKey: 'errorImpossibleLogin',
        },
      });
    }
  };

  handleLoginCacheUpdate = async (cache, { data }) => {
    let login;

    if (data && data.login) {
      const {
        login: {
          token,
          user: { email },
        },
      } = data;
      login = { token, email };
    }
    const { t } = this.props;

    if (!login) {
      this.setState({
        mutationError: {
          message: t('errorImpossibleLogin'),
          translationKey: 'errorImpossibleLogin',
        },
      });
    } else {
      await CacheResolvers.Mutation.login(undefined, login || {}, { cache });
    }
  };

  showLoader() {
    this.setState({
      isSsoLoading: true,
    });
  }

  hideLoader() {
    this.setState({
      isSsoLoading: false,
    });
  }

  async handleFieldChange(field, event) {
    const { setLoginFormValue } = this.props;
    const {
      currentTarget: { value },
    } = event;

    await setLoginFormValue(field, value);
  }

  tryLoginWithSso = async swapToken => {
    this.showLoader();
    const {
      loginForm: { values },
      setPartnerHeaderCode,
    } = this.props;

    let result;
    try {
      result = await ectorV4ApolloClient.mutate({
        mutation: CHECK_EMAIL,
        variables: {
          email: values.email,
        },
      });
      await LocalForage.setItem(checkedEmailKey, values.email);
    } catch (e) {
      this.hideLoader();
    }

    if (result && result.data && result.data.checkEmail) {
      const {
        checkEmail: { found, idSSO, standardSSO },
      } = result.data;

      if (found && idSSO) {
        let ssoToken;
        try {
          await signInWithSSO(idSSO, standardSSO);
          ssoToken = await getSsoToken();
        } catch (error) {
          await this.handleGetSsoTokenError(error);

          return;
        }

        try {
          const response = await swapToken({
            variables: {
              ssoToken,
            },
          });

          if (response && response.data && response.data.swapToken) {
            const partnerCode = await pursueLogin();
            await setPartnerHeaderCode(partnerCode);
          } else {
            this.setState({ ssoStep: SSO_STEPS.NOT_AVAILABLE });
            await ssoLogout();
          }
        } catch (e) {
          this.hideLoader();
          throw e;
        }
      } else {
        this.setState({ ssoStep: SSO_STEPS.NOT_AVAILABLE });
      }
    }
    this.hideLoader();
  };

  async handleGetSsoTokenError(error) {
    this.hideLoader();
    await LocalForage.removeItem(checkedEmailKey);

    const { showModal } = this.props;
    switch (error.message) {
      case SsoTokenErrors.NOT_SAME_EMAIL:
        showModal(Modals.notSameLoginEmail);
        await ssoLogout();
        break;
      case SsoTokenErrors.NO_TOKEN:
        this.setState({
          ssoStep: SSO_STEPS.NOT_CHECKED,
        });
        console.warn('❌ No token available');
        break;

      default:
        console.warn('❌ Unhandled error');
    }
  }

  handleSubmit = async login => {
    const {
      isLoginFormValid,
      loginForm: { values },
      setPartnerHeaderCode,
    } = this.props;

    const isFormValid = await isLoginFormValid(values);

    if (isFormValid) {
      await login({ variables: values });
      const partnerCode = await pursueLogin();
      await setPartnerHeaderCode(partnerCode);
    }
  };

  handleCloseAlert = () => this.setState({ mutationError: undefined });

  handlePreventSubmit = event => event.preventDefault();

  render() {
    const {
      t,
      loginForm: { values, errors },
      modals: { [Modals.missingPassword]: missingPasswordModalVisible },
    } = this.props;
    const { mutationError, ssoStep, isSsoLoading } = this.state;

    return (
      <>
        <Form autoComplete="off" onSubmit={this.handlePreventSubmit} className="w-100">
          <Alert
            dismissible
            variant="danger"
            show={typeof mutationError !== 'undefined'}
            onClose={this.handleCloseAlert}
          >
            <Alert.Heading>{t('errorOccurred')}</Alert.Heading>
            <p>{mutationError ? mutationError.message : ''}</p>
          </Alert>

          <Form.Group controlId="email">
            {ssoStep === SSO_STEPS.NOT_AVAILABLE ? ( // This will prevent the user from bypassing check email step
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <p style={{ margin: 'auto 0' }}>{values.email}</p>
                <Button
                  size="sm"
                  variant="transparent"
                  onClick={() => {
                    this.setState({
                      ssoStep: SSO_STEPS.NOT_CHECKED,
                    });
                  }}
                >
                  <FontAwesomeIcon icon="pen" size="sm" />
                </Button>
              </div>
            ) : (
              <>
                <Form.Control
                  autoComplete="new-email"
                  type="email"
                  value={values.email || ''}
                  placeholder={t('emailAddress')}
                  onChange={this.handleEmailValueChange}
                  isInvalid={typeof errors.email === 'string'}
                />
                <Form.Text className="text-muted">{t('emailAddressHelper')}</Form.Text>
                <Form.Control.Feedback type="invalid">
                  {t(errors.email || '')}
                </Form.Control.Feedback>
              </>
            )}
          </Form.Group>

          {ssoStep === SSO_STEPS.NOT_AVAILABLE && (
            <Form.Group controlId="password">
              <Form.Label>{t('password')}</Form.Label>
              <Form.Control
                autoComplete="new-password"
                type="password"
                value={values.password || ''}
                placeholder={t('password')}
                onChange={this.handlePasswordValueChange}
                isInvalid={typeof errors.password === 'string'}
              />
              <Form.Control.Feedback type="invalid">
                {t(errors.password || '')}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <Row noGutters>
            <Col>
              <ApolloProvider client={ectorV4ApolloClient}>
                {ssoStep === SSO_STEPS.NOT_AVAILABLE ? (
                  <MutationButton
                    mutation={LOGIN}
                    update={this.handleLoginCacheUpdate}
                    type="submit"
                    onClick={this.handleSubmit}
                    block
                  >
                    {t('login')}
                  </MutationButton>
                ) : (
                  <MutationButton
                    mutation={SWAP_TOKEN}
                    update={this.loginWithSsoCacheUpdate}
                    type="submit"
                    onClick={this.tryLoginWithSso}
                    isLoading={isSsoLoading}
                    block
                  >
                    {t('next')}
                  </MutationButton>
                )}
              </ApolloProvider>
            </Col>
            {ssoStep === SSO_STEPS.NOT_AVAILABLE && (
              <Col>
                <Button variant="link" onClick={this.handleMissingPasswordShowModal} block>
                  <u>{t('missingPassword')}</u>
                </Button>
              </Col>
            )}
          </Row>
        </Form>
        <MissingPasswordModal
          show={missingPasswordModalVisible}
          onHide={this.handleMissingPasswordHideModal}
          onExit={this.handleMissingPasswordHideModal}
        />
        {AppConfig.isStaging && (
          <Badge variant="light" className="my-2">
            v{AppConfig.persistence.schemaVersion}
          </Badge>
        )}
      </>
    );
  }
}

export default compose(
  withReduxForm(Forms.login),
  withReduxModals(),
  withAuth(),
  translate(['login_form', 'form_errors', 'gql_errors']),
)(LoginForm);
