// @flow

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Card, Table } from 'react-bootstrap';
import { Button, GlobalInvoicesListItem } from 'Components';

type GlobalInvoiceMonthPropsType = {
  month: GlobalInvoiceMonthType,
};

type GlobalInvoiceMonthStateType = {
  show: boolean,
};

class GlobalInvoiceMonth extends Component<
  GlobalInvoiceMonthPropsType,
  GlobalInvoiceMonthStateType,
> {
  constructor(props: GlobalInvoiceMonthPropsType) {
    super(props);

    this.state = {
      show: false,
    };
  }

  toggleShow = () => {
    const { show } = this.state;

    this.setState({ show: !show });
  };

  render() {
    const { month } = this.props;
    const { show } = this.state;

    return (
      <Table className={['table-borderless']}>
        <thead>
          <Card
            as="tr"
            className="d-table-row text-primary rounded border-light border-bottom-0"
            onClick={this.toggleShow}
          >
            <td className="align-middle text-capitalize font-weight-bold p-2">
              {moment(month.date).format('MMMM YYYY')}
            </td>
            <td />
            <td />
            <td />
            <td />
            <td className="d-flex justify-content-end align-middle">
              <Button
                className="btn btn-transparent"
                variant="transparent"
                onClick={this.toggleShow}
              >
                {!show && <FontAwesomeIcon icon="sort-down" size="lg" className="mr-1" />}
                {show && <FontAwesomeIcon icon="sort-up" size="lg" className="mr-1" />}
              </Button>
            </td>
          </Card>
        </thead>
        <tbody>
          {show &&
            month.globalInvoices.map(globalInvoice => (
              <GlobalInvoicesListItem globalInvoice={globalInvoice} key={globalInvoice.id} />
            ))}
        </tbody>
      </Table>
    );
  }
}

export default GlobalInvoiceMonth;
