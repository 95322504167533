// @flow

import { gql } from '@apollo/client';

export const CREATE_BOOKING_QUOTE = gql`
  mutation CreateQuote(
    $startAt: DateTime!
    $endAt: DateTime!
    $fromSpot: String!
    $toSpot: String!
    $partner: String
  ) {
    createQuote(
      startAt: $startAt
      endAt: $endAt
      fromSpot: $fromSpot
      toSpot: $toSpot
      partner: $partner
    ) {
      id
      allowed
      startAt
      endAt
      fromSpot {
        id
        code
        name
      }
      toSpot {
        id
        code
        name
      }
      availableServices {
        code
        name
        price
      }
      subscribedServices
      hasCancellationInsurance
      summaryPrices {
        parkingValet
        totalPrice
        serviceUpdates {
          name
          price
        }
      }
    }
  }
`;

export const GET_BOOKING = gql`
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
    }
  }
`;
