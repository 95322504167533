// @flow

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { detect as detectBrowser } from 'detect-browser';
import { Dropdown, Form, InputGroup } from 'react-bootstrap';
import { Button } from 'Components';
import Styles from './Stylesheets/Autosuggest.module.css';

export type AutosuggestPropsType = {
  placeholder: ?string,
  data: Array<any>,
  value: ?string,
  id: ?string,
  onChange: any => void,
  getItemKey: any => string,
  getItemLabel: any => string,
  getItemValue: any => string,
  error: ?string,
  className: string,
  isInvalid: boolean,
  onToggle: () => void,
  InputPrepend: ?React.Node,
  InputGroupAppend: ({ onClick: () => void, describedBy: string }) => React.Element<*>,
  name: string,
  onInputValueChange: ?(string) => void,
  disabled: boolean,
};

type AutosuggestStateType = {
  inputValue: string,
  showMenu: boolean,
  isIE: boolean,
};

const getInputValueFromProps = props => {
  const { value, data, getItemValue, getItemLabel } = props;

  if (value) {
    const dataValue = data.find(spot => getItemValue(spot) === value);

    return dataValue ? getItemLabel(dataValue) : '';
  }

  return '';
};

class Autosuggest extends React.Component<AutosuggestPropsType, AutosuggestStateType> {
  suggestionsContainer: ?HTMLDivElement;

  static getDerivedStateFromProps(props: AutosuggestPropsType) {
    const { value } = props;
    const newState = {};

    if (value) {
      newState.inputValue = value ? getInputValueFromProps(props) : '';
    }

    return newState;
  }

  static defaultProps = {
    getItemKey: (item: any) => item.key,
    getItemLabel: (item: any) => item.label,
    getItemValue: (item: any) => item.value,
    name: 'autoSuggest',
    placeholder: '',
    className: '',
    error: undefined,
    isInvalid: false,
    InputPrepend: undefined,
    id: undefined,
    onToggle: () => {},
    value: undefined,
    onChange: () => {},
    data: [],
    InputGroupAppend: ({ onClick, describedBy }: { onClick: () => void, describedBy: string }) => (
      <InputGroup.Append id={describedBy}>
        <Button onClick={onClick} variant="accent" size="sm">
          <FontAwesomeIcon icon="times" />
        </Button>
      </InputGroup.Append>
    ),
    onInputValueChange: undefined,
    disabled: false,
  };

  constructor(props: AutosuggestPropsType) {
    super(props);
    const browser = detectBrowser();

    this.state = {
      inputValue: props.value ? getInputValueFromProps(props) : '',
      showMenu: false,
      isIE: browser && browser.name === 'ie',
    };
  }

  setSuggestionContainerRef = (element: ?HTMLDivElement) => {
    this.suggestionsContainer = element;
  };

  onSelect = (value: any) => {
    const { getItemLabel, onChange } = this.props;

    if (value) {
      onChange(value);
      this.setState({ inputValue: getItemLabel(value), showMenu: false });
    }
  };

  onChange = (event: SyntheticInputEvent<*>) => {
    const { onChange, onInputValueChange } = this.props;
    const {
      target: { value },
    } = event;

    if (onInputValueChange) {
      onInputValueChange(value);
    }
    this.setState({ inputValue: value });
    onChange({});
  };

  onFocus = () => {
    this.setState({ showMenu: true });
  };

  hideMenu(activeElement: ?Node) {
    if (this.suggestionsContainer && !this.suggestionsContainer.contains(activeElement)) {
      this.setState({ showMenu: false });
    }
  }

  onBlur = (event: { relatedTarget: Node }) => {
    const { isIE } = this.state;
    const activeElement = isIE ? document.activeElement : event.relatedTarget;

    if (isIE) {
      window.setTimeout(() => this.hideMenu(activeElement), 300);
    } else {
      this.hideMenu(activeElement);
    }
  };

  renderSuggestion = (item: any) => {
    const { getItemKey, getItemLabel } = this.props;
    const onSelect = () => this.onSelect(item);

    return (
      <Dropdown.Item key={getItemKey(item)} onSelect={onSelect}>
        {getItemLabel(item)}
      </Dropdown.Item>
    );
  };

  filterDataFromInputValue = (data: Array<any>) => {
    const { getItemLabel } = this.props;
    const { inputValue } = this.state;

    return getItemLabel(data).toUpperCase().includes(inputValue.toUpperCase());
  };

  handleClear = () => {
    const { onChange, onInputValueChange } = this.props;

    if (onInputValueChange) {
      onInputValueChange('');
    }
    this.setState({ inputValue: '' });
    onChange({});
  };

  render() {
    const {
      data,
      placeholder,
      id,
      className,
      error,
      InputPrepend,
      onToggle,
      InputGroupAppend,
      name,
      disabled,
    } = this.props;
    const { inputValue, showMenu } = this.state;

    return (
      <Dropdown show={showMenu} className={className} onToggle={onToggle}>
        <InputGroup>
          {InputPrepend && (
            <InputGroup.Prepend>
              <InputGroup.Text id={name}>{InputPrepend}</InputGroup.Text>
            </InputGroup.Prepend>
          )}
          <Form.Control
            id={id}
            autoComplete="off"
            placeholder={placeholder}
            aria-label={placeholder}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            value={inputValue}
            isInvalid={error && typeof error === 'string'}
            aria-describedby={name}
            disabled={disabled}
          />
          {inputValue.length > 0 && !disabled && (
            <InputGroupAppend onClick={this.handleClear} descibedBy={name} />
          )}
          {error && (
            <Form.Control.Feedback className="d-block" type="invalid">
              {error}
            </Form.Control.Feedback>
          )}
        </InputGroup>
        <Dropdown.Menu className={Styles.dropdownMenu}>
          <div ref={this.setSuggestionContainerRef}>
            {data.filter(this.filterDataFromInputValue).map(this.renderSuggestion)}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default Autosuggest;
