// @flow
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';

const TravelerRecapContent = ({ traveler }: { traveler: BookingContactType }) => (
  <Row className="w-100">
    <Col className="align-items-center">
      <FontAwesomeIcon icon="user-circle" className="mr-1" />
      {`${traveler.lastName.toUpperCase()} ${traveler.firstName}`}
    </Col>
    <Col className="align-items-center">
      <FontAwesomeIcon icon="envelope" className="mr-1" />
      {traveler.email}
    </Col>
    <Col className="align-items-center">
      <FontAwesomeIcon icon="phone" className="mr-1" />
      {traveler.phone}
    </Col>
  </Row>
);

export default TravelerRecapContent;
