// @flow
import { createTransform } from 'redux-persist';
import Immutable from 'seamless-immutable';

const convertToJs = state => state.asMutable({ deep: true });

const fromImmutable = object => (Immutable.isImmutable(object) ? convertToJs(object) : object);

const toImmutable = object => (Immutable.isImmutable(object) ? object : Immutable(object));

export default createTransform(
  inboudState => fromImmutable(inboudState),
  outboundState => toImmutable(outboundState),
);
