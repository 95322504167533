// @flow

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import { Autosuggest, CarFormFields, Query } from 'Components';
import { translate } from 'Hoc';
import { GET_COLLABORATOR } from './Gql/CollaboratorCarSwitchCardContent';

type CollaboratorCarSwitchCardContentPropsType = {
  t: string => string,
  choice: 'new' | 'existing',
  values: CarFormValuesType,
  errors: CarFormErrorsType,
  onChangeCarFormProperties: ({ [string]: string | boolean }) => void,
  collaboratorId?: string,
  contactId?: string,
  onChoiceChange: ('new' | 'existing') => void,
};

const getCarValue = (car: CarType) => car.id;

class CollaboratorCarSwitchCardContent extends Component<CollaboratorCarSwitchCardContentPropsType> {
  static defaultProps = {
    contactId: undefined,
    collaboratorId: undefined,
  };

  handleGetCollaboratorCompleted = ({
    me = { businessPartner: { getCollaborator: { cars: [] } } },
  }) => {
    const { onChoiceChange } = this.props;
    const {
      businessPartner: { getCollaborator = { cars: [] } },
    } = me;

    if (getCollaborator === null || getCollaborator.cars.length === 0) {
      onChoiceChange('new');
    }
  };

  handleSelectCar = (car: CarType) => {
    const { onChangeCarFormProperties } = this.props;

    onChangeCarFormProperties({ carId: car.id });
  };

  render() {
    const { choice, values, errors, onChangeCarFormProperties, collaboratorId, contactId, t } =
      this.props;

    return choice === 'new' ? (
      <Form.Row className="w-100">
        <CarFormFields
          values={values}
          errors={errors}
          onChange={onChangeCarFormProperties}
          componentClassName="col-md-6"
        />
      </Form.Row>
    ) : (
      <Query
        query={GET_COLLABORATOR}
        variables={{ id: collaboratorId, contactId }}
        onCompleted={this.handleGetCollaboratorCompleted}
      >
        {({ data = { me: { businessPartner: { getCollaborator: { cars: [] } } } } }) => {
          const {
            businessPartner: { getCollaborator = { cars: [] } },
          } = data.me;

          if (getCollaborator === null || getCollaborator.cars.length === 0) {
            return <p className="text-center">{t('noCarForTraveler')}</p>;
          }

          return (
            <Autosuggest
              className="align-self-start w-50"
              data={getCollaborator.cars}
              getItemKey={getCarValue}
              getItemLabel={car =>
                `${
                  car.utilsCar
                    ? `${car.utilsCar.brand} ${car.utilsCar.model} ${
                        car.utilsCar.variants > 1 ? `(${t(car.utilsCar.motorization)})` : ''
                      }`
                    : car.name
                } ${car.numberPlate ? `- ${car.numberPlate}` : ''}`
              }
              getItemValue={getCarValue}
              placeholder={t('carPlaceholder')}
              onChange={this.handleSelectCar}
              InputPrepend={<FontAwesomeIcon icon="search" />}
              value={values.carId}
              error={t(errors.carId || '')}
            />
          );
        }}
      </Query>
    );
  }
}

export default translate(['collaborator_car_switch_card', 'form_errors', 'car_motorization'])(
  CollaboratorCarSwitchCardContent,
);
