import { gql } from '@apollo/client';

export const GET_GLOBAL_INVOICES = gql`
  query GetGlobalInvoice {
    me {
      businessPartner {
        globalInvoices {
          id
          type
          month
          invoiceNumber
          debitAmount
          creditAmount
          isPaid
          isJustSummary
          total
        }
      }
    }
  }
`;
