// @flow

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, ListGroup, Row } from 'react-bootstrap';
import { PaymentMethodItemTitle } from 'Components';
import { PaymentCards } from 'Config';
import { translate } from 'Hoc';

type PaymentMethodPropsType = {
  paymentMethod: PaymentMethodType,
  onClick: PaymentMethodType => void,
  selected: boolean,
  t: (string, { [string]: string }) => string,
};

type PaymentMethodItemStateType = {
  isHovered: boolean,
};

class PaymentMethodItem extends Component<PaymentMethodPropsType, PaymentMethodItemStateType> {
  constructor(props: PaymentMethodPropsType) {
    super(props);

    this.state = {
      isHovered: false,
    };
  }

  handleClick = () => {
    const { paymentMethod, onClick } = this.props;

    onClick(paymentMethod);
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const { paymentMethod, selected, t } = this.props;
    const { isHovered } = this.state;
    const hoverVariant = isHovered ? 'primary' : undefined;

    return (
      <ListGroup.Item
        className="m-1 w-25 rounded cursor-pointer"
        variant={hoverVariant}
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        active={selected}
      >
        <Row>
          <Col xs={1} className="d-flex justify-content-center align-items-center">
            <FontAwesomeIcon
              icon={
                paymentMethod.type === PaymentCards.airplus ||
                paymentMethod.type === PaymentCards.card
                  ? 'credit-card'
                  : 'university'
              }
            />
          </Col>
          <Col className="p-0">
            <div className="text-center">
              <PaymentMethodItemTitle paymentMethod={paymentMethod} />
            </div>
            <div className="text-center small">
              {t('endingWith', { ending: paymentMethod.last4digits })}
            </div>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }
}

export default translate('payment_method_item')(PaymentMethodItem);
