// @flow

import React from 'react';
import { Col, Container } from 'react-bootstrap';
import { compose } from 'redux';
import { GlobalInvoiceList } from 'Containers';
import { protect, Roles, translate, withAuth } from 'Hoc';

type BillingScreenPropsType = {
  t: string => string,
};

const BillingScreen = ({ t }: BillingScreenPropsType) => (
  <Container>
    <Col xs={12} className="d-flex justify-content-center mt-4 mb-2">
      <h2 className="font-weight-bold">{t('billingScreenTitle')}</h2>
    </Col>
    <GlobalInvoiceList />
  </Container>
);

export default compose(
  translate(['billing_screen', 'gql_errors']),
  withAuth(true),
  protect(Roles.BusinessBillingList),
)(BillingScreen);
