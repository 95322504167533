// @flow

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import { compose } from 'redux';
import { CollaboratorCarSwitchCard } from 'Containers';
import { translate, withReduxForm } from 'Hoc';
import { Forms } from 'Reducers';

type CarRecapCardContentPropsType = {
  booking: BookingType,
  selected: boolean,
  t: string => string,
  setUpdateBookingFormValues: ({ [string]: ?string | boolean }) => void,
  updateBookingForm: UpdateBookingType,
  setUpdateBookingFormValue: (string, string | typeof undefined | boolean) => string,
};

type CarRecapCardContentStateType = {
  carChoice: 'new' | 'existing',
};

class CarRecapCardContent extends Component<
  CarRecapCardContentPropsType,
  CarRecapCardContentStateType,
> {
  carTexts: SwitchCardTextsType;

  constructor(props) {
    super(props);
    const { t } = props;

    this.state = {
      carChoice: 'existing',
    };

    this.carTexts = {
      title: t('carTitle'),
      firstChoice: t('existingCar'),
      secondChoice: t('newCar'),
    };
  }

  componentDidUpdate(prevProps) {
    const { selected: wasSelected } = prevProps;
    const { selected: isSelected } = this.props;

    if (wasSelected && !isSelected) {
      this.setState({ carChoice: 'existing' });
    }
  }

  handleChoiceChange = (choice: 'new' | 'existing') => {
    const { setUpdateBookingFormValues, booking } = this.props;

    if (choice === 'existing') {
      setUpdateBookingFormValues({
        carId: booking.car.id,
        utilsCarId: undefined,
        carNumberPlate: undefined,
        carColor: undefined,
        carHasRoofBox: false,
      });
    } else {
      setUpdateBookingFormValues({
        carId: undefined,
      });
    }
    this.setState({ carChoice: choice });
  };

  renderSwitchCardRootComponent = ({ children }) => children;

  renderSwitchCardTitleComponent = () => null;

  render() {
    const {
      selected,
      booking,
      booking: { car },
      updateBookingForm: { values, errors },
      setUpdateBookingFormValues,
      t,
    } = this.props;
    const { carChoice } = this.state;

    return selected ? (
      <CollaboratorCarSwitchCard
        choice={carChoice}
        contactId={booking.contact.id}
        values={values}
        errors={errors}
        onChangeCarFormProperties={setUpdateBookingFormValues}
        onChoiceChange={this.handleChoiceChange}
        TitleComponent={this.renderSwitchCardTitleComponent}
        RootComponent={this.renderSwitchCardRootComponent}
      />
    ) : (
      <Row className="w-100 d-flex">
        <Col className="d-flex align-items-center">
          <FontAwesomeIcon icon="car" className="mr-1" />
          <p className="mb-0">{`${
            car.utilsCar
              ? `${car.utilsCar.brand} ${car.utilsCar.model} ${
                  car.utilsCar.variants > 1 ? `(${t(car.utilsCar.motorization)})` : ''
                }`
              : car.name
          } ${booking.car.numberPlate === '' ? `- ${booking.car.numberPlate}` : ''} `}</p>
        </Col>
      </Row>
    );
  }
}

export default compose(
  translate(['recap_booking_screen', 'car_motorization']),
  withReduxForm(Forms.updateBooking),
)(CarRecapCardContent);
