// @flow

import Jwt from 'jsonwebtoken';
import LocalForage from 'localforage';
import { AppConfig } from 'Config';
import { GET_AUTH as query } from 'Gql';
import { InitialStates } from 'LocalStates';

export const InitialState = {
  token: undefined,
  expireAt: undefined,
  email: undefined,
  isConnected: false,
  partnerHeaderCode: undefined,
  __typename: 'Auth',
};

// $FlowFixMe
const login = async (obj, { token }, { cache }) => {
  const jwtToken = Jwt.decode(token);

  if (!jwtToken) {
    return null;
  }
  const { username: email, exp } = jwtToken;
  const updatedData = {
    auth: {
      ...InitialStates.auth,
      token,
      email,
      expireAt: exp * 1000,
      isConnected: true,
      partnerHeaderCode: '',
    },
  };

  LocalForage.setItem(AppConfig.persistence.authKey, updatedData.auth);
  await cache.writeQuery({ query, data: updatedData });

  return null;
};

export const Mutations = {
  login,
};
