// @flow

import React, { Component } from 'react';
import moment from 'moment';
import TimePicker from 'rc-time-picker/es/TimePicker';

type TimePickerInputPropsType = {
  value: string,
};

type TimePickerInputStateType = {
  value: string | null,
};

class TimePickerInput extends Component<TimePickerInputPropsType, TimePickerInputStateType> {
  static getDerivedStateFromProps(props: TimePickerInputPropsType) {
    const { value: valueFromProps } = props;
    const newState = {};

    if (valueFromProps) {
      newState.value = moment(valueFromProps, 'HH:mm:ss');
    } else {
      newState.value = null;
    }

    return newState;
  }

  state = {
    value: null,
  };

  render() {
    const { value } = this.state;

    return <TimePicker {...this.props} autoComplete="off" value={value} />;
  }
}

export default TimePickerInput;
