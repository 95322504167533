import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import { Loader } from 'Components';

const Button = ({ loading, children, ...props }) => {
  const buttonProps = {
    ...props,
    variant: props.variant || 'accent',
  };

  if (!props.className && !props.variant) {
    buttonProps.className = 'text-uppercase text-truncate';
  }

  return (
    <BootstrapButton {...buttonProps}>
      {loading ? <Loader size="xs" noPadding /> : children}
    </BootstrapButton>
  );
};

export default Button;
