// @flow

import React from 'react';
import { compose } from 'redux';
import { Stepper } from 'Components';
import { PurchaseCanalSteps } from 'Config';
import { withAppSettings } from 'Context/AppSettings';
import { translate } from 'Hoc';

const CreateBookingStepper = ({
  t,
  path,
  onStepChange,
  appSettings,
}: {
  t: string => string,
  path: string,
  onStepChange: string => void,
  appSettings: AppSettingsStateType,
}) => (
  <Stepper
    currentStep={path}
    steps={[
      {
        value: PurchaseCanalSteps.placeAndDates,
        label: '1',
        description: t('placeAndDates'),
        disabled: !appSettings.purchaseCanalHistory.placeAndDates,
      },
      {
        value: PurchaseCanalSteps.options,
        label: '2',
        description: t('options'),
        disabled: !appSettings.purchaseCanalHistory.options,
      },
      {
        value: PurchaseCanalSteps.travelerInformation,
        label: '3',
        description: t('travelerInformation'),
        disabled: !appSettings.purchaseCanalHistory.travelerInformation,
      },
      {
        value: PurchaseCanalSteps.payment,
        label: '4',
        description: t('payment'),
        disabled: !appSettings.purchaseCanalHistory.payment,
      },
    ]}
    onStepChange={onStepChange}
  />
);

export default compose(withAppSettings, translate('create_booking_screen'))(CreateBookingStepper);
