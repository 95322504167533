// @flow

import React, { Component } from 'react';
import { Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { compose } from 'redux';
import { MutationButton } from 'Components';
import { translate, withReduxForm } from 'Hoc';
import { Forms } from 'Reducers';
import {
  ADD_DISCOUNT_CODE,
  DELETE_DISCOUNT_CODE,
  GET_BOOKING,
} from './Gql/DiscountCodeCardContent';

type DiscountCodeCardContentPropsType = {
  t: (string, ?{ [string]: string }) => string,
  bookingId: string,
  discountCode: DiscountCodeType | null,
  paymentForm: FormStateType,
  setPaymentFormValue: (string, string | typeof undefined) => void,
  onClickApplyDiscountCode: () => void,
};

class DiscountCodeCardContent extends Component<DiscountCodeCardContentPropsType> {
  handleDiscountCodeChange = event => {
    const { setPaymentFormValue } = this.props;

    setPaymentFormValue('discountCode', event.target.value);
  };

  handleDeleteDiscountCode = deleteDiscountCode => {
    const { bookingId } = this.props;

    deleteDiscountCode({ variables: { id: bookingId } });
  };

  handleDeleteDiscountCodeCacheUpdate = (cache, { data: { removeDiscountCode } }) => {
    cache.writeQuery({
      query: GET_BOOKING,
      variables: { id: removeDiscountCode.id },
      data: { getBooking: removeDiscountCode },
    });
  };

  handleAddDiscountCodeCacheUpdate = (cache, { data: { applyDiscountCode } }) => {
    const { setPaymentFormValue } = this.props;

    cache.writeQuery({
      query: GET_BOOKING,
      variables: { id: applyDiscountCode.id },
      data: { getBooking: applyDiscountCode },
    });
    setPaymentFormValue('discountCode', undefined);
  };

  render() {
    const {
      t,
      bookingId,
      paymentForm: { values, errors },
      onClickApplyDiscountCode,
      discountCode,
    } = this.props;

    return discountCode !== null ? (
      <Row className="d-flex align-items-center">
        {t('appliedDiscountCode', { discountCode: discountCode.code })}
        <MutationButton
          mutation={DELETE_DISCOUNT_CODE}
          variables={{ id: bookingId }}
          onClick={this.handleDeleteDiscountCode}
          className="ml-4"
          update={this.handleDeleteDiscountCodeCacheUpdate}
        >
          {t('deleteDiscountCodeLabel')}
        </MutationButton>
      </Row>
    ) : (
      <InputGroup className="mb-2 w-50 align-items-start">
        <FormControl
          aria-label="Promo code"
          aria-describedby="promo code"
          onChange={this.handleDiscountCodeChange}
          value={values.discountCode || ''}
          isInvalid={!!errors.discountCode}
        />
        <InputGroup.Append>
          <MutationButton
            mutation={ADD_DISCOUNT_CODE}
            variables={{ id: bookingId, code: values.discountCode || null }}
            onClick={onClickApplyDiscountCode}
            update={this.handleAddDiscountCodeCacheUpdate}
            checkErrorTranslation={false}
            className="rounded-right"
          >
            {t('discountCodeButtonLabel')}
          </MutationButton>
        </InputGroup.Append>
        <Form.Control.Feedback type="invalid" className="d-block">
          {errors.discountCode ? t(errors.discountCode) : ''}
        </Form.Control.Feedback>
      </InputGroup>
    );
  }
}

export default compose(
  translate('booking_payment_form'),
  withReduxForm(Forms.payment),
)(DiscountCodeCardContent);
