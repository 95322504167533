// @flow
import React, { Fragment } from 'react';
import { compose } from 'redux';
import { PlaceAndDatesContent, Query } from 'Components';
import { GET_SPOTS_FROM_CODES } from 'Gql';
import { translate, withReduxForm } from 'Hoc';
import { Forms } from 'Reducers';

type FreeUpdatePlaceAndDatesModalBodyContentPropsType = {
  t: string => string,
  className: string,
  updateBookingForm: UpdateBookingType,
};

const FreeUpdatePlaceAndDatesModalBodyContent = ({
  t,
  className,
  updateBookingForm: {
    values: { fromSpot: fromSpotCode, toSpot: toSpotCode, endDate, endTime, startDate, startTime },
  },
}: FreeUpdatePlaceAndDatesModalBodyContentPropsType) => (
  <>
    <p className="px-2 mt-2">{t('description')}</p>
    <Query query={GET_SPOTS_FROM_CODES} variables={{ codes: [fromSpotCode, toSpotCode] }}>
      {({ data: { getSpotsFromCodes: spots } = { getSpotsFromCodes: null }, loading }) => {
        if (loading || !spots) {
          return null;
        }
        const fromSpot = spots.find(spot => spot.code === fromSpotCode);
        const toSpot = spots.find(spot => spot.code === toSpotCode);
        const placeAndDatesContentProps = {
          fromSpot,
          toSpot,
          endAt: `${endDate || ''} ${endTime || ''}`,
          startAt: `${startDate || ''} ${startTime || ''}`,
          className,
          horizontal: false,
        };

        return <PlaceAndDatesContent {...placeAndDatesContentProps} />;
      }}
    </Query>
  </>
);

export default compose(
  translate(['free_update_place_dates_modal']),
  withReduxForm(Forms.updateBooking, 'values'),
)(FreeUpdatePlaceAndDatesModalBodyContent);
