// @flow

import React, { PureComponent } from 'react';
import { ListGroup } from 'react-bootstrap';
import { ServiceListItem } from 'Components';

type ServiceListPropsType = {
  services: Array<ServiceType>,
  selectedServices: Array<ServiceType>,
  fetchingServices: Array<ServiceType>,
  onClick: ServiceType => void,
};

class ServiceList extends PureComponent<ServiceListPropsType> {
  renderService = (service: ServiceType) => {
    const { onClick, selectedServices, fetchingServices } = this.props;
    const isFetching =
      fetchingServices.findIndex(fetchingService => fetchingService.code === service.code) >= 0;
    const selectedService = selectedServices.find(srv => srv.code === service.code);

    return (
      <ServiceListItem
        key={service.code}
        service={selectedService || service}
        selected={typeof selectedService !== 'undefined'}
        disabled={isFetching || (selectedService && selectedService.isOffered)}
        fetching={isFetching}
        onClick={onClick}
      />
    );
  };

  render() {
    const { services } = this.props;

    return (
      <ListGroup className="flex-row flex-wrap justify-content-center">
        {services.map(this.renderService)}
      </ListGroup>
    );
  }
}

export default ServiceList;
