// @flow

import React, { Fragment } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Input, MutationButton } from 'Components';
import { translate } from 'Hoc';
import { UPDATE_BOOKING } from './Gql/TravelRecapCardContent';

type TravelRecapCardContentPropsType = {
  t: (string, ?{ [string]: string }) => string,
  selected: boolean,
  booking: BookingType,
  onChangeTravelingNumberFrom: string => void,
  onChangeTravelingNumberTo: string => void,
  values: UpdateBookingFormValuesType,
  errors: UpdateBookingFormErrorsType,
  onClickCancelButton: () => void,
  onClickSaveMutation: () => void,
  onUpdateCompleted: () => void,
};

const TravelRecapCardContent = ({
  selected,
  t,
  booking,
  onChangeTravelingNumberFrom,
  onChangeTravelingNumberTo,
  onClickCancelButton,
  onClickSaveMutation,
  errors,
  values,
  onUpdateCompleted,
}: TravelRecapCardContentPropsType) =>
  !selected ? (
    <Row className="w-100 d-flex">
      <Col>
        <p className="mb-0">
          {t('travelFromLabel', { number: booking.travelingNumberFrom || t('numberNotSpecified') })}
        </p>
      </Col>
      <Col>
        <p className="mb-0">
          {t('travelToLabel', { number: booking.travelingNumberTo || t('numberNotSpecified') })}
        </p>
      </Col>
    </Row>
  ) : (
    <>
      <Form.Row className="w-100">
        <Input
          label={t('in')}
          className="col"
          placeholder={t('travelingNumberInPlaceholder')}
          value={values.travelingNumberFrom || ''}
          onChange={onChangeTravelingNumberFrom}
        />
        <Input
          label={t('out')}
          className="col"
          placeholder={t('travelingNumberOutPlaceholder')}
          value={values.travelingNumberTo || ''}
          onChange={onChangeTravelingNumberTo}
          error={errors.travelingNumberTo}
          isMandatory
        />
      </Form.Row>
      <Row className="d-flex w-100 justify-content-center mt-2">
        <Button variant="link" className="mr-2 font-weight-bold" onClick={onClickCancelButton}>
          {t('cancelButton')}
        </Button>
        <MutationButton
          mutation={UPDATE_BOOKING}
          onClick={onClickSaveMutation}
          onCompleted={onUpdateCompleted}
        >
          {t('saveButton')}
        </MutationButton>
      </Row>
    </>
  );

export default translate(['recap_booking_screen', 'travel_recap_card'])(TravelRecapCardContent);
