// @flow
import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Dropdown, Image, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { type Location, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Button } from 'Components';
import { translate, withAuth } from 'Hoc';
import { Forms, FormsStateActions } from 'Reducers';
import Styles from './Stylesheets/UserMenu.module.css';

type UserMenuPropsType = {
  businessPartners: Array<BusinessPartnerType>,
  mainBusinessPartner: BusinessPartnerType,
  t: string => string,
  logout: () => void,
  setPartnerHeaderCode: string => void,
  clearForms: () => void,
  auth: AuthStateType,
  clearPurchaseCanalForm: () => void,
  history: RouterHistoryType,
  location: Location,
};

type UserMenuStateType = {
  businessPartners: Array<BusinessPartnerType>,
};

class UserMenu extends Component<UserMenuPropsType, UserMenuStateType> {
  constructor(props) {
    super(props);
    this.state = { businessPartners: props.businessPartners };
  }

  handleLogout = () => {
    const { logout, clearForms } = this.props;

    clearForms();
    logout();
  };

  handleSelectBusinessPartner = async businessPartner => {
    const { setPartnerHeaderCode, history } = this.props;

    await setPartnerHeaderCode(businessPartner.code);
    await history.push('/');
  };

  onFilter = ({ currentTarget: { value } }) => {
    const { businessPartners } = this.props;
    this.setState(() => ({
      businessPartners: businessPartners.filter(businessPartner =>
        businessPartner.name.toLowerCase().includes(value.toLowerCase()),
      ),
    }));
  };

  renderFilter = t => (
    <div className="input-group">
      <div className="input-group-prepend">
        <div className={`input-group-text ${Styles.businessPartnerFilterIcon}`}>
          <FontAwesomeIcon icon={['fas', 'search']} />
        </div>
      </div>
      <input
        className={`form-control ${Styles.businessPartnerFilterInput}`}
        onChange={this.onFilter}
        placeholder={t('user_menu:search_placholder')}
      />
    </div>
  );

  renderBusinessPartner = businessPartner => {
    const onClick = () => this.handleSelectBusinessPartner(businessPartner);

    return (
      <Fragment key={businessPartner.code}>
        <Row
          as={Dropdown.Item}
          className="align-items-center d-inline-flex px-1 bg-light-neutral"
          noGutters
          onClick={onClick}
        >
          <Col md={3} className="text-center pr-1">
            {businessPartner.logoUrl ? (
              <Image
                src={businessPartner.logoUrl}
                className={['bg-transparent', 'border-0', Styles.partnerLogo].join(' ')}
                thumbnail
              />
            ) : (
              <FontAwesomeIcon icon="user-circle" size="2x" />
            )}
          </Col>
          <Col md={9} className="text-truncate">
            {businessPartner.name}
          </Col>
        </Row>
        <Dropdown.Divider className="m-0" />
      </Fragment>
    );
  };

  render() {
    const {
      businessPartners,
      mainBusinessPartner,
      auth: { email },
      t,
    } = this.props;
    const { businessPartners: filteredBusinessPartners } = this.state;

    return (
      <>
        <p className="text-center font-weight-bold small">{t('welcome')}</p>
        <Row className="align-items-center d-inline-flex p-1" as={Dropdown.Item} noGutters>
          <Col md={3} className="text-center pr-1">
            {mainBusinessPartner.logoUrl ? (
              <Image
                src={mainBusinessPartner.logoUrl}
                className={['bg-transparent', 'border-0', Styles.mainPartnerLogo].join(' ')}
                thumbnail
                fluid
              />
            ) : (
              <FontAwesomeIcon icon="user-tie" size="4x" />
            )}
          </Col>
          <Col md={8}>
            <Row className="text-truncate d-block text font-weight-bold m-0" noGutters as="h5">
              {mainBusinessPartner.name}
            </Row>
            <Row noGutters className="text-truncate d-block">
              {email}
            </Row>
          </Col>
        </Row>
        <Dropdown.Divider className="m-0" />
        {businessPartners.length >= 5 && this.renderFilter(t)}
        <div className={Styles.businessPartnersList}>
          {filteredBusinessPartners.map(this.renderBusinessPartner)}
        </div>
        {businessPartners.length > 0 && filteredBusinessPartners.length === 0 && (
          <div>{t('user_menu:noresult')}</div>
        )}
        <Button onClick={this.handleLogout} className="d-block my-1 mx-auto text-uppercase">
          {t('logout')}
        </Button>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  clearForms: () => dispatch(FormsStateActions.clearForms()),
  clearPurchaseCanalForm: () => dispatch(FormsStateActions.clearForm(Forms.purchaseCanal)),
});

export default compose(
  withRouter,
  withAuth(),
  translate('user_menu'),
  connect(null, mapDispatchToProps),
)(UserMenu);
