// @flow

import React, { Component } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from 'react-stripe-elements';
import { compose } from 'redux';
import { Input } from 'Components';
import { translate, withReduxForm } from 'Hoc';
import { Forms } from 'Reducers';
import { StripePaymentFormValidation } from 'Services';

type PaymentFormPropsType = {
  t: string => string,
  setPaymentFormValue: (string, string) => void,
  clearPaymentForm: () => void,
  setPaymentFormError: (string, ?string) => void,
  setPaymentFormErrors: PaymentFormErrorsType => void,
  paymentForm: FormStateType,
};

const stripeInputStyle = {
  base: {
    lineHeight: 1.5,
    backgroundColor: '#fff',
    borderRadius: '0,25rem',
    fontSize: '1rem',
    height: 'calc(2.25rem + 2px)',
    fontFamily:
      'GTWalsheim, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  },
};

class PaymentForm extends Component<PaymentFormPropsType> {
  handleCardNumberChange: StripeElementValueType => void;

  handleExpirationDateChange: StripeElementValueType => void;

  handleSecurityCodeChange: StripeElementValueType => void;

  constructor(props) {
    super(props);
    const { clearPaymentForm } = props;

    this.handleCardNumberChange = this.handleFormFieldChange.bind(this, 'cardNumber');
    this.handleExpirationDateChange = this.handleFormFieldChange.bind(this, 'cardExpirationDate');
    this.handleSecurityCodeChange = this.handleFormFieldChange.bind(this, 'cardSecurityCode');
    clearPaymentForm();
  }

  handleFormFieldChange(field: string, value: StripeElementValueType) {
    const {
      paymentForm: { errors: paymentFormErrors },
      setPaymentFormError,
      setPaymentFormErrors,
    } = this.props;

    if (value.error && !paymentFormErrors[field]) {
      setPaymentFormErrors(StripePaymentFormValidation(value.error));
    } else if (paymentFormErrors[field] && !value.error) {
      setPaymentFormError(field, undefined);
    }
  }

  renderCardNumberElement = inputProps => (
    <CardNumberElement
      {...inputProps}
      onChange={this.handleCardNumberChange}
      style={stripeInputStyle}
    />
  );

  renderCardExpiryElement = inputProps => (
    <CardExpiryElement
      {...inputProps}
      onChange={this.handleExpirationDateChange}
      style={stripeInputStyle}
    />
  );

  renderCardCVCElement = inputProps => (
    <CardCvcElement
      {...inputProps}
      onChange={this.handleSecurityCodeChange}
      style={stripeInputStyle}
    />
  );

  render() {
    const {
      t,
      paymentForm: { errors },
    } = this.props;

    return (
      <Col as={Form}>
        <Row>
          <Col md={{ offset: 2, span: 8 }}>
            <Input
              as={this.renderCardNumberElement}
              label={t('cardNumberLabel')}
              labelClassName="text-truncate"
              placeholder={t('cardNumberPlaceholder')}
              error={errors.cardNumber}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ offset: 2, span: 4 }}>
            <Input
              as={this.renderCardExpiryElement}
              label={t('expirationDateLabel')}
              labelClassName="text-truncate"
              placeholder={t('expirationDatePlaceholder')}
              error={errors.cardExpirationDate}
            />
          </Col>
          <Col md={4}>
            <Input
              label={t('securityCodeLabel')}
              as={this.renderCardCVCElement}
              labelClassName="text-truncate"
              placeholder={t('securityCodePlaceholder')}
              error={errors.cardSecurityCode}
            />
          </Col>
        </Row>
      </Col>
    );
  }
}

export default compose(
  translate('booking_payment_form'),
  withReduxForm(Forms.payment),
)(PaymentForm);
