// @flow

import React, { type Node } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { BookingBusinessFieldValue, Price } from 'Components';
import { BookingsFilters } from 'Config';

type BookingRowType = {
  currentTab: string,
  booking: BookingType,
  ActionsComponent: () => Node,
  utilsBusinessFields: Array<BusinessFieldType>,
};

const BookingRow = ({
  booking,
  utilsBusinessFields,
  ActionsComponent,
  currentTab,
}: BookingRowType) => (
  <tr key={booking.id} className="small">
    <td>{booking.shortId}</td>
    <td>{booking.contact && `${booking.contact.firstName} ${booking.contact.lastName}`}</td>
    <td>{booking.fromSpot && booking.fromSpot.name}</td>
    <td>{booking.startAt && moment(booking.startAt).format('DD/MM/YY HH:mm')}</td>
    <td>{booking.endAt && moment(booking.endAt).format('DD/MM/YY HH:mm')}</td>
    {utilsBusinessFields.map(utilsBusinessField => (
      <BookingBusinessFieldValue
        key={`${booking.id}-${utilsBusinessField.code}`}
        booking={booking}
        utilsBusinessField={utilsBusinessField}
      />
    ))}
    {[BookingsFilters.cancel, BookingsFilters.past].includes(currentTab) && (
      <td>
        {booking.refundAmount ? (
          <Price value={booking.refundAmount * -1} />
        ) : (
          <FontAwesomeIcon icon="times" size="lg" />
        )}
      </td>
    )}
    <td>
      <Price value={booking.summaryPrices.totalPrice} />
    </td>
    <td className="p-1">
      <ActionsComponent />
    </td>
  </tr>
);

export default BookingRow;
