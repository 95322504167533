// @flow

import { isValidNumber, parseNumber } from 'libphonenumber-js';
import moment from 'moment';
import { GET_UTILS_BUSINESS_FIELDS } from 'Gql';
import { Forms } from 'Reducers';
import { apolloClient, transformBusinessFieldsObjectToArray } from 'Services';

const regexEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

const getBusinessFieldError = (
  businessFieldValue: string,
  utilsBusinessField: UtilsBusinessFieldType,
  skipMandatory: boolean = false,
) => {
  if (
    !skipMandatory &&
    utilsBusinessField.isMandatory &&
    (!businessFieldValue || businessFieldValue === '')
  ) {
    return 'isRequired';
  }

  if (
    typeof businessFieldValue === 'string' &&
    utilsBusinessField.regex &&
    !new RegExp(utilsBusinessField.regex).test(businessFieldValue)
  ) {
    return 'invalidRegex';
  }

  return undefined;
};

const updateContactPhoneNumberValidation = {
  phoneNumber: ({ phoneNumber }) => {
    if (!phoneNumber) {
      return 'phoneNumberRequired';
    }

    return undefined;
  },
};

const loginFormValidations = {
  email: ({ email }) => {
    if (!email || typeof email === 'undefined') {
      return 'loginEmailRequired';
    }

    if (!regexEmail.test(email)) {
      return 'emailInvalid';
    }

    return undefined;
  },
  password: ({ password }) => {
    if (!password || typeof password === 'undefined') {
      return 'passwordRequired';
    }

    return undefined;
  },
};

const createCollaboratorFormValidations = {
  email: ({ email }) => {
    if (!email || typeof email === 'undefined') {
      return 'collaboratorEmailRequired';
    }

    if (!regexEmail.test(email)) {
      return 'emailInvalid';
    }

    return undefined;
  },
  firstName: ({ firstName }) =>
    typeof firstName === 'undefined' || firstName === ''
      ? 'collaboratorFirstNameRequired'
      : undefined,
  lastName: ({ lastName }) =>
    typeof lastName === 'undefined' || lastName === '' ? 'collaboratorLastNameRequired' : undefined,
  phoneNumber: updateCollaboratorData => {
    const { phone, country } = parseNumber(updateCollaboratorData.phoneNumber || '');
    let formatedPhoneNumber;

    if (!updateCollaboratorData.phoneNumber) {
      return 'collaboratorPhoneNumberRequired';
    }

    if (!country && !phone) {
      formatedPhoneNumber = `+33${updateCollaboratorData.phoneNumber.substring(1)}`;
    }

    if (!isValidNumber(formatedPhoneNumber || updateCollaboratorData.phoneNumber)) {
      return 'phoneInvalid';
    }

    return undefined;
  },
  businessFields: async ({ businessFields }) => {
    const { data = { me: { businessPartner: { utilsBusinessFields: [] } } } } =
      await apolloClient.query({
        query: GET_UTILS_BUSINESS_FIELDS,
        variables: { isFilterable: false, businessFieldType: 'user' },
      });
    const businessFieldsErrorsObject = data.me.businessPartner.utilsBusinessFields.reduce(
      (errors, utilsBusinessField) => ({
        ...errors,
        [utilsBusinessField.code]: getBusinessFieldError(
          businessFields[utilsBusinessField.code],
          utilsBusinessField,
          false,
        ),
      }),
      {},
    );
    const businessFieldValues = [];
    const businessFieldsErrorsArray = transformBusinessFieldsObjectToArray(
      businessFieldsErrorsObject,
    );

    businessFieldsErrorsArray.forEach(element => {
      if (element.value !== undefined) {
        businessFieldValues.push(element);
      }
    });

    return businessFieldValues.length > 0 ? businessFieldsErrorsObject : undefined;
  },
};

const pricingFormValidations = {
  fromSpot: ({ fromSpot }) => (!fromSpot ? 'pricingFromSpotRequired' : undefined),
  toSpot: ({ toSpot }) => (!toSpot ? 'pricingToSpotRequired' : undefined),
  startDate: pricingFormData => {
    if (!pricingFormData.startDate) {
      return 'pricingStartDateRequired';
    }

    if (moment(pricingFormData.startDate).isAfter(moment(pricingFormData.endDate))) {
      return 'pricingStartDateAfterEnd';
    }

    return undefined;
  },
  endDate: pricingFormData => {
    if (!pricingFormData.endDate) {
      return 'pricingEndDateRequired';
    }

    if (moment(pricingFormData.endDate).isBefore(moment(pricingFormData.startDate))) {
      return 'pricingEndDateBeforeStart';
    }

    return undefined;
  },
  startTime: pricingFormData => {
    if (!pricingFormData.startTime) {
      return 'pricingStartTimeRequired';
    }

    if (
      moment(`${pricingFormData.startDate} ${pricingFormData.startTime}`).isAfter(
        moment(`${pricingFormData.endDate} ${pricingFormData.endTime}`),
      )
    ) {
      return 'pricingStartTimeAfterEnd';
    }

    return undefined;
  },
  endTime: pricingFormData => {
    if (!pricingFormData.endTime) {
      return 'pricingEndTimeRequired';
    }

    if (
      moment(`${pricingFormData.endDate} ${pricingFormData.endTime}`).isBefore(
        moment(`${pricingFormData.startDate} ${pricingFormData.startTime}`),
      )
    ) {
      return 'pricingEndTimeBeforeStart';
    }

    return undefined;
  },
};

const updateCollaboratorValidations = {
  firstName: ({ firstName }) => (!firstName ? 'updateCollaboratorFirstNameRequired' : undefined),
  lastName: ({ lastName }) => (!lastName ? 'updateCollaboratorLastNameRequired' : undefined),
  email: ({ email }) => {
    if (!email) {
      return 'updateCollaboratorEmailRequired';
    }

    if (!regexEmail.test(email)) {
      return 'emailInvalid';
    }

    return undefined;
  },
  phoneNumber: updateCollaboratorData => {
    const { phone, country } = parseNumber(updateCollaboratorData.phoneNumber || '');

    if (!updateCollaboratorData.phoneNumber) {
      return 'updateCollaboratorPhoneNumberRequired';
    }

    if (!country && !phone) {
      const formatedPhoneNumber = `+33${updateCollaboratorData.phoneNumber.substring(1)}`;

      if (!isValidNumber(formatedPhoneNumber)) {
        return 'phoneInvalid';
      }

      return undefined;
    }

    if (!isValidNumber(updateCollaboratorData.phoneNumber)) {
      return 'phoneInvalid';
    }

    return undefined;
  },
  businessFields: async ({ businessFields }) => {
    const { data = { me: { businessPartner: { utilsBusinessFields: [] } } } } =
      await apolloClient.query({
        query: GET_UTILS_BUSINESS_FIELDS,
        variables: { isFilterable: false, businessFieldType: 'user' },
      });
    const businessFieldsErrorsObject = data.me.businessPartner.utilsBusinessFields.reduce(
      (errors, utilsBusinessField) => ({
        ...errors,
        [utilsBusinessField.code]: getBusinessFieldError(
          businessFields[utilsBusinessField.code],
          utilsBusinessField,
          true,
        ),
      }),
      {},
    );
    const businessFieldValues = [];
    const businessFieldsErrorsArray = transformBusinessFieldsObjectToArray(
      businessFieldsErrorsObject,
    );

    businessFieldsErrorsArray.forEach(element => {
      if (element.value !== undefined) {
        businessFieldValues.push(element);
      }
    });

    return businessFieldValues.length > 0 ? businessFieldsErrorsObject : undefined;
  },
};

const createCarFormValidations = {
  carBrand: ({ utilsCarId }) => {
    if (!utilsCarId) {
      return 'createCarFormCarRequired';
    }

    return undefined;
  },
  carColor: ({ carColor }) => {
    if (!carColor) {
      return 'createCarFormColorRequired';
    }

    return undefined;
  },
};

const carFormValidations = {
  carId: ({ carId, utilsCarId }) => (!carId && !utilsCarId ? 'carIdRequired' : undefined),
  utilsCarId: ({ carId, utilsCarId }) => (!carId && !utilsCarId ? 'isRequired' : undefined),
  carColor: ({ carId, carColor }) => (!carId && !carColor ? 'isRequired' : undefined),
};
const travelFormValidations = {
  travelingNumberTo: ({ travelingNumberTo }) => (!travelingNumberTo ? 'isRequired' : undefined),
};

const travelerFormValidations = {
  firstName: ({ contactId, firstName }) => (!contactId && !firstName ? 'isRequired' : undefined),
  lastName: ({ contactId, lastName }) => (!contactId && !lastName ? 'isRequired' : undefined),
  email: ({ contactId, email }) => {
    if (contactId) {
      return undefined;
    }

    if (!email || typeof email === 'undefined') {
      return 'isRequired';
    }

    if (!regexEmail.test(email)) {
      return 'isInvalid';
    }

    return undefined;
  },
  phoneNumber: ({ contactId, phoneNumber }) => {
    const { phone, country } = parseNumber(phoneNumber || '');

    if (contactId) {
      return undefined;
    }

    if (!phoneNumber) {
      return 'isRequired';
    }

    if (!country && !phone) {
      const formatedPhoneNumber = `+33${phoneNumber.substring(1)}`;

      if (!isValidNumber(formatedPhoneNumber)) {
        return 'isInvalid';
      }

      return undefined;
    }

    if (!isValidNumber(phoneNumber)) {
      return 'isInvalid';
    }

    return undefined;
  },
};

const purchaseCanalFormValidations = {
  ...carFormValidations,
  ...travelFormValidations,
  ...travelerFormValidations,
  contactId: ({ contactId, firstName, lastName, email, phoneNumber }) =>
    !contactId && (!firstName || !lastName || !email || !phoneNumber)
      ? 'contactIdRequired'
      : undefined,
  businessFields: async ({ businessFields, businessFieldType }) => {
    const { data = { me: { businessPartner: { utilsBusinessFields: [] } } } } =
      await apolloClient.query({
        query: GET_UTILS_BUSINESS_FIELDS,
        variables: { isFilterable: false, businessFieldType },
      });

    if (!data) {
      return undefined;
    }

    const businessFieldsErrorsObject = data.me.businessPartner.utilsBusinessFields.reduce(
      (errors, utilsBusinessField) => ({
        ...errors,
        [utilsBusinessField.code]: getBusinessFieldError(
          businessFields ? businessFields[utilsBusinessField.code] : '',
          utilsBusinessField,
        ),
      }),
      {},
    );
    const businessFieldValues = [];
    const businessFieldsErrorsArray = transformBusinessFieldsObjectToArray(
      businessFieldsErrorsObject,
    );

    businessFieldsErrorsArray.forEach(element => {
      if (element.value !== undefined) {
        businessFieldValues.push(element);
      }
    });

    return businessFieldValues.length > 0 ? businessFieldsErrorsObject : undefined;
  },
};

const paymentFormValidations = {
  discountCode: ({ discountCode }) => (!discountCode ? 'noDiscountCodeTyped' : undefined),
};

export const UpdateBookingFormSteps = {
  placeAndDates: 'placeAndDates',
  travel: 'travel',
  traveler: 'traveler',
  car: 'car',
};

const updateBookingFormValidations = step => ({
  ...(step === UpdateBookingFormSteps.placeAndDates ? pricingFormValidations : {}),
  ...(step === UpdateBookingFormSteps.travel ? travelFormValidations : {}),
  ...(step === UpdateBookingFormSteps.car ? carFormValidations : {}),
  ...(step === UpdateBookingFormSteps.traveler ? travelerFormValidations : {}),
});

const FormsValidationMap = {
  [Forms.login]: loginFormValidations,
  [Forms.createCollaborator]: createCollaboratorFormValidations,
  [Forms.pricing]: pricingFormValidations,
  [Forms.updateCollaborator]: updateCollaboratorValidations,
  [Forms.createCar]: createCarFormValidations,
  [Forms.purchaseCanal]: purchaseCanalFormValidations,
  [Forms.payment]: paymentFormValidations,
  [Forms.updateBooking]: step => updateBookingFormValidations(step),
  [Forms.updateContactPhoneNumber]: updateContactPhoneNumberValidation,
};

export const FormsValidation = async (name: string, values: Array<string>, step?: string) => {
  const formValidation =
    typeof FormsValidationMap[name] === 'function'
      ? FormsValidationMap[name](step)
      : FormsValidationMap[name];

  return Object.keys(formValidation).reduce(async (previousPromise, field) => {
    const acc = await previousPromise;

    return {
      ...acc,
      [field]: formValidation[field] ? await formValidation[field](values) : undefined,
    };
  }, Promise.resolve({}));
};

export const StripePaymentFormValidation = (error: StripeErrorType) => {
  switch (error.code) {
    case 'invalid_number':
      return {
        cardNumber: error.message,
      };
    case 'invalid_expiry_month':
      return {
        cardExpirationDate: error.message,
      };
    case 'invalid_expiry_year':
      return {
        cardExpirationDate: error.message,
      };
    case 'invalid_cvc':
      return {
        cardSecurityCode: error.message,
      };
    case 'incomplete_cvc':
      return {
        cardSecurityCode: error.message,
      };
    case 'expired_card':
      return {
        cardExpirationDate: error.message,
      };
    case 'invalid_expiry_year_past':
      return {
        cardExpirationDate: error.message,
      };
    case 'incomplete_expiry':
      return {
        cardExpirationDate: error.message,
      };
    default:
      return {};
  }
};
