// @flow

import React from 'react';
import type { DocumentNode } from 'graphql';
import { Autosuggest, Query } from 'Components';
import { GET_COLLABORATORS } from './Gql/CollaboratorAutosuggest';

const getCollaboratorLabel = (item: CollaboratorType) =>
  `${item.firstName} ${item.lastName} - ${item.email}`;

const getCollaboratorKey = (item: CollaboratorType) => item.email;

const getCollaboratorValue = (item: CollaboratorType) => item.mainContact && item.mainContact.id;

type CollaboratorAutosuggestPropsType = {
  getItemKey: CollaboratorType => string,
  getItemLabel: CollaboratorType => string,
  getItemValue: CollaboratorType => string,
  query: DocumentNode,
};

type CollaboratorAutosuggestStateType = {
  inputValue: string,
};

class CollaboratorAutosuggest extends React.Component<
  CollaboratorAutosuggestPropsType,
  CollaboratorAutosuggestStateType,
> {
  static defaultProps = {
    getItemKey: getCollaboratorKey,
    getItemLabel: getCollaboratorLabel,
    getItemValue: getCollaboratorValue,
    query: GET_COLLABORATORS,
  };

  state = {
    inputValue: '',
  };

  handleInputValueChange = (inputValue: string) => {
    this.setState({ inputValue });
  };

  render() {
    const { query, ...autoSuggestProps } = this.props;
    const { inputValue } = this.state;

    return (
      <Query query={query} variables={{ searchText: inputValue }} withLoading={false}>
        {({
          data = {
            me: {
              businessPartner: { collaborators: { results: [] } },
            },
          },
        }) => (
          <Autosuggest
            {...autoSuggestProps}
            data={data.me.businessPartner.collaborators.results}
            onInputValueChange={this.handleInputValueChange}
          />
        )}
      </Query>
    );
  }
}

export default CollaboratorAutosuggest;
