// @flow

import React from 'react';
import { compose } from 'redux';
import { Loader, Query } from 'Components';
import { translate, withAuth } from 'Hoc';
import { GET_AIRTABLE_LINKS } from '../../Gql/Jockey';
import Styles from '../Stylesheets/Jockey.module.scss';

type BookingsScreenProps = { t: (string, ?{}) => string };
type RenderIframeProps = {
  data: {
    me: {
      businessPartner: {
        airtableBookingsLink: string,
      },
    },
  },
};

const BookingsScreen = ({ t }: BookingsScreenProps) => {
  const renderIframe = ({ data }: RenderIframeProps) => {
    const regex = /(https:\/\/airtable\.com)/gm;
    const subst = `$1/embed`;
    const jockeyListView =
      data && data.me && data.me.businessPartner && data.me.businessPartner.airtableBookingsLink
        ? data.me.businessPartner.airtableBookingsLink.replace(regex, subst)
        : null;

    return (
      jockeyListView && (
        <>
          <h2 className={Styles.header}>{t('bookingsSummary')}</h2>
          <div className={Styles.iframeContainer}>
            <Loader size="md" className={Styles.iframeLoader} />
            <div
              className={Styles.bookingForm}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `<iframe title="${t(
                  'bookingsSummary',
                )}" class="airtable-embed" src="${jockeyListView}?backgroundColor=blue&viewControls=on" frameborder="0" onmousewheel="" width="100%" height="100%" style="background: transparent;"></iframe>`,
              }}
            />
          </div>
        </>
      )
    );
  };

  return (
    <Query query={GET_AIRTABLE_LINKS} withLoading={false} pollInterval={30}>
      {renderIframe}
    </Query>
  );
};

export default compose(withAuth(true), translate('jockey'))(BookingsScreen);
