// @flow

import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { CarPicker, ColorPicker, Input } from 'Components';
import { translate } from 'Hoc';

type CarFormPropsType = {
  t: string => string,
  values: CarFormType,
  errors: CarFormType,
  onChange: ({ [code: string]: string | boolean }) => void,
  componentClassName: string,
};

class CarFormFields extends Component<CarFormPropsType> {
  onChangeNumberPlate: string => void;
  onChangeColor: string => void;
  onChangeHasRoofBox: boolean => void;

  constructor(props: CarFormPropsType) {
    super(props);

    this.onChangeNumberPlate = this.onChange.bind(this, 'carNumberPlate');
    this.onChangeColor = this.onChange.bind(this, 'carColor');
    this.onChangeHasRoofBox = this.onChange.bind(this, 'carHasRoofBox');
  }

  onChangeCar = (car: {
    brand: string,
    model: string,
    motorization: string,
    variants: number,
    id: string,
  }) => {
    const { onChange } = this.props;
    onChange({
      utilsCarId: car.id,
    });
  };

  onChange = (field: string, value: string | boolean) => {
    const { onChange } = this.props;
    onChange({ [field]: value });
  };

  render() {
    const { t, values, componentClassName, errors } = this.props;

    return (
      <>
        <CarPicker
          className={componentClassName}
          onChange={this.onChangeCar}
          error={errors.utilsCarId}
          value={values.utilsCarId}
          isMandatory
        />
        <ColorPicker
          className={componentClassName}
          value={values.carColor || ''}
          onChange={this.onChangeColor}
          error={errors.carColor}
          isMandatory
        />
        <Input
          label={t('numberPlate')}
          className={componentClassName}
          placeholder={t('numberPlatePlaceholder')}
          value={values.carNumberPlate || ''}
          onChange={this.onChangeNumberPlate}
        />
        <Form.Row className="w-100" />
        <Form.Check
          label={t('hasRoofBox')}
          type="checkbox"
          onChange={e => this.onChangeHasRoofBox(e.target.checked)}
          checked={values.carHasRoofBox || false}
        />
      </>
    );
  }
}

export default translate('car_form')(CarFormFields);
