// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, Query } from 'Components';
import { Protect, Roles, translate } from 'Hoc';
import { Forms, FormsStateActions } from 'Reducers';
import { withAppSettings } from '../Context/AppSettings';
import { GET_SIDEMENU_INFOS } from './Gql/SideMenu';
import Styles from './Stylesheets/Sidemenu.module.scss';

type SidemenuPropsType = {
  className: ?string,
  visible: boolean,
  t: string => string,
  clearForm: string => void,
  appSettings: { clearPurchaseCanalHistory: () => void },
};
type RenderMenuProps = {
  error: boolean,
  data: {
    me: {
      businessPartner: {
        paymentMethodTypes: string[],
        enableJockey: boolean,
      },
    },
  },
};

const Sidemenu = (props: SidemenuPropsType) => {
  const handleClickCollaborators = () => {
    const { clearForm } = props;

    clearForm(Forms.collaboratorsFilter);
  };

  const handleClickBookings = () => {
    const { clearForm } = props;

    clearForm(Forms.bookingsFilter);
  };

  const handleClickBilling = () => {};

  const handleClickCreateBooking = () => {
    const {
      clearForm,
      appSettings: { clearPurchaseCanalHistory },
    } = props;

    clearForm(Forms.purchaseCanal);
    clearForm(Forms.pricing);
    clearForm(Forms.payment);
    clearPurchaseCanalHistory();
  };

  const { visible, className, t } = props;

  const renderItems = ({
    error,
    data = {
      me: { businessPartner: { paymentMethodTypes: [], enableJockey: false } },
    },
  }: RenderMenuProps) => {
    const {
      businessPartner: { paymentMethodTypes, enableJockey },
    } = data.me;
    const showJockey = !error && enableJockey;

    return (
      <Nav className={['flex-column', Styles.container, className].join(' ')}>
        <Link
          to="/dashboard"
          className={['text-white', Styles.menuItem].join(' ')}
          exact
          as={Nav.Link}
        >
          <span className="menu-icon-container">
            <FontAwesomeIcon icon="home" className="text-accent" />
          </span>
          {t('dashboard')}
        </Link>
        <Protect required={Roles.BusinessBookingCreate}>
          <Link
            to={showJockey ? '/jockey/bookings/create' : '/bookings/create'}
            className={['text-white', Styles.menuItem].join(' ')}
            exact
            as={Nav.Link}
            onClick={handleClickCreateBooking}
          >
            <span className="menu-icon-container">
              <FontAwesomeIcon icon="search" className="text-accent" />
            </span>
            {t('createBooking')}
          </Link>
        </Protect>
        <Protect required={Roles.BusinessBookingList}>
          <Link
            to={showJockey ? '/jockey/bookings' : '/bookings'}
            className={['text-white', Styles.menuItem].join(' ')}
            exact
            as={Nav.Link}
            onClick={handleClickBookings}
          >
            <span className="menu-icon-container">
              <FontAwesomeIcon icon="ticket-alt" className="text-accent" />
            </span>
            {t('bookings')}
          </Link>
        </Protect>
        <Protect required={Roles.BusinessCollaboratorList}>
          {!showJockey && (
            <Link
              to="/collaborators"
              className={['text-white', Styles.menuItem].join(' ')}
              exact
              as={Nav.Link}
              onClick={handleClickCollaborators}
            >
              <span>
                <FontAwesomeIcon icon="users" className="text-accent" />
              </span>
              {t('collaborators')}
            </Link>
          )}
        </Protect>
        <Protect required={Roles.BusinessBillingList}>
          {!error &&
            !showJockey &&
            paymentMethodTypes &&
            (paymentMethodTypes.includes('account') ||
              paymentMethodTypes.includes('airplus') ||
              paymentMethodTypes.includes('transfer') ||
              paymentMethodTypes.includes('transfer-eom')) && (
              <Link
                to="/billing"
                className={['text-white', Styles.menuItem].join(' ')}
                exact
                as={Nav.Link}
                onClick={handleClickBilling}
              >
                <span className="menu-icon-container">
                  <FontAwesomeIcon icon="file-invoice" className="text-accent" />
                </span>
                {t('billing')}
              </Link>
            )}
        </Protect>
      </Nav>
    );
  };

  return visible ? (
    <Query query={GET_SIDEMENU_INFOS} withLoading={false}>
      {renderItems}
    </Query>
  ) : null;
};

const mapDispatchToProps = dispatch => ({
  clearForm: formName => dispatch(FormsStateActions.clearForm(formName)),
});

export default compose(
  withAppSettings,
  translate('side_menu'),
  connect(null, mapDispatchToProps),
)(Sidemenu);
