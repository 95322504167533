// @flow

import { translate } from 'Hoc';
import { ucfirst } from 'Services';

const PaymentMethodItemTitle = ({
  paymentMethod,
  t,
}: {
  paymentMethod: PaymentMethodType,
  t: string => string,
}) => {
  const getCardTitle = () =>
    `${t('card')} ${ucfirst(paymentMethod.brand)} ${
      paymentMethod.name ? ` - ${paymentMethod.name}` : ''
    }`;

  return paymentMethod.type === 'card' ? getCardTitle() : t(paymentMethod.type);
};

export default translate('payment_method_item_title')(PaymentMethodItemTitle);
