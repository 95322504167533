// @flow

import React from 'react';

type BookingBusinessFieldValuePropsType = {
  booking: BookingType,
  utilsBusinessField: BusinessFieldType,
};

const BookingBusinessFieldValue = ({
  booking,
  utilsBusinessField,
}: BookingBusinessFieldValuePropsType) => {
  const bookingBusinessField = booking.businessFields.find(
    businessField => businessField.code === utilsBusinessField.code,
  );

  if (bookingBusinessField) {
    return <td>{bookingBusinessField.value}</td>;
  }

  return <td />;
};

export default BookingBusinessFieldValue;
