import { GET_USER } from 'Gql';
import { apolloClient } from './ApolloClient';

export const pursueLogin = async () => {
  const {
    data: { me = { businessPartner: { code: '' } } },
  } = await apolloClient.query({
    query: GET_USER,
  });

  return me.businessPartner.code;
};
