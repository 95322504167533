// @flow
import React from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { translate } from 'Hoc';

type PlaceAndDatesContentPropsType = {
  fromSpot: SpotType,
  toSpot: SpotType,
  t: (string, ?{}) => string,
  startAt: string,
  endAt: string,
  className: ?string,
};

type PlaceAndDatesContentContainerPropsType = PlaceAndDatesContentPropsType & {
  horizontal: ?boolean,
};

const PlaceAndDatesVerticalContent = ({
  t,
  fromSpot,
  toSpot,
  startAt,
  endAt,
  className,
}: PlaceAndDatesContentPropsType) => (
  <Col className={className}>
    <Row>
      <Col xs={3}>
        <p className="font-weight-bold">{t('in')}:</p>
      </Col>
      <Col>
        <p className="mb-0">{t('at', { at: fromSpot.name })}</p>
        <p className="mb-0">{`${t('date', {
          date: moment(startAt).format('dddd D MMM YYYY'),
        })} ${t('at', {
          at: moment(startAt).format('HH:mm'),
        })}`}</p>
      </Col>
    </Row>
    <Row className="mt-2">
      <Col xs={3}>
        <p className="font-weight-bold">{t('out')}:</p>
      </Col>
      <Col>
        <p className="mb-0">{t('at', { at: toSpot.name })}</p>
        <p className="mb-0">{`${t('date', {
          date: moment(endAt).format('dddd D MMM YYYY'),
        })} ${t('at', {
          at: moment(endAt).format('HH:mm'),
        })}`}</p>
      </Col>
    </Row>
  </Col>
);

const PlaceAndDatesHorizontalContent = ({
  t,
  fromSpot,
  toSpot,
  startAt,
  endAt,
  className,
}: PlaceAndDatesContentPropsType) => (
  <Row className={['w-100 d-flex', className].join(' ')}>
    <Col>
      <p className="font-weight-bold">{t('in')}</p>
      <p className="mb-0">{t('at', { at: fromSpot.name })}</p>
      <p className="mb-0">{`${t('date', {
        date: moment(startAt).format('dddd D MMM YYYY'),
      })} ${t('at', {
        at: moment(startAt).format('HH:mm'),
      })}`}</p>
    </Col>
    <Col>
      <p className="font-weight-bold">{t('out')}</p>
      <p className="mb-0">{t('at', { at: toSpot.name })}</p>
      <p className="mb-0">{`${t('date', {
        date: moment(endAt).format('dddd D MMM YYYY'),
      })} ${t('at', {
        at: moment(endAt).format('HH:mm'),
      })}`}</p>
    </Col>
  </Row>
);

const PlaceAndDatesContent = ({ horizontal, ...props }: PlaceAndDatesContentContainerPropsType) =>
  horizontal ? (
    <PlaceAndDatesHorizontalContent {...props} />
  ) : (
    <PlaceAndDatesVerticalContent {...props} />
  );

PlaceAndDatesContent.defaultProps = {
  horizontal: true,
  className: undefined,
};

export default translate('place_and_dates_content')(PlaceAndDatesContent);
