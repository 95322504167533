// @flow

import { gql } from '@apollo/client';

export const CANCEL_BOOKING = gql`
  mutation CancelBooking($id: ID!) {
    cancelBooking(id: $id) {
      id
      isRideInSigned
      isRideOutSigned
      isCancelled
      isRefunded
      hasCancellationInsurance
    }
  }
`;

export const GET_BOOKING = gql`
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      hasCancellationInsurance
      isRefundable
      partnerRefundDelay
      shouldRefundWithoutCancellationInsurance
    }
  }
`;
