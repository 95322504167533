// @flow
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

type StatCardPropsType = {
  children: React.Node,
  label: string,
  className: ?string,
};

const StatCard = ({ children, label, className, ...rest }: StatCardPropsType) => (
  <Col className={['bg-white p-2 rounded', className].join(' ')} {...rest}>
    <Row noGutters className="font-weight-bold">
      {label}
    </Row>
    {children}
  </Col>
);

export default StatCard;
