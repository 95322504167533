import { gql } from '@apollo/client';

export const GET_SPOTS = gql`
  query GetSpots {
    spots {
      name
      code
      priority
    }
  }
`;

export const GET_SPOTS_FROM_CODES = gql`
  query GetSpotsFromCodes($codes: [String]!) {
    getSpotsFromCodes(codes: $codes) @client {
      name
      code
    }
  }
`;
