// @flow
export const InitialState = {
  businessPartner: {},
  businessPartners: [],
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  roles: undefined,
  __typename: 'AppSettings',
};
