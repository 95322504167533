// @flow

import React, { Component } from 'react';
import firebase from 'firebase/app';
import LocalForage from 'localforage';
import { Col, Jumbotron, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import LoginMethodSelector from 'Containers/LoginMethodSelector';
import { SWAP_TOKEN } from 'Gql';
import { translate, withAuth, withReduxModals } from 'Hoc';
import { Modals } from 'Reducers';
import { ectorV4ApolloClient } from 'Services';
import NotSameLoginEmailModal from '../Components/NotSameLoginEmailModal';
import { pursueLogin } from '../Services/Login';
import {
  checkedEmailKey,
  getSsoToken,
  initFirebaseAndCheckRedirect,
  ssoLogout,
  SsoTokenErrors,
  updateCacheLoginSwapToken,
} from '../Services/SSO';
import Styles from './Stylesheets/LoginScreen.module.css';

type LoginScreenPropsType = {
  form: FormStateType,
  setLoginFormValue: (string, string) => void,
  setLoginFormError: (string, string) => void,
  login: (string, string) => void,
  isLoginFormValid: () => boolean,
  t: string => string,
  auth: AuthStateType,
  modals: ModalsStateType,
  showModal: (modalName: string) => void,
  hideModal: (modalName: string) => void,
  setPartnerHeaderCode: string => void,
};

class LoginScreen extends Component<LoginScreenPropsType> {
  async componentDidMount(): * {
    try {
      const { user } = await initFirebaseAndCheckRedirect();

      if (!user) {
        return;
      }

      const ssoToken = await getSsoToken();
      await ectorV4ApolloClient.mutate({
        mutation: SWAP_TOKEN,
        variables: {
          ssoToken,
        },
        update: this.loginWithSsoCacheUpdate,
      });
      const { setPartnerHeaderCode } = this.props;
      const partnerCode = await pursueLogin();
      await setPartnerHeaderCode(partnerCode);
    } catch (error) {
      await this.handleGetSsoTokenError(error);
    }
  }

  loginWithSsoCacheUpdate = async (cache, { data }) => {
    const firebaseUser = firebase && firebase.apps.length ? firebase.auth().currentUser : null;

    await updateCacheLoginSwapToken(cache, { data }, firebaseUser ? firebaseUser.email : '');
  };

  async handleGetSsoTokenError(error) {
    await LocalForage.removeItem(checkedEmailKey);

    const { showModal } = this.props;
    switch (error.message) {
      case SsoTokenErrors.NOT_SAME_EMAIL:
        showModal(Modals.notSameLoginEmail);
        await ssoLogout();
        break;
      case SsoTokenErrors.NO_TOKEN:
        console.warn('❌ No token available');
        break;

      default:
        console.warn('❌ Unhandled error');
    }
  }

  renderLoginForm = ({ className }) => {
    const { t } = this.props;

    return (
      <Jumbotron
        className={['bg-background', 'mr-sm-3', 'py-4', className, Styles.loginForm].join(' ')}
      >
        <h3 className="h2 text-primary font-weight-bold mb-4">{t('login_form:title')}</h3>
        <LoginMethodSelector />
      </Jumbotron>
    );
  };

  render() {
    const {
      auth: { isConnected },
      modals: { [Modals.notSameLoginEmail]: notSameLoginEmailModalVisible },
      hideModal,
    } = this.props;

    if (isConnected) {
      return <Redirect to="/" />;
    }

    return (
      <Row>
        <Col as={this.renderLoginForm} />
        <NotSameLoginEmailModal
          show={notSameLoginEmailModalVisible}
          onExit={() => hideModal(Modals.notSameLoginEmail)}
          onHide={() => hideModal(Modals.notSameLoginEmail)}
        />
      </Row>
    );
  }
}

export default compose(withAuth(), withReduxModals(), translate(['login_form']))(LoginScreen);
