// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import { Autosuggest } from 'Components';
import { translate } from 'Hoc';
import { cars } from 'Resources';

type CarPickerPropsType = {
  t: string => string,
  onChange: CarType => void,
  className: string,
  isMandatory?: boolean,
  error: string,
  value: string,
};

const CarPicker = ({ t, onChange, className, isMandatory, error, value }: CarPickerPropsType) => (
  <Form.Group controlId="color" className={className}>
    <Form.Label className={!isMandatory || 'font-weight-bold'}>
      {t('model')}
      {!isMandatory || '*'}
    </Form.Label>
    <Autosuggest
      className="align-self-start"
      data={cars}
      getItemLabel={item =>
        `${item.brand} ${item.model} ${item.variants > 1 ? `(${t(item.motorization)})` : ''}`
      }
      getItemValue={item => item.id}
      placeholder={t('modelPlaceholder')}
      onChange={onChange}
      error={t(error)}
      InputPrepend={<FontAwesomeIcon icon="search" />}
      value={value}
      name="carAutoSuggest"
    />
  </Form.Group>
);

CarPicker.defaultProps = {
  isMandatory: false,
  value: '',
};

export default translate(['car_picker', 'form_errors', 'car_motorization'])(CarPicker);
