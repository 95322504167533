// @flow

import { gql } from '@apollo/client';

export const UPDATE_COLLABORATOR = gql`
  mutation UpdateCollaborator(
    $id: ID!
    $email: String = null
    $firstName: String = null
    $lastName: String = null
    $phoneNumber: String = null
    $groupName: String = null
    $businessFields: [BusinessFieldInput] = []
  ) {
    updateCollaborator(
      id: $id
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      groupName: $groupName
      businessFields: $businessFields
    ) {
      id
      email
      firstName
      lastName
      phoneNumber
      businessGroupName
      businessFields {
        name
        code
        value
      }
    }
  }
`;

export const GET_USER_UTILS_BUSINESS_FIELDS = gql`
  query GetUserUtilsBusinessFields {
    me {
      businessPartner {
        code
        utilsBusinessFields(isFilterable: false, type: user) {
          name
          code
          regex
          isMandatory
          type
          linkedTo
          choices
        }
      }
    }
  }
`;
