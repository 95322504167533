// @flow

import React, { Component } from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { GlobalInvoiceMonth, Query } from 'Components';
import { protect, Roles, translate, withAuth, withReduxAlerts } from 'Hoc';
import { GET_GLOBAL_INVOICES } from './Gql/GlobalInvoiceList';

type BillingScreenPropsType = {
  t: string => string,
};

const getGlobalInvoicesByMonth = (globalInvoices: Array<GlobalInvoiceType>): Array<any> =>
  Object.values(
    globalInvoices.reduce((accumulator, globalInvoice) => {
      const date = moment(globalInvoice.month).format('MMMM YYYY');

      return {
        ...accumulator,
        [date]: {
          date: globalInvoice.month,
          globalInvoices: [
            ...(accumulator[date] && accumulator[date].globalInvoices
              ? accumulator[date].globalInvoices
              : []),
            globalInvoice,
          ],
        },
      };
    }, {}),
  );

class GlobalInvoiceList extends Component<BillingScreenPropsType> {
  renderListGlobalInvoice = (months: Array<GlobalInvoiceMonthType>) =>
    months.map(month => <GlobalInvoiceMonth month={month} key={month.date} />);

  renderListMonth = ({ error, data }) => {
    if (error || !data || !data.me) {
      return null;
    }

    const {
      me: {
        businessPartner: { globalInvoices },
      },
    } = data;
    const months = getGlobalInvoicesByMonth(globalInvoices);

    return <>{this.renderListGlobalInvoice(months)}</>;
  };

  render() {
    return <Query query={GET_GLOBAL_INVOICES}>{this.renderListMonth}</Query>;
  }
}

export default compose(
  translate(['global_invoice_list', 'gql_errors']),
  withAuth(true),
  protect(Roles.BusinessBillingList),
  withReduxAlerts(),
)(GlobalInvoiceList);
