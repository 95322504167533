// @flow

import React, { Component } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { compose } from 'redux';
import { Input, MutationButton } from 'Components';
import { translate, withReduxAlerts, withReduxForm } from 'Hoc';
import { Alerts, Forms } from 'Reducers';
import { UPDATE_COLLABORATOR } from './Gql/CollaboratorEditCard';
import s from './Stylesheets/UpdateContactPhoneNumberModal.module.css';

type UpdateContactPhoneNumberModalPropsType = {
  t: string => string,
  t2: string => string,
  onExit: () => void,
  onHide: () => void,
  showAlert: (string, string, string) => void,
  show: boolean,
  isUpdateContactPhoneNumberFormValid: UpdateContactPhoneNumberFormValuesType =>
    | string
    | typeof undefined,
  updateContactPhoneNumberForm: UpdateContactPhoneNumberType,
  setUpdateContactPhoneNumberFormValue: (string, string) => void,
  purchaseCanalForm: PurchaseCanalFormType,
  showAlert: (string, string, string) => void,
  clearUpdateContactPhoneNumberFormErrors: () => void,
};

type UpdateContactPhoneNumberModalStateType = {
  mutationError: ?ErrorType,
};

class UpdateContactPhoneNumberModal extends Component<
  UpdateContactPhoneNumberModalPropsType,
  UpdateContactPhoneNumberModalStateType,
> {
  constructor(props) {
    super(props);

    this.state = {
      mutationError: undefined,
    };
  }

  componentDidMount() {
    const { clearUpdateContactPhoneNumberFormErrors } = this.props;

    clearUpdateContactPhoneNumberFormErrors();
  }

  componentDidCatch(mutationError) {
    this.setState({ mutationError });
  }

  handleCloseAlert = () => this.setState({ mutationError: undefined });

  handleOnCompleted = () => {
    const { onExit, showAlert, t } = this.props;

    onExit();
    showAlert(
      Alerts.updateCollaboratorSucceeded,
      t('updateCollaboratorSucceededTitle'),
      t('updateCollaboratorSucceededMessage'),
    );
  };

  handleInputChange = phoneNumber => {
    const { setUpdateContactPhoneNumberFormValue, clearUpdateContactPhoneNumberFormErrors } =
      this.props;

    clearUpdateContactPhoneNumberFormErrors();
    setUpdateContactPhoneNumberFormValue('phoneNumber', phoneNumber);
  };

  handleClick = async updateContact => {
    const { updateContactPhoneNumberForm, purchaseCanalForm, isUpdateContactPhoneNumberFormValid } =
      this.props;
    const updateCollaboratorPhoneNumberMutationVariables = {
      id:
        purchaseCanalForm && purchaseCanalForm.values && purchaseCanalForm.values.userId
          ? purchaseCanalForm.values.userId
          : null,
      phoneNumber:
        updateContactPhoneNumberForm &&
        updateContactPhoneNumberForm.values &&
        updateContactPhoneNumberForm.values.phoneNumber
          ? updateContactPhoneNumberForm.values.phoneNumber
          : null,
    };

    const isFormValid = await isUpdateContactPhoneNumberFormValid(
      updateCollaboratorPhoneNumberMutationVariables,
    );

    if (isFormValid) {
      updateContact(updateCollaboratorPhoneNumberMutationVariables);
    }
  };

  render() {
    const { t, t2, show, onHide, onExit, purchaseCanalForm, updateContactPhoneNumberForm } =
      this.props;
    const { mutationError } = this.state;
    const updateCollaboratorPhoneNumberMutationVariables = {
      id:
        purchaseCanalForm && purchaseCanalForm.values && purchaseCanalForm.values.userId
          ? purchaseCanalForm.values.userId
          : null,
      phoneNumber:
        updateContactPhoneNumberForm &&
        updateContactPhoneNumberForm.values &&
        updateContactPhoneNumberForm.values.phoneNumber
          ? updateContactPhoneNumberForm.values.phoneNumber
          : null,
    };

    return (
      <Modal show={show} onHide={onHide} onExit={onExit}>
        <Modal.Header closeButton>
          <Modal.Title>{t('modalTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body onCompleted={onExit}>
          <Alert
            dismissible
            variant="danger"
            show={typeof mutationError !== 'undefined'}
            onClose={this.handleCloseAlert}
            className="m-2"
          >
            <Alert.Heading>{t('errorOccurred')}</Alert.Heading>
            <p>{mutationError ? mutationError.message : ''}</p>
          </Alert>
          {t2('modalText')}
          <Input
            onChange={this.handleInputChange}
            className={s.phoneInput}
            placeholder={t('phoneInputPlaceholder')}
            error={
              updateContactPhoneNumberForm &&
              updateContactPhoneNumberForm.errors &&
              updateContactPhoneNumberForm.errors.phoneNumber
                ? t(updateContactPhoneNumberForm.errors.phoneNumber)
                : ''
            }
            isMandatory
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <MutationButton
            type="submit"
            mutation={UPDATE_COLLABORATOR}
            variables={updateCollaboratorPhoneNumberMutationVariables}
            onCompleted={this.handleOnCompleted}
            onClick={this.handleClick}
          >
            {t('submit')}
          </MutationButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default compose(
  translate('update_contact_phone_number_modal'),
  withReduxForm(Forms.updateContactPhoneNumber),
  withReduxForm(Forms.purchaseCanal),
  withReduxAlerts(),
)(UpdateContactPhoneNumberModal);
