// @flow
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { VictoryLegend, VictoryPie } from 'victory';
import { translate } from 'Hoc';
import {
  blue,
  cyan,
  darkenBlue,
  darkenCyan,
  darkenGreen,
  darkenIndigo,
  darkenMidnightBlue,
  darkenOrange,
  darkenPink,
  darkenPurple,
  darkenRed,
  darkenTeal,
  darkenYellow,
  green,
  indigo,
  midnightBlue,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from 'Resources/scss/_ector_bootstrap.scss';

type FlashReportPieChartPropsType = {
  t: string => string,
  flashReport: FlashReportType,
};

const VictoriaPieColorScale = [
  blue,
  midnightBlue,
  indigo,
  purple,
  pink,
  red,
  orange,
  yellow,
  green,
  teal,
  cyan,
];

const PlaceholderPieEntries = [{ y: 35 }, { y: 21 }, { y: 18 }, { y: 15 }, { y: 6 }];

const PlaceholderVictoriaPieColorScale = [
  darkenBlue,
  darkenMidnightBlue,
  darkenIndigo,
  darkenPurple,
  darkenPink,
  darkenRed,
  darkenOrange,
  darkenYellow,
  darkenGreen,
  darkenTeal,
  darkenCyan,
];

const FlashReportPieChart = ({ flashReport, t }: FlashReportPieChartPropsType) => {
  const pieEntries = flashReport.countBookingsByZones.map(countBookingsByZone => ({
    name: `${countBookingsByZone.zone.name} (${countBookingsByZone.zone.code})`,
    y: countBookingsByZone.value,
    symbol: { type: 'square' },
  }));
  let victoryPieProps = {
    data: pieEntries,
    labelRadius: 90,
    padding: 20,
    labels: entry => entry.y,
    colorScale: VictoriaPieColorScale,
    style: { labels: { fill: 'white', fontSize: 20 } },
    sortOrder: 'descending',
    startAngle: -90,
    animate: { duration: 500 },
    height: 400,
  };

  if (pieEntries.length === 0) {
    victoryPieProps = {
      ...victoryPieProps,
      height: 300,
      labels: () => null,
      colorScale: PlaceholderVictoriaPieColorScale,
      data: PlaceholderPieEntries,
    };
  }

  return (
    <Row className="align-content-center position-relative" noGutters>
      <Col md={7}>
        <VictoryPie {...victoryPieProps} />
      </Col>
      {pieEntries.length > 0 ? (
        <VictoryLegend
          x={270}
          y={40}
          colorScale={VictoriaPieColorScale}
          data={pieEntries}
          style={{ parent: { position: 'absolute' }, labels: { fill: midnightBlue, fontSize: 11 } }}
        />
      ) : (
        <Col md={5} className="d-flex flex-column justify-content-center">
          {t('noEntries')}
        </Col>
      )}
    </Row>
  );
};

export default translate('flash_report_pie_chart')(FlashReportPieChart);
