// @flow
import * as React from 'react';
import type { Location } from 'react-router-dom';

type ScrollToTopPropsType = {
  location: Location,
  children: React.Node,
};

class ScrollToTop extends React.PureComponent<ScrollToTopPropsType> {
  componentDidUpdate(prevProps: ScrollToTopPropsType) {
    const { location: prevLocation } = prevProps;
    const { location: currentLocation } = this.props;

    if (prevLocation.key !== currentLocation.key) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default ScrollToTop;
