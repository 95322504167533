// @flow

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { Elements } from 'react-stripe-elements';
import { compose } from 'redux';
import { Button, OptionsRecapCard, Query } from 'Components';
import {
  CarRecapCard,
  PlaceAndDatesRecapCard,
  PricingSummaryCard,
  TravelerRecapCard,
  TravelRecapCard,
} from 'Containers';
import { translate, withAuth, withReduxAlerts, withReduxForm } from 'Hoc';
import { Alerts, Forms } from 'Reducers';
import { GET_BOOKING } from './Gql/UpdateBookingScreen';

type UpdateBookingScreenPropsType = {
  t: string => string,
  match: {
    params: {
      id: string,
    },
  },
  clearUpdateBookingForm: () => {},
  setUpdateBookingFormValue: (string, string) => void,
  alerts: AlertsStateType,
  hideAlert: string => void,
  history: RouterHistoryType,
};

type UpdateBookingScreenStateType = {
  mutationError: ?ErrorType,
  selectedCard: ?UpdateBookingCardNameType,
  isCarError: boolean,
};

class UpdateBookingScreen extends Component<
  UpdateBookingScreenPropsType,
  UpdateBookingScreenStateType,
> {
  handleCloseUpdateCarSucceedAlert: string => void;

  handleCloseUpdatePlaceAndDatesSucceedAlert: string => void;

  handleCloseUpdateTravelSucceedAlert: string => void;

  constructor(props) {
    super(props);
    const { clearUpdateBookingForm, hideAlert } = props;

    clearUpdateBookingForm();
    this.handleCloseUpdateCarSucceedAlert = hideAlert.bind(this, Alerts.bookingUpdateCarSucceed);
    this.handleCloseUpdatePlaceAndDatesSucceedAlert = hideAlert.bind(
      this,
      Alerts.bookingUpdatePlaceAndDatesSucceed,
    );
    this.handleCloseUpdateTravelSucceedAlert = hideAlert.bind(
      this,
      Alerts.bookingUpdateTravelSucceed,
    );
    this.state = {
      mutationError: undefined,
      isCarError: false,
      selectedCard: undefined,
    };
  }

  componentDidCatch(mutationError) {
    this.setState({
      isCarError:
        !!mutationError.message &&
        ['apiErrorNotFoundParking', 'apiErrorfindParkingError'].includes(mutationError.message),
      mutationError,
    });
    window.scrollTo(0, 0);
  }

  handleSelectCard = selectedCard => {
    const {
      match: {
        params: { id: bookingId },
      },
      setUpdateBookingFormValue,
    } = this.props;

    setUpdateBookingFormValue('id', bookingId);
    this.setState({ selectedCard });
  };

  handleCloseAlert = () => this.setState({ mutationError: undefined });

  handleGoBack = () => {
    const {
      history: { push },
    } = this.props;

    push('/bookings');
  };

  renderUpdateCards = ({ data: { getBooking } = { getBooking: null }, loading, error }) => {
    const { selectedCard } = this.state;

    if (!loading && !error && getBooking) {
      return (
        <Elements>
          <Col xs={8}>
            <PlaceAndDatesRecapCard
              booking={getBooking}
              editable
              selected={selectedCard === 'placeAndDates'}
              onSelect={this.handleSelectCard}
            />
            <TravelRecapCard
              booking={getBooking}
              editable
              selected={selectedCard === 'travel'}
              onSelect={this.handleSelectCard}
            />
            <TravelerRecapCard booking={getBooking} />
            <CarRecapCard
              booking={getBooking}
              editable
              selected={selectedCard === 'car'}
              onSelect={this.handleSelectCard}
            />
            <OptionsRecapCard booking={getBooking} />
          </Col>
        </Elements>
      );
    }

    return null;
  };

  render() {
    const {
      t,
      match: {
        params: { id: bookingId },
      },
      alerts: {
        [Alerts.bookingUpdateCarSucceed]: bookingUpdateCarSucceedAlert,
        [Alerts.bookingUpdatePlaceAndDatesSucceed]: bookingUpdatePlaceAndDatesSucceedAlert,
        [Alerts.bookingUpdateTravelSucceed]: bookingUpdateTravelSucceedAlert,
      },
    } = this.props;
    const { mutationError, isCarError } = this.state;

    return (
      <Container fluid>
        <Row as="h2" className="justify-content-center font-weight-bold my-2">
          {t('updateScreenTitle')}
        </Row>
        <Alert
          dismissible
          variant="danger"
          show={typeof mutationError !== 'undefined'}
          onClose={this.handleCloseAlert}
        >
          <Alert.Heading>
            {t(isCarError ? 'bookingDisallowedHeightTitle' : 'errorOccurred')}
          </Alert.Heading>
          <p>
            {isCarError
              ? t('bookingDisallowedHeightContent')
              : mutationError
              ? mutationError.message
              : ''}
          </p>
        </Alert>
        <Alert
          dismissible
          variant="success"
          show={bookingUpdateCarSucceedAlert.visible}
          onClose={this.handleCloseUpdateCarSucceedAlert}
        >
          <Alert.Heading>{bookingUpdateCarSucceedAlert.title}</Alert.Heading>
          <p>{bookingUpdateCarSucceedAlert.message}</p>
        </Alert>
        <Alert
          dismissible
          variant="success"
          show={bookingUpdatePlaceAndDatesSucceedAlert.visible}
          onClose={this.handleCloseUpdatePlaceAndDatesSucceedAlert}
        >
          <Alert.Heading>{bookingUpdatePlaceAndDatesSucceedAlert.title}</Alert.Heading>
          <p>{bookingUpdatePlaceAndDatesSucceedAlert.message}</p>
        </Alert>
        <Alert
          dismissible
          variant="success"
          show={bookingUpdateTravelSucceedAlert.visible}
          onClose={this.handleCloseUpdateTravelSucceedAlert}
        >
          <Alert.Heading>{bookingUpdateTravelSucceedAlert.title}</Alert.Heading>
          <p>{bookingUpdateTravelSucceedAlert.message}</p>
        </Alert>
        <Row>
          <Button variant="link" onClick={this.handleGoBack}>
            <FontAwesomeIcon icon="chevron-left" />
            <u className="ml-1">{t('goBack')}</u>
          </Button>
        </Row>
        <Row>
          <Query query={GET_BOOKING} variables={{ id: bookingId }}>
            {this.renderUpdateCards}
          </Query>
          <Col xs={4}>
            <PricingSummaryCard bookingId={bookingId} visible totalPriceKey="totalPrice" />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default compose(
  translate(['update_booking_screen', 'gql_errors', 'create_booking_screen']),
  withReduxForm(Forms.updateBooking, 'dispatch'),
  withAuth(true),
  withReduxAlerts(),
)(UpdateBookingScreen);
