// @flow

import React from 'react';
import { Autosuggest, Input } from 'Components';
import { translate } from 'Hoc';
import { translateBusinessField } from 'Services';

type UtilsBusinessFieldAutosuggestPropsType = {
  t: string => string,
  className: string,
  partnerCode: string,
  utilsBusinessField: UtilsBusinessFieldType,
  value: string,
  onChange: any => void,
};

type UtilsBusinessFieldAutosuggestStateType = {
  inputValue: string,
};

class UtilsBusinessFieldAutosuggest extends React.Component<
  UtilsBusinessFieldAutosuggestPropsType,
  UtilsBusinessFieldAutosuggestStateType,
> {
  constructor(props: UtilsBusinessFieldAutosuggestPropsType) {
    super(props);

    this.state = {
      inputValue: '',
    };

    this.handleAutosuggestChange = this.handleAutosuggestChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }

  getFilteredChoices = () => {
    const { utilsBusinessField } = this.props;
    const { inputValue } = this.state;

    return JSON.parse(utilsBusinessField.choices ? utilsBusinessField.choices : '[]')
      .filter(choiceCode => !inputValue || choiceCode.value.includes(inputValue))
      .map(choiceCode => ({
        label: choiceCode.label ? choiceCode.label : choiceCode.value,
        key: choiceCode.value,
        value: choiceCode.value,
      }));
  };

  handleInputValueChange = (inputValue: string) => {
    this.setState({ inputValue });
  };

  handleAutosuggestChange = choice => {
    const { onChange } = this.props;

    if (onChange) onChange(choice.value);
  };

  handleTextChange = inputValue => {
    const { onChange } = this.props;
    this.setState({ inputValue });

    if (onChange) onChange(inputValue);
  };

  render() {
    const { t, partnerCode, utilsBusinessField, className } = this.props;
    const { inputValue } = this.state;

    const fieldName = translateBusinessField(
      t,
      partnerCode,
      utilsBusinessField.code,
      'Name',
      utilsBusinessField.name,
    );

    switch (utilsBusinessField.type) {
      case 'choice':
        return (
          <Autosuggest
            className={className}
            placeholder={fieldName}
            data={this.getFilteredChoices()}
            onChange={this.handleAutosuggestChange}
            onInputValueChange={this.handleInputValueChange}
          />
        );

      case 'text':
      default:
        return (
          <Input
            type="text"
            className={className}
            placeholder={fieldName}
            aria-label={fieldName}
            onChange={this.handleTextChange}
            value={inputValue}
          />
        );
    }
  }
}

export default translate()(UtilsBusinessFieldAutosuggest);
