// @flow
import * as React from 'react';
import { SwitchCard } from 'Components';
import { CollaboratorCarSwitchCardContent } from 'Containers';
import { translate } from 'Hoc';

type CollaboratorCarSwitchCardPropsType = {
  t: string => string,
  children: React.Node,
  choice: SwitchCardChoiceType,
  choices: SwitchCardChoicesType,
  onChoiceChange: ('new' | 'existing') => void,
  collaboratorId: string,
  contactId: string,
  values: CarFormValuesType,
  errors: CarFormErrorsType,
  onChangeCarFormProperties: ({ [string]: string }) => void,
  hideButtons: boolean,
};

class CollaboratorCarSwitchCard extends React.Component<CollaboratorCarSwitchCardPropsType> {
  carTexts: SwitchCardTextsType;

  static defaultProps = {
    choices: {
      firstChoice: 'existing',
      secondChoice: 'new',
    },
    hideButtons: false,
  };

  constructor(props) {
    super(props);
    const { t } = props;

    this.carTexts = {
      title: t('carTitle'),
      firstChoice: t('existingCar'),
      secondChoice: t('newCar'),
    };
  }

  render() {
    const {
      children,
      choice,
      onChoiceChange,
      values,
      errors,
      onChangeCarFormProperties,
      collaboratorId,
      contactId,
      choices,
      hideButtons,
      ...rest
    } = this.props;

    return (
      <SwitchCard
        {...rest}
        className="w-100 border-0"
        texts={this.carTexts}
        value={choice}
        name="carChoice"
        choices={choices}
        onChange={onChoiceChange}
        hideButtons={hideButtons}
      >
        {children || (
          <CollaboratorCarSwitchCardContent
            choice={choice}
            values={values}
            errors={errors}
            onChangeCarFormProperties={onChangeCarFormProperties}
            collaboratorId={collaboratorId}
            contactId={contactId}
            onChoiceChange={onChoiceChange}
          />
        )}
      </SwitchCard>
    );
  }
}

export default translate('collaborator_car_switch_card')(CollaboratorCarSwitchCard);
