// @flow

import { gql } from '@apollo/client';

export const GET_BOOKING = gql`
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      allowed
      disallowedReason
      summaryPrices {
        totalPrice
      }
      latestBookingUpdate {
        id
        parkingFee
        administrativeFee
        routingFee
        yieldsFee
      }
    }
  }
`;
