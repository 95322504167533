// @flow

import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Col, Form, Row } from 'react-bootstrap';
import { compose } from 'redux';
import {
  BookingRecapCard,
  Button,
  FreeUpdatePlaceAndDatesModal,
  MutationButton,
  PaidUpdatePlaceAndDatesModal,
  PlaceAndDatesContent,
  PlaceAndDatesForm,
} from 'Components';
import { AppConfig } from 'Config';
import { translate, withReduxAlerts, withReduxForm, withReduxModals } from 'Hoc';
import { Alerts, Forms, Modals } from 'Reducers';
import { UpdateBookingFormSteps } from 'Services';
import { UPDATE_BOOKING } from './Gql/PlaceAndDatesRecapCard';

type PlaceAndDatesRecapCardPropsType = {
  t: (string, ?{ [string]: string }) => string,
  booking: BookingType,
  editable: boolean,
  updateBookingForm: UpdateBookingType,
  setUpdateBookingFormValue: UpdateBookingFormValuesType => void,
  setUpdateBookingFormValues: ({ [string]: ?string }) => void,
  isUpdateBookingFormValid: (UpdateBookingFormValuesType, string) => Promise<boolean>,
  showModal: string => void,
  modals: ModalsStateType,
  hideModal: string => void,
  showAlert: (string, string, string) => void,
  selected: boolean,
  onSelect: (?UpdateBookingCardNameType) => void,
  clearUpdateBookingForm: () => void,
};

class PlaceAndDatesRecapCard extends Component<PlaceAndDatesRecapCardPropsType> {
  handleFromSpotChange: string => void;

  handleToSpotChange: string => void;

  handleStartDateChange: string => void;

  handleStartTimeChange: string => void;

  handleEndDateChange: string => void;

  handleEndTimeChange: string => void;

  hideFreeUpdatePlaceAndDatesModal: () => void;

  hidePaidUpdatePlaceAndDatesModal: () => void;

  static defaultProps = {
    editable: false,
  };

  constructor(props) {
    super(props);
    const { setUpdateBookingFormValue, hideModal } = props;

    this.handleFromSpotChange = setUpdateBookingFormValue.bind(this, 'fromSpot');
    this.handleToSpotChange = setUpdateBookingFormValue.bind(this, 'toSpot');
    this.handleStartDateChange = setUpdateBookingFormValue.bind(this, 'startDate');
    this.handleStartTimeChange = setUpdateBookingFormValue.bind(this, 'startTime');
    this.handleEndDateChange = setUpdateBookingFormValue.bind(this, 'endDate');
    this.handleEndTimeChange = setUpdateBookingFormValue.bind(this, 'endTime');

    this.hideFreeUpdatePlaceAndDatesModal = hideModal.bind(this, Modals.freeUpdatePlaceAndDates);

    this.hidePaidUpdatePlaceAndDatesModal = hideModal.bind(this, Modals.paidUpdatePlaceAndDates);
  }

  handleEdit = () => {
    const { booking, setUpdateBookingFormValues, onSelect } = this.props;

    const startAt = moment(booking.startAt);
    const endAt = moment(booking.endAt);
    setUpdateBookingFormValues({
      fromSpot: booking.fromSpot.code,
      toSpot: booking.toSpot.code,
      startDate: startAt.format(AppConfig.format.date),
      startTime: startAt.format(AppConfig.format.time),
      endDate: endAt.format(AppConfig.format.date),
      endTime: endAt.format(AppConfig.format.time),
    });
    onSelect('placeAndDates');
  };

  handleCancel = () => {
    const { clearUpdateBookingForm, onSelect } = this.props;

    clearUpdateBookingForm();
    onSelect(undefined);
  };

  handleSubmit = async updateBooking => {
    const {
      isUpdateBookingFormValid,
      updateBookingForm: { values: updateBookingFormValues },
    } = this.props;
    const isValid = await isUpdateBookingFormValid(
      updateBookingFormValues,
      UpdateBookingFormSteps.placeAndDates,
    );

    if (!isValid) {
      return;
    }

    const { startTime, endTime, startDate, endDate, ...values } = updateBookingFormValues;
    const variables = {
      ...values,
      startAt: `${startDate || ''} ${startTime || ''}`,
      endAt: `${endDate || ''} ${endTime || ''}`,
    };

    updateBooking({ variables });
  };

  handlePreUpdateBookingCompleted = ({
    updateBooking = {
      latestBookingUpdate: { routingFee: 0, administrativeFee: 0, parkingFee: 0, yieldsFee: 0 },
    },
  }) => {
    const { showModal } = this.props;
    const {
      latestBookingUpdate: { routingFee, administrativeFee, parkingFee, yieldsFee },
    } = updateBooking;
    const bookingUpdateFees = routingFee + administrativeFee + parkingFee + yieldsFee;

    if (bookingUpdateFees === 0) {
      showModal(Modals.freeUpdatePlaceAndDates);
    } else {
      showModal(Modals.paidUpdatePlaceAndDates);
    }
  };

  showAlert(alert, title, message) {
    const { showAlert } = this.props;

    showAlert(alert, title, message);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }, 300);
  }

  handleFreeBookingUpdateCompleted = () => {
    const { t, onSelect } = this.props;

    this.showAlert(
      Alerts.bookingUpdatePlaceAndDatesSucceed,
      t('updatePlaceAndDatesSucceedTitle'),
      t('updatePlaceAndDatesSucceedMessage'),
    );
    onSelect(undefined);
    this.hideFreeUpdatePlaceAndDatesModal();
  };

  handlePaidBookingUpdateCompleted = () => {
    const { t, onSelect } = this.props;

    this.showAlert(
      Alerts.bookingUpdatePlaceAndDatesSucceed,
      t('updatePlaceAndDatesSucceedTitle'),
      t('updatePlaceAndDatesSucceedMessage'),
    );
    onSelect(undefined);
    this.hidePaidUpdatePlaceAndDatesModal();
  };

  renderFormGroupComponent = ({ children, className }) => (
    <Form.Group as={Col} className={className} xl={12}>
      {children}
    </Form.Group>
  );

  render() {
    const {
      booking,
      t,
      editable,
      selected,
      updateBookingForm: { values: updateBookingFormValues, errors: updateBookingFormErrors },
      modals: {
        [Modals.freeUpdatePlaceAndDates]: freeUpdatePlaceAndDatesModalVisible,
        [Modals.paidUpdatePlaceAndDates]: paidUpdatePlaceAndDatesModalVisible,
      },
    } = this.props;

    return (
      <>
        <FreeUpdatePlaceAndDatesModal
          show={freeUpdatePlaceAndDatesModalVisible}
          onHide={this.hideFreeUpdatePlaceAndDatesModal}
          onCompleted={this.handleFreeBookingUpdateCompleted}
        />
        <PaidUpdatePlaceAndDatesModal
          show={paidUpdatePlaceAndDatesModalVisible}
          onHide={this.hidePaidUpdatePlaceAndDatesModal}
          onCompleted={this.handlePaidBookingUpdateCompleted}
        />
        <BookingRecapCard
          title={t('placeAndDateTitle')}
          onSelect={this.handleEdit}
          selected={selected}
          editable={editable}
        >
          {selected ? (
            <>
              <PlaceAndDatesForm
                values={updateBookingFormValues}
                errors={updateBookingFormErrors}
                onFromSpotChange={this.handleFromSpotChange}
                onToSpotChange={this.handleToSpotChange}
                onStartDateChange={this.handleStartDateChange}
                onEndDateChange={this.handleEndDateChange}
                onStartTimeChange={this.handleStartTimeChange}
                onEndTimeChange={this.handleEndTimeChange}
                FormGroupComponent={this.renderFormGroupComponent}
                className="w-100"
                shouldDisablePickers
              />
              <Row className="d-flex w-100 justify-content-center mt-2">
                <Button
                  variant="link"
                  className="mr-2 font-weight-bold"
                  onClick={this.handleCancel}
                >
                  {t('cancelButton')}
                </Button>
                <MutationButton
                  mutation={UPDATE_BOOKING}
                  className="mr-2 font-weight-bold"
                  onClick={this.handleSubmit}
                  onCompleted={this.handlePreUpdateBookingCompleted}
                >
                  {t('submitButton')}
                </MutationButton>
              </Row>
            </>
          ) : (
            <PlaceAndDatesContent
              fromSpot={booking.fromSpot}
              toSpot={booking.toSpot}
              startAt={booking.startAt}
              endAt={booking.endAt}
            />
          )}
        </BookingRecapCard>
      </>
    );
  }
}

export default compose(
  withReduxForm(Forms.updateBooking),
  translate(['recap_booking_screen', 'form_errors', 'place_and_dates_recap_card', 'alerts']),
  withReduxModals(),
  withReduxAlerts('dispatch'),
)(PlaceAndDatesRecapCard);
