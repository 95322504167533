// @flow

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap';
import { translate } from 'Hoc';

type OptionsRecapCardPropsType = {
  t: string => string,
  booking: BookingType,
};

const renderService = service => (
  <Col xs={6} key={service.code}>
    <span className="text-nowrap">{service.name}</span>
    <FontAwesomeIcon className="ml-2" icon="check" />
  </Col>
);

const OptionsRecapCard = ({ t, booking }: OptionsRecapCardPropsType) => (
  <Card className="mb-3">
    <Card.Body>
      <Card.Title className="text-center mb-3">{t('optionsTitle')}</Card.Title>
      <Row xs={12} className="px-2">
        {booking.subscribedServices.length > 0
          ? booking.subscribedServices.map(renderService)
          : t('noOptionsSelected')}
      </Row>
    </Card.Body>
  </Card>
);

export default translate('recap_booking_screen')(OptionsRecapCard);
