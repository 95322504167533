// @flow

import { gql } from '@apollo/client';

export const GET_BOOKING_INVOICE = gql`
  mutation GetBookingInvoice($id: String) {
    getBookingInvoice(id: $id) {
      url
    }
  }
`;
