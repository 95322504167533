// @flow
import * as React from 'react';
import { Query, SwitchCard } from 'Components';
import { PaymentForm } from 'Containers';
import { translate } from 'Hoc';
import { GET_PAYMENT_METHODS } from './Gql/PaymentMethodSwitchCard';

type PaymentMethodSwitchCardPropsType = {
  t: string => string,
  choice: SwitchCardChoiceType,
  onChange: SwitchCardChoiceType => void,
  FirstComponent: ({
    paymentMethods: Array<PaymentMethodType>,
  }) => React.Element<*> | null,
  className: ?string,
  RootComponent: () => React.Element<*> | null,
  TitleComponent: () => React.Element<*> | null,
};

class PaymentMethodSwitchCard extends React.PureComponent<PaymentMethodSwitchCardPropsType> {
  static defaultProps = {
    FirstComponent: () => null,
  };

  handleGetPaymentMethodsCompleted = ({
    me: {
      businessPartner: { paymentMethods },
    },
  }) => {
    const { choice, onChange } = this.props;

    if (choice === 'existing' && paymentMethods.length === 0) {
      onChange('new');
    }
  };

  render() {
    const { choice, t, onChange, FirstComponent, RootComponent, TitleComponent, className } =
      this.props;

    return (
      <Query query={GET_PAYMENT_METHODS} onCompleted={this.handleGetPaymentMethodsCompleted}>
        {({ data = { me: { businessPartner: { paymentMethods: [] } } } }) => (
          <SwitchCard
            texts={{
              title: t('paymentMethodTitle'),
              firstChoice: t('paymentMethodExistingLabel'),
              secondChoice: t('paymentMethodNewLabel'),
            }}
            value={choice}
            name="paymentMethod"
            choices={{ firstChoice: 'existing', secondChoice: 'new' }}
            onChange={onChange}
            FirstComponent={
              <FirstComponent paymentMethods={data.me.businessPartner.paymentMethods} />
            }
            SecondComponent={<PaymentForm />}
            FirstChoiceComponent={
              data.me.businessPartner.paymentMethods.length === 0 ? () => null : undefined
            }
            SecondChoiceComponent={
              data.me.businessPartner.paymentMethods.length === 0 ? () => null : undefined
            }
            RootComponent={RootComponent}
            TitleComponent={TitleComponent}
            className={className}
          />
        )}
      </Query>
    );
  }
}

export default translate('payment_method_switch_card')(PaymentMethodSwitchCard);
