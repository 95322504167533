// @flow

import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { type Location, Route } from 'react-router-dom';
import { Elements } from 'react-stripe-elements';
import { compose } from 'redux';
import { PurchaseCanalSteps } from 'Config';
import {
  BookingInformationForm,
  BookingOptionsForm,
  BookingPaymentForm,
  CreateBookingCard,
  CreateBookingStepper,
  PricingSummaryCard,
} from 'Containers';
import { withAppSettings } from 'Context/AppSettings';
import { protect, Roles, translate, withAuth, withReduxForm } from 'Hoc';
import { Forms } from 'Reducers';

type CreateBookingScreenPropsType = {
  t: string => string,
  location: Location,
  appSettings: AppSettingsStateType,
  history: RouterHistoryType,
  appSettings: AppSettingsStateType,
  purchaseCanalForm: PurchaseCanalFormType,
};

class CreateBookingScreen extends Component<CreateBookingScreenPropsType> {
  handleStepChange = stepValue => {
    const {
      history,
      appSettings: { selectPurchaseCanalStep },
      purchaseCanalForm: {
        values: { bookingId },
      },
    } = this.props;
    let nextRoute = stepValue;

    if (stepValue !== PurchaseCanalSteps.placeAndDates && bookingId) {
      nextRoute = `${stepValue}?id=${bookingId}`;
    }
    history.push(nextRoute);
    selectPurchaseCanalStep(stepValue);
  };

  renderBookingPaymentForm = props => (
    <Elements>
      <BookingPaymentForm {...props} />
    </Elements>
  );

  render() {
    const {
      t,
      location: { pathname },
      purchaseCanalForm: {
        values: { bookingId },
      },
    } = this.props;

    return (
      <Container fluid>
        <Col xs={12} className="d-flex justify-content-center mt-4 mb-2">
          <h2 className="font-weight-bold">{t('createBookingScreenTitle')}</h2>
        </Col>
        <CreateBookingStepper path={pathname} onStepChange={this.handleStepChange} />
        <Route path="/bookings/create" component={CreateBookingCard} exact />
        <Row>
          <Col xs={8}>
            <Route path="/bookings/create/options" component={BookingOptionsForm} exact />
            <Route path="/bookings/create/informations" component={BookingInformationForm} exact />
            <Route
              path="/bookings/create/payment"
              component={this.renderBookingPaymentForm}
              exact
            />
          </Col>
          <Col xs={4}>
            <PricingSummaryCard
              bookingId={bookingId}
              visible={pathname !== PurchaseCanalSteps.placeAndDates}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default compose(
  withAuth(true),
  protect(Roles.BusinessBookingCreate),
  withAppSettings,
  withReduxForm(Forms.purchaseCanal, 'values'),
  translate('create_booking_screen'),
)(CreateBookingScreen);
