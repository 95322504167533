// @flow

import React, { Component } from 'react';
import { Alert, ButtonToolbar, Card } from 'react-bootstrap';
import { compose } from 'redux';
import { Button, Mutation, MutationButton, Query, ServiceList } from 'Components';
import { PurchaseCanalSteps } from 'Config';
import { withAppSettings } from 'Context/AppSettings';
import { protect, Roles, translate, withReduxForm } from 'Hoc';
import { Forms } from 'Reducers';
import { getParamQueryString } from 'Services';
import { GET_BOOKING, UPDATE_BOOKING_QUOTE } from './Gql/BookingOptionsForm';

type BookingOptionsFormPropsType = {
  t: string => string,
  history: RouterHistoryType,
  location: Location,
  purchaseCanalForm: PurchaseCanalFormType,
  setPurchaseCanalFormValue: (string, Array<ServiceType> | string) => void,
  appSettings: AppSettingsStateType,
};

type BookingOptionsFormStateType = {
  fetchingAddServices: Array<ServiceType>,
  fetchingRemoveServices: Array<ServiceType>,
  isAllowed: boolean,
};

class BookingOptionsForm extends Component<
  BookingOptionsFormPropsType,
  BookingOptionsFormStateType,
> {
  handleBackClick: () => void;

  handleCompleted: () => void;

  constructor(props) {
    super(props);
    const { history, location } = props;
    const id = getParamQueryString('id', location.search);

    if (!id) {
      history.push('/bookings/create');
    }

    this.handleBackClick = this.handleClick.bind(this, '/bookings/create');
    this.handleCompleted = this.handleClick.bind(this, `/bookings/create/informations?id=${id}`);
    this.state = {
      fetchingAddServices: [],
      fetchingRemoveServices: [],
      isAllowed: true,
    };
  }

  handleSelectService = (service: ServiceType, updateQuote) => {
    const {
      purchaseCanalForm: {
        values: { services, bookingId: id },
      },
      setPurchaseCanalFormValue,
    } = this.props;
    const { fetchingAddServices, fetchingRemoveServices } = this.state;
    let selectedServices = services.filter(
      subscribedService => service.code !== subscribedService.code,
    );

    if (selectedServices.length === services.length) {
      selectedServices = [...selectedServices, service];
      fetchingAddServices.push(service);
    } else {
      fetchingRemoveServices.push(service);
    }
    setPurchaseCanalFormValue('services', selectedServices);
    updateQuote({ variables: { services: selectedServices.map(srv => srv.code), id } });
  };

  handleClick(path) {
    const {
      history,
      appSettings: { selectPurchaseCanalStep },
    } = this.props;

    history.push(path);
    selectPurchaseCanalStep(PurchaseCanalSteps.travelerInformation);
  }

  handleUpdate = (cache, { data: { updateQuote } }) => {
    cache.writeQuery({
      query: GET_BOOKING,
      variables: { id: updateQuote.id },
      data: { getBooking: updateQuote },
    });
  };

  handleSelectServiceCompleted = ({
    updateQuote: {
      summaryPrices: { serviceUpdates },
      allowed,
      disallowedReason,
    },
  }) => {
    this.setState(prevState => ({
      isAllowed: allowed || disallowedReason !== 'parking' || !serviceUpdates.length,
      fetchingAddServices: prevState.fetchingAddServices.filter(
        fetchingService =>
          serviceUpdates.findIndex(
            subscribedService => subscribedService.code === fetchingService.code,
          ) < 0,
      ),
      fetchingRemoveServices: prevState.fetchingRemoveServices.filter(
        fetchingService =>
          serviceUpdates.findIndex(
            subscribedService => subscribedService.code === fetchingService.code,
          ) >= 0,
      ),
    }));
  };

  renderServicesList = ({
    data = {
      getBooking: {
        allowed: true,
        disallowedReason: null,
        availableServices: [],
        summaryPrices: { serviceUpdates: [] },
        shouldRefundWithoutCancellationInsurance: false,
      },
    },
  }) => {
    const {
      purchaseCanalForm: {
        values: { services },
      },
      t,
    } = this.props;
    const { fetchingAddServices, fetchingRemoveServices, isAllowed } = this.state;

    if (
      !data.getBooking.allowed &&
      data.getBooking.disallowedReason === 'parking' &&
      isAllowed &&
      data.getBooking.summaryPrices.serviceUpdates.length
    ) {
      this.setState({ isAllowed: false });
    }

    return (
      <>
        {data.getBooking.shouldRefundWithoutCancellationInsurance && (
          <Alert variant="accent" show>
            {t('shouldRefundWithoutCancellationInsuranceInfo')}
          </Alert>
        )}
        <Mutation
          mutation={UPDATE_BOOKING_QUOTE}
          variables={{ services }}
          onCompleted={this.handleSelectServiceCompleted}
        >
          {updateQuote => {
            const onClick = service => this.handleSelectService(service, updateQuote);

            return (
              <ServiceList
                services={data.getBooking.availableServices}
                selectedServices={data.getBooking.summaryPrices.serviceUpdates}
                fetchingServices={fetchingAddServices.concat(fetchingRemoveServices)}
                onClick={onClick}
              />
            );
          }}
        </Mutation>
      </>
    );
  };

  handleSubmit = async updateQuote => {
    const {
      purchaseCanalForm: {
        values: { services: subscribedServices, bookingId: id },
      },
    } = this.props;
    const services = subscribedServices.map(subscribedService => subscribedService.code);

    await updateQuote({ variables: { services, id } });
  };

  render() {
    const {
      t,
      purchaseCanalForm: {
        values: { bookingId: id },
      },
    } = this.props;
    const { isAllowed } = this.state;

    return (
      <>
        <Alert variant="danger" show={!isAllowed}>
          <Alert.Heading>{t('optionDisallowedTitle')}</Alert.Heading>
          <p>{t('optionDisallowedContent')}</p>
        </Alert>
        <Card>
          <Card.Body>
            <Card.Title className="text-center">{t('createOptionsScreenTitle')}</Card.Title>
            <Query query={GET_BOOKING} variables={{ id }}>
              {this.renderServicesList}
            </Query>
          </Card.Body>
        </Card>
        <ButtonToolbar className="justify-content-between my-2">
          <Button variant="link" onClick={this.handleBackClick} className="text-capitalize">
            {t('cancelOptionsScreenButton')}
          </Button>
          <MutationButton
            disabled={!isAllowed}
            mutation={UPDATE_BOOKING_QUOTE}
            type="submit"
            onClick={this.handleSubmit}
            onCompleted={this.handleCompleted}
            update={this.handleUpdate}
          >
            {t('saveOptionsScreenButton')}
          </MutationButton>
        </ButtonToolbar>
      </>
    );
  }
}

export default compose(
  protect(Roles.BusinessBookingCreate),
  withReduxForm(Forms.purchaseCanal),
  withAppSettings,
  translate('create_booking_screen'),
)(BookingOptionsForm);
