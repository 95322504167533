// @flow

import * as React from 'react';
import moment from 'moment';
import type Moment from 'moment';
import { Dropdown, InputGroup } from 'react-bootstrap';
import Styles from './Stylesheets/MonthPicker.module.css';

type MonthPickerPropsType = {
  selected: Moment,
  start: Moment,
  end: Moment,
  onChange: Moment => void,
  DropdownToggleComponent: ({ value: Moment }) => React.Element<*>,
  className: ?string,
  getMonths: (Moment, Moment) => Array<Moment>,
};

type MonthPickerStateType = {
  selected: Moment,
};

const getMonthsList = (start: Moment, end: Moment): Array<Moment> => {
  const months = [];

  const startDate = moment(start).startOf('month').startOf('day');
  const endDate = moment(end).startOf('month').startOf('day');
  let currentDate = startDate;
  while (currentDate.isBefore(endDate)) {
    months.push(currentDate.clone());
    currentDate = currentDate.add(1, 'month');
  }

  return months;
};

class MonthPicker extends React.Component<MonthPickerPropsType, MonthPickerStateType> {
  months: Array<Moment>;

  static getDerivedStateFromProps(props: MonthPickerPropsType) {
    if (props.selected) {
      return {
        selected: props.selected.clone(),
      };
    }

    return null;
  }

  static defaultProps = {
    start: moment().subtract(1, 'year'),
    end: moment().add(1, 'year'),
    onChange: () => {},
    DropdownToggleComponent: ({ value }: { value: Moment }) => (
      <Dropdown.Toggle
        drop="down"
        bsPrefix="dd-toggle"
        id="monthSelection"
        className="w-100 bg-white text-primary"
      >
        {value.format('MMMM YYYY')}
      </Dropdown.Toggle>
    ),
    className: undefined,
    getMonths: getMonthsList,
  };

  constructor(props: MonthPickerPropsType) {
    super(props);
    const { start, end, getMonths, onChange } = props;
    const selected = moment().startOf('month').startOf('day');

    this.months = getMonths(start, end);
    this.state = {
      selected,
    };
    onChange(selected);
  }

  handleChange(selected: Moment) {
    const { onChange } = this.props;

    this.setState({ selected });
    onChange(selected);
  }

  handleToggle = (visible: boolean) => {
    if (!visible) {
      return;
    }

    setTimeout(() => {
      const { selected } = this.state;
      const monthPicker = document.getElementById('monthPicker');

      if (!monthPicker) {
        return;
      }
      const monthDropdownItems = monthPicker.getElementsByClassName('dropdown-item');

      for (let i = 0; i < monthDropdownItems.length; i += 1) {
        const monthDropdownItem = monthDropdownItems[i];

        if (
          monthDropdownItem.className.includes('active') ||
          monthDropdownItem.innerText === selected.format('MMMM YYYY')
        ) {
          monthDropdownItem.focus();
          break;
        }
      }
    }, 150);
  };

  renderMonth = (date: Moment) => {
    const { selected } = this.state;
    const onSelect = () => this.handleChange(date);

    return (
      <Dropdown.Item
        onSelect={onSelect}
        key={date.toISOString()}
        active={selected.isSame(date, 'M')}
      >
        {date.format('MMMM YYYY')}
      </Dropdown.Item>
    );
  };

  render() {
    const { DropdownToggleComponent, className } = this.props;
    const { selected } = this.state;

    return (
      <InputGroup className={className} id="monthPicker">
        <input className="form-control invisible" />
        <Dropdown
          className={['position-absolute w-100', Styles.dropdown].join(' ')}
          onToggle={this.handleToggle}
        >
          <DropdownToggleComponent value={selected} />
          <Dropdown.Menu className={['w-100 mt-2', Styles.menu].join(' ')}>
            {this.months.map(this.renderMonth)}
          </Dropdown.Menu>
        </Dropdown>
      </InputGroup>
    );
  }
}

export default MonthPicker;
