// @flow

import React, { Component } from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { compose } from 'redux';
import { Input, MonthPicker, Query } from 'Components';
import { BookingsFilters } from 'Config';
import { CollaboratorAutosuggest, UtilsBusinessFieldFilter, ZoneAutosuggest } from 'Containers';
import { GET_UTILS_BUSINESS_FIELDS } from 'Gql';
import { translate, withReduxForm } from 'Hoc';
import { Forms } from 'Reducers';

type BookingScreenFiltersPropsType = {
  t: string => void,
  bookingsFilterForm: FormStateType,
  setBookingsFilterFormValue: (string, string) => void,
  getBookingFilterFormValue: () => void,
  onBookingsFilterChange: string => void,
};

const now = moment().startOf('month');

const start = moment(now).subtract(5, 'year');

const end = moment(now).add(1, 'year');

class BookingsScreenFilters extends Component<BookingScreenFiltersPropsType> {
  timeout: TimeoutID | null;

  constructor(props) {
    super(props);

    this.timeout = null;
  }

  handleMonthChange = month => {
    const { setBookingsFilterFormValue, onBookingsFilterChange } = this.props;

    setBookingsFilterFormValue('selectedMonth', month.format());

    if (now.isAfter(month)) {
      onBookingsFilterChange(BookingsFilters.past);
    } else {
      onBookingsFilterChange(BookingsFilters.current);
    }
  };

  handleShortIdChange = shortId => {
    const { setBookingsFilterFormValue } = this.props;

    setBookingsFilterFormValue('shortId', shortId);
  };

  handleZoneChange = zone => {
    const { setBookingsFilterFormValue } = this.props;

    setBookingsFilterFormValue('selectedZone', zone.code);
  };

  handleCollaboratorChange = (collaborator: CollaboratorType) => {
    const { setBookingsFilterFormValue } = this.props;

    setBookingsFilterFormValue('selectedCollaborator', collaborator.email);
  };

  handleChangeBusinessFieldFilter = (value: string, code: string) => {
    const {
      setBookingsFilterFormValue,
      bookingsFilterForm: { values },
    } = this.props;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      const filtersValue = {
        ...values.businessFields,
        [code]: value || '',
      };

      setBookingsFilterFormValue('businessFields', filtersValue);
    }, 1000);
  };

  renderUtilsBusinessFieldFilter = (partnerCode, utilsBusinessField) => {
    const {
      bookingsFilterForm: {
        values: { businessFields },
      },
    } = this.props;

    return (
      <UtilsBusinessFieldFilter
        key={utilsBusinessField.code}
        className="ml-2"
        partnerCode={partnerCode}
        utilsBusinessField={utilsBusinessField}
        value={businessFields ? businessFields[utilsBusinessField.code] : ''}
        onChange={choice => this.handleChangeBusinessFieldFilter(choice, utilsBusinessField.code)}
      />
    );
  };

  renderUtilsBusinessFieldsFilters = ({
    data: {
      me = {
        businessPartner: { code: '', utilsBusinessFields: [] },
      },
    },
  }) => {
    const {
      businessPartner: { code, utilsBusinessFields },
    } = me;

    return utilsBusinessFields.map(utilsBusinessField =>
      this.renderUtilsBusinessFieldFilter(code, utilsBusinessField),
    );
  };

  getCollaboratorAutosuggestValue = collaborator => collaborator.email;

  render() {
    const {
      t,
      bookingsFilterForm: { values },
    } = this.props;

    return (
      <Row noGutters>
        <Col xs={12} className="p-2 rounded bg-white">
          <span className="d-block">{t('filtersTitle')}</span>
          <Row className="pt-2">
            <MonthPicker
              className="ml-2 w-auto"
              start={start}
              end={end}
              selected={moment(values.selectedMonth)}
              onChange={this.handleMonthChange}
            />
            <Input
              type="text"
              className="ml-2"
              placeholder={t('shortIdPlaceholder')}
              aria-label={t('shortIdPlaceholder')}
              onChange={this.handleShortIdChange}
              value={values.shortId}
            />
            <ZoneAutosuggest
              className="ml-2"
              placeholder={t('zoneAutosuggestPlaceholder')}
              onChange={this.handleZoneChange}
              name="bookingListZoneAutosuggest"
            />
            <CollaboratorAutosuggest
              className="ml-2"
              placeholder={t('collaboratorAutosuggestPlaceholder')}
              onChange={this.handleCollaboratorChange}
              name="bookingListCollaboratorAutoSuggest"
              getItemValue={this.getCollaboratorAutosuggestValue}
            />
            <Query query={GET_UTILS_BUSINESS_FIELDS} variables={{ isFilterable: true }}>
              {this.renderUtilsBusinessFieldsFilters}
            </Query>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default compose(
  withReduxForm(Forms.bookingsFilter),
  translate('booking_screen'),
)(BookingsScreenFilters);
