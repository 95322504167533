import { gql } from '@apollo/client';

export const GET_UTILS_BUSINESS_FIELDS = gql`
  query GetUtilsBusinessFields($businessFieldType: String = null, $isFilterable: Boolean = false) {
    me {
      businessPartner {
        code
        utilsBusinessFields(isFilterable: $isFilterable, type: $businessFieldType) {
          name
          code
          regex
          isMandatory
          type
          linkedTo
          choices
          tooltip
        }
      }
    }
  }
`;
