// @flow

import React, { Component } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { compose } from 'redux';
import { Button, MutationButton, Query } from 'Components';
import { AppConfig } from 'Config';
import { translate, withReduxAlerts, withReduxForm } from 'Hoc';
import { Alerts, Forms } from 'Reducers';
import { CANCEL_BOOKING, GET_BOOKING } from './Gql/CancelBookingModal';

type CancelBookingModalPropsType = {
  t: (string, ?{ [key: string]: any }) => string,
  t2: (string, ?{ [key: string]: any }) => string,
  onExit: () => void,
  cancelBookingForm: FormStateType,
  showAlert: (string, string, string) => void,
  show: boolean,
  onHide: () => void,
};

type CancelBookingModalStateType = {
  mutationError: ?ErrorType,
};

class CancelBookingModal extends Component<
  CancelBookingModalPropsType,
  CancelBookingModalStateType,
> {
  constructor(props) {
    super(props);

    this.state = {
      mutationError: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    const { show: wasOpen } = prevProps;
    const { show: isOpen } = this.props;

    if (wasOpen && !isOpen) {
      this.setState({ mutationError: undefined });
    }
  }

  componentDidCatch(mutationError) {
    this.setState({ mutationError });
  }

  handleCloseAlert = () => this.setState({ mutationError: undefined });

  handleClick = ({ cancelBooking }) => {
    const { t, onExit, showAlert } = this.props;
    const { isRefunded } = cancelBooking;

    const description = isRefunded
      ? t('cancel_booking_modal:cancelBookingAndRefundSucceedAlertMessage')
      : t('cancel_booking_modal:cancelBookingSucceedAlertMessage');

    showAlert(Alerts.cancelBookingSucceed, t('cancelBookingSucceedAlertTitle'), description);
    onExit();
  };

  handleUpdate = (cache, { data: { cancelBooking } }) => {
    cache.writeQuery({
      query: GET_BOOKING,
      variables: { id: cancelBooking.id },
      data: { getBooking: cancelBooking },
    });
  };

  renderCancellationDescription = booking => {
    const { t2 } = this.props;
    const {
      hasCancellationInsurance,
      isRefundable,
      partnerRefundDelay,
      shouldRefundWithoutCancellationInsurance,
    } = booking;

    if (!hasCancellationInsurance && !isRefundable) {
      return t2('cancel_booking_modal:textWithOutCancellationInsurance');
    }

    if (isRefundable && shouldRefundWithoutCancellationInsurance) {
      return t2('cancel_booking_modal:textWithoutCancellationInsuranceRefundable');
    }

    return isRefundable
      ? t2('cancel_booking_modal:textWithCancellationInsurance')
      : t2('cancel_booking_modal:textWithCancellationInsuranceNotRefundable', {
          delay: partnerRefundDelay,
        });
  };

  renderModal = ({
    data = {
      getBooking: {
        hasCancellationInsurance: false,
        isRefundable: false,
        partnerRefundDelay: AppConfig.refundDelay,
        shouldRefundWithoutCancellationInsurance: false,
      },
    },
    error,
    loading,
  }) => {
    const {
      t,
      cancelBookingForm: {
        values: { id },
      },
      show,
      onHide,
      onExit,
    } = this.props;
    const { mutationError } = this.state;

    if (error || loading) {
      return null;
    }
    const { getBooking: booking } = data;

    return (
      <Modal {...{ show, onExit, onHide }}>
        <Modal.Header closeButton>
          <Modal.Title>{t('modalTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert
            dismissible
            variant="danger"
            show={typeof mutationError !== 'undefined'}
            onClose={this.handleCloseAlert}
            className="m-2"
          >
            <Alert.Heading>{t('errorOccurred')}</Alert.Heading>
            <p>{mutationError ? mutationError.message : ''}</p>
          </Alert>
          {this.renderCancellationDescription(booking)}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="link" onClick={onExit}>
            {t('backButton')}
          </Button>
          <MutationButton
            type="submit"
            mutation={CANCEL_BOOKING}
            variables={{ id }}
            onCompleted={this.handleClick}
            update={this.handleUpdate}
          >
            {t('submit')}
          </MutationButton>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    const {
      show,
      cancelBookingForm: {
        values: { id },
      },
    } = this.props;

    return show ? (
      <Query query={GET_BOOKING} variables={{ id }}>
        {this.renderModal}
      </Query>
    ) : null;
  }
}

export default compose(
  translate('cancel_booking_modal'),
  withReduxForm(Forms.cancelBooking),
  withReduxAlerts(),
)(CancelBookingModal);
