// @flow

import React from 'react';
import { Button } from 'react-bootstrap';
import Styles from './Stylesheets/BurgerButton.module.css';

type BurgerButtonPropsType = {
  visible: boolean,
  active: boolean,
  onClick: () => void,
  className: ?string,
};

export default ({ visible, active, onClick, className }: BurgerButtonPropsType) => (
  <Button
    className={[
      'hamburger',
      'hamburger--collapse',
      'border-0',
      active ? 'is-active' : undefined,
      visible ? undefined : 'd-none',
      className,
    ].join(' ')}
    onClick={onClick}
  >
    <span className={['hamburger-box', Styles.burgerBox].join(' ')}>
      <span className={['hamburger-inner', Styles.hamburgerInner].join(' ')} />
    </span>
  </Button>
);
