export const PaymentCards = {
  airplus: 'airplus',
  card: 'card',
};

export const BookingsFilters = {
  current: 'CURRENT',
  incoming: 'INCOMING',
  past: 'PAST',
  cancel: 'CANCEL',
};

export const PurchaseCanalSteps = {
  placeAndDates: '/bookings/create',
  options: '/bookings/create/options',
  travelerInformation: '/bookings/create/informations',
  payment: '/bookings/create/payment',
};
