// @flow

import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { compose } from 'redux';
import { BookingRecapCard, Button, MutationButton } from 'Components';
import { CarRecapCardContent } from 'Containers';
import { translate, withReduxAlerts, withReduxForm } from 'Hoc';
import { Alerts, Forms } from 'Reducers';
import { UpdateBookingFormSteps } from 'Services';
import { UPDATE_BOOKING } from './Gql/CarRecapCard';

type CarRecapCardPropsType = {
  t: string => string,
  booking: BookingType,
  editable: boolean,
  updateBookingForm: UpdateBookingType,
  clearUpdateBookingForm: () => void,
  setUpdateBookingFormValue: (string, string) => void,
  isUpdateBookingFormValid: (UpdateBookingFormValuesType, string) => Promise<boolean>,
  showAlert: (string, string, string) => void,
  selected: boolean,
  onSelect: (?UpdateBookingCardNameType) => void,
};

class CarRecapCard extends Component<CarRecapCardPropsType> {
  static defaultProps = {
    editable: false,
  };

  handleEdit = () => {
    const { setUpdateBookingFormValue, booking, onSelect } = this.props;

    if (booking && booking.car && booking.car.id) {
      setUpdateBookingFormValue('carId', booking.car.id);
    }
    onSelect('car');
  };

  handleCancel = () => {
    const { clearUpdateBookingForm, onSelect } = this.props;

    clearUpdateBookingForm();
    onSelect(undefined);
  };

  handleSubmit = async updateBooking => {
    const {
      updateBookingForm: { values: updateBookingFormValues },
      isUpdateBookingFormValid,
    } = this.props;
    const isFormValid = await isUpdateBookingFormValid(
      updateBookingFormValues,
      UpdateBookingFormSteps.car,
    );

    if (!isFormValid) {
      return;
    }

    await updateBooking({ variables: updateBookingFormValues });
  };

  handleUpdateBookingCompleted = () => {
    const { showAlert, t, onSelect, clearUpdateBookingForm } = this.props;

    showAlert(
      Alerts.bookingUpdateCarSucceed,
      t('updateCarSucceedTitle'),
      t('updateCarSucceedMessage'),
    );
    window.scrollTo(0, 0);
    onSelect(undefined);
    clearUpdateBookingForm();
  };

  render() {
    const { t, booking, editable, selected } = this.props;

    return (
      <BookingRecapCard
        title={t('carRecapTitle')}
        onSelect={this.handleEdit}
        selected={selected}
        editable={editable}
      >
        <CarRecapCardContent selected={selected} booking={booking} />
        {selected && (
          <Row className="d-flex w-100 justify-content-center mt-2">
            <Button variant="link" className="mr-2 font-weight-bold" onClick={this.handleCancel}>
              {t('cancelButton')}
            </Button>
            <MutationButton
              mutation={UPDATE_BOOKING}
              onCompleted={this.handleUpdateBookingCompleted}
              onClick={this.handleSubmit}
              refetchQueries={['GetCollaborator']}
            >
              {t('saveButton')}
            </MutationButton>
          </Row>
        )}
      </BookingRecapCard>
    );
  }
}

export default compose(
  translate(['recap_booking_screen', 'car_recap_card']),
  withReduxForm(Forms.updateBooking),
  withReduxAlerts('dispatch'),
)(CarRecapCard);
