// @flow

import React, { Component, Fragment } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Input } from 'Components';
import { translate } from 'Hoc';

type TravelerFormPropsType = {
  t: string => string,
  values: PurchaseCanalFormValuesType,
  errors: PurchaseCanalFormErrorsType,
  onChange: ({ [code: string]: string }) => void,
  componentClassName: string,
};

class TravelerFormFields extends Component<TravelerFormPropsType> {
  onChangeFirstName: string => void;

  onChangeLastName: string => void;

  onChangeEmail: string => void;

  onChangePhoneNumber: string => void;

  constructor(props: TravelerFormPropsType) {
    super(props);

    this.onChangeFirstName = this.onChange.bind(this, 'firstName');
    this.onChangeLastName = this.onChange.bind(this, 'lastName');
    this.onChangeEmail = this.onChange.bind(this, 'email');
    this.onChangePhoneNumber = this.onChange.bind(this, 'phoneNumber');
  }

  onChange = (field: string, value: string) => {
    const { onChange } = this.props;

    onChange({ [field]: value });
  };

  render() {
    const { t, values, errors, componentClassName } = this.props;

    return (
      <>
        <Form.Row className="w-100">
          <Col>
            <Input
              label={t('firstName')}
              className={componentClassName}
              placeholder={t('firstNamePlaceholder')}
              value={values.firstName || ''}
              error={errors.firstName}
              onChange={this.onChangeFirstName}
              isMandatory
            />
          </Col>
          <Col>
            <Input
              label={t('lastName')}
              className={componentClassName}
              placeholder={t('lastNamePlaceholder')}
              value={values.lastName || ''}
              error={errors.lastName}
              onChange={this.onChangeLastName}
              isMandatory
            />
          </Col>
        </Form.Row>
        <Form.Row className="w-100">
          <Col>
            <Input
              label={t('email')}
              className={componentClassName}
              type="email"
              placeholder={t('emailPlaceholder')}
              value={values.email || ''}
              error={errors.email}
              onChange={this.onChangeEmail}
              isMandatory
            />
          </Col>
          <Col>
            <Input
              label={t('phone')}
              className={componentClassName}
              type="phone"
              placeholder={t('phonePlaceholder')}
              value={values.phoneNumber || ''}
              error={errors.phoneNumber}
              onChange={this.onChangePhoneNumber}
              isMandatory
            />
          </Col>
        </Form.Row>
      </>
    );
  }
}

export default translate('traveler_form')(TravelerFormFields);
