// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Query } from 'Components';
import { GET_USER } from 'Gql';

type ProtectPropsType = {
  required: string,
  children: React.Node,
  FailureComponent: React.ComponentType<any>,
};

export const Roles = {
  BusinessCollaboratorList: 'ROLE_BUSINESS_COLLABORATOR_LIST',
  BusinessCollaboratorCreate: 'ROLE_BUSINESS_COLLABORATOR_CREATE',
  BusinessCollaboratorEdit: 'ROLE_BUSINESS_COLLABORATOR_EDIT',
  BusinessCollaboratorDisable: 'ROLE_BUSINESS_COLLABORATOR_DISABLE',
  BusinessCollaboratorExport: 'ROLE_BUSINESS_COLLABORATOR_EXPORT',
  BusinessBookingList: 'ROLE_BUSINESS_BOOKING_LIST',
  BusinessBookingCreate: 'ROLE_BUSINESS_BOOKING_CREATE',
  BusinessBookingEdit: 'ROLE_BUSINESS_BOOKING_EDIT',
  BusinessBookingExport: 'ROLE_BUSINESS_BOOKING_EXPORT',
  BusinessBookingCancel: 'ROLE_BUSINESS_BOOKING_CANCEL',
  BusinessBookingInvoice: 'ROLE_BUSINESS_BOOKING_INVOICE',
  BusinessBillingList: 'ROLE_BUSINESS_BILLING_LIST',
  BusinessBillingExport: 'ROLE_BUSINESS_BILLING_EXPORT',
  BusinessBillingInvoice: 'ROLE_BUSINESS_BILLING_INVOICE',
};

export const Protect = ({
  required,
  children,
  FailureComponent = () => null,
}: ProtectPropsType) => (
  <Query query={GET_USER} LoadingComponent={null}>
    {({ data = { me: { roles: [] } } }) => (
      <>{data.me.roles.includes(required) ? children : <FailureComponent />}</>
    )}
  </Query>
);

const protect =
  (required: string, redirectTo: string = '/') =>
  (Component: React.ComponentType<any>) =>
  (props: any) =>
    (
      <Protect required={required} FailureComponent={() => <Redirect to={redirectTo} />}>
        <Component {...props} />
      </Protect>
    );

export default protect;
