// @flow

import React, { Component, Fragment } from 'react';
import { Row } from 'react-bootstrap';
import { compose } from 'redux';
import { BookingRecapCard, Button, MutationButton, TravelerRecapContent } from 'Components';
import { TravelerSwitchCard } from 'Containers';
import { translate, withReduxForm } from 'Hoc';
import { Forms } from 'Reducers';
import { UpdateBookingFormSteps } from 'Services';
import { UPDATE_BOOKING } from './Gql/TravelerRecapCard';

type TravelerRecapCardPropsType = {
  t: string => string,
  booking: BookingType,
  editable: boolean,
  selected: boolean,
  updateBookingForm: UpdateBookingType,
  setUpdateBookingFormValue: (string, ?string) => void,
  setUpdateBookingFormValues: UpdateBookingFormValuesType => void,
  isUpdateBookingFormValid: (UpdateBookingFormValuesType, string) => Promise<boolean>,
  clearUpdateBookingForm: () => void,
};

type TravelerRecapCardStateType = {
  travelerChoice: SwitchCardChoiceType,
};

class TravelerRecapCard extends Component<TravelerRecapCardPropsType, TravelerRecapCardStateType> {
  state = {
    travelerChoice: 'existing',
  };

  componentDidUpdate(prevProps) {
    const { selected: wasSelected } = prevProps;
    const { selected: isSelected } = this.props;

    if (!wasSelected && isSelected) {
      this.setState({ travelerChoice: 'existing' });
    }
  }

  handleEdit = () => {
    const { setUpdateBookingFormValue, booking } = this.props;

    setUpdateBookingFormValue('contactId', booking.contact.id);
  };

  handleCancel = () => {
    const { clearUpdateBookingForm } = this.props;

    clearUpdateBookingForm();
  };

  handleSubmit = async () => {
    const {
      updateBookingForm: { values: updateBookingFormValues },
      isUpdateBookingFormValid,
    } = this.props;
    const { travelerChoice } = this.state;

    if (travelerChoice === 'new') {
      await isUpdateBookingFormValid(updateBookingFormValues, UpdateBookingFormSteps.traveler);
    }
  };

  handleTravelerChoiceChange = travelerChoice => {
    this.setState({ travelerChoice });
  };

  handleSelectTraveler = traveler => {
    const { setUpdateBookingFormValue } = this.props;

    if (traveler && traveler.mainContact) {
      setUpdateBookingFormValue('contactId', traveler.mainContact.id);
    } else {
      setUpdateBookingFormValue('contactId', undefined);
    }
  };

  renderSwitchCardRootComponent = ({ children }) => children;

  renderSwitchCardTitleComponent = () => null;

  render() {
    const {
      t,
      booking,
      editable,
      selected,
      updateBookingForm: { values: updateBookingFormValues, errors: updateBookingFormErrors },
      setUpdateBookingFormValues,
    } = this.props;
    const { travelerChoice } = this.state;

    return (
      <BookingRecapCard
        title={t('travelerRecapTitle')}
        onSelect={this.handleEdit}
        selected={selected}
        editable={editable}
      >
        {selected ? (
          <>
            <TravelerSwitchCard
              autosuggestValue={updateBookingFormValues.contactId}
              RootComponent={this.renderSwitchCardRootComponent}
              TitleComponent={this.renderSwitchCardTitleComponent}
              choice={travelerChoice}
              values={updateBookingFormValues}
              errors={updateBookingFormErrors}
              onChoiceChange={this.handleTravelerChoiceChange}
              onChangeTravelerFormProperties={setUpdateBookingFormValues}
              onSelectTraveler={this.handleSelectTraveler}
            />
            <Row className="d-flex w-100 justify-content-center mt-2">
              <Button variant="link" className="mr-2 font-weight-bold" onClick={this.handleCancel}>
                {t('cancelButton')}
              </Button>
              <MutationButton
                mutation={UPDATE_BOOKING}
                className="mr-2 font-weight-bold"
                onClick={this.handleSubmit}
              >
                {t('submitButton')}
              </MutationButton>
            </Row>
          </>
        ) : (
          <TravelerRecapContent traveler={booking.contact} />
        )}
      </BookingRecapCard>
    );
  }
}

export default compose(
  withReduxForm(Forms.updateBooking),
  translate('traveler_recap_card'),
)(TravelerRecapCard);
