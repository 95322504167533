// @flow
import React, { PureComponent } from 'react';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';

type DatePickerInputPropsType = {
  date: ?string,
  onFocusChange?: () => void,
  invalid?: boolean,
};

type DatePickerInputStateType = {
  date: ?string,
  focused: boolean,
};

class DatePickerInput extends PureComponent<DatePickerInputPropsType, DatePickerInputStateType> {
  static getDerivedStateFromProps(props: DatePickerInputPropsType) {
    const { date: dateFromProps } = props;
    const newState = {};

    if (!dateFromProps) {
      newState.date = null;

      return newState;
    }
    newState.date = moment(dateFromProps);

    if (!newState.date.isValid()) {
      newState.date = null;
    }

    return newState;
  }

  static defaultProps = {
    onFocusChange: () => {},
    invalid: false,
  };

  state = {
    date: null,
    focused: false,
  };

  handleFocusChange = ({ focused }: { focused: boolean }) => {
    const { onFocusChange } = this.props;

    this.setState({ focused });

    if (onFocusChange) {
      onFocusChange();
    }
  };

  render() {
    const { invalid, ...datePickerProps } = this.props;
    const { date, focused } = this.state;

    return (
      <div className={invalid ? 'is-invalid' : undefined}>
        <SingleDatePicker
          placeholder=""
          {...datePickerProps}
          date={date}
          focused={focused}
          onFocusChange={this.handleFocusChange}
          hideKeyboardShortcutsPanel
        />
      </div>
    );
  }
}

export default DatePickerInput;
