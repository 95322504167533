// @flow

import React from 'react';
import { GlobalInvoiceCard } from 'Components';

type GlobalInvoicesListItemPropsType = {
  globalInvoice: GlobalInvoiceType,
};

const GlobalInvoicesListItem = ({ globalInvoice }: GlobalInvoicesListItemPropsType) => (
  <GlobalInvoiceCard globalInvoice={globalInvoice} />
);

export default GlobalInvoicesListItem;
