// @flow
import React, { Component, Fragment } from 'react';
import { Alert, Card, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { MutationButton, PlaceAndDatesForm } from 'Components';
import { PurchaseCanalSteps } from 'Config';
import { withAppSettings } from 'Context/AppSettings';
import { protect, Roles, translate, withReduxAlerts, withReduxForm } from 'Hoc';
import { Alerts, Forms } from 'Reducers';
import withAuth from '../Hoc/withAuth';
import { CREATE_BOOKING_QUOTE, GET_BOOKING } from './Gql/CreateBookingForm';

type CreateBookingCardPropsType = {
  setPricingFormValue: (string, ?string) => void,
  clearPricingFormErrors: () => void,
  isPricingFormValid: TravelFormValuesType => boolean,
  t: string => string,
  pricingForm: PricingType,
  history: RouterHistoryType,
  showAlert: (string, string, string) => void,
  hideAlert: string => void,
  alerts: AlertsStateType,
  setPurchaseCanalFormValue: (string, string) => void,
  clearPurchaseCanalForm: () => void,
  appSettings: AppSettingsStateType,
  clearPaymentForm: () => void,
  me: { businessPartner: { code: string } },
};

type CreateBookingCardStateType = {
  mutationError: ?{
    message: string,
  },
  hasFormError: boolean,
};

class CreateBookingCard extends Component<CreateBookingCardPropsType, CreateBookingCardStateType> {
  handleCloseDisallowedBookingAlert: () => void;

  handleFromSpotChange: string => void;

  handleToSpotChange: string => void;

  handleStartTimeChange: string => void;

  handleEndTimeChange: string => void;

  handleStartDateChange: string => void;

  handleEndDateChange: string => void;

  constructor(props: CreateBookingCardPropsType) {
    super(props);
    const {
      appSettings: { selectPurchaseCanalStep },
      hideAlert,
      setPricingFormValue,
    } = props;

    this.handleFromSpotChange = setPricingFormValue.bind(this, 'fromSpot');
    this.handleToSpotChange = setPricingFormValue.bind(this, 'toSpot');
    this.handleStartTimeChange = setPricingFormValue.bind(this, 'startTime');
    this.handleEndTimeChange = setPricingFormValue.bind(this, 'endTime');
    this.handleStartDateChange = setPricingFormValue.bind(this, 'startDate');
    this.handleEndDateChange = setPricingFormValue.bind(this, 'endDate');
    this.handleCloseDisallowedBookingAlert = hideAlert.bind(this, Alerts.bookingDisallowed);

    this.state = {
      mutationError: undefined,
      hasFormError: false,
    };
    selectPurchaseCanalStep(PurchaseCanalSteps.placeAndDates);
    this.handleCloseDisallowedBookingAlert();
  }

  componentDidCatch(mutationError) {
    this.setState({ mutationError, hasFormError: undefined });
  }

  handleCloseErrorAlert = () => {
    this.setState({
      mutationError: undefined,
      hasFormError: false,
    });
  };

  handleSubmit = async createBookingQuote => {
    const {
      isPricingFormValid,
      pricingForm: { values },
      clearPricingFormErrors,
      me: { businessPartner },
    } = this.props;

    this.setState({ hasFormError: false, mutationError: undefined });
    clearPricingFormErrors();
    const isFormValid = await isPricingFormValid(values);

    if (isFormValid) {
      createBookingQuote({
        variables: {
          startAt: `${values.startDate || ''} ${values.startTime || ''}`,
          endAt: `${values.endDate || ''} ${values.endTime || ''}`,
          fromSpot: values.fromSpot,
          toSpot: values.toSpot,
          ...(businessPartner ? { partner: businessPartner.code } : {}),
        },
      });
      this.setState({ hasFormError: false });
    } else {
      this.setState({ hasFormError: true });
    }
  };

  handleUpdate = (cache, { data: { createQuote } }) => {
    cache.writeQuery({
      query: GET_BOOKING,
      variables: { id: createQuote.id },
      data: { getBooking: createQuote },
    });
  };

  handleCompletion = async ({ createQuote }) => {
    const {
      history,
      showAlert,
      t,
      setPurchaseCanalFormValue,
      clearPurchaseCanalForm,
      appSettings: { selectPurchaseCanalStep, clearPurchaseCanalHistory },
      clearPaymentForm,
    } = this.props;
    const { allowed: bookingAllowed, id: bookingId } = createQuote;

    if (bookingAllowed) {
      clearPurchaseCanalHistory();
      clearPurchaseCanalForm();
      clearPaymentForm();
      setPurchaseCanalFormValue('bookingId', createQuote.id);
      selectPurchaseCanalStep(PurchaseCanalSteps.placeAndDates);
      selectPurchaseCanalStep(PurchaseCanalSteps.options);
      history.push(`/bookings/create/options?id=${bookingId}`);
    } else {
      showAlert(
        Alerts.bookingDisallowed,
        t('disallowedBookingAlertTitle'),
        t('disallowedBookingAlertMessage'),
      );
    }
  };

  handleDateInputFocusChange = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 500,
        behavior: 'auto',
      });
    }, 300);
  };

  render() {
    const {
      pricingForm: { values: pricingFormValues, errors: pricingFormErrors },
      t,
      alerts: { [Alerts.bookingDisallowed]: bookingDisallowedAlert },
    } = this.props;
    const { mutationError, hasFormError } = this.state;

    return (
      <>
        <Card>
          <Card.Body>
            <Card.Title className="text-center">{t('createBookingScreenTitle')}</Card.Title>
            <Alert
              dismissible
              variant="danger"
              show={typeof mutationError !== 'undefined' || hasFormError}
              onClose={this.handleCloseErrorAlert}
            >
              <Alert.Heading>{t('errorOccurred')}</Alert.Heading>
              <p>{mutationError ? mutationError.message : ''}</p>
              <p>{t('hasFormError')}</p>
            </Alert>
            <Alert
              dismissible
              variant="danger"
              show={bookingDisallowedAlert.visible}
              onClose={this.handleCloseDisallowedBookingAlert}
            >
              <Alert.Heading>{bookingDisallowedAlert.title}</Alert.Heading>
              <p>{bookingDisallowedAlert.message}</p>
            </Alert>
            <PlaceAndDatesForm
              values={pricingFormValues}
              errors={pricingFormErrors}
              onFromSpotChange={this.handleFromSpotChange}
              onToSpotChange={this.handleToSpotChange}
              onStartDateChange={this.handleStartDateChange}
              onEndDateChange={this.handleEndDateChange}
              onStartTimeChange={this.handleStartTimeChange}
              onEndTimeChange={this.handleEndTimeChange}
              onDateInputFocusChange={this.handleDateInputFocusChange}
            />
          </Card.Body>
        </Card>
        <Row className="justify-content-center my-2">
          <MutationButton
            size="lg"
            mutation={CREATE_BOOKING_QUOTE}
            type="submit"
            onClick={this.handleSubmit}
            onCompleted={this.handleCompletion}
            update={this.handleUpdate}
          >
            {t('submit')}
          </MutationButton>
        </Row>
      </>
    );
  }
}

export default compose(
  withRouter,
  withAuth(),
  protect(Roles.BusinessBookingCreate),
  withAppSettings,
  withReduxForm(Forms.pricing),
  withReduxForm(Forms.purchaseCanal, 'dispatch'),
  withReduxForm(Forms.payment, 'dispatch'),
  withReduxAlerts(),
  translate(['create_booking_form', 'form_errors', 'gql_errors']),
)(CreateBookingCard);
